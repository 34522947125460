import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import NestedList from './listMenu';
import { routers } from 'router/router';
import { useHistory, useLocation } from 'react-router';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import CounselorListMenu from './CounselorListMenu';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import {
  setCheckModule,
  setParent,
} from 'app/basicInformationSlice/basicInformationSlice';
import Cookies from 'js-cookie';
import counselorServices from 'services/counselorServices';
import { checkEnableParseJSON } from 'components/helper/helper';
interface Props {
  hideList?: boolean;
}

const SideBar = (props: Props) => {
  const history = useHistory();
  let location = useLocation();
  const checkModule = useAppSelector((store) => store.basicInformation.checkModule);
  const dispath = useAppDispatch();
  let id = Cookies.get('userId');

  const role = localStorage.getItem('userRole');


  useEffect(() => {
    if(role === 'counselor') { 
      counselorServices.getPartner(Number(id)).then((res: any) => {
        dispath(setParent(res));
      });
    } 
    else {
      counselorServices.getCounselor(Number(id)).then((res: any) => {
        const loadedCheckModule = checkEnableParseJSON(res[0]?.notes_array, {
          checkModule,
          notes: [],
        })?.checkModule;
        dispath(setCheckModule(loadedCheckModule));
      });
    }
    
  }, [dispath]);
  
  return (
    <div className="w-100 h-100 d-flex">
      <div className="gm-sidebar-toolbar">
        <OverlayTrigger
          placement={'right'}
          overlay={
            <Tooltip id={`tooltip-dashboard`}>
              <strong>Dashboard</strong>
            </Tooltip>
          }
        >
          <Button
            className={`py-3 border-top rounded-0 ${
              location.pathname.includes('/dashboard')
                ? 'bg-primary text-white'
                : 'text-field-light'
            }`}
            variant="outline"
            onClick={() =>
              history.push({
                pathname: routers.dashBoard.path,
                search: `?role=${localStorage.getItem('userRole')}`,
              })
            }
          >
            <LaunchOutlinedIcon className="my-3" />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement={'right'}
          overlay={
            <Tooltip id={`tooltip-application`}>
              <strong>Application</strong>
            </Tooltip>
          }
        >
          <Button
            className={`py-3 border-top rounded-0 ${
              location.pathname.includes('/application')
                ? 'bg-primary text-white'
                : 'text-field-light'
            }`}
            variant="outline"
            onClick={() =>
              role === 'counselor'
                ? history.push(routers.viewStudent.path)
                : history.push(routers.basicInformation.path)
            }
          >
            <ContentPasteOutlinedIcon className="my-3" />
          </Button>
        </OverlayTrigger>
      </div>
      {!props.hideList && (
        <div className="w-100">{role === 'counselor' ? <CounselorListMenu /> : <NestedList />}</div>
      )}
    </div>
  );
};

export default SideBar;
