/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Avatar } from '@mui/material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { routers } from 'router/router';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { authenticateSettings } from 'components/helper/appConfig';
import basicInformationServices from 'services/basicInformationApi';
import { FileToBase64 } from 'components/helper/helper';
import { USER_ROLE } from 'constants/common';
import { catName } from 'components/helper/helper';
import { getCurrentBasicDetail, getStripeSubscription, getStripeSubscriptionInfo, logout } from 'app/basicInformationSlice/basicInformationSlice';
import { getStudent } from 'app/authorization/authorizationSlice';
import toast from 'react-hot-toast';

interface Props {
  title: string;
}
const Header = (props: Props) => {
  const dispatch = useAppDispatch();
  let history = useHistory();
  const [profilePicture, setProfilePicture] = React.useState<any>();
  const [profileDescript, setprofileDescript] = React.useState('');
  const personalStore = useAppSelector((store) => store.basicInformation.myBasicInformation);
  const [displayName, setDisplayName] = React.useState('');

  React.useEffect(() => {
    const profilePictureName = personalStore?.profile_picture_file_name ?? null;
    const userRole: string = (personalStore?.user_role ?? '').toLowerCase()?.trim();
    if (profilePictureName) {
      basicInformationServices.getProfilePicture(profilePictureName).then(async (res) => {
        const blob = new Blob([res], { type: 'image/png;charset=utf-8' });
        const url = await FileToBase64(blob);
        setProfilePicture(url);
      });
    }

    if (userRole) {
      setprofileDescript(userRole);
      if (userRole === USER_ROLE.student.toLowerCase()) {
        let s =
          personalStore && personalStore?.year_finish_high_school
            ? `Class of ${personalStore?.year_finish_high_school}`
            : '';
        setprofileDescript(s);
      } else if (userRole === USER_ROLE.counselor.toLowerCase()) {
        setprofileDescript('Counselor');
      } else {
        setprofileDescript(`Role: ${userRole}.`);
      }
    }
    setDisplayName(
      catName(personalStore?.first_name, personalStore?.middle_name, personalStore?.last_name)
    );
  }, [personalStore?.first_name, personalStore?.last_name, personalStore?.middle_name]);

  const handleLogout = (showMsg:boolean) => {
    dispatch(logout());
    if (showMsg) {
      toast.success("You have been successfully logged out!");
    }
    history.push(routers.login.path);
  };
  
  async function getData() {
    let id = Cookies.get('userId');
    let token = Cookies.get(authenticateSettings.tokenName);
    try {
      if (token && id) {
        if (personalStore?.status !== 'loading') {
          try {
            await dispatch(getStudent(id)).unwrap();
            await dispatch(getCurrentBasicDetail(id)).unwrap();
            await dispatch(getStripeSubscription(id)).unwrap();
            await dispatch(getStripeSubscriptionInfo(id)).unwrap();
          }
          catch(ex){
            console.log('error:', ex);
            // force logout
            handleLogout(false);
          }
        }
    }
    else {
      // force logout
      handleLogout(false);
    }
    }
    catch(ex){
      console.log('Error: ', ex);      
    }

  }

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalStore?.first_name, personalStore?.last_name, personalStore?.middle_name]);

  return (
    <div className="gm-topbar">
      <div className="w-50 d-flex gm-topbar-height">
        <img
          src={'/assets/images/logo-color.svg'}
          alt="GradMap logo"
          style={{ alignSelf: 'center' }}
          height={48}
          className="d-inline mx-3 pl-3 pointer"
          onClick={() =>
            history.push({
              pathname: routers.dashBoard.path,
              search: `?role=${localStorage.getItem('userRole')}`,
            })
          }
        />
        <div className="d-inline border-end" style={{ width: '1px' }}></div>
        <div
          className="d-inline text-field-light h4 text-center pl-3"
          style={{ fontWeight: 600, alignSelf: 'center', margin: '8px' }}
        >
          {props.title}
        </div>
      </div>
      <div className="w-50 d-flex justify-content-end align-items-center">
        <span className="mx-3">
          <Button variant="outline-dart" className="border rounded" size="sm">
            <NotificationsOutlinedIcon />
          </Button>
        </span>
        <span>
          <Avatar
            className="me-md-1"
            alt="sb-avatar"
            src={profilePicture}
            sx={{ width: 40, height: 40 }}
          />
        </span>
        <span>
          <Dropdown as={ButtonGroup} size="sm">
            <Dropdown.Toggle variant="white" id="dropdown-split-basic">
              <div className="d-flex">
                <div className="text-start text-left">
                  {/* {displayEmail} */}
                  <span>{displayName}</span>
                  <div className="text-field-light">{profileDescript}</div>
                </div>
                <div className="icon-arrowdown">
                  <ArrowDropDownIcon sx={{ color: '#231F20' }}></ArrowDropDownIcon>
                </div>
                <div className="icon-arrowup">
                  <ArrowDropUpIcon sx={{ color: '#231F20' }}></ArrowDropUpIcon>
                </div>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => history.push(routers.settings.path)}>
                <SettingsIcon fontSize="small" className="mx-1" />
                Settings
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleLogout(true)}>
                <PowerSettingsNewIcon fontSize="small" className="mx-1" />
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </span>
      </div>
      <style scoped>{`
      .dropdown-toggle::after {
        display: none !important;
      }
      `}</style>
    </div>
  );
};

export default Header;
