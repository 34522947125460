import { checkEnableParseJSON, formatDate } from 'components/helper/helper';
import React from 'react';
import ItemContent from './ItemContent';
import SimpleTable from 'components/table/SimpleTable';

interface Props {
  data: any;
}

const SATTestSum = ({ data }: Props) => {

  return (
    <>
      <div className="border my-3 w-100" />
      <div className="text-field-light fs-5 my-3">SAT Tests</div>
      <ItemContent values={data?.csu_info?.collegeBoardId} label="College Board ID" />
      <ItemContent
        label="Number of past SAT scores you wish to report*"
        values={data?.number_of_past_sat_scores}
      />
      <ItemContent label="Have you taken the SAT essay?" values={data?.taken_sat_essay_option} />
      {/* <ItemContent
        label="Do you have any SAT Scores you wish to report?"
        values={data.is_have_sat_scores_report}
        mdCol={12}
      /> */}
      <SimpleTable
        column={[
          { label: 'Test Date', value: 'test_date', formatDate: true },
          { label: 'Total Score', value: 'total_score' },
          { label: 'R/W', value: 'reading_writing_score' },
          { label: 'Math', value: 'math_score' },
          {...(data?.taken_sat_essay_option === "true" || data?.taken_sat_essay_option === true)? { label: 'Essay Score', value: 'essay_scores' }: {}},
          {...(data?.taken_sat_essay_option === "true" || data?.taken_sat_essay_option === true) ? { label: 'Essay Reading', value: 'essay_reading' }: {}},
          {...(data?.taken_sat_essay_option === "true" || data?.taken_sat_essay_option === true) ? { label: 'Essay Analysis', value: 'essay_analysis' }: {}},
          {...(data?.taken_sat_essay_option === "true" || data?.taken_sat_essay_option === true) ? { label: 'Essay Writing', value: 'essay_writing' } : {}},
        ]}
        data={data?.csu_info?.sat_score}
      />

      {/* {data?.is_have_sat_scores_report === true && (
        <> */}
          <div className="m-2 text-field-light fs-5">Super Score</div>
          <ItemContent
            mdCol={4}
            values={data?.highest_evidence_reading_writing_score}
            secondValues={formatDate(data?.evidence_reading_writing_date)}
            label="Highest R/W score"
          />
          <ItemContent
            mdCol={4}
            values={data?.highest_math_score_based_evidence_reading_writing_score}
            secondValues={formatDate(data?.math_reading_writing_date)}
            label="Highest Math Score"
          />
          {(data?.taken_sat_essay_option === "true" || data?.taken_sat_essay_option === true) && <ItemContent
            mdCol={4}
            values={data?.combined_essay_score}
            secondValues={formatDate(data?.combined_essay_date)}
            label="Highest Comb. Essay Score"
          />}

          <div className="m-2 text-field-light fs-5" id="FutureSAT">
            Future SATs
          </div>
          <ItemContent
            label="Number of future SAT sittings you expect*"
            values={data?.future_sat_tests_plan_to_take}
          />
          <ItemContent
            label="Future testing date 1"
            values={[formatDate(checkEnableParseJSON(data.future_testing_date_1)?.test1)]}
          />
          <ItemContent
            label="Future testing date 2"
            values={[formatDate(checkEnableParseJSON(data.future_testing_date_1)?.test2)]}
          />
          <ItemContent
            label="Future testing date 3"
            values={[formatDate(checkEnableParseJSON(data.future_testing_date_1)?.test3)]}
          />
          <div className="border w-100 my-3" />
        {/* </>
      )} */}
    </>
  );
};

export default SATTestSum;
