/* eslint-disable no-empty-pattern */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import courseServices from 'services/courseServices';

export interface CourseState {
  myGeneralInfo: any;
  myGradeAndCourse: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: CourseState = {
  myGeneralInfo: null,
  myGradeAndCourse: null,
  status: 'idle',
};
export const getGeneral: any = createAsyncThunk('course/getGeneral', async (studentId: any, {rejectWithValue}) => {
  try {
    const response = await courseServices.getGeneral(studentId);
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updateGeneral: any = createAsyncThunk(
  'course/updateGeneral',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const myGeneralInfo = state.course.myGeneralInfo;
    try {
      if (myGeneralInfo) {
        const response = await courseServices.updateGeneral(payload);
        return response;
      } else {
        const response = await courseServices.createGeneral(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getGradeAndCourse: any = createAsyncThunk(
  'course/getGradeAndCourse',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await courseServices.getGradeAndCourse(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateGradeAndCourse: any = createAsyncThunk(
  'course/updateGradeAndCourse',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const myGradeAndCourse = state.course.myGradeAndCourse;
    try {
      if (myGradeAndCourse) {
        const response = await courseServices.updateGradeAndCourse({
          ...myGradeAndCourse,
          ...payload,
        });
        return response;
      } else {
        const response = await courseServices.createGradeAndCourse(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {},
  extraReducers: {
    [getGeneral.pending]: (state) => {
      state.status = 'loading';
    },
    [getGeneral.rejected]: (state) => {
      state.status = 'failed';
    },
    [getGeneral.fulfilled]: (state, action) => {
      state.myGeneralInfo = action.payload;
      state.status = 'idle';
    },
    //
    [updateGeneral.pending]: (state) => {
      state.status = 'loading';
    },
    [updateGeneral.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateGeneral.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myGeneralInfo = action.payload;
      state.status = 'idle';
    },
    [getGradeAndCourse.pending]: (state) => {
      state.status = 'loading';
    },
    [getGradeAndCourse.rejected]: (state) => {
      state.status = 'failed';
    },
    [getGradeAndCourse.fulfilled]: (state, action) => {
      state.myGradeAndCourse = action.payload;
      state.status = 'idle';
    },
    //
    [updateGradeAndCourse.pending]: (state) => {
      state.status = 'loading';
    },
    [updateGradeAndCourse.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateGradeAndCourse.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myGradeAndCourse = action.payload;
      state.status = 'idle';
    },
    //
  },
});

export const {} = courseSlice.actions;
