/* eslint-disable react-hooks/exhaustive-deps */
import YesNoSwitch from 'components/input/YesNoSwitch';
import React from 'react';
import { Card, Row } from 'react-bootstrap';
import SelectInput from 'components/input/SelectInput';
import CBOConfig from './components/CBOConfig';
import Counselor from './Counselor';
import { setArrayLength } from 'components/helper/helper';
interface Props {
  formik: any;
  CBO?: any;
  CBOHelpful?: any;
}

const CBO = (props: Props) => {
  const [CBOHelpful, setCBOHelpful] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (props.CBOHelpful) {
      setCBOHelpful(props.CBOHelpful);
    }
  }, [props.CBOHelpful]);

  React.useEffect(() => {
    props.formik.setFieldValue('your_cbo_array', JSON.stringify(CBO));
  }, [CBO]);

  React.useEffect(() => {
    props.formik.setFieldValue('cbo_info_array', JSON.stringify(CBOHelpful));
  }, [CBOHelpful]);
  const handleChangeIndicateNumber = (e: any) => {
    setCBOHelpful(setArrayLength(CBOHelpful, e.target.value));
    props.formik.handleChange(e);
  };
  return (
    <Row className="m-0 common-coallition-flag">
      <p>Community-Based Organization (CBO)? </p>

      <YesNoSwitch
        label="Are you participating in a Community-Based Organization (CBO)?"
        name="is_participate_cbo"
        values={props.formik.values.is_participate_cbo}
        onChange={(e: any) => {
          props.formik.handleChange(e);
          if (e.target.value === 'false') {
            props.formik.setFieldValue('indicate_number_community_programs', 0);
            props.formik.setFieldValue('advisor_prefix', '');
            props.formik.setFieldValue('advisor_first_name', '');
            props.formik.setFieldValue('advisor_last_name', '');
            props.formik.setFieldValue('advisor_phone_number', '');
            props.formik.setFieldValue('advisor_email', '');
            setCBOHelpful([]);
          }
        }}
      />
      {props.formik.values.is_participate_cbo === 'true' && (
        <Card className="border rounded m-1 p-3">
          <div className="m-1">Community- Based Organizations </div>
          <div className="text-field-light m-1">You are allowed to list a maximum of 5 CBOS</div>
          <div className="my-1" />
          <SelectInput
            form={props.formik}
            mdCol={'12'}
            option={[0, 1, 2, 3, 4, 5]}
            name="indicate_number_community_programs"
            values={props.formik.values.indicate_number_community_programs}
            onChange={handleChangeIndicateNumber} //props.formik.handleChange}
            label="Indicate the number of community programs or organizations that have provided you with free
        assistance in your application process.*"
          />
          <div className="mx-2">
            Please indicate the program you received the most help from as your first option
          </div>
          {CBOHelpful.map((item, index) => (
            <CBOConfig
              key={index}
              // selectedCBO={CBO}
              item={item}
              indexText={`${index + 1}/${CBOHelpful.length}`}
              handleSave={(string) => {
                const newCBOHelpful = [...CBOHelpful];
                newCBOHelpful.splice(index, 1, string);
                setCBOHelpful(newCBOHelpful);
              }}
              handleRemove={() => {
                const newCBOHelpful = [...CBOHelpful];
                newCBOHelpful.splice(index, 1);
                setCBOHelpful(newCBOHelpful);
              }}
            />
          ))}

          <div className="border w-100 my-3" />
          <Counselor formik={props.formik} />
        </Card>
      )}
    </Row>
  );
};

export default CBO;
