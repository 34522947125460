import { optionValues } from 'components/helper/helper';
import DatePickerInput from 'components/input/DatePicker';
import GroupRadioButton from 'components/input/GroupRadioButton';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import React from 'react';

interface Props {
  formik: any;
}

const LeavingExamination = (props: Props) => {
  return (
    <>
      <SelectInput
        form={props.formik}
        label="Number of Senior Secondary Leaving Examinations you have already taken*"
        option={optionValues(Array.from(Array(11).keys()))}
        name="number_of_time_taken_senior_secondary_exam"
        values={props.formik.values.number_of_time_taken_senior_secondary_exam}
        onChange={props.formik.handleChange}
      />
      <div />
      {(props.formik.values.senior_secondary_exam_date_taken || []).map(
        (item: any, index: number) => (
          <React.Fragment key={index}>
            <div className="border my-3" />

            <DatePickerInput
              key={index}
              label={`Date taken (${index + 1})*`}
              name={`senior_secondary_exam_date_taken[${index}]`}
              values={item}
              onChange={(values) =>
                props.formik.setFieldValue(`senior_secondary_exam_date_taken[${index}]`, values)
              }
            />

            <SelectInput
              form={props.formik}
              label={`Examination board (${index + 1})*`}
              option={[
                'IB Diploma',
                'Abitur',
                'Apolytirion',
                'Baccalauréat',
                'Bagrut',
                'CAPE',
                'Center Shiken',
                'CIE Pre-U',
                'Concurso Nacional',
                'ENTER/TER/UAI',
                'European Baccalaureate',
                'Other',
              ]}
              name={`examination_board[${index}]`}
              values={props.formik.values.examination_board[index]}
              onChange={props.formik.handleChange}
            />
            {props.formik.values.examination_board[index] === 'Other' && (
              <TextInput
                label={`Specify Other (${index + 1})`}
                name={`specify_other[${index}]`}
                values={props.formik.values.specify_other[index]}
                onChange={props.formik.handleChange}
              />
            )}

            <TextInput
              label={`Academic subject (${index + 1})*`}
              name={`academic_subject[${index}]`}
              values={props.formik.values.academic_subject[index]}
              onChange={props.formik.handleChange}
              countText={30}
            />
            <TextInput
              label={`Score (${index + 1})*`}
              type="number"
              max={9999}
              name={`score[${index}]`}
              values={props.formik.values.score[index]}
              onChange={props.formik.handleChange}
            />
            <GroupRadioButton
              label={`Score Type (${index + 1})`}
              options={[{ value: 'Actual' }, { value: 'Predicted' }]}
              name={`score_type[${index}]`}
              values={props.formik.values.score_type[index]}
              onChange={props.formik.handleChange}
            />
          </React.Fragment>
        )
      )}
      <style>{`
      div[name="specify_other"] {
        background-color: #f7f8fa
      }
      `}</style>
    </>
  );
};

export default LeavingExamination;
