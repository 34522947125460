export const USER_ROLE = {
  student: 'student',
  counselor: 'counselor',
};

export const RECEIVE_NOTIFICATION_TYPE = {
  email: 'email',
  phone: 'phone',
};

export const INVITATION_STATUS = {
  pending: 'pending',
  approved: 'approved', //accepted:   'accepted',
  rejected: 'rejected',
  removed: 'removed',
  withdrawn: 'withdrawn',
};

export enum UserRole {
  student = 'Student',
  counselor = 'Counselor',
}

export enum ReceiveNoticationType {
  email = 'email',
  phone = 'phone',
}

export enum InviteStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  removed = 'removed',
  withdrawn = 'withdrawn',
}


export const STRIPE_SUBSCRIPTION_STATUS = {
  active: 'active',
  canceled: 'canceled',
  incomplete: 'incomplete',
  incomplete_expired: 'incomplete_expired',
  past_due: 'past_due',
  paused: 'paused',
  trialing: 'trialing',
  unpaid: 'unpaid',
};

export const STRIPE_PRICE_NAME = {
  SilverMonth: 'silver_month',
  SilverYear: 'silver_year',
  GoldMonth: 'gold_month',
  GoldYear: 'gold_year',
  SilverPlusMonth: 'silver_plus_month',
  SilverPlusYear: 'silver_plus_year',  
}