import Cookies from "js-cookie"
import http from "./http"

const dashboardServices = {

  async getPersonalData() {
    const student_id = Cookies.get('userId')
    const res = await http.get(`api/personal-percentage-complete/${student_id}`)
    return res
  },

  async getFamilyData() {
    const student_id = Cookies.get('userId')
    const res = await http.get(`api/family-percentage-complete/${student_id}`)
    return res
  },
  
  async getAdditionalInformationData() {
    const student_id = Cookies.get('userId')
    const res = await http.get(`api/additional-information/percentage-complete/${student_id}`)
    return res
  },
  
  async getEducationData() {
    const student_id = Cookies.get('userId')
    const res = await http.get(`api/education-percentage-complete/${student_id}`)
    return res
  },

  async getActivityAndHonorData() {
    const student_id = Cookies.get('userId')
    const res = await http.get(`api/activity-honor/percentage-complete/${student_id}`)
    return res
  },
  async getCourseAndGradeData() {
    const student_id = Cookies.get('userId')
    const res = await http.get(`api/course-grade-percentage-complete/${student_id}`)
    return res
  },
  async getTestData() {
    const student_id = Cookies.get('userId')
    const res = await http.get(`api/standardized-test-percentage-complete/${student_id}`)
    return res
  },
  
  
  
  
  
}

export default dashboardServices