import LoadingContent from 'components/LoadingContent';
import { debounce } from 'lodash';
import React from 'react';
import { Card } from 'react-bootstrap';
import courseServices from 'services/courseServices';
import axios from 'axios';
import ScrollTo from 'react-scroll-into-view';

type Props = {
  currentHighschool: any;
  onUpdateCourseTitle: (course: any) => void;
  searchKey: String;
  schoolYear?: String;
};

const SuggestCourse = (props: Props) => {
  let searchCtrl = new AbortController();
  const [listCourse, setListCourse] = React.useState<any[]>([]);
  const [notFoundCourse, setNotFoundCourse] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [params, setParams] = React.useState({
    school_id: props.currentHighschool.id,
    course_title: '',
    course_list_year: props.schoolYear,
  });
  React.useEffect(() => {
    fetchCourse(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  const fetchCourse = async (params: any) => {
    setListCourse([]);
    searchCtrl.abort();
    searchCtrl = new AbortController();
    setLoading(true);
    try {
      const res = await courseServices.getCourseSuggestSignal({...params, limit_item: 100}, searchCtrl);
      if (res.length) {
        setNotFoundCourse(false);
        setListCourse(res);
      } else {
        setListCourse([]);
        setNotFoundCourse(true);
      }
    } catch (error: any) {
      if (axios.isCancel(error)) {
      } else {
      }
    }
    setLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetch = React.useCallback(
    debounce((key) => setParams(key), 500),
    []
  );
  React.useEffect(() => {
    if (props.currentHighschool.hasOwnProperty('id') && props.currentHighschool.id!==null) {
      debounceFetch({
        school_id: props.currentHighschool.id,
        course_title: props.searchKey?.trim(),
        course_list_year: props.schoolYear,
      });
    }
    else if (props.currentHighschool.hasOwnProperty('college_board_code') && props.currentHighschool.college_board_code!==null) {
      debounceFetch({
        college_board_code: props.currentHighschool.college_board_code,
        course_title: props.searchKey?.trim(),
        course_list_year: props.schoolYear,
      });
    }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentHighschool.id, props.searchKey, props.schoolYear]);

  const handleUpdateCourseTitle = (course: any) => {
    course.course_title && props.onUpdateCourseTitle(course);
    setListCourse([]);
  };

  return (
    <div style={{ marginTop: '-12px' }}>
      {loading ? (
        <LoadingContent />
      ) : notFoundCourse ? (
        <div className="w-100 text-center">{`There are no suitable courses for the school year ${props.schoolYear}`}</div>
      ) : (
        <ScrollTo
          selector=".pointer"
          smooth
          style={{ maxHeight: '300px', overflowY: 'auto' }}
        >
          {
             listCourse.map((course, index) => (
              <Card
                className="border rounded pointer my-1 p-1 pt-2"
                key={index}
                onClick={() => handleUpdateCourseTitle(course)}
              >
                <div className="d-flex align-items-end position-relative">
                  <div className="mx-3 pb-2">{course?.course_title}</div>
                  <div className="text-field-light sized70 pb-2">{course?.course_length}</div>
                </div>
              </Card>
            ))
          }
        </ScrollTo>
       
      )}
    </div>
  );
};

export default SuggestCourse;
