import React from 'react';
import { Accordion, Button } from 'react-bootstrap';

export class ErrorBoundary extends React.Component<
  { history?: any },
  { hasError: boolean; errorInfo: string }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, errorInfo: '' };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ errorInfo: error.stack });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="m-5 text-center">
          <img alt="support" src="./assets/images/404.png" width={800} />
          <h5>There is an error:</h5>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Show Error</Accordion.Header>
              <Accordion.Body>
                <p>{this.state.errorInfo}</p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <h5>Please send this issue to us.</h5>
          <Button className="my-3" onClick={() => (window.location.href = '/')}>
            Go back
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}
