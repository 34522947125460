import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import ScrollTo from 'react-scroll-into-view';
export interface StepProcessProps {
  title: string;
  percentOnProcess: number;
  specialId?: string;
}
interface Props {
  icon?: any;
  title?: string;
  stepProcess: Array<StepProcessProps>;
  process: number;
}

const TopProcessBar = (props: Props) => {
  return (
    <div
      style={{
        zIndex: 999,
        position: 'fixed',
        width: window.innerWidth < 600 ? '100vw' : 'calc(100vw - 290px)',
        background: 'white',
      }}
    >
      <div className="w-100 d-flex align-items-center" style={{ height: 40 }}>
        <div
          className="d-none d-md-flex flex-nowrap"
          style={{ width: `${props.stepProcess[0]?.percentOnProcess || 100}%` }}
        >
          <span className="text-primary mx-2 rounded-circle ">{props.icon}</span>
          <span className="text-primary fs-5 text-nowrap">{props.title}</span>
        </div>
        <div className="w-100 d-md-none">
          <span className="text-primary mx-2 rounded-circle ">{props.icon}</span>
          <span className="text-primary fs-5">{props.title}</span>
        </div>
        {props.stepProcess.map((process, index) => {
          let w =
            props.stepProcess[index + 1]?.percentOnProcess -
            props.stepProcess[index]?.percentOnProcess;
          return (
            <div
              key={index}
              className="text-field-light sized70 d-none d-md-inline-block"
              style={{ width: `${w}%` }}
            >
              <ScrollTo
                smooth
                selector={
                  '#' + (process.specialId ?? process.title.replace(/ /g, '').replace('/', ''))
                }
                alignToTop
                scrollOptions={{ block: 'center' }}
              >
                <span className="pointer" style={{ backgroundColor: 'white', padding: 1 }}>
                  {process.title}
                </span>
              </ScrollTo>
            </div>
          );
        })}
      </div>
      <ProgressBar
        variant="success"
        className="w-100"
        now={props.process}
        style={{ height: '3px' }}
      />
      <style>
        {props.stepProcess.map((process) => {
          return `#${process.title} {
          margin-bottom: 12px !important
        }`;
        })}
      </style>
    </div>
  );
};

export default TopProcessBar;
