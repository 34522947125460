/* eslint-disable react-hooks/exhaustive-deps */
import Typography from '@mui/material/Typography';
import React from 'react';
import { Row } from 'react-bootstrap';
import { CollegeInformationDetail, ListCollegeByGroup, typoSx } from './CollegeSearch';
import collegeServices from 'services/collegeServices';
import { useAppDispatch } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import { checkEnableParseJSON, reorder } from 'components/helper/helper';
import { Box, Grid } from '@mui/material';
import { ConversationCard } from 'screen/summary/ReviewPersonal';
import LoadingContent from 'components/LoadingContent';
import counselorServices from 'services/counselorServices';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const MyCollege = () => {
  const [myLikedCurrentList, setMyLikedCurrentList] = React.useState<any[]>([]);
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const [myCurrentList, setMyCurrentList] = React.useState<ListCollegeByGroup>([]);
  const [loading, setLoading] = React.useState(false);
  
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };     

  const loadingData = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      const result: any = await collegeServices.getMyCollege(studentId);
      if (result?.colleges_array) {
        const list = checkEnableParseJSON(result?.colleges_array, []);
        const likedList: any[] = [];
        list.forEach((element: any) => {
          (element?.collegeList ?? []).forEach((college: any) => {
            college.is_saved && likedList.push(college);
          });
        });
  
        const reoderLikedList = likedList.sort((a, b) => a.id - b.id);
        setMyCurrentList(list);
        setMyLikedCurrentList(reoderLikedList);
      }
      setLoading(false);      
    }
    else{
      handleLogout();
    }
    if (!studentId) return;
  };

  React.useEffect(() => {
    loadingData();
  }, []);

  const handleSavingData = async (savedData: any) => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      await collegeServices.updatetMyCollege(studentId, JSON.stringify(savedData));
      loadingData();      
    }
    else{
      handleLogout();
    }
  };

  const handleCheckedItem = (nameSelected: string) => {
    setLoading(true);

    const newMyCurrentList = myCurrentList.map((list) => {
      let newListCollege = [...list.collegeList].map((i) =>
        i.name === nameSelected ? { ...i, is_saved: false } : { ...i }
      );
      return {
        name: list.name,
        collegeList: newListCollege,
      };
    });
    setMyCurrentList(newMyCurrentList);
    handleSavingData(newMyCurrentList);
  };
  const [listCounselorConnection, setListCounselorConnection] = React.useState<any[]>([]);

  React.useEffect(() => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);    
    if (token && studentId) {
      counselorServices.getCounselor(studentId).then((res: any[]) => {
        setListCounselorConnection(res ?? []);
      });      
    }
    else{
      handleLogout();
    }

  }, [studentId, token]);

  const [forceRenderKey, setForceRenderKey] = React.useState(0);
  
  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const dataList = reorder(myLikedCurrentList, result.source.index, result.destination.index);

    const startItem = myLikedCurrentList[result.source.index];
    const endItem = myLikedCurrentList[result.destination.index];

    const newMyCurrentList = myCurrentList.map((list) => {
      let newListCollege = [...list.collegeList].map((i) =>
        i.id === startItem.id
          ? { ...i, id: endItem.id }
          : i.id === endItem.id
          ? { ...i, id: startItem.id }
          : { ...i }
      );
      return {
        name: list.name,
        collegeList: newListCollege,
      };
    });
    studentId && collegeServices.updatetMyCollege(studentId, JSON.stringify(newMyCurrentList));
    setMyLikedCurrentList([...dataList]);
    setForceRenderKey(forceRenderKey + 1);
  };

  return loading ? (
    <LoadingContent />
  ) : (
    <Grid container sx={{ width: '100%' }}>
      <Grid item xs={12} md={8}>
        <Row className="px-2 m-3">
          <Typography sx={typoSx.title}>Colleges I'm Applying To</Typography>
          <Box sx={{ padding: '0 84px !important' }}>
            <DragDropContext onDragEnd={onDragEnd} key={forceRenderKey}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {myLikedCurrentList.map((miniItem: any, index: number) => (
                      <div key={index}>
                        <Draggable draggableId={index.toString() + '-'} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="position-relative">
                                <CollegeInformationDetail
                                  miniItem={miniItem}
                                  index={index}
                                  item={myCurrentList}
                                  handleUnSave={handleCheckedItem}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      </div>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Row>
      </Grid>
      <Grid item xs={12} md={4} sx={{ marginTop: { md: '48px' } }}>
        {listCounselorConnection.map((item, index) => (
          <ConversationCard conversationItem={item} currentModuleId={'8.2'} key={index} />
        ))}
      </Grid>
    </Grid>
  );
};

export default MyCollege;
