import http from './http';

const courseServices = {
  async getGeneral(student_id: any) {
    const res = await http.get(`api/course-general-info/${student_id}`);
    return res;
  },

  async createGeneral(body: any): Promise<any> {
    const res = await http.post('api/course-general-info', body);
    return res;
  },

  async updateGeneral(body: any): Promise<any> {
    const res = await http.put('api/course-general-info', body);
    return res;
  },
  async getGradeAndCourse(student_id: any) {
    const res = await http.get(`api/grade-and-college-course/${student_id}`);
    return res;
  },

  async createGradeAndCourse(body: any): Promise<any> {
    const res = await http.post('api/grade-and-college-course', body);
    return res;
  },

  async updateGradeAndCourse(body: any): Promise<any> {
    const res = await http.put('api/grade-and-college-course', body);
    return res;
  },

  async getCourseSuggest(body: any): Promise<any> {
    const res = await http.post(`api/scrapped-courses-list`, body);
    return res;
  },

  async getCourseSuggestSignal(body: any, controller: AbortController): Promise<any> {
    const res = await http.post(`api/scrapped-courses-list`, body, { signal: controller.signal });
    return res;
  },

  async getSchoolSuggest(
    body: any,
    searchOption: string,
    controller?: AbortController
  ): Promise<any> {
    let acceptNewSchool: boolean = ['IN_CA', 'In California'].includes(searchOption) ? true : false;
    let data: any[] = [];
    try {
      const res: any = await http.post('api/scrapped-school-list', body, {
        signal: controller?.signal,
      });
      
      if (acceptNewSchool) {
        if (res.new_schools) {
          data = [...res.new_schools];
        }
       
      } else {
        if (res.old_schools) {
          data = [...res.old_schools];
        }
      }
  
      let returnData = data;
      switch (searchOption) {
        case 'IN_CA' || 'In California':
          returnData = data.filter((i: any) => i.state === 'CA');
          break;
        case 'OUTSIDE_CA' || 'Outside California (in the U.S.)':
          returnData = data.filter((i: any) => i.state !== 'CA');
          break;
        case 'OUTSIDE_USA' || 'Outside the U.S.':
          break;
      }
      return returnData;
    }
    catch(ex){
      // skip
    }
    return data;
  },

  async searchHighSchool(keyword: string, state: string, country: string, 
                         searchOptions: string, controller?: AbortController): Promise<any> {
    let data: any[] = [];
    try {
      const body = {
        keyword : keyword,
        options: searchOptions,
        state: state,
        country: country
      }
      data = await http.post(`api/high-school-board`, body, {
        signal: controller?.signal,
      });
      
    }
    catch(ex){
      // skip
    }
    return data;
  },
};

export default courseServices;
