import { optionValues } from 'components/helper/helper';

//#region Grade
export const gradeOptions = [
  { value: '- Select -' },
  { value: '9th' },
  { value: '10th' },
  { value: '11th' },
  { value: '12th' },
];
//#endregion

//#region Academic Year selection *
export const academicOptions = [
  { value: '- Select -' },
  { value: '2018 - 2019' },
  { value: '2019 - 2020' },
  { value: '2020 - 2021' },
  { value: '2021 - 2022' },
  { value: '2022 - 2023' },
  { value: '2023 - 2024' },
  { value: '2024 - 2025' },
  { value: '2025 - 2026' },
  { value: '2026 - 2027' },
  { value: '2027 - 2028' },
];
//#endregion

//#region Date received or to be received
const years = {
  start: 1922,
  end: 2028,
};

export const receivedYears = [
  '- Select -',
  ...optionValues(
    Array.from({ length: years.end + 1 - years.start }, (_, i) => i + years.start)
  ).reverse(),
];
//#endregion
