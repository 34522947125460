import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Badge } from 'react-bootstrap';
import { Stack } from '@mui/material';

interface Props {
  values?: any;
  onChange?: any;
  name?: any;
  disabled?: boolean;
  hidden?: boolean;
  className?: string;
  label?: string;
  logo?: string;
  onClear?: any;
  helperText?: string;
}

const YesNoSwitch = (props: Props) => {
  const [isActive, setIsActive] = React.useState<Boolean | null>(null);
  React.useEffect(() => {
    props.values === 'true'
      ? setIsActive(true)
      : props.values === 'false'
      ? setIsActive(false)
      : setIsActive(null);
  }, [props.values]);
  const handleCheck = (e: any) => {
    props.onChange(e);
  };
  const handleClear = () => {
    props.onClear && props.onClear();
  };
  return props.hidden ? null : (
    <div className={`${props.className ?? ''} px-1`.trim()}>
      <FormControl>
        <FormLabel className={`label-14px d-block mb-2 ${props.logo ?? ''}`.trim()}>
          {props.label}
        </FormLabel>
        {props.helperText && (
          <span className="sized70 w-100 mb-2 text-field-light">{props.helperText}</span>
        )}
        <RadioGroup row name={props.name} className="mx-2">
          <Stack direction="row">
            <FormControlLabel
              className={
                isActive === true
                  ? 'border rounded ps-1 pe-3 d-inline-block radio-checked-style'
                  : 'border rounded ps-1 pe-3 d-inline-block radio-unchecked-style'
              }
              control={<Radio />}
              checked={isActive === true}
              name={props.name}
              value={'true'}
              id={props.name + 'true'}
              label={'Yes'}
              disabled={props.disabled}
              onChange={handleCheck}
            />
            <FormControlLabel
              className={
                isActive === false
                  ? 'ms-1 border rounded ps-1 pe-3 d-inline-block radio-checked-style'
                  : 'ms-1 border rounded ps-1 pe-3 d-inline-block radio-unchecked-style'
              }
              control={<Radio />}
              checked={isActive === false}
              name={props.name}
              value={'false'}
              id={props.name + 'false'}
              label={'No'}
              disabled={props.disabled}
              onChange={handleCheck}
            />
            {props.onClear && (
              <span className="me-3">
                <Badge bg="light" text="dark" className="pointer p-3" onClick={handleClear}>
                  Clear
                </Badge>
              </span>
            )}
          </Stack>
        </RadioGroup>
      </FormControl>
      <style>{`
        .radio-unchecked-style {
          margin-left:-8px!important;
          min-width: 90px !important;

        }
        .radio-checked-style {
          min-width: 90px !important;
          border-color: #4D88F0 !important;
          background-color: #4D88F0 !important;
          color: white !important;
          margin-left:-8px!important;
        }
        .radio-checked-style > .Mui-checked {
          color: white !important;
        }
        .form-check-input:checked {
          background-color: #4D88F0 !important;
          border-color: #4D88F0 !important;
        }
      `}</style>
    </div>
  );
};

export default YesNoSwitch;
