/* eslint-disable eqeqeq */
import { Autocomplete, createFilterOptions } from '@mui/material';
import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Col, Form, Button } from 'react-bootstrap';
import { CssTextField } from 'components/helper/helperInut';
type OptionType = any;

interface Props {
  clg?: boolean;
  borderless?: boolean;
  label?: string;
  option: OptionType;
  mdCol?: string;
  name?: string;
  values?: any;
  isValid?: boolean;
  feedback?: any;
  defautlValues?: any;
  disabled?: boolean;
  placeholder?: string;
  form?: any;
  className?: string;
  multiple?: boolean;
  logo?: string;
  hidden?: boolean;
  borderType?: number;
  onChange?: (val: any) => void;
  onInputChange?: any;
  onCreateNew?: (val: any) => void;
  transformValue?: (val:string) => string;
}

const AutocompleteInput = (props: Props) => {
  const [inputValue, setInputValue] = React.useState('');
  const [forceEmpty, setForceEmpty] = React.useState(false);

  React.useEffect(() => {
    if (
      props.option?.length &&
      props.values === undefined &&
      !forceEmpty
    ) {
      props.form &&
        props.form.setFieldValue(
          props.name,
          typeof props.option[0] === 'string' ? props.option[0] : props.option[0]?.value
        );
    }
  }, [props.values, props.option, props.form, props.name, forceEmpty]);

  const handleChange = (event: any, newValue: any) => {
    if (newValue === null || newValue === '') {
      setForceEmpty(true);
    } else {
      setForceEmpty(false);
    }
    props.form?.setFieldValue(props.name, newValue);
    props.onChange && props.onChange(newValue);
  };

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    let val:string = value;
    if (props.transformValue) {
      val = props.transformValue(value);
    }

    setInputValue(val);
    if (val === '') {
      setForceEmpty(true);
    } else {
      setForceEmpty(false);
    }
    props.onInputChange && props.onInputChange(val);
  };

  return props.hidden ? null : (
    <Form.Group
      className={`p-1 p-md-1 mt-2 ${props.className ?? ''}`.trim()}
      name={props.name}
      as={Col}
      md={props.mdCol || '6'}
      controlId="formGridState"
    >
      <Form.Label className={`select-auto-label label-14px ${props.logo ?? ''}`.trim()}>{props.label}</Form.Label>
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '0.5rem',
        }}
      >
        <Autocomplete
          filterOptions={createFilterOptions({ trim: true })}
          size="small"
          multiple={props.multiple ?? false}
          id={props.name}
          renderInput={(params) => <CssTextField {...params} />}
          options={props.option}
          onChange={handleChange}
          onInputChange={handleInputChange}
          inputValue={inputValue}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} size="small" />
              {option}
            </li>
          )}
          value={forceEmpty ? null : props.values}
          noOptionsText={
            props.onInputChange && (
              <Button onClick={() => props.onCreateNew && props.onCreateNew(inputValue)}>
                Create new: {inputValue}
              </Button>
            )
          }
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
        />
      </div>
      <div className="sized70 text-field-light w-100 d-flex justify-content-between mh-1rem">
        {props.feedback}
      </div>
    </Form.Group>
  );
};

export default AutocompleteInput;
