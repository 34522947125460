import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import TextInput from 'components/input/textInput';
import DatePickerInput from 'components/input/DatePicker';
import SelectInput from 'components/input/SelectInput';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  handleSave: (value: any) => void;
  item: any;
  index: any;
  disabled?: boolean;
  count: string;
}

const gradeOption = [
  { value: 'A*' },
  { value: 'A' },
  { value: 'B' },
  { value: 'C' },
  { value: 'D' },
  { value: 'E' },
];
const AdvanceExamConfig = (props: Props) => {
  const formItem = useFormik({
    initialValues: {
      grade: 'A*',
      dateTaken: undefined,
      alExam: undefined,
    },
    onSubmit: (values) => {},
  });
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    if (props.disabled) {
      setExpanded(false);
    } else {
      setExpanded(!expanded);
    }
  };
  React.useEffect(() => {
    if (props.item) formItem.setValues(props.item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="w-100 my-3 px-0">
      <CardActions
        disableSpacing
        onClick={handleExpandClick}
        sx={{
          backgroundColor: expanded ? '#AAEBF7' : 'none',
          borderBottom: expanded ? '2px solid #4D88F0' : 'none',

          marginLeft: '-8px',
        }}
      >
        <div className="w-100 d-flex justify-content-between">
          <span className="p-1 ms-3">Advanced Level Exam No.{props.index}</span>
          <strong className="text-field-light">{props.count}</strong>
        </div>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Row>
            <TextInput
              label="AL Exam *"
              name="alExam"
              countText={50}
              values={formItem.values.alExam}
              feedback={
                <span className="sized70">Write the score you indicate in the field before</span>
              }
              onChange={formItem.handleChange}
            />
            <SelectInput
              form={formItem}
              option={gradeOption}
              label="Grade *"
              name="grade"
              values={formItem.values.grade}
              onChange={formItem.handleChange}
            />
            <DatePickerInput
              label="Date taken*"
              name="dateTaken"
              values={formItem.values.dateTaken}
              onChange={(value) => formItem.setFieldValue('dateTaken', value)}
            />

            <Col xs={6} md={9} />
            <Col xs={6} md={3} className="p-3">
              <Button
                className="w-100"
                onClick={() => {
                  props.handleSave(formItem.values);
                  setExpanded(false);
                }}
              >
                Save Info
              </Button>
            </Col>
          </Row>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default AdvanceExamConfig;
