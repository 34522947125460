/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import SelectInput from 'components/input/SelectInput';
import { getDaysInMonth, getMonthIndex } from 'components/helper/datetime.helper';
import { monthOptions, dayOptions } from 'components/helper/helper';
import { receivedYears } from './education.config';

type Props = {
  formik: any;
};

const DateReceiveInfo = ({ formik }: Props) => {
  const [receivedDays, setRecievedDays] = React.useState<any[]>(dayOptions);

  const receivedDateChane = (e: any, type: string) => {
    try {
      let month = getMonthIndex(formik.values.month); // base 0
      let year = Number(formik.values.year);
      let day = formik.values.day ?? '1';
      const defaultYear = 2000;
      let days = getDaysInMonth(month, defaultYear);

      switch (type) {
        case 'year':
          year = Number(e.target.value);
          break;
        case 'month':
          month = getMonthIndex(e.target.value);
          break;
        case 'day':
          day = e.target.value;
          break;
      }
      
      if (Number.isNaN(year)){
        year = defaultYear;
      }

      if (Number.isNaN(formik.values.day)){
        formik.setFieldValue('day', '1');
      }

      days = getDaysInMonth(month, year);
      setRecievedDays(days);
      
      if (!(day in days)) {
        formik.setFieldValue('day', days[days.length - 1]);
      }
    } catch (ex) {}

    formik.handleChange(e);
  };

  return (
    <div id="school_degree_detail" className="row">
      <div className="text-field-light  mt-3">Date received or to be received</div>
      <SelectInput
        form={formik}
        mdCol={'4'}
        option={monthOptions}
        label="Month"
        values={formik.values.month}
        name="month"
        onChange={(e: any) => receivedDateChane(e, 'month')}
      />
      <SelectInput
        form={formik}
        mdCol={'4'}
        option={receivedDays}
        label="Day"
        values={formik.values.day}
        name="day"
        onChange={(e: any) => receivedDateChane(e, 'day')}
      />
      <SelectInput
        form={formik}
        option={receivedYears}
        mdCol={'4'}
        label="Year"
        values={formik.values.year}
        name="year"
        onChange={(e: any) => receivedDateChane(e, 'year')}
      />
    </div>
  );
};

export default DateReceiveInfo;
