import React from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import LockOutlinedIcon from 'components/icons/LockOutlinedIcon';
import VisibilityIcon from 'components/icons/VisibilityIcon';
import VisibilityOffIcon from 'components/icons/VisibilityOffIcon';

interface Props {
  mdCol: string;
  label: string;
  name: string;
  values: any;
  onChange: (e: any) => void;
  isValid?: boolean;
  feedback?: any;
  iconClass?: string;
  textClass?: string;
}

const PasswordInput = (props: Props) => {
  const [view, setView] = React.useState(false);
  return (
    <Form.Group className="p-1 p-md-1" as={Col} md={props.mdCol || '6'}>
      <Form.Label
        className={`${props.textClass ?? ''}`}
        style={{ fontSize: '1rem', color: '#4E5A6F' }}
      >
        {props.label}
      </Form.Label>
      <InputGroup className="input-with-prefix">
        <LockOutlinedIcon
          className={`${props.iconClass ?? 'text-field-light'} icon-prefix`.trim()}
        />
        {view ? (
          <VisibilityIcon
            className={`${props.iconClass ?? 'text-field-light'} icon-sufix`.trim()}
            onClick={() => setView(false)}
          />
        ) : (
          <VisibilityOffIcon
            className={`${props.iconClass ?? 'text-field-light'} icon-sufix`.trim()}
            onClick={() => setView(true)}
          />
        )}

        <Form.Control
          className={`${props.textClass ?? ''}`}
          style={{ paddingLeft: `36px` }}
          type={view ? 'text' : 'password'}
          name={props.name}
          value={props.values}
          onChange={props.onChange}
          isValid={props.isValid}
        />
      </InputGroup>
      {props.feedback}
      <style scoped>{`
      .input-with-prefix {
        position: relative;
      }
      `}</style>
    </Form.Group>
  );
};

export default PasswordInput;
