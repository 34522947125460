import PasswordInput from 'components/input/PasswordInput';
import { useFormik } from 'formik';
import React from 'react';
import { Col, Button, Spinner } from 'react-bootstrap';
import LockOutlinedIcon from 'components/icons/LockOutlinedIcon';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import basicInformationServices from 'services/basicInformationApi';
import toast from 'react-hot-toast';
import collegeServices from 'services/collegeServices';
//import { useAppSelector } from 'app/hooks';
interface Props {}

const SettingsSecurity = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required('Required'),
      newPassword: Yup.string().min(6, 'Minlength is 6').required('Required'),
      newPasswordConfirm: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const body = {
        student_id: Cookies.get('userId'),
        email: localStorage.getItem('currentUserEmail'),
        current_password: values.currentPassword,
        new_password: values.newPasswordConfirm,
      };
      try {
        const res = await basicInformationServices.changePassword(body);
        console.log('res:', res);
        if (res.message) {
          await collegeServices.sendMailResetPassword({ email: '', username: '' });
          toast.success(res.message);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        }
        setLoading(false);
      }
      catch(ex){
        console.log('Error: ',ex);
        setLoading(false);
      }
    },
  });
  //const personalStore = useAppSelector((store) => store.basicInformation.myBasicInformation);

  return (
    <div className="tab-wrap-panel">
      <div className="tab-panel">
        <div className="text-left" style={{ paddingBottom: '16px' }}>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <div
                  className="text-primary p-2 rounded-circle bg-primary-light d-flex align-items-center justify-content-center"
                  style={{ width: '35px', height: '35px', marginRight: '8px' }}
                >
                  <LockOutlinedIcon className="text-primray-light" width={'18px'} height={'18px'} />
                </div>
                <div className="text-primary fs-4 fw-bold mx-1">Security</div>
              </div>
            </div>
          </div>

          <div className="row my-3">
            <PasswordInput
              textClass="text-field-light"
              mdCol="6"
              label="Current Password"
              values={formik.values.currentPassword}
              onChange={formik.handleChange}
              name="currentPassword"
              feedback={
                formik.touched.currentPassword &&
                formik.errors.currentPassword && (
                  <span className="text-danger sized70">{formik.errors.currentPassword}</span>
                )
              }
            />
            <Col md={6} />
          </div>

          <div className="row my-3">
            <PasswordInput
              textClass="text-field-light"
              mdCol="6"
              label="New Password"
              values={formik.values.newPassword}
              onChange={formik.handleChange}
              name="newPassword"
              feedback={
                formik.touched.newPassword &&
                formik.errors.newPassword && (
                  <span className="text-danger sized70">{formik.errors.newPassword}</span>
                )
              }
            />
            <PasswordInput
              textClass="text-field-light"
              mdCol="6"
              label="Confirm New Password"
              values={formik.values.newPasswordConfirm}
              onChange={formik.handleChange}
              name="newPasswordConfirm"
              feedback={
                formik.touched.newPasswordConfirm &&
                formik.errors.newPasswordConfirm && (
                  <span className="text-danger sized70">{formik.errors.newPasswordConfirm}</span>
                )
              }
            />
          </div>

          <div className="row">
            <div className="my-3 d-flex justify-content-end">
              <Button variant="primary" onClick={() => formik.handleSubmit()} className="btn-update">
                {loading ? <Spinner size="sm" animation="border" variant="white" /> : 'Update'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsSecurity;
