/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button, Col } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { appRoutes, routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import OtherSecondary from './components/OtherSecondary';
import CounselorInformation from './components/CounselorInformation';
import GraduatingSchool from './components/GraduatingSchool';
import { getHighSchool, updateHighSchool } from 'app/highSchoolSlice/highSchoolSlice';
import { checkEnableParseJSON, reorder, userpilotDomain } from 'components/helper/helper';
import TextAreaInput from 'components/input/TextAreaInput';
import { debounce } from 'lodash';
import { Userpilot } from 'userpilot';
import SelectInput from 'components/input/SelectInput';
interface Props {}

const HighSchool = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const [otherSchoolData, setOtherSchoolData] = React.useState<any[]>([{}]);
  const [highSchoolData, setHighSchoolData] = React.useState<any[]>([{}]);
  const information = useAppSelector((store) => store.basicInformation.myBasicInformation);

  const studentAdditionalInfo = useAppSelector(
    (store) => store.authorization.studentAdditionalInfo
  );

  const formik = useFormik({
    initialValues: {
      high_school_info: {
        where_attend_high_school: '',
        state: '',
        city: '',
        country: '',
        is_home_school: false,
        is_independant_study: false,
        my_home_school_associated_with_high_school: false,
        school_name: '',
        school_type: '',
        left_highschool_reason: '',
        academic_history: '',
      },
      high_school_array: '',
      number_of_other_school_attended: '0',
      other_school_array: '',
      graduating_school_array: '',
      counselor_prefix: '',
      counselor_first_name: '',
      counselor_middle_initial: '',
      counselor_last_name: '',
      counselor_job_title: '',
      counselor_email: '',
      counselor_country_code: '',
      counselor_phone_number: '',
    },
    onSubmit: async (values) => {
      try {
        studentId = Cookies.get('userId');
        token = Cookies.get(authenticateSettings.tokenName);
        if (token && studentId && information) {
          setSaving(true);
          const newValues = {
            ...values,
            student_id: studentId,
            high_school_array: JSON.stringify(highSchoolData),
            other_school_array: JSON.stringify(otherSchoolData),
            graduating_school_array: JSON.stringify(formikGraduatingSchool.values),
            high_school_info: JSON.stringify(values.high_school_info),
          };
          Userpilot.identify((studentId ?? 'undefined') + userpilotDomain, {
            name: information?.first_name ?? 'undefined',
            email: information?.email ?? 'undefined',
            created_at: information?.created_at ?? 'undefined',
            highSchool: highSchoolData.map((item) => item.high_school_name).join(', '),
            termType: highSchoolData.map((item) => item.classes_schedule).join(', '),
          });
          await dispatch(updateHighSchool(newValues));
          setSaving(false);
        } else {
          handleLogout();
        }
      }
      catch(ex){
        setSaving(false);
        console.log('Error:', ex);
      }
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = async () => {
    formik.handleSubmit();
    history.push(appRoutes.collegeAndUniversity.path);
  };
  const formikGraduatingSchool = useFormik({
    initialValues: {
      graduating_date_received: '',
      graduation_status: '',
      graduating_high_school: '',
      graduating_school_year: '',
      graduating_school_gpa_type: 'Weighted',
      graduating_school_gpa_scale: '',
      graduating_school_cumulative_gpa: '',
      graduating_school_rank_report: '',
      graduating_school_class_size: '',
      graduating_class_size: 0,
      class_rank_reporting: 'Exact',
      class_rank: 0,
      gpa_scale_reporting: '',
      culmative_gpa: '',
      gpa_weighting: 'Weighted',
      gpa_scale_other: '',
      rank_weighting: '',
      progression_is_graduate_early: false,
      progression_is_graduate_late: false,
      progression_is_time_off: false,
      progression_is_take_gap_year: false,
      progression_is_no_change: false,
    },
    onSubmit: () => {},
  });
  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      const res = await dispatch(getHighSchool(studentId));
      if (res?.payload) {
        formik.setValues({
          ...formik.values,
          ...res.payload,
          high_school_info: {
            ...formik.values.high_school_info,
            ...checkEnableParseJSON(res.payload.high_school_info),
          },
        });
        formikGraduatingSchool.setValues({
          ...formikGraduatingSchool.values,
          ...checkEnableParseJSON(res.payload.graduating_school_array),
        });
        setHighSchoolData(checkEnableParseJSON(res.payload.high_school_array, []));
        setOtherSchoolData(checkEnableParseJSON(res.payload.other_school_array, []) || []);
      }
      setLoading(false);
      formik.setStatus('idle');
    } else {
      handleLogout();
    }
  };
  React.useEffect(() => {
    getDetail();
  }, []);

  // Auto save function ==========
  const notInitialRender = React.useRef(false);
  React.useEffect(() => {
    formik.dirty && debounceSaving();
  }, [formik.values, formikGraduatingSchool.values, highSchoolData, studentAdditionalInfo]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (notInitialRender.current) {
        if (!loading) formik.handleSubmit();
      } else {
        notInitialRender.current = true;
      }
    }, 1000),
    []
  );
  // Auto save function ==========

  return loading ? (
    <LoadingContent />
  ) : (
    <div>
      <TopProcessBar
        stepProcess={[
          { title: 'General', percentOnProcess: 20 },
          { title: 'Graduation', percentOnProcess: 40 },
          { title: 'Other High Schools', percentOnProcess: 55 },
          { title: 'Graduating School', percentOnProcess: 70 },
          { title: 'H.S. Counselor', percentOnProcess: 85, specialId: 'HSCounselor' },
        ]}
        title="Education"
        process={1}
        icon={<StickyNote2OutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2" id="General">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit} name="high_school_form">
            <Row className="m-0">
              {/* <div className="text-dark fs-5 p-1 fw-bold mb-3">High School</div>
              <HighSchoolInfo formik={formik} /> */}

              <div className="text-field-mid fs-3 mb-4">High Schools</div>
              <div id="Graduation">Let's get all your educational information in one place!</div>
              <SelectInput
                label="Current or Most Recent High School"
                name="mostHighSchool"
                values={highSchoolData?.[0]?.high_school_name}
                option={(highSchoolData ?? []).map((school) => school.high_school_name)}
                onChange={(e) => {
                  try {
                    if (e.target.value) {
                      const index = (highSchoolData ?? []).findIndex(
                        (school) => school.high_school_name === e.target.value
                      );
                      const dataList = reorder(highSchoolData, index, 0);
                      setHighSchoolData([...dataList]);
                    }
                  }
                  catch(ex){
                    console.log('Error:', ex);
                  }
                }}
                mdCol="12"
              />

              <div className="border w-100 my-3" id="OtherHighschools" />

              <OtherSecondary
                formik={formik}
                setOtherSchoolData={setHighSchoolData}
                otherSchoolData={highSchoolData}
                setLoading={setLoading}
              />

              <Col xs={12} className="text-dart fs-5 p-1 mt-3">
                Academic Details/Comments
              </Col>
              {highSchoolData.length > 1 && (
                <>
                  {/* <TextAreaInput
                  mdCol={'12'}
                  label="Please provide details about why you left each of the above secondary/high schools"
                  name="high_school_info.left_highschool_reason"
                  countWord={250}
                  values={formik.values.high_school_info?.left_highschool_reason}
                  onChange={formik.handleChange}
                /> */}

                  <TextAreaInput
                    mdCol={'12'}
                    label="Why you left each secondary/high school"
                    name="high_school_info.left_highschool_reason"
                    countWord={250}
                    isOverCount={true}
                    values={formik.values.high_school_info?.left_highschool_reason}
                    onChange={formik.handleChange}
                    logo="span-cm-logo"
                  />

                  <div
                    className='text-dark p-1'
                    style={{ fontSize: '1rem', color: '#4E5A6F' }}
                  >
                    
                    <div className='mb-1'>
                      If there's anything else you want us to know about your academic history, you
                      can do so in the space below. But remember, you should use this space only if
                      you want to describe anything that you have not had the opportunity to include
                      elsewhere in this section (for example: a break in attendance, poor grades in
                      a particular course or year, specific information about your school
                      environment or policies that affect your academic record or choices for
                      classes).
                    </div>

                    <div className='span-uc-logo'>
                      Why you have missing academic year courses, aka summer courses only, at a
                      specific high school listed above.
                    </div>
                  </div>

                  <TextAreaInput
                    mdCol={'12'}
                    label=""
                    name="high_school_info.academic_history"
                    countText={500}
                    isOverCount={true}
                    values={formik.values.high_school_info?.academic_history}
                    onChange={formik.handleChange}
                    logo="span-uc-logo"
                  />
                </>
              )}
              <div className="border w-100 my-3" id="GraduatingSchool" />
              <GraduatingSchool
                formikGraduatingSchool={formikGraduatingSchool}
                highSchoolOptions={[...highSchoolData]}
              />
              <div className="border w-100 my-3" id="HSCounselor" />

              <Row>
                <CounselorInformation formik={formik} />
              </Row>
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  { saving ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ):(
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default HighSchool;
