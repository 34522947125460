/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Button, Spinner, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useFormik } from 'formik';
import Military from './Military';
import Ethnicity from './Ethnicity';
import Language from './Language';
import {
  getDemoGraphics,
  updateDemoGraphics,
} from 'app/basicInformationSlice/basicInformationSlice';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import { formatBoolean, BoolToString, checkEnableParseJSON } from 'components/helper/helper';
import LoadingContent from 'components/LoadingContent';
import GroupCheckbox from 'components/input/GroupCheckbox';
import YesNoSwitch from 'components/input/YesNoSwitch';
import { debounce } from 'lodash';

export const militaryOptions = [
  {
    value: 'is_married_or_in_civil_union',
    label: 'I am married or in a civil union/registered domestic partnership',
  },
  {
    value: 'is_have_children',
    label: 'I have children',
  },
  {
    value: 'is_foster_care',
    label: `I've been in foster care or am a ward of the court in the United States`,
  },
  {
    value: 'is_will_be_current_member',
    label: 'When I attend UC, I will be a current or former member of the U.S. military',
  },
  {
    value: 'is_none_above_apply_to_me',
    label: 'None of the above apply to me',
  },
];

const Demographics = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const basicInformationStore = useAppSelector((store) => store.basicInformation);
  const [militaryExperience, setMilitaryExperience] = React.useState<any[]>([]);
  const formik = useFormik({
    initialValues: {
      anticipated_status: '',
      number_military_experience: 0,
      military_experience_array: '',
      language: '',
      religious_preference: '',
      other_religion: '',
      us_armed_forces_status: '',
      other_religion_language: '',
      is_dependent_us_military: '',
      is_consider_hispanic_latino: '',
      is_complete_satisfaction: false,
      number_language_proficient: 0,
      language_array: [{}],
      is_option_1: false,
      is_option_2: false,
      is_option_3: false,
      is_option_4: false,
      is_central_america: false,
      is_cuba: false,
      is_mexico: false,
      is_puerto_rico: false,
      is_south_america: false,
      is_spain: false,
      is_other_background: false,
      specifyOtherHispanic: '',
      best_group_describe_racial_background_array: {
        csu_reporting_preferences: 'Select',
        racial_background: '',
        racial_background_Asian: '',
        racial_OtherAsian_value: '',
        racial_background_White: '',
        racial_OtherWhite_value: '',
        racial_background_African: '',
        racial_OtherAfrican_value: '',
        racial_background_AmericanIndian: false,
        enrolledMemberOfAmericanIndianOrAlaskaNative: '',
        tribal_enrollment: '',
        is_enrolled_member_of_American_Indian_or_Alaska_Native: '',
        tribal_enrollment_primary: '',
        tribal_citizenship_or_enrollment_number: '',
        is_consent_to_the_use_of_tribal_citizenship_or_enrollment_information: false,
        racial_background_more_AfricanAmerican: false,
        racial_background_more_Asian: false,
        racial_background_more_AmericanIndian: false,
        racial_background_more_White: false,
        racial_background_more_NativeHawaiian: false,
        racial_background_white_Europe: false,
        racial_background_white_MiddleEast: false,
        racial_background_white_NorthAfrica: false,
        racial_background_white_Other: false,
        racial_background_identify_AfricanAmerican: false,
        racial_background_identify_NativeHawaiian: false,
        racial_background_identify_White: false,
        racial_background_identify_AmericanIndian: false,
        racial_background_identify_Asian: false,
        racial_background_identify_Southwest_Asian_and_North_African: false,
        racial_AmericanIndia_primary: '',
        racial_AmericanIndia_tribal: '',
        racial_AmericanIndia_tribal_affiliation: '',
        racial_AmericanIndia_is_recognized_tribe: '',
        racial_AmericanIndia_tribe: '',
        racial_AmericanIndia_tribal_number: '',
        is_consent_to_use_for_UC: false,
        cm_tribe_enrolled: '',
        csu_tribe_enrolled: '',
        racial_background_NativeHawaiian: '',
        racial_OtherPacific_value: '',
        racial_Southwest_Asian_and_North_African: '',
        racial_Southwest_Asian_and_North_African_other: '',
        value_consider_hispanic_latino: '',
        is_Argentinean: false,
        is_Bolivian: false,
        is_Chilean: false,
        is_Colombian: false,
        is_Dominican: false,
        is_Ecuadorian: false,
        is_Guatemalan: false,
        is_Honduran: false,
        is_Mexican: false,
        is_Nicaraguan: false,
        is_Panamanian: false,
        is_Paraguayan: false,
        is_Peruvian: false,
        is_Puerto: false,
        is_Salvadoran: false,
        is_Spaniard: false,
        is_Uruguayan: false,
        is_Venezuelan: false,
        is_Other_Central_American: false,
        is_Other_South_American: false,
        is_Other_Hispanic_or_Latino: false,
        specify_is_Other_Central_American: '',
        specify_is_Other_South_American: '',
        specify_is_Other_Hispanic_or_Latino: '',
      },

      is_married_or_in_civil_union: false,
      is_none_above_apply_to_me: true,
      is_have_children: false,
      is_foster_care: false,
      is_will_be_current_member: false,
      is_want_receive_AmericanIndia_support_information: true,

      demographics_additional_data: {
        isInFosterCare: '',
        usMilitaryStatus: '',
        isPermanentlyStationed: '',
        is_US_military_service_permanently_stationed_in_California: false,
        is_US_military_service_on_active_duty: false,
        specify_other_language: '',
        statusOfPersonYouAreDependance: '',
        is_plan_to_submit_us_military_transcript: false,
      },
    },
    onSubmit: async (values) => {
      studentId = Cookies.get('userId');
      token = Cookies.get(authenticateSettings.tokenName);
      if (token && studentId) {
        const newValues = {
          student_id: parseInt(studentId || ''),
          language: values.language,
          religious_preference: values.religious_preference,
          other_religion: values.other_religion,
          us_armed_forces_status: values.us_armed_forces_status,
          other_religion_language: values.other_religion_language,
          is_dependent_us_military: formatBoolean(values.is_dependent_us_military),
          is_consider_hispanic_latino: formatBoolean(values.is_consider_hispanic_latino),
          is_complete_satisfaction: values.is_complete_satisfaction,
          number_language_proficient: values.number_language_proficient,
          all_apply_array: JSON.stringify({
            is_none_above_apply_to_me: values.is_none_above_apply_to_me,
            is_married_or_in_civil_union: values.is_married_or_in_civil_union,
            is_have_children: values.is_have_children,
            is_foster_care: values.is_foster_care,
            is_will_be_current_member: values.is_will_be_current_member,
            is_want_receive_AmericanIndia_support_information:
              values.is_want_receive_AmericanIndia_support_information,
          }),
          best_group_latino_background_array: JSON.stringify({
            is_central_america: values.is_central_america,
            is_cuba: values.is_cuba,
            is_mexico: values.is_mexico,
            is_puerto_rico: values.is_puerto_rico,
            is_south_america: values.is_south_america,
            is_spain: values.is_spain,
            is_other_background: values.is_other_background,
            specifyOtherHispanic: values.specifyOtherHispanic,
          }),
          best_group_describe_racial_background_array: JSON.stringify(
            values.best_group_describe_racial_background_array
          ),
          language_array: values.language_array,
          military_experience_array: JSON.stringify(militaryExperience),
          number_military_experience: values.number_military_experience,
          anticipated_status: values.anticipated_status,
          demographics_additional_data: JSON.stringify(values.demographics_additional_data),
        };
        await dispatch(updateDemoGraphics(newValues));        
      }
      else {
        handleLogout();
      }
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };  

  const handleContinue = async () => {
    formik.handleSubmit();
    history.push(routers.citizenship.path);
  };

  const checkFirstLanguage = (lang:any[]) => {
    if (lang && lang.length>0) {
      if (lang[0].isFirstLanguage === false) return false;

      if (lang.length>1) {
        for (var i=1; i< lang.length; i++ ){
          if (lang[i].isFirstLanguage === true) return false;
        }
      }
      return true;
    }
    return false;
  }
  const fixFirstLanguage = (lang:any[]) => {
    if (lang && lang.length>0) {
      lang[0].isFirstLanguage = true;
      if (lang.length>1) {
        for (var i=1; i< lang.length; i++ ){
          lang[i].isFirstLanguage = false;
        }
      }
    }
  }

  const [languageArray, setLanguageArray] = React.useState([]);
  React.useEffect(() => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      try {
        setLoading(true);
        dispatch(getDemoGraphics(studentId)).then((res: any) => {
          
          if (!res.payload) {
            setLoading(false);
          } else {
            const parseDataMilitary = checkEnableParseJSON(res.payload.all_apply_array);
            const parseDataLatinoOption = checkEnableParseJSON(
              res.payload.best_option_latino_backgroud_array
            );
            const parseDataLatinoGroup = checkEnableParseJSON(
              res.payload.best_group_latino_background_array
            );
            const parseLanguageArray = checkEnableParseJSON(res.payload.language_array);

            let fixLang = !checkFirstLanguage(parseLanguageArray);
            if (fixLang) {
              fixFirstLanguage(parseLanguageArray);
            }
  
            const values = {
              ...res.payload,
              best_group_describe_racial_background_array: {
                ...formik.values.best_group_describe_racial_background_array,
                ...checkEnableParseJSON(res.payload.best_group_describe_racial_background_array),
              },
              demographics_additional_data: checkEnableParseJSON(
                res.payload.demographics_additional_data
              ),
              is_dependent_us_military: BoolToString(res.payload.is_dependent_us_military),
              is_consider_hispanic_latino: BoolToString(res.payload.is_consider_hispanic_latino),
              number_language_proficient:
                Number(res.payload.number_language_proficient) !== parseLanguageArray.length
                  ? parseLanguageArray.length
                  : res.payload.number_language_proficient,
              ...parseDataMilitary,
              ...parseDataLatinoOption,
              ...parseDataLatinoGroup,
              language_array: parseLanguageArray,
            };         
            setLanguageArray(parseLanguageArray);
            setMilitaryExperience(checkEnableParseJSON(res.payload.military_experience_array));
            formik.resetForm({ values: { ...formik.values, ...values } });
            setLoading(false);
  
            if (fixLang) {
              //checkFirstLanguage is false => run autoSave to fix Data
              formik.handleSubmit();
            }
  
          }
        }); 
      }
      catch(ex){
        console.log('Error at getDemoGraphics(): ', ex);
      }
    }
    else {
      handleLogout();
    }
  }, []);

  const checkNoneApply = (e: boolean) => {
    if (e) {
      formik.setFieldValue('is_will_be_current_member', false);
      formik.setFieldValue('is_foster_care', false);
      formik.setFieldValue('is_have_children', false);
      formik.setFieldValue('is_married_or_in_civil_union', false);
    }
  };
  const checkOtherThanNoneApply = (e: boolean) => {
    if (e) {
      formik.setFieldValue('is_none_above_apply_to_me', false);
      return;
    } else if (
      [
        formik.values.is_will_be_current_member,
        formik.values.is_foster_care,
        formik.values.is_have_children,
        formik.values.is_married_or_in_civil_union,
      ].filter((i) => i === true).length <= 1
    ) {
      formik.setFieldValue('is_none_above_apply_to_me', true);
    }
  };
  // Auto save function ==========
  React.useEffect(() => {
    debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <div>
      <TopProcessBar
        stepProcess={[
          { title: 'Military', percentOnProcess: 30 },
          { title: 'Ethnicity', percentOnProcess: 60 },
          { title: 'Language', percentOnProcess: 90 },
        ]}
        title="Personal Information"
        process={1}
        icon={<PersonOutlineOutlinedIcon />}
      />
      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <div id="Military" className="text-field-mid fs-3 mb-4">
              Demographics
            </div>
            <Row>
              <GroupCheckbox
                label="Check any of the following that apply to you:"
                options={[
                  {
                    onChecked: checkOtherThanNoneApply,
                    value: 'is_married_or_in_civil_union',
                    label: 'I am married or in a civil union/registered domestic partnership',
                  },
                  {
                    onChecked: checkOtherThanNoneApply,
                    value: 'is_have_children',
                    label: 'I have children',
                  },
                  {
                    onChecked: checkOtherThanNoneApply,
                    value: 'is_foster_care',
                    label: `I've been in foster care or am a ward of the court in the United States`,
                    subContent: formik.values.is_foster_care ? (
                      <YesNoSwitch
                        className="uc-flag"
                        label="Are you a ward of the court or in foster care in California? *"
                        name="demographics_additional_data.isInFosterCare"
                        onChange={formik.handleChange}
                        values={formik.values.demographics_additional_data?.isInFosterCare}
                      />
                    ) : null,
                  },
                  {
                    onChecked: checkOtherThanNoneApply,
                    value: 'is_will_be_current_member',
                    label:
                      'When I attend UC, I will be a current or former member of the U.S. military',
                  },
                  {
                    value: 'is_none_above_apply_to_me',
                    label: 'None of the above apply to me',
                    onChecked: checkNoneApply,
                  },
                ]}
                form={formik}
              />

              <Military
                formik={formik}
                militaryExperience={militaryExperience}
                setMilitaryExperience={setMilitaryExperience}
              />
              <div className="border w-100 my-3" id="Ethnicity" />
              <Ethnicity formik={formik} />
              <div className="border w-100 my-3" id="Language" />

              <Language formik={formik} languageArray={languageArray} />

              <div className="my-3 d-flex justify-content-end border-top pt-3">
                <div className="col col-md-6 text-center text-md-end">
                  <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                    {basicInformationStore.status === 'loading' ? (
                      <Spinner size="sm" animation="border" variant="light" />
                    ) : (
                      'Save'
                    )}
                  </Button>
                  <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                    Continue
                  </Button>
                </div>
              </div>
            </Row>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default Demographics;
