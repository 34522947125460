/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { Row } from 'react-bootstrap';
import TextInput from 'components/input/textInput';
import SelectInput from 'components/input/SelectInput';

type Props = {
  open: boolean;
  handleSave: (values: any) => void;
  onClose: () => void;
};

const FormCreateCollegeModal = ({ open, handleSave, onClose }: Props) => {
  const formik = useFormik({
    initialValues: {
      label: '',
      city: '',
      type: '',
      ceeb: '',
    },
    onSubmit: (values) => {
      handleSave(values);
    },
  });
  React.useEffect(() => {
    !open && formik.resetForm();
  }, [open]);
  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>College Information</DialogTitle>
        <DialogContent>
          <Row>
            <TextInput
              mdCol={12}
              label="College name"
              values={formik.values.label}
              name="label"
              onChange={formik.handleChange}
            />
            <TextInput
              mdCol={12}
              label="City/Province*"
              values={formik.values.city}
              name="city"
              onChange={formik.handleChange}
            />
            <TextInput
              mdCol={12}
              label="CEEB"
              values={formik.values.ceeb}
              name="ceeb"
              onChange={formik.handleChange}
            />
            <SelectInput
              mdCol={'12'}
              option={['Two-Year College', 'Four-Year College']}
              label="College Type"
              values={formik.values.type}
              name="type"
              onChange={formik.handleChange}
            />
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={() => handleSave(formik.values)}>Create</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default FormCreateCollegeModal;
