import Header from 'components/layout/header';
import MobileHeader from 'components/layout/MobileHeader';
import MobileSettingHeader from 'components/layout/MobileSettingHeader';
import SideBar from 'components/layout/sideBar';
import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Switch } from 'react-router';
import ProtectedRoute from './ProtectedRoute';
import { appRoutes } from './router';
import { useRouteMatch } from 'react-router-dom';

interface Props {}

const DashBoardLayout = (props: Props) => {
  const match = useRouteMatch();
  const [title, setTitle] = React.useState('Dashboard');
  useEffect(() => {
    if (match.path === '/support') {
      setTitle('Support');
    } else if (match.path === '/settings') {
      setTitle('Settings');
    } else {
      setTitle('Dashboard');
    }
  }, [match]);
  
  return (
    <Container fluid className="vh-100 overflow-hidden px-0">
      <div className="d-none d-sm-block w-100">
        <Header title={title} />
      </div>
      <div className="d-block d-sm-none w-100">
        {title!=="Settings" ? (
          <MobileHeader />
        ):(
          <MobileSettingHeader />
        )}
      </div>
      <div className="gm-content">
        <div className="d-none d-sm-block gm-sidebar">
          <SideBar hideList />
        </div>
        <div className="gm-main-content">
          <Switch>
            {Object.keys(appRoutes).map((route: any, index: any) => {
              return (
                <ProtectedRoute
                  key={index}
                  path={appRoutes[route].path}
                  component={appRoutes[route].component}
                  exact
                />
              );
            })}
          </Switch>
        </div>
      </div>
    </Container>
  );
};

export default DashBoardLayout;
