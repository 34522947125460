import { capitalizeFirstLetter } from 'components/helper/helper';
import DatePickerInput from 'components/input/DatePicker';
import SelectInput from 'components/input/SelectInput';
import React from 'react';

interface Props {
  arrayName: { fieldName: string; dateName: string; label: string; options?: any[] }[];
  formik: any;
  disabled?: boolean;
  selectOptions: any[];
}

const ReuseFieldScoreAndDate = (props: Props) => {
  return (
    <>
      {(props.arrayName || []).map((item, index) => {
        const labelScore = capitalizeFirstLetter(`Highest ${item.label} score*`);
        return (
          <React.Fragment key={index}>
            {/* <div>Highest {item.label} score*</div> */}
            <SelectInput
              form={props.formik}
              disabled={props.disabled}
              option={item.options ?? props.selectOptions}
              label={labelScore}
              name={`${item.fieldName}`}
              values={props.formik.values[item.fieldName]}
              onChange={props.formik.handleChange}
            />
            <DatePickerInput
              disabled={props.disabled}
              label="Date taken"
              name={`${item.dateName}`}
              values={props.formik.values[item.dateName]}
              onChange={(values) => props.formik.setFieldValue(item.dateName, values)}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ReuseFieldScoreAndDate;
