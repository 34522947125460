import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, OutlinedInput } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import YesNoSwitch from 'components/input/YesNoSwitch';
import { useFormik } from 'formik';
import collegeServices from 'services/collegeServices';
import { removeDuplicate } from 'components/helper/helper';
import { debounce } from 'lodash';

const ModalCampusLife = ({ open, setOpen, formik }: any) => {
  const formikCampus = useFormik({
    initialValues: {
      rural: false,
      small: false,
      subrb: false,
      urban: false,
    },
    onSubmit: (values) => {},
  });
  const formikSize = useFormik({
    initialValues: {
      SMALL: false,
      MEDIUM: false,
      LARGE: false,
      VERY_LARGE: false,
    },
    onSubmit: (values) => {},
  });
  const [result, setResult] = React.useState<{ description: string; sport_code: string }[]>([]);
  const [checkedResult, setCheckedResult] = React.useState<
    { description: string; sport_code: string }[]
  >([]);
  const handleChange = async (e: any) => {
    if (e.target.value) {
      const res: any = await collegeServices.getUSCollegeSportV1({ keyword: e.target.value });
      setResult(res);
    }
  };

  const onChecked = (checked: boolean, result: { description: string; sport_code: string }) => {
    checked
      ? setCheckedResult(removeDuplicate([...checkedResult, result]))
      : setCheckedResult(checkedResult.filter((item) => item.sport_code !== result.sport_code));
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} keepMounted fullWidth>
      <DialogTitle className="fw-bold fs-3">Campus Life</DialogTitle>
      <DialogContent>
        <Box className="fw-bold fs-5 mt-3">Campus setting</Box>
        <FormControlLabel
          className="w-100 m-0"
          control={
            <Checkbox
              name="rural"
              checked={formikCampus.values.rural}
              onChange={formikCampus.handleChange}
            />
          }
          label={'Rural'}
        />
        <FormControlLabel
          className="w-100 m-0"
          control={
            <Checkbox
              name="small"
              checked={formikCampus.values.small}
              onChange={formikCampus.handleChange}
            />
          }
          label={'Small Town'}
        />
        <FormControlLabel
          className="w-100 m-0"
          control={
            <Checkbox
              name="subrb"
              checked={formikCampus.values.subrb}
              onChange={formikCampus.handleChange}
            />
          }
          label={'Suburban'}
        />
        <FormControlLabel
          className="w-100 m-0"
          control={
            <Checkbox
              name="urban"
              checked={formikCampus.values.urban}
              onChange={formikCampus.handleChange}
            />
          }
          label={'Urban'}
        />

        <Box className="fw-bold fs-5 mt-3">Student Body Size</Box>

        <FormControlLabel
          className="w-100 m-0"
          control={
            <Checkbox
              name="SMALL"
              checked={formikSize.values.SMALL}
              onChange={formikSize.handleChange}
            />
          }
          label={'SMALL = <2000'}
        />
        <FormControlLabel
          className="w-100 m-0"
          control={
            <Checkbox
              name="MEDIUM"
              checked={formikSize.values.MEDIUM}
              onChange={formikSize.handleChange}
            />
          }
          label={'MEDIUM = 2000-15k'}
        />
        <FormControlLabel
          className="w-100 m-0"
          control={
            <Checkbox
              name="LARGE"
              checked={formikSize.values.LARGE}
              onChange={formikSize.handleChange}
            />
          }
          label={'LARGE = 15k-30k'}
        />
        <FormControlLabel
          className="w-100 m-0"
          control={
            <Checkbox
              name="VERY_LARGE"
              checked={formikSize.values.VERY_LARGE}
              onChange={formikSize.handleChange}
            />
          }
          label={'VERY LARGE = >30k'}
        />

        <Box className="fw-bold fs-5 mt-3">Guaranteed Freshmen Housing</Box>

        <Box className="fw-bold fs-5 mt-3">Greek Life</Box>
        <YesNoSwitch
          label="Frats?"
          values={formik.values.life_frat_nat}
          name="life_frat_nat"
          onChange={formik.handleChange}
        />
        <YesNoSwitch
          label="Sororities?"
          values={formik.values.life_sor_nat}
          name="life_sor_nat"
          onChange={formik.handleChange}
        />
        <Box className="fw-bold fs-5 mt-3">Sports</Box>
        <OutlinedInput
          autoFocus
          margin="dense"
          placeholder="Sports"
          fullWidth
          onChange={debounce(handleChange, 500)}
        />
        {(result ?? []).map((item, index) => (
          <FormControlLabel
            className="w-100 m-0"
            control={<Checkbox onChange={(e) => onChecked(e.target.checked, item)} />}
            label={item.description}
          />
        ))}
      </DialogContent>
      <DialogActions className="p-4">
        <Button
          variant="contained"
          onClick={() => {
            formik.setFieldValue(
              'cmps_setting',
              // @ts-ignore
              Object.keys(formikCampus.values).filter((key) => formikCampus.values[key])
            );
            formik.setFieldValue(
              'size_of_school',
              Object.keys(formikSize.values)
                .filter(
                  (key) =>
                    // @ts-ignore
                    formikSize.values[key]
                )
                .map((key) => key.replace('_', ' '))
            );

            formik.setFieldValue(
              'sport_codes',
              checkedResult.map((item) => item.sport_code)
            );
            formik.handleSubmit();
            setOpen(false);
          }}
        >
          Show Colleges
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalCampusLife;
