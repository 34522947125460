import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, Button } from 'react-bootstrap';
import SelectInput from 'components/input/SelectInput';
import CheckboxInput from 'components/input/CheckboxInput';
import { languageOptionProps } from '../Language';
import { useAppDispatch } from 'app/hooks';
import { setUnsaveFalse, setUnsaveTrue } from 'app/appSlice';
import { otherLanguageOptions } from 'components/helper/appConfig';
import AutocompleteInput from 'components/input/AutoCompleteInput';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
interface Props {
  information: languageOptionProps;
  index: number;
  titleIndex: string;
  handleSave: (value: languageOptionProps) => void;
  handleRemove: () => void;
  handleCheckFirstLanguage: () => void;
  key: number;
}

const LanguageConfig = (props: Props) => {
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const [languageInformation, setLanguageInformation] = React.useState<languageOptionProps>(
    props.information
  );
  // const [fluencyValue, setFluencyValue] = React.useState('');
  React.useEffect(() => {
    setLanguageInformation(props.information);
  }, [props.information]);

  const handleExpandClick = () => {
    dispatch(setUnsaveTrue());
    setExpanded(!expanded);
  };
  const handlePickLanguage = (e: any) => {
    setLanguageInformation({
      ...languageInformation,
      language: e,
    });
  };

  React.useEffect(() => {
    props.handleSave(languageInformation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageInformation]);

  const buildHeader = () => {
    let title = languageInformation.language;
    const details = [];

    if (languageInformation?.proficiencyLevel) {
      details.push(languageInformation.proficiencyLevel);
    }

    if (languageInformation?.isRead) {
      details.push('Read');
    }

    if (languageInformation?.isWrite) {
      details.push('Write');
    }

    if (languageInformation?.isSpeak) {
      details.push('Speak');
    }

    if (languageInformation?.isSpokenAtHome) {
      details.push('Spoken at Home');
    }

    if (languageInformation?.isFirstLanguage === true) {
      details.push('First Language');
    }

    return details.length > 0 ? `${title} - ${details.join(', ')}` : title;
  };
  return (
    <Row>
      <Col xs={12}>
        <Card className="w-100 my-3">
          <CardActions
            disableSpacing
            onClick={handleExpandClick}
            sx={{
              backgroundColor: expanded ? '#AAEBF7' : 'none',
              borderBottom: expanded ? '2px solid #4D88F0' : 'none',
            }}
          >
            <div className="w-100 d-flex justify-content-between">
              <span className="p-1">{buildHeader()}</span>
              <strong className="text-field-light">{props.titleIndex}</strong>
            </div>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Row>
                <AutocompleteInput
                  logo="span-cm-logo"
                  option={otherLanguageOptions}
                  values={languageInformation.language}
                  mdCol={'6'}
                  label={'Language'}
                  onChange={handlePickLanguage}
                />

                <SelectInput
                  logo="span-csu-logo"
                  label="Proficiency Level"
                  name="proficiencyLevel"
                  option={['Select', 'Beginner', 'Intermediate', 'Advanced']}
                  values={languageInformation.proficiencyLevel}
                  onChange={(e) =>
                    setLanguageInformation({
                      ...languageInformation,
                      proficiencyLevel: e.target.value,
                    })
                  }
                />
                <div className="text-field-light span-cm-logo">
                  Select your Language Proficiency
                </div>
                <Col md={3}>
                  <CheckboxInput
                    id={props.titleIndex}
                    label="First Language"
                    name="isFirstLanguage"
                    values={props.index === 0}
                    onChange={(e) => {
                      props.handleCheckFirstLanguage && props.handleCheckFirstLanguage();
                    }}
                  />
                </Col>
                <Col md={2}>
                  <CheckboxInput
                    id={props.titleIndex}
                    label="Speak"
                    name="isSpeak"
                    values={languageInformation.isSpeak}
                    onChange={(e) => {
                      setLanguageInformation({
                        ...languageInformation,
                        isSpeak: e.target.checked,
                      });
                    }}
                  />
                </Col>
                <Col md={2}>
                  <CheckboxInput
                    id={props.titleIndex}
                    label="Read"
                    name="isRead"
                    values={languageInformation.isRead}
                    onChange={(e) => {
                      setLanguageInformation({
                        ...languageInformation,
                        isRead: e.target.checked,
                      });
                    }}
                  />
                </Col>
                <Col md={2}>
                  <CheckboxInput
                    id={props.titleIndex}
                    label="Write"
                    name="isWrite"
                    values={languageInformation.isWrite}
                    onChange={(e) => {
                      setLanguageInformation({
                        ...languageInformation,
                        isWrite: e.target.checked,
                      });
                    }}
                  />
                </Col>
                <Col md={3} className="coallition-flag">
                  <CheckboxInput
                    id={props.titleIndex}
                    label="Spoken at Home"
                    name="isSpokenAtHome"
                    values={languageInformation.isSpokenAtHome}
                    onChange={(e) => {
                      setLanguageInformation({
                        ...languageInformation,
                        isSpokenAtHome: e.target.checked,
                      });
                    }}
                  />
                </Col>
                <div />

                <Col xs={6} md={9} />
                <Col xs={6} md={3} className="p-3">
                  <Button
                    className="w-100"
                    // disabled={props.information === languageInformation}
                    onClick={() => {
                      props.handleSave(languageInformation);
                      setExpanded(false);
                      dispatch(setUnsaveFalse());
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </CardContent>
          </Collapse>
        </Card>
      </Col>
      {/* <Col xs={1}>
        <RemoveCircleOutlineIcon
          className="text-field-light pointer my-4"
          onClick={() => props.handleRemove()}
        />
      </Col> */}
    </Row>
  );
};

export default LanguageConfig;
