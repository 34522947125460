import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Button } from 'react-bootstrap';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { appMenuGroups, icons, routers } from 'router/router';
import { useHistory, useLocation } from 'react-router';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';

export default function MobileMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [title, setTitle] = React.useState('Menu');
  let location = useLocation();
  const handleClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const redirectPage = async (child: any) => {
    setAnchorEl(null);

    history.push(child?.path);
  };
  const [open, setOpen] = React.useState<string | null>(null);
  let history = useHistory();
  const handleClick = (route: any) => {
    if (open === route.name) return setOpen(null);
    setOpen(route.name);
  };
  React.useEffect(() => {
    appMenuGroups.forEach((route: any) => {
      const currentRoute = route.children.find((e: any) => e?.path === location.pathname);
      if (currentRoute) {
        setOpen(route.name);
        setTitle(currentRoute.name);
      }
    });
  }, [location]);
  return (
    <div className="BLEBLEBLE">
      <Button variant="outline-dart" onClick={handleClickButton}>
        {title}
      </Button>
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        open={openMenu}
        PaperProps={{
          style: {
            width: '100%',
            padding: '12px',
          },
        }}
      >
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <ListItemButton
            onClick={async () => {
              await redirectPage(routers.dashBoard);
            }}
          >
            <DashboardOutlinedIcon
              className="text-field-light"
              style={{ marginLeft: '-12px', marginRight: '4px' }}
            />
            <span className="mr-1 pr-1">Dashboard</span>
          </ListItemButton>
          {/* <ListItemButton
            onClick={async () => {
              await redirectPage(routers.support);
            }}
          >
            <SmsOutlinedIcon
              className="text-field-light"
              style={{ marginLeft: '-12px', marginRight: '4px' }}
            />
            <span className="mr-1 pr-1">Support</span>
          </ListItemButton> */}
          {appMenuGroups.map((route: any, index: any) => {
            const IconBefore = icons[route.icon] || 'Component';
            return (
              <div key={index}>
                <ListItemButton
                  onClick={() => handleClick(route)}
                  className={`px-1 my-1 ${open === route.name && 'bg-light fw-bold'}`}
                >
                  <IconBefore fontSize="small" className="me-2" />
                  <span>{route.name}</span>
                  {route.isGroup &&
                    (open === route.name ? (
                      <ExpandLess fontSize="small" className="text-field-light" />
                    ) : (
                      <ExpandMore fontSize="small" className="text-field-light" />
                    ))}
                </ListItemButton>
                {route.isGroup && (
                  <Collapse in={open === route.name} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {route.children.map((child: any, i: number) => (
                        <ListItemButton
                          key={i}
                          sx={{ pl: 4 }}
                          onClick={async () => {
                            await redirectPage(child);
                          }}
                        >
                          <span>{child?.icon}</span>
                          <span
                            className={
                              location.pathname === child?.path
                                ? 'text-primary fw-bold'
                                : 'text-dark'
                            }
                          >
                            {child?.name}
                          </span>
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </div>
            );
          })}
        </List>
      </Menu>
    </div>
  );
}
