export const collegeCourseSubjectOption = [
  '',
  'Abnormal Psychology',
  'Accounting',
  'Acting',
  'Acupuncture',
  'Administration of Justice',
  'Adolescent Psychology',
  'Aerospace Studies',
  'African American Studies',
  'Agribusiness',
  'Agriculture',
  'Algebra',
  'American Sign Language',
  'American Studies',
  'Analytic Chemistry',
  'Analytical Geometry',
  'Anatomy',
  'Anatomy & Physiology',
  'Animal Behavior',
  'Animal Science',
  'Anthropology',
  'Arabic',
  'Archeology',
  'Architecture',
  'Armchair Chemistry',
  'Art',
  'Art History',
  'Asian American Studies',
  'Astronomy',
  'Athletic Training',
  'Atmospheric Science',
  'Audiology',
  'Bacteriology',
  'Banking',
  'Basic Chemistry',
  'Basic Composition',
  'Basic Computer Skills/Computer Basics',
  'Behavioral Sciences',
  'Behavioral Statistics',
  'Bible Literature',
  'Bilingual/Bicultural Studies',
  'Biobehavioral Health',
  'Biochemistry',
  'Biodiversity',
  'Bioengineering',
  'Bioethics',
  'Bioinformatics',
  'Biological Anthropology',
  'Biological Chemistry',
  'Biological Psychology',
  'Biology',
  'Biomechanics',
  'Biomedical Engineering',
  'Biomedical Science',
  'Biometry/Biometrics',
  'Biomolecules',
  'Bioorganic Chemistry',
  'Biophysics',
  'Biostatistics',
  'Biotechnology',
  'Botany',
  'Brain and Behavior',
  'Business',
  'Business Administration',
  'CPR',
  'Calculus',
  'Cellular & Molecular Biology',
  'Cellular Biology',
  'Cellular Physiology',
  'Chemical Engineering',
  'Chemical Math',
  'Chemistry',
  'Child Psychology',
  'Chinese Internal Medicine',
  'Chiropractic Medicine',
  'Civil Engineering',
  'Classics',
  'Clinical Laboratory Sciences',
  'Clinical Laboratory Technician',
  'Clinical Sciences',
  'Cognitive Psychology',
  'Cognitive Science',
  'Communication Science Disorders',
  'Communications',
  'Community Health',
  'Community Planning',
  'Composition',
  'Computer Applications',
  'Computer Programing',
  'Computer Science',
  'Conservation',
  'Counseling',
  'Creative Writing',
  'Criminal Justice',
  'Criminology',
  'Cultural Geography',
  'Cultural Studies',
  'Cytology/Cytotechnology',
  'Dance',
  'Death & Dying',
  'Debate',
  'Dental Assisting',
  'Dental Hygiene',
  'Dentistry',
  'Developmental Biology',
  'Developmental Psychology',
  'Dietetics',
  'Disease',
  'Drama',
  'Early Childhood Education',
  'Earth Science',
  'Ecology',
  'Economics',
  'Education',
  'Educational Psychology',
  'Electricity',
  'Electricity & Light',
  'Electronics',
  'Embryology',
  'Emergency Med-Tech',
  'Endocrinology',
  'Engineering',
  'English',
  'English as a Second Language',
  'Entomology',
  'Environmental Health',
  'Environmental Science',
  'Environmental Studies',
  'Epidemiology',
  'Ergonomics',
  'Ethics',
  'Ethnic Studies',
  'Eukaryotics',
  'Evolution',
  'Excel',
  'Exercise Physiology',
  'Exercise Science',
  'Family Studies',
  'Family and Consumer Sciences',
  'Fashion Design',
  'Film/Media',
  'Finance',
  'Fire Aid/Firefighting',
  'First Aid',
  'Folklore',
  'Food Management',
  'Food Science',
  'Foreign Languages',
  'Forensic Sciences',
  'Forestry',
  'French',
  'Gender Studies',
  'Genetics',
  'Genomics',
  'Geographic Information Systems',
  'Geography',
  'Geology',
  'German',
  'Gerontology/Aging',
  'Global Studies',
  'Government',
  'Graphic Design',
  'Growth & Development',
  'Gynecology',
  'Health',
  'Health Education',
  'Health Science',
  'Health Science Administration',
  'Health Services',
  'Health Technology',
  'Heat',
  'Hematology',
  'Herbology',
  'Histology',
  'History',
  'Honors Core',
  'Horticulture',
  'Human Behavior',
  'Human Development',
  'Human Geography',
  'Human Movement',
  'Human Sexuality',
  'Humanities',
  'Hygiene',
  'Immunology',
  'Informatics',
  'Information Systems',
  'Information Technology',
  'Inorganic Chemistry',
  'Intellectual Heritage',
  'Internet',
  'Intro to Chemistry',
  'Journalism',
  'Kinesiology',
  'Latina/o American Studies',
  'Law',
  'Law Enforcement',
  'Library Science',
  'Life Span Development',
  'Light',
  'Limnology',
  'Linear Algebra',
  'Linguistics',
  'Literature',
  'Locomotion Studies',
  'Logic',
  'Magnetism',
  'Management',
  'Marine Biology',
  'Marine Science',
  'Marketing',
  'Marriage/Family',
  'Massage',
  'Math Analysis',
  'Mathematics',
  'Mechanical Heat',
  'Mechanics',
  'Media Studies',
  'Medical Chemistry',
  'Medical Ethics',
  'Medical Laboratory Sciences',
  'Medical Laboratory Technician',
  'Medical Technology',
  'Medical Terminology',
  'Medicine',
  'Metabolism',
  'Meteorology',
  'Micro-Organisms',
  'Microbiology',
  'Military Science',
  'Molecular Biology',
  'Mosaic',
  'Motor Control',
  'Movement Science',
  'Multicultural Studies',
  'Music',
  'Native American Studies',
  'Natural Resources',
  'Natural Science',
  'Neural Mechanisms',
  'Neurobiology',
  'Neurology',
  'Neuropsychology',
  'Neuroscience',
  'Nuclear',
  'Nuclear Physics',
  'Nursing',
  'Nutrition',
  'Occupational Therapy',
  'Oceanography',
  'Office Technology',
  'Optics',
  'Optometry',
  'Organic Chemistry',
  'Organizational Studies',
  'Oriental Medicine',
  'Orientation',
  'Other Health Prof',
  'Paramedic',
  'Parasitology',
  'Pathology',
  'Pathophysiology',
  'Personal Health',
  'Pharmaceutical Chemistry',
  'Pharmacology',
  'Pharmacy',
  'Philosophy',
  'Phlebotomy',
  'Phonetics',
  'Photography',
  'Physical Anthropology',
  'Physical Biochemistry',
  'Physical Chemistry',
  'Physical Education',
  'Physical Geography',
  'Physical Sciences',
  'Physical Therapy',
  'Physical Therapy Assistant',
  'Physician Assistant',
  'Physics',
  'Physiological Chemistry',
  'Physiological Science',
  'Physiology',
  'Plant',
  'Plant Biology',
  'Plant Sciences',
  'Podiatry',
  'Poetry',
  'Political Science',
  'Population Biology',
  'Prokaryote Physiology',
  'Psychobiology',
  'Psychology',
  'Psychopharmacology',
  'Public Administration',
  'Public Affairs/Administration',
  'Public Health',
  'Public Policy',
  'Public Relations',
  'Public Speaking',
  'Qualitative Analysis',
  'Quantitative Analysis',
  'Queer Studies',
  'Radiation Therapy',
  'Radiology',
  'Range Management',
  'Reading',
  'Recreation',
  'Rehabilitation',
  'Religion',
  'Respiratory Therapy',
  'Rhetoric',
  'Science Reading/Writing',
  'Sensorimotor Development',
  'Sexuality Studies',
  'Social Ecology',
  'Social Justice Studies',
  'Social Psychology',
  'Social Science',
  'Social Sciences',
  'Social Welfare',
  'Social Work',
  'Sociology',
  'Sonography',
  'Spanish',
  'Special Topics',
  'Speech and Hearing Disorders',
  'Speech and Hearing Science',
  'Sports Administration',
  'Sports Medicine',
  'Sports Psychology',
  'Sports Sciences',
  'Statistics',
  'Structure & Bonds',
  'Structure & Reaction',
  'Surgery Tech',
  'Teaching Science',
  'Technical Writing',
  'Test Credit - No Subject',
  'Theater',
  'Theater Literature',
  'Theology',
  'Therapeutic Exercise',
  'Thermodynamics',
  'Toxicology',
  'Trigonometry',
  'Typing',
  'Urban Planning',
  'Veterinary',
  'Virology',
  'Western Civilization',
  'Wildlife',
  `Women's Studies`,
  'Word Processing',
  'Writing',
  'Zoology',
];
