import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useHistory } from 'react-router-dom';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { debounce } from 'lodash';
import LoadingContent from 'components/LoadingContent';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SelectInput from 'components/input/SelectInput';
import SiblingsConfig from './SiblingsConfig';
import { getSiblings, updateSiblings } from 'app/family/familySlice';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import { setArrayLength } from 'components/helper/helper';

const Siblings = () => {
  const [loading, setLoading] = useState(false);
  const family = useAppSelector((store) => store.family);
  const [arraySiblings, setArraySiblings] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const [initialLoad, setInitialLoad] = useState(true);

  const formik = useFormik({
    initialValues: {
      number_of_siblings: '',
      siblings_array: '',
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        student_id: parseInt(studentId || ''),
        siblings_array: JSON.stringify(arraySiblings),
      };
      await dispatch(updateSiblings(newValues));
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.enrolimentStatus.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      let res = await dispatch(getSiblings(studentId));
      const dataLoaded: any = res.payload;
      formik.resetForm({ values: { ...dataLoaded, number_of_siblings: dataLoaded.number_of_siblings || '' } });
      if (dataLoaded.siblings_array) {
        const arraySibling = JSON.parse(dataLoaded.siblings_array);
        setArraySiblings(arraySibling);
      }
      setLoading(false);
      setInitialLoad(false);
    } else {
      handleLogout();
    }
  };

  useEffect(() => {
    getDetail();
  }, []);

  useEffect(() => {
    if (!initialLoad) {
      setArraySiblings(setArrayLength(arraySiblings, formik.values.number_of_siblings));
    }
  }, [formik.values.number_of_siblings]);

  const debouncedSubmit = React.useCallback(
    debounce(() => {
      if (!loading && !initialLoad) {
        formik.handleSubmit();
      }
    }, 1500),
    [formik.values, arraySiblings, loading, initialLoad]
  );

  useEffect(() => {
    if (!initialLoad) {
      debouncedSubmit();
    }
    // Cleanup debounce on unmount
    return () => {
      debouncedSubmit.cancel();
    };
  }, [formik.values, arraySiblings, debouncedSubmit]);

  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[]}
        title="Family Information"
        process={1}
        icon={<PeopleAltOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4 common-coallition-flag push-flag-up">
                Siblings
              </div>
              <SelectInput
                logo="span-cm-logo"
                form={formik}
                name="number_of_siblings"
                values={formik.values.number_of_siblings || 0}
                onChange={formik.handleChange}
                label="Please specify number of siblings you have*"
                option={[
                  { value: 0 },
                  { value: 1 },
                  { value: 2 },
                  { value: 3 },
                  { value: 4 },
                  { value: 5 },
                ]}
              />
              {arraySiblings.map((item, index) => (
                <SiblingsConfig
                  key={index}
                  item={item}
                  total={arraySiblings.length}
                  current={index}
                  handleSave={(values) => {
                    const newArraySiblings = [...arraySiblings];
                    newArraySiblings.splice(index, 1, values);
                    setArraySiblings(newArraySiblings);
                  }}
                />
              ))}
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {family.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={handleContinue}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default Siblings;
