import CardConfig from 'components/CardConfig';
import DatePickerInput from 'components/input/DatePicker';
import GroupRadioButton from 'components/input/GroupRadioButton';
import { useFormik } from 'formik';
import React from 'react';

type Props = {
  index: number;
  item: any;
  handleSave: (value: any) => void;
};

const MilitaryConfig = (props: Props) => {
  React.useEffect(() => {
    if (props.item) formik.setValues(props.item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formik = useFormik({
    initialValues: {
      branch: '',
      startDate: '',
      endDate: '',
    },
    onSubmit: (values) => {},
  });

  React.useEffect(() => {
    if (formik.dirty) {
      props.handleSave(formik.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);
  return (
    <CardConfig
      onExpandClick={() => {}}
      headerElement={
        <>
          <span className="p-1">Military experience {props.index + 1}</span>
        </>
      }
      handleSave={() => {
        props.handleSave(formik.values);
      }}
    >
      <div>Branch</div>
      <GroupRadioButton
        mdCol={6}
        options={[
          { value: 'Air Force' },
          { value: 'Army' },
          { value: 'Coast Guard' },
          { value: 'Marine Corps' },
          { value: 'Navy' },
        ]}
        name="branch"
        values={formik.values.branch}
        onChange={formik.handleChange}
      />
      <div />
      <DatePickerInput
        label="Service Start Date"
        name="startDate"
        values={formik.values.startDate}
        onChange={(value) => formik.setFieldValue('startDate', value)}
      />
      <DatePickerInput
        label="Actual or Projected Service end date"
        name="endDate"
        values={formik.values.endDate}
        onChange={(value) => formik.setFieldValue('endDate', value)}
      />
    </CardConfig>
  );
};

export default MilitaryConfig;
