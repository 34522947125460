import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';

import { debounce } from 'lodash';
import collegeServices from 'services/collegeServices';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { removeDuplicate } from 'components/helper/helper';
import { useFormik } from 'formik';
import GroupRadioButton from 'components/input/GroupRadioButton';
import { Col, Row } from 'react-bootstrap';

export default function ModalLocation({ open, setOpen, formik }: any) {
  const [result, setResult] = React.useState<
    { descr: string; id: number; state_code: string; state_i: string }[]
  >([]);
  const [keyword, setKeyword] = React.useState<string>('');
  const [checkedResult, setCheckedResult] = React.useState<
    { descr: string; id: number; state_code: string; state_i: string }[]
  >([]);

  React.useEffect(() => {
    collegeServices.getState().then((res: any) => setResult(res));
  }, []);

  const onChecked = (
    checked: boolean,
    result: { descr: string; id: number; state_code: string; state_i: string }
  ) => {
    checked
      ? setCheckedResult(removeDuplicate([...checkedResult, result]))
      : setCheckedResult(checkedResult.filter((item) => item.state_code !== result.state_code));
  };
  const formikRegion = useFormik({
    initialValues: {
      region: '',
      type_of_school: '',
      searchKey: '',
    },
    onSubmit: (values) => {},
  });

  const [checkedResultRegion, setCheckedResultRegion] = React.useState<string[]>([]);
  const onCheckedRegion = (checked: boolean, result: string) => {
    checked
      ? setCheckedResultRegion(removeDuplicate([...checkedResultRegion, result]))
      : setCheckedResultRegion(checkedResultRegion.filter((item) => item !== result));
  };
  return (
    <React.Fragment>
      <Dialog open={open} onClose={() => setOpen(false)} keepMounted fullWidth>
        <DialogTitle className="fw-bold fs-3">Location</DialogTitle>
        <DialogContent>
          <Box className="fs-5 fw-bold my-2 text-dark">Search by:</Box>
          <GroupRadioButton
            mdCol={12}
            values={formikRegion.values.searchKey}
            onChange={(e: any) => {
              setCheckedResult([]);
              setCheckedResultRegion([]);
              formikRegion.handleChange(e);
            }}
            name="searchKey"
            label="Search by:"
            options={['State', 'Region']}
          />
          {formikRegion.values.searchKey === 'State' && (
            <>
              <OutlinedInput
                autoFocus
                margin="dense"
                placeholder="Colleges in..."
                fullWidth
                onChange={debounce((e) => setKeyword(e.target.value), 500)}
              />
              <Box sx={{ maxHeight: '35dvh', overflow: 'auto' }}>
                {(result ?? [])
                  .filter((i) => i.descr.toLowerCase().includes(keyword.toLowerCase()))
                  .map((item, index) => (
                    <FormControlLabel
                      key={index}
                      className="w-100 m-0"
                      control={<Checkbox onChange={(e) => onChecked(e.target.checked, item)} />}
                      label={item.descr + ' (' + item.state_code + ')'}
                    />
                  ))}
              </Box>
            </>
          )}
          {formikRegion.values.searchKey === 'Region' && (
            <Row>
              {['Northwest', 'Southwest', 'Northeast', 'Southeast', 'Midwest'].map(
                (item, index) => (
                  <Col key={index} md={4}>
                    <FormControlLabel
                      className="w-100 m-0"
                      control={
                        <Checkbox onChange={(e) => onCheckedRegion(e.target.checked, item)} />
                      }
                      label={item}
                    />
                  </Col>
                )
              )}
            </Row>
            // <GroupRadioButton
            //   mdCol={4}
            //   label="Region"
            //   options={['Northwest', 'Southwest', 'Northeast', 'Southeast', 'Midwest']}
            //   name="region"
            //   onChange={formikRegion.handleChange}
            //   values={formikRegion.values.region}
            // />
          )}
        </DialogContent>
        <DialogActions className="p-4">
          <Button
            variant="contained"
            onClick={() => {
              formik.setFieldValue(
                'state_codes',
                checkedResult.map((item) => item.state_code)
              );
              formik.setFieldValue('region', checkedResultRegion);
              formik.handleSubmit();
              setOpen(false);
            }}
          >
            Show Colleges
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
