import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useFormik } from 'formik';
import GroupRadioButton from 'components/input/GroupRadioButton';

const ModalCollegeInfo = ({ open, setOpen, formik }: any) => {
  const formikRegion = useFormik({
    initialValues: {
      region: '',
      type_of_school_public: false,
      type_of_school_privated: false,
      year_types: 'null' as string | undefined,
    },
    onSubmit: (values) => {},
  });
  return (
    <Dialog open={open} onClose={() => setOpen(false)} keepMounted fullWidth>
      <DialogTitle className="fw-bold fs-3">College Info</DialogTitle>
      <DialogContent>
        {/* <Box className="fw-bold fs-5 mt-3">Religion</Box> */}
        {/* <GroupRadioButton
          options={['Northwest', 'Southwest', 'Northeast', 'Southeast', 'Midwest']}
          name="region"
          onChange={formikRegion.handleChange}
          values={formikRegion.values.region}
        /> */}

        <Box className="fw-bold fs-5 mt-3">Type of School</Box>
        <FormControlLabel
          className="w-100 m-0"
          control={
            <Checkbox
              name="type_of_school_public"
              checked={formikRegion.values.type_of_school_public}
              onChange={formikRegion.handleChange}
            />
          }
          label={'Public'}
        />
        <FormControlLabel
          className="w-100 m-0"
          control={
            <Checkbox
              name="type_of_school_privated"
              checked={formikRegion.values.type_of_school_privated}
              onChange={formikRegion.handleChange}
            />
          }
          label={'Private'}
        />
        <GroupRadioButton
          label="Institution Type"
          options={[
            { label: '2-year', value: '2YEAR', mdCol: 12 },
            { label: '4-year', value: '4YEAR', mdCol: 12 },
            { label: 'All', value: 'null', mdCol: 12 },
          ]}
          name="year_types"
          onChange={formikRegion.handleChange}
          values={formikRegion.values.year_types}
        />
        {/* 4YEAR, 2YEAR */}
      </DialogContent>
      <DialogActions className="p-4">
        <Button
          variant="contained"
          onClick={async () => {
            let data: any = [];
            if (formikRegion.values?.type_of_school_public) {
              data = await data.concat([
                'G',
                'GCI',
                'GCO',
                'GCW',
                'GDI',
                'GF',
                'GP',
                'GS',
                'GSL',
                'GSR',
                'GT',
              ]);
            }
            if (formikRegion.values?.type_of_school_privated) {
              data = await data.concat(['P', 'PN', 'PP', 'PR']);
            }

            formik.setFieldValue('type_of_school', data);
            formik.setFieldValue(
              'year_types',
              formikRegion.values.year_types !== 'null'
                ? [formikRegion.values.year_types]
                : undefined
            );
            formik.handleSubmit();
            setOpen(false);
          }}
        >
          Show Colleges
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalCollegeInfo;
