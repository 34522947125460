/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabUnstyled from '@mui/base/TabUnstyled';
import PersonAddOutlinedIcon from 'components/icons/PersonAddOutlineIcon';
import PersonalInformationOutlinedIcon from 'components/icons/PersonalInformationOutlinedIcon';
import BillingOutlineIcon from 'components/icons/BillingOutlineIcon';
import LockOutlinedIcon from 'components/icons/LockOutlinedIcon';
import BellOutlinedIcon from 'components/icons/BellOutlinedIcon';
import SettingsPersonalInformation from './components/SettingsPersonalInformation';
import SettingsSecurity from './components/SettingsSecurity';
import SettingsCounselor from './components/SettingsCounselor';
import SettingsNotification from './components/SettingsNotification';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SettingsMajor from './components/SettingsMajor';
import SettingBilling from './components/SettingBilling';
import { USER_ROLE } from 'constants/common';
import { useHistory, useLocation } from 'react-router-dom';
import PaymentServices from 'services/payment';
import { AssistWalker } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';

const searchParamsToObject = (str: string) => {
  return str
    .slice(1)
    .split('&')
    .map((p) => p.split('='))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
};
const PersonalSetting = () => {
  const history = useHistory();
  let location = useLocation(); 
  const [activeTab, setActiveTab] = React.useState<any>(0); 
  const [portalUrl, setPortalUrl] = React.useState('');

  function GetPortalUrl()  {
    const domainUrl = `${window.location.origin}`;
    const studentId = parseInt(Cookies.get('userId')??"0");      
    if (localStorage.getItem('userRole') === USER_ROLE.student) {
        PaymentServices.getBillPortalUrl(domainUrl, studentId).then((res: any) => {
          setPortalUrl(res);
          console.log('portalUrl: ',res);
        });
    }
  }

  useEffect(() => {
    GetPortalUrl();
  }, []);


  //#region Create Fixed Column - Setting Menu
  React.useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
      function FixedSettingMenuColumn() {
        var w = ((window.innerWidth - 50)* 16.66666667 /100) ; // .col-md-2     
        let col = document.querySelector("#col-setting-menu");
        if (col) {
        col.style.width = null; // remove style width
        if (window.innerWidth > 1024){
          col.style.width = w +'px';
        }}
      }

      // call on document ready
      FixedSettingMenuColumn();

      // call on resize event
      window.addEventListener('resize', function(){
          FixedSettingMenuColumn();
      });

    `;
    document.body?.appendChild(script);
  }, []);
  //#endregion
  
  useEffect(() => {
    const searchParamsObject: any = searchParamsToObject(location.search);

    if (searchParamsObject?.['active-tab'] !== activeTab.toString()) {
      const tab = searchParamsObject?.['active-tab']
        ? parseInt(searchParamsObject?.['active-tab'])
        : 0;
      setActiveTab(tab);
    }
  }, [location.search]);
  return (
    <TabsUnstyled defaultValue={0}>
      <div className="gm-setting">
        <Row className="m-0">
          <Col md={2} id="col-setting-menu" className="col-setting-menu col-sm-12 m-0 p-0">
            <TabsListUnstyled
              id="tablist-menu"
              className="d-flex flex-md-column align-items-between rounded border-light"
            >
              <TabUnstyled
                className={`tab-label text-start ${activeTab === 0 ? 'activated' : ''}`}
                style={{ minWidth: '190px' }}
                onClick={() => {
                  setActiveTab(0);
                  history.replace({ search: `active-tab=0` });
                }}
              >
                <PersonalInformationOutlinedIcon
                  className="icon-style"
                  width={'18px'}
                  height={'18px'}
                />
                <span className="title">Personal Information</span>
              </TabUnstyled>

              <TabUnstyled
                className={`tab-label text-start ${activeTab === 2 ? 'activated' : ''}`}
                style={{ minWidth: '120px' }}
                onClick={() => {
                  setActiveTab(2);
                  history.replace({ search: `active-tab=2` });
                }}
              >
                <LockOutlinedIcon className="icon-style" />
                <span className="title">Password</span>
              </TabUnstyled>
              <TabUnstyled
                className={`tab-label text-start ${activeTab === 3 ? 'activated' : ''}`}
                style={{ minWidth: '110px' }}
                onClick={() => {
                  setActiveTab(3);
                  history.replace({ search: `active-tab=3` });
                }}
                id="tab-student-3"
              >
                <PersonAddOutlinedIcon className="icon-style" />
                <span className="title">
                  {localStorage.getItem('userRole') === 'counselor' ? 'Students' : 'Counselors'}
                </span>
              </TabUnstyled>
              {localStorage.getItem('userRole') === USER_ROLE.student && (
                <TabUnstyled
                  className={`tab-label text-start ${activeTab === 1 ? 'activated' : ''}`}
                  style={{ minWidth: '190px' }}
                  onClick={() => {
                    setActiveTab(1);
                    history.replace({ search: `active-tab=1` });
                  }}
                  id="tab-student-1"
                >
                  <SchoolOutlinedIcon className="icon-style" width={'18px'} height={'18px'} />
                  <span className="title">Majors of Interests</span>
                </TabUnstyled>
              )}
              <TabUnstyled
                className={`tab-label text-start ${activeTab === 4 ? 'activated' : ''}`}
                style={{ minWidth: '130px' }}
                onClick={() => {
                  setActiveTab(4);
                  history.replace({ search: `active-tab=4` });
                }}
              >
                <BellOutlinedIcon className="icon-style" />
                <span className="title">Notifications</span>
              </TabUnstyled>

              {localStorage.getItem('userRole') === USER_ROLE.student && (
              <TabUnstyled
                className={`tab-label text-start ${activeTab === 5 ? 'activated' : ''}`}
                style={{ minWidth: '130px' }}
                onClick={() => {                 
                  setActiveTab(5);
                  history.replace({ search: `active-tab=5` });
                  
                  // get and update portalUrl
                  //GetPortalUrl();
                  //window.location.href = portalUrl;
                }}
              >
                <BillingOutlineIcon className="icon-style" />
                <span className="title">Billing</span>
              </TabUnstyled>
              )}
            </TabsListUnstyled>
          </Col>
          <Col md={9} className="col-setting-content col-sm-12 m-0 p-0">
            {activeTab === 0 && <SettingsPersonalInformation />}
            {activeTab === 1 && <SettingsMajor />}
            {activeTab === 2 && <SettingsSecurity />}
            {activeTab === 3 && <SettingsCounselor />}
            {activeTab === 4 && <SettingsNotification />}
            {portalUrl && activeTab === 5 && <SettingBilling />}
          </Col>
        </Row>
      </div>
    </TabsUnstyled>
  );
};

export default PersonalSetting;
