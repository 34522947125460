import { checkEnableParseJSON } from 'components/helper/helper';
import { CheckboxContent } from './ItemContent';
import { Row } from 'react-bootstrap';
import moment from 'moment';

type Props = {
  data: any;
};

const CLEPSum = (props: Props) => {
  const data = checkEnableParseJSON(props.data, []);
  return Array.isArray(data) ? (
    <div className="my-3">
      {(data ?? []).map((item: any, index: number) => (
        <Row key={index}>
          {[
            moment(item?.date_taken).format('MM-DD-YYYY'),
            item?.CLEPExam,
            `Score: ${item?.score}`,
          ].join(' - ')}

          <CheckboxContent label="Score Not Yet Received" values={item?.is_not_received} />
        </Row>
      ))}
    </div>
  ) : null;
};

export default CLEPSum;
