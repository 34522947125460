/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Card, Grid, Stack, Typography } from '@mui/material';
import TextAreaInput from 'components/input/TextAreaInput';
import CheckboxInput from 'components/input/CheckboxInput';
import { checkEnableParseJSON } from 'components/helper/helper';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout, setParent } from 'app/basicInformationSlice/basicInformationSlice';
import { setCheckModule } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import counselorServices from 'services/counselorServices';
import collegeServices from 'services/collegeServices';
import RPersonalInformation, { moduleName } from 'screen/counselor/components/RPersonalInformation';
import { NoteModal, NoteStatus } from 'screen/counselor/counselor.types';
import { useFormik } from 'formik';
import moment from 'moment';
import './sumary.scss';

export const checkModule: any = {
  '1.1': [false, false],
  '1.2': [false, false],
  '1.3': [false, false],
  '1.4': [false, false],
  '1.5': [false, false],
  '2.1': [false, false],
  '2.2': [false, false],
  '2.3': [false, false],
  '2.4': [false, false],
  '3.1': [false, false],
  '3.2': [false, false],
  '3.3': [false, false],
  '4.1': [false, false],
  '4.2': [false, false],
  '4.3': [false, false],
  '4.4': [false, false],
  '4.5': [false, false],
  '5.1': [false, false],
  '5.2': [false, false],
  '5.3': [false, false],
  '5.4': [false, false],
  '5.5': [false, false],
  '5.6': [false, false],
  '6.1': [false, false],
  '6.2': [false, false],
  '6.3': [false, false],
  '6.6': [false, false],
  '6.5': [false, false],
  '7.1': [false, false],
  '7.2': [false, false],
  '7.3': [false, false],
  '8.1': [false, false],
  '8.2': [false, false],
};
const tabContent: any = {
  1: 'Personal Information',
  2: 'Family',
  3: 'Education',
  4: 'Tests',
  5: 'Courses & Grades',
  6: 'Activities & Honors',
  7: 'Additional Info',
};
const initValues: {
  content: string;
  status: NoteStatus;
  isStudentReviewed: boolean;
  isCounselorApproved: boolean;
} = {
  content: '',
  status: '',
  isStudentReviewed: false,
  isCounselorApproved: false,
};

const ReviewPersonal = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const [listCounselorConnection, setListCounselorConnection] = React.useState<any[]>([]);
  const [currentModuleId, setCurrentModuleId] = React.useState('1.1');

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const getData = () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      counselorServices.getCounselor(studentId).then((res: any[]) => {
        setListCounselorConnection(res ?? []);
      });      
    }
    else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Grid container sx={{ width: '100%' }}>
      <Grid item xs={12} md={8}>
        <RPersonalInformation setCurrentModuleId={setCurrentModuleId} />
      </Grid>
      <Grid item xs={12} md={4} sx={{ marginTop: { md: '48px' } }}>
        {listCounselorConnection.map((item, index) => (
          <ConversationCard conversationItem={item} currentModuleId={currentModuleId} key={index} />
        ))}
      </Grid>
    </Grid>
  );
};

export const ConversationCard = ({
  conversationItem,
  currentModuleId,
}: {
  conversationItem: any;
  currentModuleId: any;
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [notesData, setNotesData] = React.useState<NoteModal[]>([]);
  const personalStore = useAppSelector((store) => store.basicInformation.myBasicInformation);
  const [checkedData, setCheckedData] = React.useState(checkModule);
  const [studentReviewed, setStudentReviewed] = React.useState(false);
  const [partnerInfo, setParnerInfo] = useState<any>(null);
  const [runCount, setRunCount] = useState(0);
  let token = Cookies.get(authenticateSettings.tokenName);
  
  const formik = useFormik({
    initialValues: initValues,
    onSubmit: (values) => {
      var creatorName = [
        personalStore.first_name,
        personalStore.middle_name,
        personalStore.last_name,
      ].join(' ');

      if (conversationItem?.partner_info?.email) {
        const body = {
          content: values.content,
          sender: creatorName,
          email: conversationItem?.partner_info?.email,
          receiver: conversationItem?.partner_info?.first_name,
          linkPage: `${tabContent[currentModuleId.slice(0, 1)]} > ${moduleName[currentModuleId]}`,
        };
        currentModuleId.includes('8.')
          ? collegeServices?.sendMailChat(body)
          : collegeServices.sendMailUserMessage(body);
      }

      let arr = {
        ...checkedData,
        [currentModuleId]: [studentReviewed, checkedData?.[currentModuleId]?.[1]],
      };

      setCheckedData(arr);
     
      let idToQuery = conversationItem?.id;
      counselorServices.updatePartnerById({
        id: idToQuery,
        notes_array: JSON.stringify({
          checkModule: arr,
        }),
      }).then((res:any)=>{
        let couserlorId = conversationItem?.partner_info?.student_id || '';
        if (couserlorId) {
          counselorServices.sendChatNotesMessage({
            module_id: currentModuleId, 
            couserlor_id: couserlorId,
            student_id: personalStore.student_id,
            creator: creatorName,
          from_id: personalStore.student_id,
          to_id: couserlorId,
          content: values.content,
          status: values.status
            }).then((res2:any)=>{
              counselorServices.getChatNotesMessage(`${currentModuleId}|${conversationItem?.partner_id}|${conversationItem?.student_id}`)
                .then((arr:any) => {
                  setNotesData(arr);
              })
          });
        }
     });
      formik.resetForm();
      getPartnerDataById(false);
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };     

  const getPartnerDataById = async (onInit: boolean) => {
    if (!conversationItem?.id) return;
      token = Cookies.get(authenticateSettings.tokenName);
    if (token) {
      counselorServices.getPartnerById(conversationItem.id).then((res: any) => {
        setParnerInfo(res);
        dispatch(setParent(res));
  
        counselorServices.getChatNotesMessage(`${currentModuleId}|${res.partner_id}|${res.student_id}`)
          .then((arr:any) => {
            setNotesData(arr);
        });
  
        const loadedCheckModule =
          checkEnableParseJSON(res?.notes_array, {
            checkModule,
            notes: [],
          })?.checkModule ?? checkModule;


        setCheckedData(loadedCheckModule);
        dispatch(setCheckModule(loadedCheckModule));
        if (onInit) {
          setStudentReviewed(loadedCheckModule?.[currentModuleId]?.[0]); 
        }        
       
      });
    }
    else {
      handleLogout();
    }
  }


  React.useEffect(() => {
    getPartnerDataById(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModuleId, conversationItem.id]);

  

  function refreshChatNotes(){
    setTimeout(function(){
      setRunCount(runCount+1);
      if (!conversationItem?.id) return;
      if (partnerInfo) {
        getPartnerDataById(false);
      }
    }, 1000);
}

  React.useEffect(() => {
    refreshChatNotes();
  }, [runCount]);

  return (
    <Stack sx={{ margin: '16px !important' }}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary>
          <div className="w-100 ">
            <Typography className="fw-bold mx-1 d-block w-100">
              Student: {[personalStore?.first_name, personalStore?.last_name].join(' ')}
            </Typography>
            <Typography className="fw-bold mx-1 d-block w-100">
              Counselor:{' '}
              {[
                conversationItem.partner_info?.first_name,
                conversationItem.partner_info?.last_name,
              ].join(' ')}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Card sx={{ backgroundColor: 'white', m: 1, p: 1 }}>
            <Typography className="fw-bold mx-1 d-block w-100">
              {moduleName[currentModuleId]} Status
            </Typography>
            <Row>
              <CheckboxInput
                label={'Student reviewed'}
                name={'isStudentReviewed'}
                values={studentReviewed}
                onChange={(e) => {
                  setStudentReviewed(e.target.checked);
                  formik.setFieldValue(
                    'content',
                    e.target.checked ? 'Student Marked Reviewed' : 'Student UN-Checked Reviewed'
                  );
                }}
              />
              <CheckboxInput
                disabled
                label={'Counselor approved'}
                name={'isCounselorApproved'}
                values={checkedData?.[currentModuleId]?.[1]}
                // onChange={(e) => {
                //   setCheckedData({
                //     ...checkedData,
                //     [currentModuleId]: [checkedData?.[currentModuleId]?.[0], e.target.checked],
                //   });
                // }}
              />
              <div />
              <TextAreaInput
                onChange={formik.handleChange}
                name="content"
                values={formik.values.content}
                mdCol="12"
                rows={2}
              />
              <Col xs={2} md={12}>
                <Button
                  variant="primary"
                  className="py-2 w-100"
                  type="submit"
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </Button>
              </Col>
            </Row>
            <Typography className="fw-bold fs-5 mx-2">Notes</Typography>
            <Card sx={{ backgroundColor: '#F7F8FA', m: 1, p: 1 }}>
              {notesData
                .filter((item) => item.conversation_id.split('|')[0] === currentModuleId)
                .map((item, index) => (
                  <Row key={index} className="my-2">
                    <Col md={12}>
                      <Typography className="fw-bold fs-6">{item.creator}</Typography>
                    </Col>

                    <Col md={12}>
                      <Typography className="text-field-light d-inline">
                        {moment(item.created_at).format('MM/DD/YYYY - hh:mm A')}
                      </Typography>
                    </Col>
                    <Col md={12}>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: item.content.replace(/\n/g, ' <br> '),
                        }}
                      />
                    </Col>
                  </Row>
                ))}
            </Card>
          </Card>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default ReviewPersonal;
