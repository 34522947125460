export const authenticateSettings = {
  tokenName: 'access_token',
  passwordSite: 'sl619BP!^)',
};

export const primaryColor = '#000000';
export const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm');
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const visaTypeOptions = [
  'A1 - Diplomatic/Consular Official',
  'A2 - Other Government Official',
  'AM - Amnesty recipient',
  'AS - Political Asylum',
  'B2 - Temporary Visitor - Pleasure',
  'E1 - Treaty Trader',
  'E2 - Treaty Investor',
  'E3 - Australian in specialty occupation',
  'F1 - Student',
  'F2 - Spouse/Child of Student',
  'G4 - International org. officials & family',
  'H1 - Temp worker, exceptional services',
  'H2 - Temp worker, unavailable services',
  'H4 - Spouse/child of H1, H2, H3',
  'I - Foreign information media or family',
  'J1 - Exchange Visitor',
  'J2 - Spouse/child of J1',
  'K1 - Fiancee/Fiance of US citizen',
  'L1 - Intracompany transferee',
  'L2 - Spouse/child of L1',
  'M1 - Vocational',
  'M2 - Spouse/child of M1',
  'OT - Other Visa Type',
  'T1 - Victim of trafficking in persons',
  'T2 - Spouse of T1',
  'T3 - Child of T1',
  'T4 - Parent of T1',
  'TD - NAFTA dependant',
  'TN - NADTA-',
  'U1 - Victim of certain criminal activity',
  'U2 - Spouse of U1',
  'U3 - Child of U1',
  'U4 - Parent of U1',
];

export const otherLanguageOptions = [
  'English',
  'Abkhaz',
  'Achinese',
  'Acoli',
  'Adangme',
  'Adygei',
  'Afar',
  'Afrihili',
  'Afrikaans',
  'Afroasiatic',
  'Akan',
  'Akkadian',
  'Albanian',
  'Aleut',
  'Algonquian',
  'Altaic',
  'American Sign Language',
  'Amharic',
  'Apache languages',
  'Arabic',
  'Aragonese Spanish',
  'Aramaic',
  'Arapaho',
  'Arawak',
  'Armenian',
  'Artifical',
  'Assamese',
  'Athapascan',
  'Australian languages',
  'Austronesian',
  'Avaric',
  'Avestan',
  'Awadhi',
  'Aymara',
  'Azerbaijani',
  'Bable',
  'Balinese',
  'Baltic',
  'Baluchi',
  'Minangkabau',
  'Bambara',
  'Bamileke languages',
  'Banda',
  'Basa',
  'Bashkir',
  'Basque',
  'Batak',
  'Beja',
  'Belarusian',
  'Bemba',
  'Bengali',
  'Berber',
  'Bhojpuri',
  'Bihari',
  'Bikol',
  'Bislama',
  'Bosnian',
  'Braj',
  'Breton',
  'Bugis',
  'Bulgarian',
  'Buriat',
  'Burmese',
  'Cantonese',
  'Caddo',
  'Carib',
  'Catalan',
  'Caucasian',
  'Cebuano',
  'Celtic',
  'Central American Indian',
  'Chagatai',
  'Chamic languages',
  'Chamorro',
  'Chehen',
  'Cherokee',
  'Cheyenne',
  'Chibcha',
  'Chinese',
  'Chinook jargon',
  'Chipewyan',
  'Choctaw',
  'Church Slavic',
  'Chuvash',
  'Coptic',
  'Cornish',
  'Corsican',
  'Cree',
  'Creek',
  'Creoles and Pidgins',
  'Creoles and Pidgins Eng',
  'Creoles and Pidgins French',
  'Creoles and Pidgins Portu',
  'Crimean Tatar',
  'Croatian',
  'Cushitic',
  'Czech',
  'Dakota',
  'Danish',
  'Urdu',
  'Dargwa',
  'Dari',
  'Dayak',
  'Delaware',
  'Dinka',
  'Divehi',
  'Dogri',
  'Dogrib',
  'Dravidian',
  'Duala',
  'Dutch',
  'Dutch, Middle',
  'Dyula',
  'Dzongkha',
  'Edo',
  'Efik',
  'Egyptian',
  'Ekajuk',
  'Elamite',
  'Esperanto',
  'Estonian',
  'Ethiopic',
  'Ewe',
  'Ewondo',
  'Fang',
  'Fante',
  'Fanti',
  'Faroese',
  'Farsi',
  'Fijian',
  'Finnish',
  'Finno Urgian',
  'Fon',
  'French',
  'French, Middle',
  'French, Old',
  'Frisian',
  'Fruilian',
  'Fula',
  'Ga',
  'Galician',
  'Ganda',
  'Gayo',
  'Gbaya',
  'Georgian',
  'German',
  'German, Middle High',
  'Gernman, Old High',
  'Germanic',
  'Gilbertese',
  'Gondi',
  'Gorontalo',
  'Gothic',
  'Grebo',
  'Greek, Ancient',
  'Greek, Moden',
  'Guarani',
  'Gujarati',
  `Gwich'in`,
  'Haida',
  'Haitian French Creole',
  'Hausa',
  'Hawaiian',
  'Hebrew',
  'Herero',
  'Hiligaynon',
  'Himaachali',
  'Hindi',
  'Hiri Motu',
  'Hittite',
  'Hmong',
  'Hokkien',
  'Hungarian',
  'Hupa',
  'Iban',
  'Icelandic',
  'Ido',
  'Idomo',
  'Igbo',
  'Ijo',
  'Iloko',
  'Inari Sami',
  'Indic',
  'Indo European',
  'Indonesian',
  'Ingush',
  'Interlingua',
  'Interlingue',
  'Inuktitut',
  'Inupiaq',
  'Iranian',
  'Irish',
  'Irish, Old',
  'Iroquoian',
  'Italian',
  'Japanese',
  'Javanese',
  'Judeo Persian',
  'Kabardian',
  'Kabyle',
  'Kachin',
  'Kalatdlisut',
  'Kalmyk',
  'Kamba',
  'Kannada',
  'Kanuri',
  'Kara Kalpak',
  'Karen',
  'Kashmiri',
  'Kawi',
  'Kazakh',
  'Khasi',
  'Khmer',
  'Khoisan',
  'Khotanese',
  'Kiembu',
  'Kikamba',
  'Kikongo',
  'Kikuyu',
  'Kimbundu',
  'Bantu',
  'Kimeru',
  'Kinyarwanda',
  'Komi',
  'Kongo',
  'Konkani',
  'Korean',
  'Kpelle',
  'Kru',
  'Kuanyama',
  'Kumyk',
  'Kurdish',
  'Kurukh',
  'Kusaie',
  'Kutenai',
  'Kyrgyz',
  'Ladino',
  'Lahnda',
  'Lamba',
  'Lao',
  'Latin',
  'Latvian',
  'Latzeburgesch',
  'Lezgian',
  'Limburgish',
  'Lingala',
  'Lithuanian',
  'Low German',
  'Lozi',
  'Luba Katanga',
  'Luba Lulua',
  'Luiseno',
  'Lule Sami',
  'Lunda',
  'Luo',
  'Lushai',
  'Macedonian',
  'Madurese',
  'Magahi',
  'Maithili',
  'Makasar',
  'Malagasy',
  'Malay',
  'Malayalam',
  'Maltese',
  'Manchu',
  'Mandarin',
  'Mandingo',
  'Manipuri',
  'Manobo languages',
  'Manx',
  'Maori',
  'Mapuche',
  'Marathi',
  'Mari',
  'Marshallese',
  'Marwari',
  'Masai',
  'Mayan languages',
  'Mende',
  'Micmac',
  'Miscellaneous languages',
  'Mohawk',
  'Moldavian',
  'Mon Khmer',
  'Mongo Nkundu',
  'Mongolian',
  'Moore',
  'Multiple languages',
  'Munda',
  'Nahuatl',
  'Nauru',
  'Navajo',
  'Ndebele',
  'Ndonga',
  'Neapolitan Italian',
  'Nepali',
  'Newari',
  'Nias',
  'Niger Kordofanian',
  'Nilo Saharan',
  'Niuean',
  'Nogai',
  'North American Indian',
  'Northern Sami',
  'Northern Sotho',
  'Norwegian',
  'Nubian languages',
  'Nuer',
  'Nyamwezi',
  'Nyanja',
  'Nyankole',
  'Nyoro',
  'Nzema',
  'Nzima',
  'Occitan',
  'Ojibwa',
  'Old Norse',
  'Old Pertian',
  'Oriya',
  'Oromo',
  'Osaga',
  'Ossetic',
  'Otomian languages',
  'Pahlavi',
  'Palauan',
  'Pali',
  'Pampanga',
  'Pangasinan',
  'Papiamento',
  'Papuan',
  'Pashto',
  'Persian',
  'Philippine',
  'Phoenician',
  'Polish',
  'Ponape',
  'Portuguese',
  'Prakrit languages',
  'Punjabi',
  'Provencal',
  'Pushto',
  'Quechua',
  'Raeto Romance',
  'Rajasthani',
  'Rapanui',
  'Rarotongan',
  'Romance',
  'Romani',
  'Romanian',
  'Rundi',
  'Russian',
  'Salishan languages',
  'Samaritan Aramaic',
  'Sami',
  'Samoan',
  'Sandawe',
  'Sango',
  'Sanskrit',
  'Santali',
  'Sardinian',
  'Sasak',
  'Scots',
  'Scottish Gaelic',
  'Selkup',
  'Semitic',
  'Serbian',
  'Serbo-Croatian',
  'Serer',
  'Shan',
  'Shanghainese',
  'Shona',
  'Sichuan Yi',
  'Sidamo',
  'Sign languages',
  'Siksika',
  'Sindhi',
  'Sinhalese',
  'Sino Tibetan',
  'Siouan',
  'Skolt Sami',
  'Slave',
  'Slavic',
  'Slovak',
  'Slovenian',
  'Sogdian',
  'Somali',
  'Songhai',
  'Soninke',
  'Sorbian languages',
  'Sortho',
  'South American Ingian',
  'Southern Sami',
  'Spanish',
  'Sukuma',
  'Sumerian',
  'Sundanese',
  'Susu',
  'Swahili',
  'Swazi',
  'Swedish',
  'Sylhetti',
  'Syriac',
  'Tagalog',
  'Tahitian',
  'Tai',
  'Tajik',
  'Tamashek',
  'Tamil',
  'Tatar',
  'Telugu',
  'Temne',
  'Terena',
  'Tetum',
  'Thai',
  'Tibetan',
  'Tigre',
  'Tigrinya',
  'Tiv',
  'Tlingit',
  'Tok Pisin',
  'Tokelauan',
  'Tongan',
  'Truk',
  'Tsimshian',
  'Tsongo',
  'Tswana',
  'Tumbuka',
  'Tupi languages',
  'Turkish',
  'Turkish, Ottoman',
  'Turkmen',
  'Tuvaluan',
  'Tuvinian',
  'Twi',
  'Udmurt',
  'Ugaritic',
  'Uighur',
  'Ukranian',
  'Umbundu',
  'Undetermined',
  'Uzbek',
  'Vai',
  'Venda',
  'Vietnamese',
  'Volapuk',
  'Votic',
  'Wakashan languages',
  'Walamo',
  'Walloon',
  'Waray',
  'Washo',
  'Welsh',
  'Wolof',
  'Xhosa',
  'Yakut',
  'Yao',
  'Yapese',
  'Yiddish',
  'Yoruba',
  'Yupic languages',
  'Zande',
  'Zapotec',
  'Zenaga',
  'Zhuang',
  'Zulu',
];

export const CBOData = [
  '100 Black Men',
  '21st Century Scholars',
  'A Better Chance (ABC)',
  'Achieve Program',
  'AMIDEAST',
  'Arkansas Commitment',
  'APS Dallas',
  'ASPIRA',
  'Attollo Prep',
  'AVID',
  'Better Angels',
  'Bottom Line',
  `Boy's Club of NY`,
  'Boy/Girls Scouts of America',
  'Boys & Girls Club',
  'Boys Hope Girls Hope',
  'BRASA',
  'Breakthrough',
  'Bridgeport Public Education Fund',
  'Bridges Project for Education',
  'Bridges to a Brighter Future',
  'Bright Prospect',
  'Building STEPS',
  'C5 Association of Youth Programs',
  'Cal-SOAP',
  'Career Beginnings',
  'Career GPS Center at St. Nicks Alliance',
  'Center for Student Opportunity',
  'Chicago Scholars',
  'Chinese-American Planning Council',
  'Cloud Peak Project',
  'College Advising Corps',
  'College and Career Pathways',
  'College Bound',
  'College Bound Opportunities (CBO)',
  'College Confident',
  'College Crusade of Rhode Island',
  'College Forward',
  'Colllge Horizons',
  'College Match',
  'College Now Greater Cleveland',
  'College Possible',
  'College Submit',
  'College Track',
  'College Visions',
  'CollegeBound Initiative (NYC)',
  'College Community Career',
  'CollegePoint',
  'CollegeTrack - Montgomery County',
  'Collegiate Directions Inc',
  'Cooperman College Scholars',
  'Cristo Rey Network',
  'Crosby Scholars Program',
  'Daniel Murphy Scholarship Fund',
  'Daniel Trust Foundation',
  'Delaware College Scholars',
  'DREAM-RBI',
  'Early Academic Outreach Program (EAOP)',
  'East Harlem Tutorial Program',
  'EducationUSA',
  'EMERGE',
  'Emily K Center',
  'ETS',
  'Evanston Scholars',
  'First Graduate',
  `Fiver Children's Foundation`,
  'Fulfillment Fund',
  'Gear Up',
  'Genesys Works',
  'Guided Pathway Support Program (GPS); Ron Brown Scholar Initiative',
  'Hartford Youth Scholars',
  'Heckscher Scholars Program',
  'Henry Street Settlement Expanded Horizons College Success Program',
  'Higher Heights YEP',
  'HighSight',
  'Hispanic Scholarship Fund (HSF)',
  'I Have a Dream',
  'I Know I Can',
  'IEFA-USA',
  'IMANI',
  'iMentor',
  'Indian Education',
  'InspireSTL',
  'Jack and Jill',
  'Jack Kent Cooke Foundation',
  'John Hopkins CTY Scholars',
  'KIPP',
  'Latino Student Fund (LSF)',
  'Latino U College Access',
  'Launch U',
  'LEDA',
  `Let's Get Ready`,
  'Link Unlimited',
  'Making Waves',
  'Male Initiative Project',
  'Matriculate',
  'MEP',
  'MetroSquash',
  'Minds Matter',
  'MOSTe',
  'N.C. Sli',
  'Neighborhood House',
  'Neighborhood Youth Association',
  `New Settlement's College Access Center`,
  'Next Generation Venture Fund',
  'Nicholas Academic Center',
  'NJ LEEP',
  'NJ SEEDS',
  'Northwestern Academy for Chicago Public Schools',
  'Oasis College Connection',
  'Oliver Scholars',
  'One Goal',
  'One Voice',
  'Options Center - Goddard Riverside Community Center',
  'Other',
  'Palouse Pathways',
  'Philadelphia Futures',
  'POSSE',
  'Prep for Prep',
  'Project Grad',
  'Project Greer Street',
  'Project Reach Youth (PRY)',
  'Project SEED',
  'Puente',
  'Project',
  'Quest Students in Naples FL',
  'QuestBridge',
  'Rainer Scholars',
  'RAW Art Works',
  'Reach Memphis',
  'Reach Prep',
  'Red Hook Initiative',
  'Say Yes to Education',
  'ScholarMatch',
  'Schuler Scholar Program',
  'Scripps College Academy',
  'SCS Noonan Scholars',
  'SEO Scholars (Sponsors for Educational Opportunity)',
  'Service to School',
  'South Asian Youth Action (SAYA!)',
  'STEP: Student Expedition Program',
  'Steppingstone Scholars',
  'Stevenson to College',
  'Strive to College',
  'Student Expedition Program',
  'Summer Search',
  'Summerbridge',
  'Sutton Trust US Programme (US-UK Fullbright Commission)',
  'Target Hope',
  'TGR Foundation - A Tiger Woods Charity',
  'The BrandLab',
  'The College Initiative',
  'The Education Alliance (Boys and Girls Club)',
  'The Opportunity Network',
  'The Philadelphia Education Fund College Access Program',
  'The TEAK Fellowship',
  'TRIO - Educational Opportunity Centers (EOC)',
  'TRIO - Educational Talent Search',
  'TRIO - SSS (Student Support Services)',
  'TRIO - Upward Bound',
  'TRIO - Upward Bound Math-Science',
  'TRIO - Veterans Upward Bound',
  'U-Lead Athens',
  'University of Chicago Collegiate Scholars Program',
  'Urban Debate League',
  'Venture Scholars',
  'White-Williams',
  'Wight Foundation',
  'William H. Douglas Foundation',
  'Younkers Partners in Education',
  'Young Voices',
  'YMCA',
];

export const ibSubjectHaveLanguageQuestion = [
  'Art History',
  'Astronomy',
  'Biology',
  'Business Management',
  'Chemistry',
  'Chile & Pac.',
  'Classical Greek & Roman Literature',
  'Computer Science',
  'Dance',
  'Design Tech.',
  'Economics',
  'Environemtal Systems and Societies',
  'Film',
  'Further Mathematics',
  'Geography',
  'Global Politics',
  'History',
  'History of Africa',
  'History of Asia and Oceana',
  'History of Europe and the Middle East',
  'History of the Americas',
  'Information Technology in a Global Society',
  'Literature and Performance',
  'Marine Science',
  'Mathematical Studies',
  'Mathematics',
  'Music',
  'Music Creating',
  'Music Group Performance',
  'Music Solo Performance',
  'Peace and Conflict Studies',
  'Philosophy',
  'Political Thought',
  'Psychology',
  'Science, Technology and Society',
  'Social and Cultural Anthropology',
  'Sports Exercise and Health Science',
  'Theatre',
  'Visual Arts',
  'World Arts and Cultures',
  'World Religions',
];

export const ibSubjectOption = [
  'Afrikaans A',
  'Afrikaans A: Literature',
  'Akan A',
  'Akan A: Literature',
  'Albanian A',
  'Albanian A: Literature',
  'Amharic A',
  'Amharic A: Literature',
  'Arabic A',
  'Arabic A: Language and Literature',
  'Arabic A: Literature',
  'Arabic AB',
  'Arabic B',
  'Armenian A',
  'Armenian A: Literature',
  'Art History',
  'Assyrian A',
  'Assyrian A: Literature',
  'Astronomy',
  'Azerbaijani A',
  'Azerbaijani A: Literature',
  'Beginning Hindi',
  'Belarusian A: Literature',
  'Bemba A',
  'Bemba A: Literature',
  'Bengali A',
  'Bengali A: Literature',
  'Biology',
  'Bosnian A',
  'Bosnian A: Literature',
  'Brazilian Social Studies',
  'Bulgarian A',
  'Bulgarian A: Literature',
  'Burmese A',
  'Burmese A: Literature',
  'Business Management',
  'Catalan A',
  'Catalan A: Literature',
  'Chemistry',
  'Chichewa A',
  'Chichewa A: Literature',
  'Chile & Pac.',
  'Chinese A',
  'Chinese A: Language and Literature',
  'Chinese B',
  'Chinese B - Cantonese',
  'Chinese B - Mandarin',
  'Classical Greek',
  'Classical Greek & Roman Studies',
  'Computer Science',
  'Croatian A',
  'Croatian A: Literature',
  'Czech A',
  'Czech A: Literature',
  'Dance',
  'Danish A',
  'Danish A: Literature',
  'Danish AB',
  'Danish B',
  'Design Tech.',
  'Dhivehi A',
  'Dhivehi A: Literature',
  'Digital society',
  'Dutch A',
  'Dutch A: Language and Literature',
  'Dutch A: Literature',
  'Dutch AB',
  'Dutch B',
  'Dzongkha A',
  'Dzongkha A: Literature',
  'Economics',
  'Efik A',
  'English A',
  'English A: Language and Literature',
  'English A: Literature',
  'English AB',
  'English B',
  'Environmental Systems and Societies',
  'Estonian A',
  'Estonian A: Literature',
  'Faroese A',
  'Faroese A: Literature',
  'Fijian A',
  'Filipino A',
  'Filipino A: Literature',
  'Film',
  'Finnish A',
  'Finnish A: Literature',
  'Finnish B',
  'Food science and technology',
  'French A',
  'French A: Language and Literature',
  'French A: Literature',
  'French AB',
  'French B',
  'Further Mathematics',
  'Gaelic A: Literature',
  'Galician A',
  'Geography',
  'Georgian A',
  'Georgian A: Literature',
  'German A',
  'German A: Language and Literature',
  'German A: Literature',
  'German AB',
  'German B',
  'Global Politics',
  'Greenlandic A',
  'Greenlandic A: Literature',
  'Gujarati A',
  'Hausa A',
  'Hausa A: Literature',
  'Hebrew A',
  'Hebrew A: Literature',
  'Hebrew B',
  'Hindi A',
  'Hindi A: Literature',
  'Hindi B',
  'History',
  'History of Africa and the Middle East',
  'History of Asia and Oceania',
  'History of Europe and the Middle East',
  'History of the Americas',
  'Hungarian A',
  'Hungarian A: Literature',
  'Icelandic A',
  'Icelandic A: Literature',
  'Igbo A',
  'Igbo A: Literature',
  'Indonesian A',
  'Indonesian A: Language and Literature',
  'Indonesian A: Literature',
  'Indonesian AB',
  'Indonesian B',
  'Information Technology in a Global Society',
  'Irish A',
  'Irish A: Literature',
  'Italian A',
  'Italian A: Language and Literature',
  'Italian A: Literature',
  'Italian AB',
  'Italian B',
  'Japanese A',
  'Japanese A: Language and Literature',
  'Japanese A: Literature',
  'Japanese AB',
  'Japanese B',
  'Kannada A',
  'Kazakh A',
  'Kazakh A: Literature',
  'Khmer A',
  'Khmer A: Literature',
  'Kinyarwanda A',
  'Kinyarwanda A: Literature',
  'Koran A: Language and Literature',
  'Korean A',
  'Korean A: Literature',
  'Korean B',
  'Kurdish A',
  'Kurdish A: Literature',
  'Kyrgyz A',
  'Lao A',
  'Lao A: Literature',
  'Latin',
  'Latvian A',
  'Latvian A: Literature',
  'Literature and Performance',
  'Lithuanian A',
  'Lithuanian A: Literature',
  'Lozi A',
  'Luganda A',
  'Luganda A: Literature',
  'Lunda A',
  'Macedonian A: Literature',
  'Macedonian A',
  'Malagasy A',
  'Malagasy A: Literature',
  'Malay A',
  'Malay A: Literature',
  'Malay AB',
  'Malayalam A',
  'Mandarin AB',
  'Marathi A',
  'Marathi A: Literature',
  'Marine Science',
  'Mathematical Studies',
  'Mathematics',
  'Mathematics Analysis and Approaches',
  'Mathematics Applications and Interpretations',
  'Mathematics Calculus',
  'Mathematics Discrete',
  'Mathematics Sets',
  'Mathematics Statistics',
  'Modern Greek A',
  'Modern Greek A: Language and Literature',
  'Modern Greek: Literature',
  'Modern History of Kazakhstan',
  'Mongolian A',
  'Mongolian A: Literature',
  'Music',
  'Music Creating',
  'Music Group Performance',
  'Music Solo Performance',
  'Nature of Science',
  'Ndebele A',
  'Ndebele A: Literature',
  'Nepali A',
  'Nepali A: Literature',
  'Norwegian A',
  'Norwegian A: Language and Literature',
  'Norwegian A: Literature',
  'Norwegian B',
  'Oromo A: Literature',
  'Oshindonga A: Literature',
  'Pashto A',
  'Pashto A: Literature',
  'Peace and Conflict Studies',
  'Persian A ',
  'Persian A: Literature',
  'Philosophy',
  'Physics',
  'Polish A',
  'Polish A: Literature',
  'Political Thought',
  'Portuguese A',
  'Portuguese A: Language and Literature',
  'Portuguese A: Literature',
  'Portuguese B',
  'Psychology',
  'Punjabi A',
  'Punjabi A: Literature',
  'Romanian A',
  'Romanian A: Literature',
  'Romansch A',
  'Russian A',
  'Russian A: Language and Literature',
  'Russian A: Literature',
  'Russian AB',
  'Russian B',
  'Samoan A',
  'Science, Technology and Society',
  'Serbian A',
  'Serbian A: Literature',
  'Sesotho A',
  'Sesotho A: Literature',
  'Setswana A',
  'Shona A',
  'Shona A: Literature',
  'Sinhalese A',
  'Sinhalese A: Literature',
  'Siswati A',
  'Siswati A: Literature',
  'Slovak A',
  'Slovak A: Literature',
  'Slovene A',
  'Slovene A Literature',
  'Social and Cultural Anthropology',
  'Somali A',
  'Somali A: Literature',
  'Spanish A',
  'Spanish A: Language and Literature',
  'Spanish A: Literature',
  'Spanish AB',
  'Spanish B',
  'Sports Exercise and Health Science',
  'Swahili A',
  'Swahili A: Literature',
  'Swahili AB',
  'Swahili B',
  'Swedish A',
  'Swedish A: Language and Literature',
  'Swedish A: Literature',
  'Swedish AB',
  'Swedish B',
  'Tajik A',
  'Tajik A: Literature',
  'Tamil A',
  'Tamil A: Literature',
  'Tamil B',
  'Telugu A',
  'Telugu A: Literature',
  'Thai A',
  'Thai A: Language and Literature',
  'Thai A: Literature',
  'Theatre',
  'Theatre (Pilot)',
  'Tibetan A',
  'Tibetan A: Literature',
  'Tigrinya A',
  'Tigrinya A: Literature',
  'Tonga A',
  'Turkey in the 20th Century',
  'Turkish A',
  'Turkish A: Literature',
  'Ukrainian A',
  'Ukrainian A: Literature',
  'Urdu A',
  'Urdu A: Literature',
  'Uzbek A',
  'Vietnamese A',
  'Vietnamese A: Literature',
  'Visual Arts',
  'Welsh A',
  'Welsh A: Literature',
  'World Arts and Cultures',
  'World Religions',
  'Xhosa A',
  'Yoruba A',
  'Zulu A',
  'Zulu A: Literature',
];

export const ucibSubjectOption = [
  {
    value: 'Languages A1',
    optionHL: [
      'AFRIKAANS A LIT',
      'AKAN A LIT',
      'ALBANIAN A LIT',
      'AMHARIC A LIT',
      'ARABIC A LAL',
      'ARABIC A LIT',
      'ARMENIAN A LIT',
      'AZERBAIJA A LIT',
      'B/RUSSIAN A LIT',
      'BENGALI A LIT',
      'CATALAN A LIT',
      'CHINESE A LAL',
      'CHINESE A LIT',
      'CROATIAN A LIT',
      'CZECH A LIT',
      'DANISH A LIT',
      'DHIVEHI A LIT',
      'DHIVEHI A1',
      'DUTCH A LAL',
      'DUTCH A LIT',
      'EFIK A LIT',
      'ENGLISH A LAL',
      'ENGLISH A LIT',
      'FAROESE A LIT',
      'FIJIAN A LIT',
      'FINNISH A LIT',
      'FRENCH A LAL',
      'FRENCH A LIT',
      'GALICIAN A LIT',
      'GEORGIAN A LIT',
      'GEORGIAN A1',
      'GERMAN A LAL',
      'GERMAN A LIT',
      'GUJARATI A LIT',
      'HEBREW A LIT',
      'HINDI A LIT',
      'HUNGARIAN A LIT',
      'ICELANDIC A LIT',
      'IGBO A LIT',
      'INDONESIAN A LIT',
      'INUKTITUT A LIT',
      'IRISH A LIT',
      'ITALIAN A LAL',
      'ITALIAN A LIT',
      'JAPANESE A LAL',
      'JAPANESE A LIT',
      'KANNADA A LIT',
      'KHMER A LIT',
      'KOREAN A LIT',
      'KURDISH A LIT',
      'KYRGYZ A LIT',
      'LATVIAN A LIT',
      'LITHUANIAN A LIT',
      'LOZI A LIT',
      'LUGANDA A LIT',
      'LUNDA A LIT',
      'MACEDONIAN A LIT',
      'MALAY A LIT',
      'MALAYALAM A LIT',
      'MALAYALAM A1',
      'MALTESE A LIT',
      'MAORI A LIT',
      'MARATHI A LIT',
      'MOD. GREEK A LAL',
      'MOD. GREEK A LIT',
      'MONGOLIAN A LIT',
      'NDEBELE A LIT',
      'NEPALI A LIT',
      'NORWEGIAN A LAL',
      'NORWEGIAN A LIT',
      'PASHTO A LIT',
      'PERSIAN A LIT',
      'PILIPINO A LAL',
      'PILIPINO A LIT',
      'POLISH A LIT',
      'PORTUGUESE A LAL',
      'PORTUGUESE A LIT',
      'PUNJABI A LIT',
      'ROMANIAN A LIT',
      'ROMANSCH A LIT',
      'RUSSIAN A LAL',
      'RUSSIAN A LIT',
      'SAMOAN A LIT',
      'SERBIAN A LIT',
      'SESOTHO A LIT',
      'SETSWANA A LIT',
      'SHONA A LIT',
      'SINHALESE A LIT',
      'SISWATI A LIT',
      'SLOVAK A LIT',
      'SLOVENE A LIT',
      'SOMALI A LIT',
      'SPANISH A LAL',
      'SPANISH A LIT',
      'SWAHILI A LIT',
      'SWEDISH A LAL',
      'SWEDISH A LIT',
      'TAMIL A LIT',
      'THAI A LAL',
      'THAI A LIT',
      'TIBETAN A LIT',
      'TIGRINYA A LIT',
      'TONGA A LIT',
      'TURKISH A LIT',
      'UKRAINIAN A LIT',
      'URDU A LIT',
      'UZBEK A LIT',
      'VIETNAMESE A LIT',
      'WELSH A LIT',
      'XHOSA A LIT',
      'YORUBA A LIT',
      'ZULU A LIT',
      'Other',
    ],
    optionSL: [
      'AFRIKAANS A LIT',
      'AKAN A LIT',
      'ALBANIAN A LIT',
      'AMHARIC A LIT',
      'ARABIC A LAL',
      'ARABIC A LIT',
      'ARMENIAN A LIT',
      'AZERBAIJA A LIT',
      'B/RUSSIAN A LIT',
      'BEMBA A LIT',
      'CATALAN A LIT',
      'CHICHEWA A LIT',
      'CHICHEWA A1',
      'CHINESE A LAL',
      'CHINESE A LIT',
      'CROATIAN A LIT',
      'CZECH A LIT',
      'DANISH A LIT',
      'DHIVEHI A LIT',
      'DHIVEHI A1',
      'DUTCH A LAL',
      'DUTCH A LIT',
      'EFIK A LIT',
      'ENGLISH A LAL',
      'ENGLISH A LIT',
      'FAROESE A LIT',
      'FIJIAN A LIT',
      'FINNISH A LIT',
      'FRENCH A LAL',
      'FRENCH A LIT',
      'GALICIAN A LIT',
      'GEORGIAN A LIT',
      'GERMAN A LAL',
      'GERMAN A LIT',
      'GREENLAND A LIT',
      'GUJARATI A LIT',
      'HEBREW A LIT',
      'HINDI A LIT',
      'HUNGARIAN A LIT',
      'ICELANDIC A LIT',
      'IGBO A LIT',
      'INDONESIAN A LIT',
      'INUKTITUT A LIT',
      'IRISH A LIT',
      'ITALIAN A LAL',
      'ITALIAN A LIT',
      'JAPANESE A LAL',
      'JAPANESE A LIT',
      'KANNADA A LIT',
      'KAREN A LIT',
      'KAZAKH A LIT',
      'KHMER A LIT',
      'KHMER A1',
      'KINYARWAND A LIT',
      'KOREAN A LIT',
      'KURDISH A LIT',
      'KYRGYZ A LIT',
      'LAO A LIT',
      'LATVIAN A LIT',
      'LITHUANIAN A LIT',
      'LOZI A LIT',
      'LUGANDA A LIT',
      'LUNDA A LIT',
      'MACEDONIAN A LIT',
      'MALAY A LIT',
      'MALAYALAM A LIT',
      'MALAYALAM A1',
      'MALTESE A LIT',
      'MAORI A LIT',
      'MARATHI A LIT',
      'MOD. GREEK A LAL',
      'MOD. GREEK A LIT',
      'MONGOLIAN A LIT',
      'NDEBELE A LIT',
      'NEPALI A LIT',
      'NORWEGIAN A LAL',
      'NORWEGIAN A LIT',
      'ORIYA A LIT',
      'PAPIAMENTU A LIT',
      'PASHTO A LIT',
      'PERSIAN A LIT',
      'PILIPINO A LAL',
      'PILIPINO A LIT',
      'POLISH A LIT',
      'PORTUGUESE A LAL',
      'PORTUGUESE A LIT',
      'PUNJABI A LIT',
      'ROMANIAN A LIT',
      'ROMANSCH A LIT',
      'RUSSIAN A LAL',
      'RUSSIAN A LIT',
      'SAMOAN A LIT',
      'SERBIAN A LIT',
      'SESOTHO A LIT',
      'SETSWANA A LIT',
      'SHONA A LIT',
      'SINHALESE A LIT',
      'SISWATI A LIT',
      'SLOVAK A LIT',
      'SLOVENE A LIT',
      'SOMALI A LIT',
      'SPANISH A LAL',
      'SPANISH A LIT',
      'SWAHILI A LIT',
      'SWEDISH A LAL',
      'SWEDISH A LIT',
      'TAJIK A LIT',
      'TAMIL A LIT',
      'TELUGU A LIT',
      'THAI A LAL',
      'THAI A LIT',
      'TIBETAN A LIT',
      'TIGRINYA A LIT',
      'TONGA A LIT',
      'TURKISH A LIT',
      'UKRAINIAN A LIT',
      'URDU A LIT',
      'UZBEK A LIT',
      'VIETNAMESE A LIT',
      'WELSH A LIT',
      'XHOSA A LIT',
      'YORUBA A LIT',
      'ZULU A LIT',
      'Other',
    ],
  },

  {
    value: 'Second Languages A2, B',
    optionHL: [
      'AFRIKAANS B',
      'AMHARIC B',
      'ARABIC B',
      'ARMENIAN B',
      'ASANTE B',
      'BEMBA B',
      'BENGALI B',
      'BULGARIAN B',
      'BURMESE B',
      'CANTONESE B',
      'CATALAN B',
      'CLASS.GREEK',
      'CZECH B',
      'DANISH B',
      'DUTCH B',
      'DZONGKHA B',
      'EFIK B',
      'ENGLISH B',
      'ESTONIAN B',
      'FANTE B',
      'FIJIAN B',
      'FINNISH B',
      'FRENCH B',
      'GERMAN B',
      'GUJARATI B',
      'HEBREW B',
      'HINDI B',
      'HUNGARIAN B',
      'ICELANDIC B',
      'IGBO B',
      'INDONESIAN B',
      'INUKTITUT B',
      'IRISH B',
      'ITALIAN B',
      'JAPANESE B',
      'KOREAN B',
      'LATIN',
      'LITHUANIAN B',
      'LOZI B',
      'LUGANDA B',
      'LUNDA B',
      'LUO B',
      'MACEDONIAN B',
      'MALAY B',
      'MALAYALAM B',
      'MALTESE B',
      'MANDARIN B',
      'MAORI B',
      'MARATHI B',
      'MOD. GREEK B',
      'NDEBELE B',
      'NDONGA B',
      'NEPALI B',
      'NORWEGIAN B',
      'PERSIAN B',
      'PILIPINO B',
      'POLISH B',
      'PORTUGUESE B',
      'PUNJABI B',
      'ROMANIAN B',
      'ROMANSCH B',
      'RUSSIAN B',
      'SAMOAN B',
      'SERBO-CROAT B',
      'SESOTHO B',
      'SETSWANA B',
      'SHONA B',
      'SINHALESE B',
      'SISWATI B',
      'SLAVEY B',
      'SLOVAK B',
      'SLOVENE B',
      'SOMALI B',
      'SPANISH B',
      'SWAHILI B',
      'SWEDISH B',
      'TAMIL B',
      'THAI B',
      'TIGRINYA B',
      'TONGA B',
      'TURKISH B',
      'UKRAINIAN B',
      'URDU B',
      'VIETNAMESE B',
      'WELSH B',
      'XHOSA B',
      'YORUBA B',
      'ZULU B',
      'Other',
    ],
    optionSL: [
      'AFRIKAANS B',
      'AMHARIC B',
      'ARABIC AB.',
      'ARABIC B',
      'ARMENIAN B',
      'ASANTE B',
      'BEG. HINDI',
      'BEG. NYNORSK',
      'BEG. SISWATI',
      'BEMBA B',
      'BENGALI B',
      'BULGARIAN B',
      'BURMESE B',
      'CANTONESE B',
      'CATALAN B',
      'CLASS.GREEK',
      'CZECH B',
      'DANISH B',
      'DUTCH B',
      'DZONGKHA B',
      'EFIK B',
      'ENGLISH AB.',
      'ENGLISH B',
      'ESTONIAN B',
      'FANTE B',
      'FIJIAN B',
      'FINNISH B',
      'FRENCH AB.',
      'FRENCH B',
      'GERMAN AB.',
      'GERMAN B',
      'GUJARATI B',
      'HEBREW B',
      'HINDI AB.',
      'HINDI B',
      'HUNGARIAN B',
      'ICELANDIC B',
      'IGBO B',
      'INDONESIAN AB',
      'INDONESIAN B',
      'INUKTITUT B',
      'IRISH B',
      'ITALIAN AB.',
      'ITALIAN B',
      'JAPANESE AB.',
      'JAPANESE B',
      'KOREAN B',
      'LATIN',
      'LITHUANIAN B',
      'LOZI B',
      'LUGANDA B',
      'LUNDA B',
      'LUO B',
      'MACEDONIAN B',
      'MALAY AB.',
      'MALAY B',
      'MALAYALAM B',
      'MALTESE B',
      'MANDARIN AB.',
      'MANDARIN B',
      'MAORI B',
      'MARATHI B',
      'MOD. GREEK B',
      'NDEBELE B',
      'NDONGA B',
      'NEPALI B',
      'NORWEGIAN B',
      'PERSIAN B',
      'PILIPINO B',
      'POLISH B',
      'PORTUGUESE B',
      'PUNJABI B',
      'ROMANIAN B',
      'ROMANSCH B',
      'RUSSIAN AB.',
      'RUSSIAN B',
      'SAMOAN B',
      'SERBO-CROAT B',
      'SESOTHO B',
      'SETSWANA B',
      'SHONA B',
      'SINHALESE B',
      'SISWATI B',
      'SLAVEY B',
      'SLOVAK B',
      'SLOVENE B',
      'SOMALI B',
      'SPANISH AB.',
      'SPANISH B',
      'SWAHILI AB.',
      'SWAHILI B',
      'SWEDISH B',
      'TAMIL B',
      'THAI B',
      'TIGRINYA B',
      'TONGA B',
      'TURKISH B',
      'UKRAINIAN B',
      'URDU B',
      'VIETNAMESE B',
      'WELSH B',
      'XHOSA B',
      'YORUBA B',
      'ZULU B',
      'Other',
    ],
  },
  {
    value: 'Individuals and Society',
    optionHL: [
      'BUSINESS MANAGEMENT',
      'ECONOMICS',
      'GEOGRAPHY',
      'HIST.AFRICA',
      'HIST.AMERICAS',
      'HIST.ASIA/M.E',
      'HIST.ASIA/OCE',
      'HIST.EUROPE',
      'HIST.EUROPE/ISLAMIC WORLD',
      'HISTORY',
      'ISLAMIC HIST.',
      'ITGS',
      'PHILOSOPHY',
      'PSYCHOLOGY',
      'SOC.CUL.ANTH.',
      'Other',
    ],
    optionSL: [
      'ACCOUNTING',
      'ANC. GK. CIV.',
      'ANCIENT HIST.',
      'BRAZ.SOC.STUD',
      'BUSINESS MANAGEMENT',
      'CHILE & PAC.',
      'CHINESE STDS.',
      'CL.GK.ROM.ST.',
      'CLASSICAL CIV',
      'ECO. AND SOC.',
      'ECONOMICS',
      'EUROPE. STDS.',
      'EXP. PSYCHLGY',
      'FIJIAN STUDS.',
      'GEOGRAPHY',
      'HISPANIC STD.',
      'HIST.AFRICA',
      'HIST.AMERICAS',
      'HIST.ASIA/M.E',
      'HIST.ASIA/OCE',
      'HIST.EUROPE',
      'HISTORY',
      'HUMAN RIGHTS',
      'ISLAMIC HIST.',
      'ITGS',
      'JAPANESE STD.',
      'PEACE&CON.ST.',
      'PHILOSOPHY',
      'POLITICAL TH.',
      'PSYCHOLOGY',
      'RELIGIOUS EXP',
      'SCI.TECH.SOC.',
      'SOC.CUL.ANTH.',
      'SOCIAL STDS.',
      'TURK 20TH CEN',
      'TURK.SOC.STUD',
      'U.S. HISTORY',
      'WLD ART CULT',
      'WLD. POLITICS',
      'WORLD CULT.',
      'WORLD RELIG.',
      'Other',
    ],
  },
  {
    value: 'Experimental Sciences',
    optionHL: ['BIOLOGY', 'CHEM&PHYS SYS', 'CHEMISTRY', 'DESIGN TECH.', 'PHYSICS', 'Other'],
    optionSL: [
      'ASTRONOMY',
      'BIOLOGY',
      'CHEMISTRY',
      'DESIGN TECH.',
      'ENV. AND SOC.',
      'ENVIRON.SYST.',
      'MARINE SCI.',
      'PHYSICS',
      'Other',
    ],
  },
  {
    value: 'Mathematical and Computer Science',
    optionHL: ['COMPUTER SC.', 'MATHEMATICS ANALYSIS', 'MATHEMATICS APPLICATION', 'Other'],
    optionSL: ['COMPUTER SC.', 'MATHEMATICS ANALYSIS', 'MATHEMATICS APPLICATION', 'Other'],
  },
  {
    value: 'Arts',
    optionHL: [
      'DANCE',
      'FILM',
      'MUSIC',
      'THEATRE',
      'THEATRE ARTS',
      'VISUAL ARTS',
      'VISUAL ARTS A',
      'VISUAL ARTS B',
      'Other',
    ],
    optionSL: [
      'ART HISTORY',
      'ASIAN ARTS',
      'DANCE',
      'DRAMA',
      'ELECTRO. MUS.',
      'ELECTRO.SYST.',
      'ELECTRONICS',
      'FILM',
      'FOOD SC & TEC',
      'LIT AND PERF',
      'MUSIC',
      'MUSIC COMP.',
      'MUSIC GR.PERF',
      'MUSIC SO.PERF',
      'NUTRITION.SC.',
      'SPORTS EX SCI',
      'THEATRE',
      'THEATRE ARTS',
      'VISUAL ARTS',
      'VISUAL ARTS A',
      'VISUAL ARTS B',
      'Other',
    ],
  },
];
export const cnibSubjectOption = [
  'Art History SL',
  'Astronomy SL',
  'Biology SL',
  'Beginners SL',
  `Beginners' Hindi SL`,
  'Brazilian Socual Studies SL',
  'Business and Management HL',
  'Business and Management SL',
  'Chemistry HL',
  'Chemistry SL',
  'Chile and the Pacific Basin SL',
  'Classical Greek and Roman Studies SL',
  'Classical Languages HL: Greek',
  'Classical Languages HL: Latin',
  'lassicalLanguages SL: Greek',
  'Classical Languages SL: Latin',
  'Computer Science HL',
  'Computer Science SL',
  'Contemporary History of Kazakhstan SL',
  'Dance HL',
  'Dance SL',
  'Design Technology HL',
  'Design Technology SL',
  'Economics HL',
  'Economics SL',
  `Environmental Systems'Env. Systems and Societies SL`,
  'Film HL',
  'Film SL',
  'Food Science and Technology SL',
  'Further Mathematics HL',
  'Further Mathematics SL',
  'Geography HL',
  'Geography SL',
  'Global Politics HL',
  'Global Politics SL',
  'History HL: Afirca and the Middle East',
  'history HL: Americas',
  'History HL: Asia',
  'History HL: Europe',
  'History SL',
  'Information Technology in a Global Society HL',
  'Information Technology in a Global Society SL',
  'Language A: Language and Literasture HL',
  'Language A: Language and Literasture SL',
  'Language A: Literature HL',
  'Language A: Literature SL',
  'Language A1 HL',
  'Language A1 SL',
  'Language A2 HL',
  'Language A2 SL',
  'Language Ab Initio SL',
  'Language B HL: Arabic',
  'Language B HL: Chinese - Cantonese',
  'Language B HL: Chinese - Mandarin',
  'Language B HL: Dutch',
  'Language B HL: English',
  'Language B HL: Finnish',
  'Language B HL: French',
  'Language B HL: German',
  'Language B HL: Hindi',
  'Language B HL: Indonesian',
  'Language B HL: Italian',
  'Language B HL: Japanese',
  'Language B HL: Korean',
  'Language B HL: Norwegian',
  'Language B HL: Portuguese',
  'Language B HL: Russian',
  'Language B HL: Spanish',
  'Language B HL: Swahili',
  'Language B HL: Tamil',
  'Language B SL: Arabic',
  'Language B SL: Chinese - Cantonese',
  'Language B SL: Chinese - Mandarin',
  'Language B SL: Dutch',
  'Language B SL: English',
  'Language B SL: Finnish',
  'Language B SL: French',
  'Language B SL: German',
  'Language B SL: Hebrew',
  'Language B SL: Hindi',
  'Language B SL: Indonesian',
  'Language B SL: Italian',
  'Language B SL: Japanese',
  'Language B SL: Korean',
  'Language B SL: Malay',
  'Language B SL: Norwegian',
  'Language B SL: Portuguese',
  'Language B SL: Russian',
  'Language B SL: Spanish',
  'Language B SL: Swahili',
  'Language B SL: Swedish',
  'Language B SL: Tamil',
  'Literature and Performance SL',
  'Marine Science SL',
  'Mathematics HL: Calculus',
  'Mathematics HL: Discrete Mathematics',
  'Mathematics HL: Sets, relations, and Groups',
  'Mathematics HL: Statistics and Probability',
  'Mathematics SL',
  'Mathematics Studies SL',
  'Music HL',
  'Music SL',
  'Philiosophy HL',
  'Philosophy SL',
  'Physics HL',
  'Physics SL',
  'Political Thought SL',
  'Psychology HL',
  'Psychology SL',
  'Science, Technology and Society SL',
  'Social and Cultural Anthropology HL',
  'Social and Cultural Anthropology SL',
  'Sports Exercise and Health Science SL',
  'Theatre HL',
  'Theatre SL',
  'Turkey in the 20th Century SL',
  'Visual Arts HL',
  'Visual Arts SL',
  'World Arts and Cultures SL',
  'World Relgions SL',
];

export const csu_ibSubjectOption = [
    'Biology HL',
    'Biology SL',
    'Business and Management HL',
    'Business and Management SL',
    'Chemistry HL',
    'Chemistry SL',
    'Classical Languages HL',
    'Classical Languages SL',
    'Computer Science HL',
    'Computer Science SL',
    'Dance HL',
    'Dance SL',
    'Design technology HL',
    'Design technology SL',
    'Economics HL',
    'Economics SL',
    'Environmental systems societies SL',
    'Film HL',
    'Film SL',
    'Further mathematics HL',
    'Geography HL',
    'Geography SL',
    'Global Politics HL',
    'Global Politics SL',
    'History HL',
    'History SL',
    'Information technology in a global society HL',
    'Information technology in a global society SL',
    'Language A: language and literature HL',
    'Language A: language and literature SL',
    'Language A: language and literature SL',
    'Language A: literature HL',
    'Language A: literature SL',
    'Language ab initio SL',
    'Language B HL',
    'Language B SL',
    'Literature and performance SL',
    'Mathematical studies SL',
    'Mathematics HL',
    'Mathematics SL',
    'Mathematics: analysis and approaches HL',
    'Mathematics: analysis and approaches SL',
    'Mathematics: applications and interpretation HL',
    'Mathematics: applications and interpretation SL',
    'Music HL',
    'Music SL',
    'Philosophy HL',
    'Philosophy SL',
    'Physics HL',
    'Physics SL',
    'Psychology HL',
    'Psychology SL',
    'Social and cultural anthropology HL',
    'Social and cultural anthropology SL',
    'Sports, exercise and health science SL',
    'Theatre HL',
    'Theater SL',
    'Visual Arts HL',
    'Visual Arts SL',
    'World religions SL'
];

export const activityNumberOfYearAfter12 = [
  { value: null, name: 'Year' },
  { value: '0', name: '0' },
  { value: '1', name: '1' },
  { value: '2', name: '2' },
  { value: '3', name: '3' },
  { value: '4', name: '4' },
  { value: '5', name: '5' },
  { value: '6', name: '6' },
  { value: '7', name: '7' },
  { value: '8', name: '8' },
  { value: '9', name: '9' },
  { value: '10', name: '10' },
  { value: '11', name: '10+' },
];
export const activityNumberOfMonthAfter12 = [
  { value: null, name: 'Month' },
  { value: '1', name: '1' },
  { value: '2', name: '2' },
  { value: '3', name: '3' },
  { value: '4', name: '4' },
  { value: '5', name: '5' },
  { value: '6', name: '6' },
  { value: '7', name: '7' },
  { value: '8', name: '8' },
  { value: '9', name: '9' },
  { value: '10', name: '10' },
  { value: '11', name: '11' },
];
export const sportOptions = [
  'Archery',
  'Badminton',
  'Baseball',
  'Basketball',
  'Bowling',
  'Boxing',
  'Cheerleading',
  'Cricket',
  'Cross-country',
  'Diving',
  'Equestrian',
  'Fencing',
  'Field Hockey',
  'Figure Skating',
  'Football',
  'Football (non-tackle)',
  'Golf',
  'Gymnastics',
  'Handball',
  'Ice Hockey',
  'Indoor Track',
  'Judo',
  'Lacrosse',
  'Other Sport',
  'Other Track',
  'Racquet Ball',
  'Rifle',
  'Rock climbing/Bouldering',
  'Rodeo',
  'Rowing (Crew)',
  'Rugby',
  'Sailing',
  'Skiing',
  'Skin Diving',
  'Soccer',
  'Softball',
  'Squash',
  'Swim',
  'Sync. Swimming',
  'Table Tennis',
  'Tennis',
  'Track and Field',
  'Triathlon',
  'Ultimate Frisbee',
  'Volleyball',
  'Water polo',
  'Weight lifting',
  'Wrestling',
];

export const primaryTribalAffiliationOption = [
  'AK - Afognak',
  'AK - Agdaagux',
  'AK - Akhiok',
  'AK - Akiachak',
  'AK - Akiak',
  'AK - Akutan',
  'AK - Alakanuk',
  'AK - Alatna',
  'AK - Aleknagik',
  'AK - Algaaciq',
  'AK - Allakaket',
  'AK - Alutiiq Tribe of Old Harbor',
  'AK - Ambler',
  'AK - Anaktuvuk Pass',
  'AK - Andreafski',
  'AK - Angoon',
  'AK - Aniak',
  'AK - Anvik',
  'AK - Arctic Village',
  `AK - Asa'carsarmiut`,
  'AK - Atka',
  'AK - Atmautluak',
  'AK - Atqasuk',
  'AK - Barrow',
  'AK - Beaver',
  'AK - Belkofski',
  `AK - Bill Moore's Slough`,
  'AK - Birch Creek',
  'AK - Brevig Mission',
  'AK - Buckland',
  'AK - Cantwell',
  'AK - Chalkyitsik',
  'AK - Cheesh-Na',
  'AK - Chefornak',
  'AK - Chenega',
  'AK - Chevak',
  'AK - Chickaloon',
  'AK - Chignik Bay',
  'AK - Chignik Lagoon',
  'AK - Chignik Lake',
  'AK - Chilkat',
  'AK - Chilkoot',
  'AK - Chinik',
  'AK - Chitina',
  'AK - Chuathbaluk',
  'AK - Chuloonawick',
  'AK - Circle',
  `AK - Clark's Point`,
  'AK - Council',
  'AK - Craig',
  'AK - Crooked Creek',
  'AK - Curyung',
  'AK - Deering',
  'AK - Diomede',
  'AK - Dot Lake',
  'AK - Douglas',
  'AK - Eagle',
  'AK - Eek',
  'AK - Egegik',
  'AK - Eklutna',
  'AK - Ekuk',
  'AK - Ekwok',
  'AK - Elim IRA',
  'AK - Emmonak',
  'AK - Evansville',
  'AK - Eyak',
  'AK - False Pass',
  'AK - Fort Yukon',
  'AK - Gakona',
  'AK - Galena',
  'AK - Gambell',
  'AK - Georgetown',
  'AK - Goodnews Bay',
  'AK - Grayling',
  'AK - Gulkana',
  'AK - Hamilton',
  'AK - Healy Lake',
  'AK - Holy Cross',
  'AK - Hoonah',
  'AK - Hooper Bay',
  'AK - Hughes',
  'AK - Huslia',
  'AK - Hydaburg',
  'AK - Igiugig',
  'AK - Iliamna',
  'AK - Inupiat Community of the Arctic Slope',
  'AK - Iqugmiut',
  'AK - Ivanof Bay Tribe',
  'AK - Kaguyuk',
  'AK - Kake',
  'AK - Kaktovik',
  'AK - Kalskag',
  'AK - Kaltag',
  'AK - Kanatak',
  'AK - Karluk',
  'AK - Kasaan',
  'AK - Kasigluk',
  'AK - Kenaitze',
  'AK - Ketchikan',
  'AK - Kiana',
  'AK - King Island',
  'AK - King Salmon',
  'AK - Kipnuk',
  'AK - Kivalina',
  'AK - Klawock',
  'AK - Kluti-Kaah',
  'AK - Knik',
  'AK - Kobuk',
  'AK - Kokhanok',
  'AK - Kongiganak',
  'AK - Kotlik',
  'AK - Kotzebue',
  'AK - Koyuk',
  'AK - Koyukuk',
  'AK - Kwethluk',
  'AK - Kwigillingok',
  'AK - Kwinhagak',
  'AK - Larsen Bay',
  'AK - Levelock',
  'AK - Lime',
  'AK - Lower Kalskag',
  'AK - Manley Hot Springs',
  'AK - Manokotak',
  'AK - Marshall',
  `AK - Mary's Igloo`,
  'AK - Mcgrath',
  'AK - Mekoryuk',
  'AK - Mentasta',
  'AK - Metlakatla',
  'AK - Minto',
  'AK - Naknek',
  'AK - Nanwalek',
  'AK - Napaimute',
  'AK - Napakiak',
  'AK - Napaskiak',
  'AK - Nelson Lagoon',
  'AK - Nenana',
  'AK - New Koliganek',
  'AK - New Stuyahok',
  'AK - Newhalen',
  'AK - Newtok',
  'AK - Nightmute',
  'AK - Nikolai',
  'AK - Nikolski',
  'AK - Ninilchik',
  'AK - Noatak',
  'AK - Nome',
  'AK - Nondalton',
  'AK - Noorvik',
  'AK - Northway',
  'AK - Nuiqsut',
  'AK - Nulato',
  'AK - Nunakauyarmiut',
  'AK - Nunam Iqua',
  'AK - Nunapitchuk',
  'AK - Ohogamiut',
  'AK - Orutsararmiut',
  'AK - Oscarville',
  'AK - Ouzinkie',
  'AK - Paimiut',
  'AK - Pauloff Harbor',
  'AK - Pedro Bay',
  'AK - Perryville',
  'AK - Petersburg',
  'AK - Pilot Point',
  'AK - Pilot Station',
  `AK - Pitka's Point`,
  'AK - Platinum',
  'AK - Point Hope IRA',
  'AK - Point Lay IRA',
  'AK - Port Graham',
  'AK - Port Heiden',
  'AK - Port Lions',
  'AK - Portage Creek',
  'AK - Qagan Tayagungin',
  'AK - Qawalangin',
  'AK - Rampart',
  'AK - Red Devil',
  'AK - Ruby',
  'AK - Saint Paul',
  'AK - Salamatof',
  'AK - Savoonga',
  'AK - Saxman',
  'AK - Scammon Bay',
  'AK - Selawik',
  'AK - Seldovia',
  'AK - Shageluk',
  'AK - Shaktoolik',
  'AK - Shishmaref IRA',
  'AK - Shungnak',
  'AK - Sitka',
  'AK - Skagway',
  'AK - Sleetmute',
  'AK - Solomon',
  'AK - South Naknek',
  'AK - St. George',
  'AK - St. Michael IRA',
  'AK - Stebbins',
  'AK - Stevens Village',
  'AK - Stony River',
  `AK - Sun'aq`,
  'AK - Takotna',
  'AK - Tanacross',
  'AK - Tanana',
  'AK - Tangirnaq',
  'AK - Tatitlek',
  'AK - Tazlina',
  'AK - Telida',
  'AK - Teller',
  'AK - Tetlin',
  'AK - Tlingit & Haida',
  'AK - Togiak',
  'AK - Tuluksak',
  'AK - Tuntutuliak',
  'AK - Tununak',
  'AK - Twin Hills',
  'AK - Tyonek',
  'AK - Ugashik',
  'AK - Umkumiut',
  'AK - Unalakleet',
  'AK - Unga',
  'AK - Venetie',
  'AK - Venetie IRA',
  'AK - Wainwright',
  'AK - Wales',
  'AK - White Mountain AK',
  'AK - Wrangell',
  'AK - Yakutat',
  'AL - Poarch',
  'AZ - Ak Chin',
  'AZ - Cocopah',
  'AZ - Colorado River',
  'AZ - Fort McDowell',
  'AZ - Gila River',
  'AZ - Havasupai',
  'AZ - Hopi',
  'AZ - Hualapai',
  'AZ - Kaibab',
  'AZ - Navajo',
  'AZ - Pascua Yaqui',
  'AZ - Quechan',
  'AZ - Salt River',
  'AZ - San Carlos',
  'AZ - San Juan',
  `AZ - Tohono O'odham`,
  'AZ - Tonto Apache',
  'AZ - White Mountain',
  'AZ - Yavapai-Apache',
  'AZ - Yavapai-Prescott',
  'CA - Agua Caliente',
  'CA - Alturas',
  'CA - Augustine',
  'CA - Bear River',
  'CA - Benton',
  'CA - Berry Creek',
  'CA - Big Lagoon',
  'CA - Big Pine',
  'CA - Big Sandy',
  'CA - Big Valley Rancheria',
  'CA - Bishop Paiute',
  'CA - Blue Lake',
  'CA - Bridgeport Indian Colony',
  'CA - Buena Vista Rancheria',
  'CA - Cabazon',
  'CA - Cachil DeHe',
  'CA - Cahto',
  'CA - Cahuilla',
  'CA - California Valley',
  'CA - Campo',
  'CA - Capitan Grande',
  'CA - Cedarville',
  'CA - Chemehuevi',
  'CA - Cher-Ae Heights',
  'CA - Chicken Ranch',
  'CA - Cloverdale',
  'CA - Cold Springs',
  'CA - Cortina',
  'CA - Coyote Valley',
  'CA - Dry Creek',
  'CA - Elem',
  'CA - Elk Valley',
  'CA - Enterprise',
  'CA - Ewiiaapaayp',
  'CA - Federated Indians of Graton',
  'CA - Fort Bidwell',
  'CA - Fort Independence',
  'CA - Fort Mojave',
  'CA - Greenville',
  'CA - Grindstone',
  'CA - Guidiville',
  'CA - Habematolel',
  'CA - Hoopa',
  'CA - Hopland',
  'CA - Iipay',
  'CA - Inaja',
  'CA - Ione',
  'CA - Jackson',
  'CA - Jamul',
  'CA - Karuk',
  'CA - Kashia',
  'CA - Koi',
  'CA - La Jolla',
  'CA - La Posta',
  'CA - Lone Pine',
  'CA - Los Coyotes',
  'CA - Lytton',
  'CA - Manchester',
  'CA - Manzanita',
  'CA - Mechoopda',
  'CA - Mesa Grande',
  'CA - Middletown',
  'CA - Mooretown',
  'CA - Morongo',
  'CA - North Fork',
  'CA - Pala',
  'CA - Paskenta',
  'CA - Pauma',
  'CA - Pechanga',
  'CA - Picayune',
  'Ca - Pinoleville',
  'CA - Pit River',
  'CA - Potter Valley',
  'CA - Quartz Valley',
  'CA - Ramona',
  'CA - Redding',
  'CA - Redwood Valley',
  'CA - Resighini',
  'CA - Rincon',
  'CA - Robinson',
  'CA - Round Valley',
  'CA - San Manuel',
  'CA - San Pasqual',
  'CA - Santa Rosa',
  'CA - Santa Rosa of Cahuilla',
  'CA - Santa Ynez',
  'CA - Scotts Valley',
  'CA - Sherwood Valley',
  'CA - Shingle Springs',
  'CA - Soboba',
  'CA - Susanville',
  'CA - Sycuan',
  'CA - Table Mountain',
  'CA - Tejon',
  'CA - Timbi-sha Shoshone',
  `CA - Tolowa Dee-Ni'`,
  'CA - Torres Martinez',
  'CA - Tule River',
  'CA - Tuolumne',
  'CA - Twenty-Nine Palms',
  'CA - United Auburn',
  'CA - Viejas',
  'CA - Wilton',
  'CA - Wiyot',
  'CA - Yocha Dehe',
  'CA - Yurok',
  'CO - Southern Ute',
  'CO - Ute Mountain Ute',
  'CT - Mashantucket Pequot',
  'CT - Mohegan',
  'FL - Miccosukee',
  'FL - Seminole',
  'IA - Sac & Fox of Mississippi',
  `ID - Coeur D'Alene`,
  'ID - Kootenai',
  'ID - Nez Perce',
  'ID - Shoshone-Bannock',
  'KS - Iowa of Kansas',
  'KS - Kickapoo of Kansas',
  'KS - Prairie Band',
  'KS - Sac and Fox Nation of Missouri in Kansas and Nebraska',
  'LA - Chitimacha',
  'LA - Coushatta',
  'LA - Jena',
  'LA - Tunica-Biloxi',
  'MA - Mashpee',
  'MA - Wampanoag',
  'ME - Aroostok',
  'ME - Houlton',
  'ME - Passamaquoddy Indian Township',
  'ME - Passamaquoddy Pleasant Point',
  'ME - Penobscot',
  'MI - Bay Mills',
  'MI - Grand Traverse',
  'MI - Hannahville',
  'MI - Keweenaw',
  'MI - Lac Vieux',
  'MI - Little River',
  'MI - Little Traverse',
  'MI - Match-e-be-nash-she-wish Band',
  'MI - Nottawaseppi Potawatomi ',
  'MI - Pokagon',
  'MI - Saginaw Chippewa',
  'MI - Sault Ste. Marie',
  'MN - Bois Forte',
  'MN - Fond du Lac',
  'MN - Grand Portage',
  'MN - Leech Lake',
  'MN - Lower Sioux',
  'MN - Mille Lacs',
  'MN - Minnesota Chippewa',
  'MN - Prairie Island',
  'MN - Red Lake',
  'MN - Shakopee',
  'MN - Upper Sioux',
  'MN - White Earth',
  'MO - Eastern Shawnee',
  'MS - Mississippi Choctaw',
  'MT - Assiniboine and Gros Ventre Tribes',
  'MT - Assiniboine and Sioux',
  'MT - Blackfeet',
  'MT - Chippewa-Cree',
  'MT - Confederated Salish',
  'MT - Crow',
  'MT - Little Shell Tribe',
  'MT - Northern Cheyenne',
  'NC - Eastern Cherokee',
  'ND - Spirit Lake',
  'ND - Standing Rock',
  'ND - Three Affiliated',
  'ND - Turtle Mountain',
  'NE - Omaha',
  'NE - Ponca of Nebraska',
  'NE - Santee Sioux',
  'NE - Winnebago',
  'NM - Jicarilla',
  'NM - Mescalero Apache',
  'NM - Ohkay Owingeh',
  'NM - Pueblo of Acoma',
  'NM - Pueblo of Cochiti',
  'NM - Pueblo of Isleta',
  'NM - Pueblo of Jemez',
  'NM - Pueblo of Laguna',
  'NM - Pueblo of Nambe',
  'NM - Pueblo of Picuris',
  'NM - Pueblo of Pojoaque',
  'NM - Pueblo of San Felipe',
  'NM - Pueblo of San Ildefonso',
  'NM - Pueblo of Sandia',
  'NM - Pueblo of Santa Ana',
  'NM - Pueblo of Santa Clara',
  'NM - Pueblo of Taos',
  'NM - Pueblo of Tesuque',
  'NM - Pueblo of Zia',
  'NM - Pueblo of Zuni',
  'NM - Ramah Navajo Chapter',
  'NM - Santo Domingo',
  'NV - Duckwater',
  'NV - Ely Shoshone',
  'NV - Fort McDermitt',
  'NV - Las Vegas',
  'NV - Lovelock',
  'NV - Moapa',
  'NV - Paiute-Shoshone',
  'NV - Pyramid Lake',
  'NV - Reno-Sparks',
  'NV - Shoshone-Paiute',
  'NV - Summit Lake',
  'NV - Te-Moak',
  'NV - Walker River',
  'NV - Washoe',
  'NV - Winnemucca',
  'NV - Yerington',
  'NV - Yomba Shoshone',
  'NY - Cayuga Nation of New York',
  'NY - Oneida Indian Nation',
  'NY - Onondaga',
  'NY - Saint Regis',
  'NY - Seneca',
  'NY - Shinnecock',
  'NY - Tonawanda',
  'NY - Tuscarora',
  'OK - Absentee-Shawnee',
  'OK - Alabama-Quassarte',
  'OK - Apache',
  'OK - Caddo',
  'OK - Cherokee',
  'OK - Chickasaw',
  'OK - Choctaw',
  'OK - Citizen Potawatomi',
  'OK - Comanche',
  'OK - Delaware Nation',
  'OK - Delaware Tribe',
  'OK - Fort Sill',
  'OK - Iowa of Oklahoma',
  'OK - Kaw',
  'OK - Kialegee',
  'OK - Kickapoo of Oklahoma',
  'OK - Kiowa',
  'OK - Miami of Oklahoma',
  'OK - Modoc',
  'OK - Muscogee (Creek) Nation',
  'OK - Osage Nation',
  'OK - Otoe-Missouria',
  'OK - Ottawa of Oklahoma',
  'OK - Pawnee',
  'OK - Peoria',
  'OK - Ponca of Oklahoma',
  'OK - Quapaw Nation',
  'OK - Sac and Fox Nation, Oklahoma',
  'OK - Seminole Nation of Oklahoma',
  'OK - Seneca-Cayuga Nation',
  'OK - Shawnee',
  'OK - Thlopthlocco Tribal Town',
  'OK - Tonkawa',
  'OK - United Keetoowah',
  'OK - Wichita',
  'OK - Wyandotte',
  'OK - Cheyenne and Arapaho Tribes',
  'OR - Burns Paiute',
  'OR - Confederated Coos',
  'OR - Coquille',
  'OR - Cow Creek',
  'OR - Grand Ronde Tribes',
  'OR - Klamath',
  'OR - Siletz Tribe',
  'OR - Umatilla Tribe',
  'OR - Warms Springs Tribe',
  'RI - Narragansett',
  'SC - Catawba',
  'SD - Cheyenne River Sioux Tribe',
  'SD - Crow Creek',
  'SD - Flandreau',
  'SD - Lower Brule',
  'SD - Oglala Sioux',
  'SD - Rosebud',
  'SD - Sisseton-Wahpeton',
  'SD - Yankton',
  'TX - Alabama-Coushatta',
  'TX - Kickapoo of Texas',
  'TX - Ysleta Del Sur',
  'UT - Confederated Goshute',
  'UT - Northwestern Shoshone',
  'UT - Paiute of Utah',
  'UT - Skull Valley',
  'UT - Ute',
  'VA - Chickahominy Indian Tribe - Eastern Division',
  'VA - Chickahominy Indian Tribe, Inc.',
  'VA - Monacan Indian Nation',
  'VA - Nansemond Indian Tribe',
  'VA - Pamunkey Indian Tribe',
  'VA - Rappahannock Tribe, Inc.',
  'VA - Upper Mattaponi Tribe',
  'WA - Chehalis',
  'WA - Confederated Colville',
  'WA - Confederated Yakama',
  'WA - Cowlitz',
  'WA - Hoh',
  'WA - Jamestown',
  'WA - Kalispel',
  'WA - Lower Elwha',
  'WA - Lummi',
  'WA - Makah',
  'WA - Muckleshoot',
  'WA - Nisqually',
  'WA - Nooksack',
  'WA - Port Gamble',
  'WA - Puyallup',
  'WA - Quileute',
  'WA - Quinault',
  'WA - Samish',
  'WA - Sauk-Suiattle',
  'WA - Shoalwater',
  'WA - Skokomish',
  'WA - Snoqualmie',
  'WA - Spokane',
  'WA - Squaxin',
  'WA - Stillaguamish',
  'WA - Suquamish',
  'WA - Swinomish',
  'WA - Tulalip',
  'WA - Upper Skagit',
  'WI - Bad River Band',
  'WI - Forest County',
  'WI - Ho-Chunk',
  'WI - Lac Courte Oreilles',
  'WI - Lac du Flambeau',
  'WI - Menominee',
  'WI - Oneida Nation (Wisconsin)',
  'WI - Red Cliff',
  'WI - Sokaogon',
  'WI - St. Croix',
  'WI - Stockbridge',
  'WY - Eastern Shoshone',
  'WY - Northern Arapaho',
];
