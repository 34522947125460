import React from 'react';
import { Form, Row, Button } from 'react-bootstrap';
import CheckboxInput from 'components/input/CheckboxInput';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
interface Props {
  onNextStep: (step: number) => void;
  formik: any;
}

export interface passwordStrengthProps {
  title: string;
  content: string;
  status: string;
}

const FormRegisterFamily = ({ formik, onNextStep }: Props) => {
  const [agreeTerm, setAgreeTerm] = React.useState(false);

  return (
    <>
      <Form>
        <Row className="m-3 p-0">
          <TextInput
            label="Parent/Guardian (1) - First/Given name"
            name="additional_info.first_name"
            values={formik.values.additional_info?.first_name}
            onChange={formik.handleChange}
            countText={20}
          />

          <TextInput
            label="Parent/Guardian (1) - Last Name"
            name="additional_info.last_name"
            values={formik.values.additional_info?.last_name}
            onChange={formik.handleChange}
            countText={20}
          />
          <SelectInput
            form={formik}
            option={[
              { value: 'Father' },
              { value: 'Mother' },
              { value: 'Brother' },
              { value: 'Sister' },
              { value: 'Grandfather' },
              { value: 'Grandmother' },
              { value: 'Step Father' },
              { value: 'Step Mother' },
              { value: 'Legal Guardian' },
              { value: 'Other' },
            ]}
            label="Relationship to you"
            name="additional_info.relationship_to_you"
            values={formik.values.additional_info?.relationship_to_you}
            onChange={formik.handleChange}
          />
          <TextInput
            countText={36}
            className="common-coallition-flag end-2-flag"
            label="Email address"
            name="additional_info.prefered_email"
            values={formik.values.additional_info?.prefered_email}
            onChange={formik.handleChange}
            feedback={<span className="text-danger">{formik.errors.prefered_email}</span>}
          />
        </Row>

        <div className="mx-3">
          <CheckboxInput
            mdCol={'6'}
            label={
              <span>
                By creating an account you agree with our
                <a
                  className="p-0 mx-1 pb-1"
                  href="#/public/privacy-policies"
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  terms and conditions.
                </a>
              </span>
            }
            name={'password_confirm'}
            values={agreeTerm}
            onChange={(e) => setAgreeTerm(e.target.checked)}
          />
        </div>
        <div className="mx-3 mb-5">
          <Button
            disabled={!agreeTerm}
            className="w-100 p-2 my-3"
            variant="primary"
            onClick={() => {
              onNextStep(3);
            }}
          >
            <span className="h5 fw-bold">Next</span>
          </Button>
        </div>
      </Form>
    </>
  );
};

export default FormRegisterFamily;
