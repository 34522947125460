/* eslint-disable no-empty-pattern */
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import basicInformationServices from 'services/basicInformationApi';

export interface CounselorState {
  status: 'idle' | 'loading' | 'failed';
  studentList: any[];
  studentBasicInfo: any;
}

const initialState: CounselorState = {
  status: 'idle',
  studentList: [],
  studentBasicInfo: null,
};

export const getStudentBasicInfo = createAsyncThunk(
  'basicInformation/getCurrentBasicDetail',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await basicInformationServices.getBasicDetail(studentId);
      return response;
      }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const setStudentList = createAction<any>('setStudentList');
export const setStudentBasicInfo = createAction<any>('setStudentBasicInfo');

export const counselorSlice = createSlice({
  name: 'counselor',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setStudentList, (state, action: any) => {
      state.studentList = action.payload;
    });
  },
});

export const {} = counselorSlice.actions;
