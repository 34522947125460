import * as React from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import NotFound from 'screen/notFound';
import AppLayout from './AppLayout';
import DashBoardLayout from './DashBoardLayout';
import ProtectedRoute from './ProtectedRoute';
import UserLayout from './UserLayout';
import { useAppSelector } from 'app/hooks';
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@mui/material';
import { get } from 'lodash';
import { routers } from './router';
import Button from 'react-bootstrap/esm/Button';
import { STRIPE_SUBSCRIPTION_STATUS } from 'constants/common';
import Cookies from 'js-cookie';
import { authenticateSettings } from 'components/helper/appConfig';

const TrialExpiresDialog = ({ open, status }: any) => {
  const history = useHistory();
  let title = '';
  switch (status) {
    case STRIPE_SUBSCRIPTION_STATUS.canceled:
      title = 'Your subscription has been canceled!';
      break;
    case STRIPE_SUBSCRIPTION_STATUS.incomplete:
      title = 'Your subscription is incomplete!';
      break;
    case STRIPE_SUBSCRIPTION_STATUS.incomplete_expired:
      title = 'Your subscription has expired!';
      break;
    case STRIPE_SUBSCRIPTION_STATUS.past_due:
      title = 'Your subscription is past due!';
      break;
    case STRIPE_SUBSCRIPTION_STATUS.paused:
      title = 'Your subscription is paused!';
      break;
    case STRIPE_SUBSCRIPTION_STATUS.unpaid:
      title = 'Your trial has expired!';
      break;
    default:
      title = 'Your subscription has expired!';
      break;
  }

 

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        <img
          src={'/assets/images/logo-color.svg'}
          alt="GradMap logo"
          style={{ alignSelf: 'center' }}
          height={48}
          className="d-inline pointer"
        />
      </DialogTitle>
      <DialogContent>
        <div className="text-center py-5">
          <h4>{title}</h4>
        </div>
      </DialogContent>

      <DialogActions className="text-center">
        <Button
          onClick={() => {
            history.push(routers.settings.path + '?active-tab=5');
          }}
          variant="primary"
          className="p-2 px-5"
        >
          Go to billing
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RouterConfig = () => {
  let location = useLocation();
  const stripeSubscription = useAppSelector((store) => store.basicInformation.myStripeSubscription);
  const subStatus = get(stripeSubscription, 'status', '');
  let token = Cookies.get(authenticateSettings.tokenName);
  let isLogin:boolean = token ? true : false;
  const shouldShowDialog = isLogin &&
    ![STRIPE_SUBSCRIPTION_STATUS.active, STRIPE_SUBSCRIPTION_STATUS.trialing].includes(subStatus) &&
    !(location.pathname.includes('/settings') || location.pathname.includes('/support') || 
      location.pathname.includes('/payment')  || location.pathname.includes('/success') 
    );

  return (
    <>
     {subStatus && <TrialExpiresDialog open={shouldShowDialog} status={subStatus} />}
      <Switch>
        <Route path="/public" component={(props: any) => <UserLayout {...props} />} />
        <ProtectedRoute path="/application">
          <AppLayout />
        </ProtectedRoute>
        <ProtectedRoute path={['/dashboard', '/support', '/settings', '/payment','/success']}>
          <DashBoardLayout />
        </ProtectedRoute>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};

export default RouterConfig;
