/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import { Chip } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import TextInput from 'components/input/textInput';
import DatePickerInput from 'components/input/DatePicker';
import GroupCheckbox from 'components/input/GroupCheckbox';
import SelectInput from 'components/input/SelectInput';
import { csuCountry, formatDate, notUsCountry, optionValues2 } from 'components/helper/helper';
import moment from 'moment';
import YesNoSwitch from 'components/input/YesNoSwitch';
import collegeServices from 'services/collegeServices';
import GroupRadioButton from 'components/input/GroupRadioButton';
import {
  collegeDetailEarnedOption,
  majorOption,
  minorOption,
  earnDegreeYears,
} from './CollegeConstant';
import CheckboxInput from 'components/input/CheckboxInput';
import { debounce, defer } from 'lodash';
import AutocompleteInput from 'components/input/AutoCompleteInput';
import FormCreateCollegeModal from './FormCreateCollegeModal';
import TextIconInput from 'components/input/TextIconInput';
import LoadingContent from 'components/LoadingContent';
import { canadaProvince, usaState } from 'components/helper/stateAndCounty';
import { countryOptions } from 'components/helper/countryPhoneCode';
import { states_usa } from 'components/helper/state_usa';

export const AliasNames = [
  { alias: 'miracosta', name: 'Mira Costa' },
];

export const UnlistedTypes = [
  'Unlisted English Speaking Canadian Institution',
  'Unlisted US Institution',
  'Unlisted French Speaking Canadian or Other Foreign Institution',
];

export const CollegeSchoolType = [
  '2-year or community college',
  '4-year college or university',
];

const loadStates = () => {
  const arr = [
    { name: 'State/U.S. territory *', value: 'N/A' },
    //{ name: "Other/Unknown", value: "N/A"}
  ];
  const states = arr.concat(
    states_usa.filter((obj) => {
      return obj.value !== 'CA';
    })
  );

  return states.map((o) => {
    return o.name;
  });
};

const getStateName = (stateCode: string) => {
  for (let i = 0; i < states_usa.length; i++) {
    if (states_usa[i].value === stateCode) return states_usa[i].name;
  }
  return stateCode;
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


type MaskEditCollegeInfo = {
  ceeb: boolean;
  name: boolean;
  school_type: boolean;
  city: boolean;
  state: boolean;
  zip: boolean;
  country: boolean;
}

interface Props {
  handleSave: (value: any) => void;
  item: any;
  indexText: string;
  index: number;
}


export const initialCollegeValues = {
  college_info: {
    where_attend_college: 'IN_CA',
    state: '',
    country: '',
    college_name: '',
    detail_degree_earned: '',
    when_you_earn_degree_year: '',
    when_you_earn_degree_month: '',
    your_major: '',
    your_minor: '',
    have_double_major: false,
    your_second_major: '',
    type_of_term_systerm: '',
    type_of_term_systerm_uc: '',
    your_tuition_status: '',
    unlisted_type: '',
  },
  // where_attend_college: '',
  school: {
    ceeb: '',
    fib_code: '',
    name: '',
    school_type: null,
    city: '',
    state: '',
    zip: '',
    country: '',
    csu_name: '',
    uc_name: '',
    cm_name: '',
    match_all: false,
    label: '',
    code: null,
    is_manual: false,
  } as any,
  //fullName: '',
  college_name: '',
  college_zip_code: '',
  college_city: '',
  college_ceeb: '',
  college_state: '',
  college_country: '',
  csu_name: '',
  fromDate: '',
  toDate: '',
  college_is_dual_enrollment: false,
  college_is_credit_awarded: false,
  college_is_summer_program: false,
  degree_earned: 'No degree earned',
  degree_info: '',
  expect_to_earn_degree_from_San_Diego_Mesa: '',
  grades: [],
  grades_fall: [],
  grades_winter: [],
  grades_spring: [],
  grades_summer: [],
  college_grading_system_alphabet: false,
  college_grading_system_100: false,
  college_grading_system_7: false,
  college_grading_system_other: false,
  lowest_number_grade: '',
  highest_number_grade: '',
  other_grading_system: '',
  isStillAttendingThisCollege: false,
  id: '',
};



const CollegeConfig = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);

  const [showNotFound, setShowNotFound] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState('');

  const getEditMask = (item: any) => {
    let mask: MaskEditCollegeInfo = {
      ceeb: true,
      name: true,
      school_type: true,
      city: true,
      state: true,
      zip: true,
      country: true,
    };

    if (item) {
      if (item.hasOwnProperty('match_all')) {
        mask.ceeb = false;
        mask.name = false;

        if (item.match_all) {
          mask.school_type = false;
          mask.city = false;
          mask.state = false;
          mask.zip = false;
          mask.country = false;
        }
        else {
          mask.school_type = false;
          mask.city = false;
          mask.state = false;
          mask.zip = false;
          mask.country = false;
          if (item.hasOwnProperty('ceeb') && (item.ceeb === null || item.ceeb?.trim() === '')) {
            mask.ceeb = true;
          }
          if (item.hasOwnProperty('school_type') && (item.school_type === null || item.ceeb?.trim() === '')) {
            mask.school_type = true;
          }
          if (item.hasOwnProperty('city') && (item.city === null || item.city?.trim() === '')) {
            mask.city = true;
          }
          if (item.hasOwnProperty('state') && (item.state === null || item.state?.trim() === '')) {
            mask.state = true;
          }
          if (item.hasOwnProperty('zip') && (item.zip === null || item.zip?.trim() === '')) {
            mask.zip = true;
          }
          if (item.hasOwnProperty('country') && (item.country === null || item.country?.trim() === '')) {
            mask.country = true;
          }

          // old school object
          if (item.hasOwnProperty('zip-code') && (item.zip === null || item.zip.trimm() === '')) {
            mask.zip = true;
          }
        }
      }
    }

    return mask;
  };
  const isNewItem = () => {
    let mask = getEditMask(props.item.school);
    return !(mask.ceeb &&
      mask.name &&
      mask.school_type &&
      mask.city &&
      mask.state &&
      mask.zip &&
      mask.country) && props.item.school?.name === '';
  };
  const initEditMask = () => {
    return isNewItem() ? {
      ceeb: false,
      name: false,
      school_type: false,
      city: false,
      state: false,
      zip: false,
      country: false,
    }
      : {
        ceeb: true,
        name: true,
        school_type: true,
        city: true,
        state: true,
        zip: true,
        country: true,
      };
  };
  const [editMask, setEditMask] = React.useState<MaskEditCollegeInfo>(initEditMask());
  const [editManual, setEditManual] = React.useState(false);
  const [collegeData, setCollegeData] = React.useState<any[] | undefined>(undefined);
  const [selectedEducation, setSelectedEducation] = React.useState<any>();
  const optionWhere = [
    { value: 'IN_CA', label: 'In California' },
    { value: 'OUTSIDE_CA', label: 'Outside California (in the U.S.)' },
    { value: 'OUTSIDE_USA', label: 'Outside the U.S.' },
  ];
  const [openFormCreate, setOpenFormCreate] = React.useState(false);
  const [gradeOptions, setGradeOptions] = React.useState<any[]>([]);
  const [collegeName, setCollegeName] = React.useState(props.item.college_name)
  let searchCtrl = new AbortController();
  let states = loadStates();
  const formItem = useFormik({
    initialValues: initialCollegeValues,
    onSubmit: (values) => { },
  });

  const handleChangeItem = (item: any) => {
    setCollegeData([]);
    let college: any = Object.assign({}, item);
    college.is_manual = false;
    //.console.log('college: ', college);
    setSelectedEducation(college);
    setEditMask(getEditMask(college));
    setCollegeName(college.name);
    setOpenMenu(false);
    setSearchInput('');
    // formItem.setFieldValue('fullName',college?.name);
    formItem.setFieldValue('college_name', college?.name);
    formItem.setFieldValue('college_ceeb', college?.ceeb);
    formItem.setFieldValue('college_zip_code', college?.zip);
    formItem.setFieldValue('college_state', college?.state);
    formItem.setFieldValue('college_city', college?.city);
    formItem.setFieldValue('college_country', college?.country);
    formItem.setFieldValue('csu_name', college?.csu_name);
    formItem.setFieldValue('college_info.college_name', college?.name);
    formItem.setFieldValue('college_info.state', college?.state);
    formItem.setFieldValue('college_info.country', college?.country);
    formItem.setFieldValue('school', college);
    setEditManual(true);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    try {
      setCollegeData(undefined);
      let isNew = isNewItem();

      ///#region fix old record
      if (props.item?.college_info?.where_attend_college === 'In California') {
        props.item.college_info.where_attend_college = 'IN_CA';
      } else
        if (props.item?.college_info?.where_attend_college === 'Outside California (in the U.S.)') {
          props.item.college_info.where_attend_college = 'OUTSIDE_CA';
        } else
          if (props.item?.college_info?.where_attend_college === 'Outside the U.S.') {
            props.item.college_info.where_attend_college = 'OUTSIDE_USA';
          }
      ///#endregion

      if (props.item) {
        formItem.resetForm({
          values: {
            ...formItem.values,
            ...props.item,
            grades: Array.isArray(props.item?.grades) ? props.item?.grades : [props.item?.grades],
            grades_fall: Array.isArray(props.item?.grades_fall)
              ? props.item?.grades_fall
              : [props.item?.grades_fall],
            grades_winter: Array.isArray(props.item?.grades_winter)
              ? props.item?.grades_winter
              : [props.item?.grades_winter],
            grades_spring: Array.isArray(props.item?.grades_spring)
              ? props.item?.grades_spring
              : [props.item?.grades_spring],
            grades_summer: Array.isArray(props.item?.grades_summer)
              ? props.item?.grades_summer
              : [props.item?.grades_summer],
          },
        });
      }

      if (props.item.school) {
        if (props.item.school.is_manual) {
          setEditManual(true);
        }
        else {
          handleChangeItem(props.item.school);
          setEditMask(getEditMask(props.item.school));
        }
      }
      else {
        setEditManual(false);
      }

      if (isNew) {
        setEditMask({
          ceeb: false,
          name: false,
          school_type: false,
          city: false,
          state: false,
          zip: false,
          country: false,
        });
        setEditManual(false);
      }
    }
    catch (ex) {
      console.log('Error:', ex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (moment(formItem.values.fromDate).year() && moment(formItem.values.toDate).year()) {
      let dateRange =
        moment(formItem.values.toDate).year() - moment(formItem.values.fromDate).year() + 1;
      if (dateRange > 0) {
        const newGradeOptions = optionValues2(dateRange).map((item) => {
          return { value: item.value + moment(formItem.values.fromDate).year() };
        });
        setGradeOptions([' ', ...newGradeOptions]);
      }
    }
  }, [formItem.values.fromDate, formItem.values.toDate]);


  const handleGetCollege = async (keyword: string) => {
    try {
      if (keyword && keyword.length > 0) {
        setCollegeData([]);
        setShowNotFound(false);
        if (keyword?.trim().length === 0) return;
        searchCtrl.abort();
        searchCtrl = new AbortController();
        setIsLoading(true);
        let res: any = await collegeServices.searchCollege(
          keyword,
          formItem.values.college_info.state,
          formItem.values.college_info.country,
          formItem.values.college_info.where_attend_college,
          searchCtrl
        );
        const data = res.map((item: any) => ({
          ...item,
          id: item.id,
          label: item.name,
          value: item.name,
        }));

        if (res && res.length === 0) {
          setShowNotFound(true);
        }
        else {
          setShowNotFound(false);
        }

        setCollegeData(data);
        setIsLoading(false);
        setOpenMenu(true);
      }
    }
    catch (ex) {
      console.log('Error: ', ex);
    }
  };

  const handleSearchCollege = debounce((keyword: string) => {
    defer(() => {
      handleGetCollege(keyword);
    });
  }, 500);


  const handleAliasValue = (value: string) => {
    if (value) {
      try {
        let keyword = value.toLowerCase().trim();
        // search keyword as alias name
        for (let i = 0; i < AliasNames.length; i++) {
          if (keyword === AliasNames[i].alias) {
            return AliasNames[i].name;
          }
        }
      }
      catch (ex) {
        console.log('Error:', ex);
      }
    }
    return value;
  };

  React.useEffect(() => {
    if (formItem.dirty) {
      setTimeout(() => {
        props.handleSave({ ...formItem.values });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formItem.values]);

  const handleAddTerm = () => {
    if (formItem.values.grades.length > 3) return;
    formItem.setFieldValue('grades', [...formItem.values.grades, ' ']);
    formItem.setFieldValue('grades_fall', [...formItem.values.grades_fall, ' ']);
    formItem.setFieldValue('grades_winter', [...formItem.values.grades_winter, ' ']);
    formItem.setFieldValue('grades_summer', [...formItem.values.grades_summer, ' ']);
    formItem.setFieldValue('grades_spring', [...formItem.values.grades_spring, ' ']);
  };

  const handleRemoveTerm = (index: number) => {
    formItem.setFieldValue(
      'grades',
      formItem.values.grades.filter((item: any, i: number) => i !== index)
    );
    formItem.setFieldValue(
      'grades_fall',
      formItem.values.grades_fall.filter((item: any, i: number) => i !== index)
    );
    formItem.setFieldValue(
      'grades_winter',
      formItem.values.grades_winter.filter((item: any, i: number) => i !== index)
    );
    formItem.setFieldValue(
      'grades_summer',
      formItem.values.grades_summer.filter((item: any, i: number) => i !== index)
    );
    formItem.setFieldValue(
      'grades_spring',
      formItem.values.grades_spring.filter((item: any, i: number) => i !== index)
    );
  };

  const resetCollegeInfo = () => {
    formItem.setFieldValue('school.ceeb', '');
    formItem.setFieldValue('school.fib_code', '');
    formItem.setFieldValue('school.name', '');
    formItem.setFieldValue('school.school_type', null);
    formItem.setFieldValue('school.city', '');
    formItem.setFieldValue('school.state', '');
    formItem.setFieldValue('school.zip', '');
    formItem.setFieldValue('school.country', '');
    formItem.setFieldValue('school.csu_name', '');
    formItem.setFieldValue('school.uc_name', '');
    formItem.setFieldValue('school.cm_name', '');
    formItem.setFieldValue('school.match_all', false);
    formItem.setFieldValue('school.value', '');
    formItem.setFieldValue('school.label', '');
    formItem.setFieldValue('school.code', null);
    formItem.setFieldValue('school.is_manual', true);
    // formItem.setFieldValue('fullName', '');
    formItem.setFieldValue('college_name', '');
    formItem.setFieldValue('college_ceeb', '');
    formItem.setFieldValue('college_zip_code', '');
    formItem.setFieldValue('college_state', '');
    formItem.setFieldValue('college_city', '');
    formItem.setFieldValue('college_country', '');
    formItem.setFieldValue('csu_name', '');
    formItem.setFieldValue('college_info.college_name', '');
    formItem.setFieldValue('college_info.state', '');
    formItem.setFieldValue('college_info.country', '');
  };

  const handleSaveCollege = (values: any) => {
    formItem.setFieldValue('college_code', values?.ceeb);
    formItem.setFieldValue('college_city', values?.city);
    formItem.setFieldValue('college_ceeb', values?.ceeb);

    formItem.setFieldValue('csu_name', values?.csu_name);
    if (!values?.csu_name || values?.csu_name.length == 0) {
      formItem.setFieldValue('csu_name', values?.label);
    }
    setOpenFormCreate(false);
  };

  const buildHeader = () => {
    //const title = formItem.values.fullName ? formItem.values.fullName.split(' - ')[0] : '';
    const title = formItem.values.college_name;
    const details = [];

    if (formItem.values?.college_ceeb) {
      details.push(`CEEB: ${formItem.values.college_ceeb}`);
    }

    // Add date range if both fromDate and toDate are available
    if (formItem.values?.fromDate && formItem.values?.toDate) {
      details.push(
        `${formatDate(formItem.values.fromDate, 'MM/DD/YYYY')} to ${formatDate(
          formItem.values.toDate,
          'MM/DD/YYYY'
        )}`
      );
    }
    // Add only fromDate if toDate is not available
    else if (formItem.values?.fromDate) {
      details.push(`${formatDate(formItem.values.fromDate, 'MM/DD/YYYY')}`);
    }

    return details.length > 0 ? `${title} - ${details.join(' - ')}` : title;
  };

  const onChangeWhereAttend = (e: any) => {
    try {
      const CA = 'California';

      switch (e.target.value) {
        case 'IN_CA':
          if (formItem.values.school?.state !== CA) {
            setSelectedEducation(undefined);
            setCollegeName('');
            handleRemove();
            formItem.setFieldValue('college_name', '');
            // formItem.setFieldValue('fullName', '');
            formItem.setFieldValue('college_city', '');
            setEditManual(false);
            setShowNotFound(false);
          }
          formItem.setFieldValue('state', CA);
          formItem.setFieldValue('country', 'United States of America');
          break;
        case 'OUTSIDE_CA':
          if (formItem.values.school?.state === CA ||
            formItem.values.school?.country !== 'United States of America'
          ) {
            setSelectedEducation(undefined);
            setCollegeName('');
            handleRemove();
            formItem.setFieldValue('college_name', '');
            // formItem.setFieldValue('fullName', '');
            formItem.setFieldValue('college_city', '');
            setEditManual(false);
            setShowNotFound(false);
          }
          formItem.setFieldValue('country', 'United States of America');
          break;
        case 'OUTSIDE_USA':
          if (formItem.values.school?.country === 'United States of America') {
            setSelectedEducation(undefined);
            setCollegeName('');
            handleRemove();
            formItem.setFieldValue('college_name', '');
            // formItem.setFieldValue('fullName', '');
            formItem.setFieldValue('college_city', '');
            formItem.setFieldValue('college_state', '');
            formItem.setFieldValue('college_country', '');
            setEditManual(false);
            setShowNotFound(false);
          }

          setCollegeData([]);
          break;
      }
      formItem.handleChange(e);
      setCollegeData([]);

      console.log('formik.values.where_attend_high_school:', formItem.values.college_info.where_attend_college);
    }
    catch (ex) {
      console.log('Error: ', ex);
    }
  };

  const handleNotFound = (e: any) => {
    setCollegeName(searchInput);
    setShowNotFound(false);
    setEditManual(true);
    resetCollegeInfo();
    setSelectedEducation(undefined);
    formItem.setFieldValue('school.name', searchInput);
    formItem.setFieldValue('college_name', searchInput);
    // formItem.setFieldValue('fullName', searchInput);
    formItem.setFieldValue('school.is_manual', true);
    formItem.setFieldValue('college_info.college_name', searchInput);
    setEditMask(getEditMask(null));
  };

  const clearManualForm = () => {
    resetCollegeInfo();
    setCollegeName('');
    setEditMask(getEditMask(null));
  }

  const handleRemove = () => {
    setSelectedEducation(undefined);
    clearManualForm();
    setEditManual(false);
  }

  const checkEditMask = () => {
    return editMask.ceeb ||
      editMask.name ||
      editMask.school_type ||
      editMask.city ||
      editMask.state ||
      editMask.zip ||
      editMask.country;
  }

  return (
    <Card className="w-100 my-3 px-0" key={props.indexText}>
      <CardActions
        disableSpacing
        onClick={handleExpandClick}
        sx={{
          ml: '-8px',
          backgroundColor: expanded ? '#AAEBF7' : 'none',
          borderBottom: expanded ? '2px solid #4D88F0' : 'none',
        }}
      >
        <div className="w-100 d-flex justify-content-between ps-3"
          style={{ color: props.item.school?.is_manual ? 'black' : '#4d88f0' }}
        >
          {buildHeader()}
          <strong className="text-field-light">{props.indexText}</strong>
        </div>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Row className="px-3">
            {/* <CollegeInfo formik={formItem} /> */}
            <GroupRadioButton
              name="college_info.where_attend_college"
              onChange={onChangeWhereAttend}
              // onChange={formItem.handleChange}
              values={formItem.values.college_info?.where_attend_college}
              mdCol={4}
              label="Where is the college you attended? *"
              options={optionWhere}
            />
            {(formItem.values.college_info?.where_attend_college === 'OUTSIDE_CA' ||
              formItem.values.college_info?.where_attend_college === 'Outside California (in the U.S.)'
            ) && (
                <>
                  <SelectInput
                    name="college_info.state"
                    values={formItem.values.college_info?.state}
                    onChange={(e: any) => {
                      formItem.handleChange(e);
                      formItem.setFieldValue('college_state', e.target.value);
                    }}
                    label="State/U.S. territory"
                    option={states}
                  />
                </>
              )}
            {(formItem.values.college_info?.where_attend_college === 'OUTSIDE_USA' ||
              formItem.values.college_info?.where_attend_college === 'Outside the U.S.'
            ) && (
                <SelectInput
                  name="college_info.country"
                  values={formItem.values.college_info?.country}
                  onChange={(e: any) => {
                    formItem.handleChange(e);
                    formItem.setFieldValue('college_country', e.target.value);
                  }}
                  label="Select a country *"
                  option={notUsCountry}
                />
              )}

            <TextIconInput
              label="Search for your College / University"
              logo="span-cm-logo"
              icon={<SearchIcon />}
              mdCol="12"
              onChange={(e: any) => {
                let val = handleAliasValue(e.target.value);
                setSearchInput(val);
                handleSearchCollege(val);
                if (!selectedEducation) {
                  setCollegeName(val);
                  formItem.setFieldValue('college_info.college_name', val);
                  formItem.setFieldValue('school.is_manual', true);
                }
                formItem.setFieldValue('school.name', val);
                formItem.setFieldValue('college_name', val);
                // formItem.setFieldValue('fullName', val);
                if (val.length === 0) {
                  setCollegeData([]);
                }
              }}
              values={searchInput}
              otherProps={{
                onKeyDown: (evt: any) => {
                  if (evt.key === "Escape") {
                    setCollegeData([]);
                  }
                },
              }}

            />
            {isLoading && <LoadingContent />}
            {/* {collegeData !== undefined &&
              !collegeData?.length &&
              !isLoading &&
              !formItem.values.school?.name && <div className="text-center">Not found college</div>} */}
            {openMenu &&
              (collegeData || []).map((college: any, index: React.Key | null | undefined) =>
                college?.label ? (
                  <Card className="border rounded my-1 pt-2 pb-1" key={index}>
                    <div className="d-flex align-items-end position-relative cursor-pointer">
                      <div className="mx-3 pb-1 text-overflow-ellipses"
                        onClick={() => {
                          handleChangeItem(college);
                        }}
                      >
                        <span>{college.label}</span>
                        <span className="mx-1 text-field-light sized70"
                          title={`(ceeb: ${college?.ceeb ?? ''}, city: ${college?.city ?? ''
                            }, state: ${getStateName(college?.state) ?? ''}, country: ${college?.country ?? ''})`}
                        >
                          {`(ceeb: ${college?.ceeb ?? ''}, city: ${college?.city ?? ''
                            }, state: ${getStateName(college?.state) ?? ''}, country: ${college?.country ?? ''})`}
                        </span>
                      </div>

                      <span className="position-absolute h-100 d-flex align-items-center end-0">
                        <AddIcon
                          fontSize="small"
                          className="bg-app text-primary rounded-circle mx-3 pointer"
                          onClick={() => {
                            handleChangeItem(college);
                          }}
                        />
                      </span>
                    </div>
                  </Card>
                ) : null
              )}

            {showNotFound && (
              <div id="not-found" className="d-block not-found">
                <div className="text-center">
                  <button type="button" className="btn-link btn-not-found" onClick={handleNotFound}>
                    Desired College/University not found, I want to enter my College/University manually
                  </button>
                </div>
              </div>
            )}

            <div className="my-2 text-field-light">Selected College/University</div>
            {selectedEducation?.id && (
              <>
                <div className="d-flex align-items-end position-relative">
                  <div className="mx-3 fw-bold">{selectedEducation?.name}</div>
                  <div className="position-absolute h-100 d-flex align-items-center end-0">
                    <RemoveIcon
                      fontSize="small"
                      className="bg-light text-dart rounded-circle mx-4 pointer"
                      onClick={handleRemove}
                    />
                  </div>
                </div>
                <div className="d-block mx-3 pb-3">
                  <div className="text-field-light sized70">
                    {`(ceeb: ${selectedEducation?.ceeb ?? ''}, fib code: ${selectedEducation?.fib_code ?? ''},  state: ${selectedEducation?.state ?? ''}, city: ${selectedEducation?.city ?? ''}, country: ${selectedEducation?.country ?? ''})`}
                  </div>
                </div>
              </>
            )}
            {(collegeData !== undefined && !collegeData?.length) || (!props.item.school?.match_all) || (props.item.school?.match_all === false) ? (
              <>
                {
                  () => {
                    console.log('editManual: ', editManual);
                    console.log('checkEditMask(): ', checkEditMask());
                    console.log('editMask: ', editMask);
                  }
                }
                {editManual && checkEditMask() && (
                  <Row className="bg-light rounded p-3">
                    <TextInput
                      mdCol={12}
                      hidden={!editMask.name}
                      name="school.name"
                      values={collegeName}
                      onChange={(e) => {
                        try {
                          setCollegeName(e.target.value);
                          formItem.setFieldValue('school.name', e.target.value);
                          // formItem.setFieldValue('fullName', e.target.value);
                          formItem.setFieldValue('college_name', e.target.value);
                          formItem.setFieldValue('college_info.college_name', e.target.value);
                          formItem.setFieldValue('school.is_manual', true);
                        }
                        catch (ex) {
                          console.log('Error: ', ex);
                        }
                      }}
                      label="Name of College/University"
                      countText={60}
                    />
                    <TextInput
                      name="school.city"
                      hidden={!editMask.city}
                      values={formItem.values.school?.city}
                      onChange={(e: any) => {
                        formItem.handleChange(e);
                        formItem.setFieldValue('college_city', e.target.value);
                      }}
                      label="City"
                      countText={30}
                    />
                    <SelectInput
                      name="school.school_type"
                      hidden={!editMask.school_type}
                      values={formItem.values.school?.school_type ?? ''}
                      onChange={(e: any) => {
                        formItem.setFieldValue('school.school_type', e.target.value);
                      }}
                      label="School Type *"
                      option={CollegeSchoolType}
                    />
                    <AutocompleteInput
                      name="school.country"
                      hidden={!editMask.country ||
                        formItem.values.college_info.unlisted_type === 'Unlisted French Speaking Canadian or Other Foreign Institution'}
                      form={formItem}
                      defautlValues={formItem.values.school?.country?.trim() ?? ''}
                      values={formItem.values.school?.country?.trim() ?? ''}
                      label="Country/Region/Territory*"
                      option={csuCountry}
                    />
                    {formItem.values.school?.country === 'United States of America' ? (
                      <AutocompleteInput
                        hidden={!editMask.state}
                        option={usaState}
                        form={formItem}
                        label="State"
                        name="school.state"
                        values={getStateName(formItem.values.school?.state)}
                      />
                    ) :
                      formItem.values.school?.country === 'Canada' ? (
                        <AutocompleteInput
                          hidden={!editMask.state}
                          form={formItem}
                          option={canadaProvince}
                          label="Province"
                          name="school.state"
                          values={formItem.values.school?.state ?? ''}
                        />
                      ) : (
                        <TextInput
                          name="school.state"
                          hidden={!editMask.state}
                          values={formItem.values.school?.state ?? ''}
                          onChange={(e: any) => {
                            formItem.setFieldValue('school.state', e.target.value);
                          }}
                          label="State or Province"
                          countText={30}
                        />
                      )}
                    <TextInput
                      name="school.zip"
                      hidden={!editMask.zip}
                      values={formItem.values.school?.zip}
                      onChange={(e: any) => {
                        formItem.setFieldValue('school.zip', e.target.value);
                      }}
                      label="Zip Code"
                      countText={10}
                    />
                    <TextInput
                      name="school.ceeb"
                      hidden={!editMask.ceeb}
                      values={formItem.values.school?.ceeb}
                      onChange={(e: any) => {
                        formItem.setFieldValue('school.ceeb', e.target.value);
                        formItem.setFieldValue('college_ceeb', e.target.value);
                      }}
                      label="CEEB Code"
                      countText={10}
                    />
                  </Row>
                )}
              </>
            ) : (<></>)}

            {editManual && props.item.school?.is_manual && (
              <>
                <GroupRadioButton
                  logo="span-csu-logo"
                  name="college_info.unlisted_type"
                  onChange={formItem.handleChange}
                  values={formItem.values.college_info.unlisted_type}
                  mdCol={4}
                  label="If you can't find your school in our list, select one of the options below:"
                  options={UnlistedTypes}
                  onClear={() => {
                    formItem.setFieldValue('college_info.unlisted_type', '');
                  }}
                />

                {formItem.values.college_info.unlisted_type === 'Unlisted French Speaking Canadian or Other Foreign Institution' && (
                  <>
                    <SelectInput
                      logo="span-cm-logo"
                      name="college_info.country"
                      values={formItem.values.college_info?.country}
                      onChange={(e: any) => {
                        formItem.handleChange(e);
                        formItem.setFieldValue('college_country', e.target.value);
                        formItem.setFieldValue('school.country', e.target.value);
                      }}
                      label="Please provide your school's country:"
                      option={countryOptions}
                    />
                    <Col md={6} />
                  </>
                )}
              </>
            )}

            <DatePickerInput
              className="mt-4"
              label={`College "${props.index}" from date`}
              name="fromDate"
              values={formItem.values.fromDate}
              onChange={(value) => formItem.setFieldValue('fromDate', value)}
            />
            <DatePickerInput
              className="mt-4"
              label={`College "${props.index}" to date`}
              name="toDate"
              values={formItem.values.toDate}
              onChange={(value) => formItem.setFieldValue('toDate', value)}
            />
            <CheckboxInput
              logo="span-csu-logo"
              label="Check if you are still attending this college or university"
              name="isStillAttendingThisCollege"
              values={formItem.values.isStillAttendingThisCollege}
              onChange={formItem.handleChange}
            />

            <Row className="common-flag mt-5">
              <GroupCheckbox
                label="Course Details"
                logo="span-cm-logo"
                options={[
                  {
                    value: 'college_is_dual_enrollment',
                    label: 'Dual enrollment with high school,',
                  },
                  {
                    value: 'college_is_credit_awarded',
                    label: 'Credit awarded directly by college ',
                  },
                  { value: 'college_is_summer_program', label: 'Summer program' },
                ]}
                form={formItem}
              />

              <YesNoSwitch
                className="py-3"
                label={`Do you expect to earn a degree from ${formItem.values.college_name}? *`}
                logo="span-cm-logo"
                name="expect_to_earn_degree_from_San_Diego_Mesa"
                values={formItem.values.expect_to_earn_degree_from_San_Diego_Mesa}
                onChange={formItem.handleChange}
              />

              {formItem.values.expect_to_earn_degree_from_San_Diego_Mesa === 'true' && (
                <>
                  <Col md={6}>
                    <GroupRadioButton
                      mdCol={12}
                      label="Degree Info"
                      logo="span-csu-logo"
                      name="degree_info"
                      values={formItem.values.degree_info}
                      onChange={formItem.handleChange}
                      options={['Degree Awarded', 'Degree In Progress']}
                    />
                  </Col>

                  <SelectInput
                    logo="span-cm-logo"
                    label="Degree in progress or will be earned"
                    name="degree_earned"
                    values={formItem.values.degree_earned}
                    onChange={formItem.handleChange}
                    option={[
                      { value: 'AA' },
                      { value: 'AS' },
                      { value: 'BA' },
                      { value: 'BS' },
                      { value: '' },
                    ]}
                  />
                  <SelectInput
                    logo="span-csu-logo"
                    label="Details of degree in progress or will be earned"
                    name="college_info.detail_degree_earned"
                    values={formItem.values.college_info?.detail_degree_earned}
                    onChange={formItem.handleChange}
                    option={collegeDetailEarnedOption}
                  />
                  <div className="fs-6 text-dark span-csu-logo">When did you earn that degree?</div>
                  <SelectInput
                    label="Select Month"
                    name="college_info.when_you_earn_degree_month"
                    values={formItem.values.college_info?.when_you_earn_degree_month}
                    onChange={formItem.handleChange}
                    option={[
                      'January',
                      'February',
                      'March',
                      'April',
                      'May',
                      'June',
                      'July',
                      'August',
                      'September',
                      'October',
                      'November',
                      'December',
                    ]}
                  />
                  <SelectInput
                    label="Select Year"
                    name="college_info.when_you_earn_degree_year"
                    values={formItem.values.college_info?.when_you_earn_degree_year}
                    onChange={formItem.handleChange}
                    option={earnDegreeYears}
                  />
                  <SelectInput
                    logo="span-csu-logo"
                    label="What was your major?"
                    name="college_info.your_major"
                    values={formItem.values.college_info?.your_major}
                    onChange={formItem.handleChange}
                    option={majorOption}
                  />
                  <SelectInput
                    logo="span-csu-logo"
                    label="What was your minor?"
                    name="college_info.your_minor"
                    values={formItem.values.college_info?.your_minor}
                    onChange={formItem.handleChange}
                    option={minorOption}
                  />
                  <CheckboxInput
                    className="py-3"
                    logo="span-csu-logo"
                    label="Check if you were a double major"
                    name="college_info.have_double_major"
                    values={formItem.values.college_info?.have_double_major}
                    onChange={(e) => {
                      formItem.setFieldValue('college_info.have_double_major', e.target.checked);
                    }}
                  />
                  {formItem.values.college_info?.have_double_major === true && (
                    <SelectInput
                      logo="span-csu-logo"
                      label="Select Second Major?"
                      name="college_info.your_second_major"
                      values={formItem.values.college_info?.your_second_major}
                      onChange={formItem.handleChange}
                      option={majorOption}
                    />
                  )}
                </>
              )}
            </Row>
            <GroupCheckbox
              className="pt-3"
              label={`What is this college's grading system? *`}
              logo="span-uc-logo"
              form={formItem}
              mdCol={6}
              options={[
                {
                  value: 'college_grading_system_alphabet',
                  label: 'A B C D F',
                },
                { value: 'college_grading_system_other', label: `Other` },
              ]}
            />
            {formItem.values.college_grading_system_other && (
              <>
                <TextInput
                  className="py-3"
                  logo="span-uc-logo"
                  countText={16}
                  label="Specify the grading system *"
                  values={formItem.values.other_grading_system}
                  onChange={formItem.handleChange}
                  name="other_grading_system"
                />
              </>
            )}
            <GroupRadioButton
              className="py-3"
              label="What type of term system does this college or university use?"
              logo="span-csu-logo"
              options={['Quarter', 'Semester', 'Trimester']}
              values={formItem.values.college_info?.type_of_term_systerm}
              onChange={formItem.handleChange}
              name="college_info.type_of_term_systerm"
            />
            <GroupRadioButton
              className="py-3"
              label="What is this college's term system?"
              logo="span-uc-logo"
              options={['Quarter', 'Semester', 'Semester with Winter Session']}
              values={formItem.values.college_info?.type_of_term_systerm_uc}
              onClear={() => formItem.setFieldValue('college_info.type_of_term_systerm_uc', '')}
              onChange={formItem.handleChange}
              name="college_info.type_of_term_systerm_uc"
            />

            <GroupRadioButton
              label="What was your tuition status (resident or non-resident) at this college or university during the last term you attended?"
              options={['In-State Tuition', 'Out-of-State Tuition', 'Not applicable']}
              values={formItem.values.college_info?.your_tuition_status}
              onChange={formItem.handleChange}
              name="college_info.your_tuition_status"
            />
            <div>During which grades and terms did you attend this college?</div>
            {(formItem.values.grades ?? []).map((item, index) => (
              <Row key={index}>
                <SelectInput
                  logo="span-uc-logo"
                  form={formItem}
                  option={[
                    { value: '' },
                    { value: '9th' },
                    { value: '10th' },
                    { value: '11th' },
                    { value: '12th' },
                  ]}
                  label="Grades"
                  name={`grades[${index}]`}
                  values={formItem.values.grades[index]}
                  onChange={formItem.handleChange}
                />
                <Col md={6} className="d-flex align-items-center justify-content-start">
                  <Chip
                    label="Remove term"
                    className="ms-3 pointer"
                    onClick={() => handleRemoveTerm(index)}
                  />
                </Col>
                <SelectInput
                  mdCol="3"
                  form={formItem}
                  option={gradeOptions}
                  label="Fall"
                  name={`grades_fall[${index}]`}
                  values={formItem.values.grades_fall[index]}
                  onChange={formItem.handleChange}
                />
                <SelectInput
                  mdCol="3"
                  form={formItem}
                  option={gradeOptions}
                  label="Winter"
                  name={`grades_winter[${index}]`}
                  values={formItem.values.grades_winter[index]}
                  onChange={formItem.handleChange}
                />
                <SelectInput
                  mdCol="3"
                  form={formItem}
                  option={gradeOptions}
                  label="Spring"
                  name={`grades_spring[${index}]`}
                  values={formItem.values.grades_spring[index]}
                  onChange={formItem.handleChange}
                />
                <SelectInput
                  mdCol="3"
                  form={formItem}
                  option={gradeOptions}
                  label="Summer - After Academic Year"
                  name={`grades_summer[${index}]`}
                  values={formItem.values.grades_summer[index]}
                  onChange={formItem.handleChange}
                />
              </Row>
            ))}
            {formItem.values.grades.length < 4 && (
              <div className="my-3 w-100 d-flex flex-column align-items-center">
                <div className="text-field-light sized70">You can add up to 4 term/year</div>
                <Button variant="primary" className="p-2 px-5" onClick={handleAddTerm}>
                  Add another term/year
                </Button>
              </div>
            )}

            <Col xs={6} md={9} />
            <Col xs={6} md={3} className="p-3">
              <Button
                className="w-100"
                onClick={() => {
                  props.handleSave(formItem.values);
                  setExpanded(false);
                }}
              >
                Save Info
              </Button>
            </Col>
          </Row>
        </CardContent>
      </Collapse>
      <FormCreateCollegeModal
        open={openFormCreate}
        onClose={() => setOpenFormCreate(false)}
        handleSave={handleSaveCollege}
      />
      <style scoped>{` 
button.btn-link.btn-not-found {
  color: #0d6efd!important;
  border: none!important;
  background: transparent;
  text-decoration: underline!important;
  text-decoration-color: #0d6efd!important;
  font-weight: 300;
  text-decoration-style: solid;
}
.text-overflow-ellipses {
  white-space: nowrap!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  padding-right:25px;
  cursor: pointer;
  caret-color: transparent; 
}
.cursor-pointer {
  cursor: pointer;
}
      `}</style>
    </Card>
  );
};

export default CollegeConfig;
