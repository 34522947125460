import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IOSSwitch from 'components/input/IOSSwitch';
import SmartPhoneOutlinedIcon from 'components/icons/SmartPhoneOutlinedIcon';
import EmailOutlinedIcon from 'components/icons/EmailOutlinedIcon';
import PersonAddAltOutlinedIcon from 'components/icons/PersonAddAltOutlinedIcon';
import BellOutlinedIcon from 'components/icons/BellOutlinedIcon';
import toast from 'react-hot-toast';
import notificationServices from 'services/notificationServices';
import { useAppSelector } from 'app/hooks';

interface Props {}

const SettingsNotification = (props: Props) => {
  const personalStore = useAppSelector((store) => store.basicInformation.myBasicInformation);
  const [data, setData] = React.useState<any>({
    id: 0,
    user_id: 0,
    application_status_array: [false, false, false, false],
    news_updates_array: [false, false, false, false],
    special_dates_array: [false, false, false, false],
    college_updates_array: [false, false, false, false],
  });
  const [refershData, setRefershData] = React.useState(true);

  const toArray = (s: string) => {
    return JSON.parse('[' + s.replaceAll(`"`, '').replace('{', '').replace('}', '') + ']');
  };

  const loadNotification = (userId: any) => {
    notificationServices.getNotification(userId).then((res: any) => {
      try {
        if (userId) {
          if (res) {
            let obj = {
              id: res.id,
              user_id: res.user_id,
              application_status_array: toArray(res.application_status_array),
              news_updates_array: toArray(res.news_updates_array),
              special_dates_array: toArray(res.special_dates_array),
              college_updates_array: toArray(res.college_updates_array),
            };

            setData(obj);
          } else {
            const body = {
              user_id: userId ?? 0,
              application_status_array: [false, false, false, false],
              news_updates_array: [false, false, false, false],
              special_dates_array: [false, false, false, false],
              college_updates_array: [false, false, false, false],
            };
            notificationServices.createNotification(body).then((res) => {
              setRefershData(true);
            });
          }
        }
      } catch (ex) {}
    });
  };

  React.useEffect(() => {
    const userId = personalStore?.student_id;
    if (refershData) {
      loadNotification(userId);
      setRefershData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refershData, personalStore?.student_id]);

  const getApplicationStatus = (index: any) => {
    try {
      return data.application_status_array[index];
    } catch (ex) {
      return false;
    }
  };

  const getNewsStatus = (index: any) => {
    try {
      return data.news_updates_array[index];
    } catch (ex) {
      return false;
    }
  };

  const getSpecialDatesStatus = (index: any) => {
    try {
      return data.special_dates_array[index];
    } catch (ex) {
      return false;
    }
  };

  const getCollegeUpdatesStatus = (index: any) => {
    try {
      return data.college_updates_array[index];
    } catch (ex) {
      return false;
    }
  };

  const updateApplicationStatus = (index: any, checked: boolean) => {
    try {
      if (data && data.application_status_array.length === 4) {
        if (index >= 0 && index <= 4) {
          const arr = data.application_status_array.map((val: any, i: any) =>
            i === index ? checked : val
          );

          let obj = Object.assign({}, data);
          obj.application_status_array = arr;
          setData(obj);

          if (obj.id && obj.user_id) {
            const toastId = toast.loading('Saving...');
            notificationServices
              .updateNotification(obj)
              .then((res: any) => {
                toast.success(res.message, { id: toastId, duration: 1000 });
                setRefershData(true);
              })
              .catch((error) => {
                toast.error(`Could not save data. ${error?.message}`);
                setTimeout(() => {
                  toast.dismiss(toastId);
                }, 1500);
                setRefershData(true);
              });
          }
        }
      }
    } catch (ex2) {}
  };

  const updateNewsStatus = (index: any, checked: boolean) => {
    try {
      if (data && data.news_updates_array.length === 4) {
        if (index >= 0 && index <= 4) {
          const arr = data.news_updates_array.map((val: any, i: any) =>
            i === index ? checked : val
          );

          let obj = Object.assign({}, data);
          obj.news_updates_array = arr;
          setData(obj);

          if (obj.id && obj.user_id) {
            const toastId = toast.loading('Saving...');
            notificationServices
              .updateNotification(obj)
              .then((res: any) => {
                toast.success(res.message, { id: toastId, duration: 1000 });
                setRefershData(true);
              })
              .catch((error) => {
                toast.error(`Could not save data. ${error?.message}`);
                setTimeout(() => {
                  toast.dismiss(toastId);
                }, 1500);
                setRefershData(true);
              });
          }
        }
      }
    } catch (ex2) {}
  };

  const updateSpecialDatesStatus = (index: any, checked: boolean) => {
    try {
      if (data && data.special_dates_array.length === 4) {
        if (index >= 0 && index <= 4) {
          const arr = data.special_dates_array.map((val: any, i: any) =>
            i === index ? checked : val
          );

          let obj = Object.assign({}, data);
          obj.special_dates_array = arr;
          setData(obj);

          if (obj.id && obj.user_id) {
            const toastId = toast.loading('Saving...');
            notificationServices
              .updateNotification(obj)
              .then((res: any) => {
                toast.success(res.message, { id: toastId, duration: 1000 });
                setRefershData(true);
              })
              .catch((error) => {
                toast.error(`Could not save data. ${error?.message}`);
                setTimeout(() => {
                  toast.dismiss(toastId);
                }, 1500);
                setRefershData(true);
              });
          }
        }
      }
    } catch (ex2) {}
  };

  const updateCollegeUpdatesStatus = (index: any, checked: boolean) => {
    try {
      if (data && data.college_updates_array.length === 4) {
        if (index >= 0 && index <= 4) {
          const arr = data.college_updates_array.map((val: any, i: any) =>
            i === index ? checked : val
          );

          let obj = Object.assign({}, data);
          obj.college_updates_array = arr;
          setData(obj);

          if (obj.id && obj.user_id) {
            const toastId = toast.loading('Saving...');
            notificationServices
              .updateNotification(obj)
              .then((res: any) => {
                toast.success(res.message, { id: toastId, duration: 1000 });
                setRefershData(true);
              })
              .catch((error) => {
                toast.error(`Could not save data. ${error?.message}`);
                setTimeout(() => {
                  toast.dismiss(toastId);
                }, 1500);
                setRefershData(true);
              });
          }
        }
      }
    } catch (ex2) {}
  };

  return (
    <div className="tab-wrap-panel">
      <div className="tab-panel2">
        <div className="tab-panel2-content">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <div
                  className="text-primary p-2 rounded-circle bg-primary-light d-flex align-items-center justify-content-center"
                  style={{ width: '35px', height: '35px', marginRight: '8px' }}
                >
                  <BellOutlinedIcon className="text-primray-light" width={'18px'} height={'18px'} />
                </div>
                <div className="text-primary fs-4 fw-bold mx-1">Notifications</div>
              </div>
            </div>
          </div>
        </div>

        <Row>
          <Col xs={12}>
            <TableContainer component={'div'}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="center" className="fs-16px">
                      <SmartPhoneOutlinedIcon
                        fontSize="small"
                        className="text-field-light mr-4px"
                      />
                      Phone
                    </TableCell>
                    <TableCell align="center" className="fs-16px">
                      <EmailOutlinedIcon fontSize="small" className="text-field-light mr-4px" />
                      Email
                    </TableCell>
                    <TableCell align="center" className="fs-16px">
                      <PersonAddAltOutlinedIcon
                        fontSize="small"
                        className="text-field-light mr-4px"
                      />
                      Partner No.1 - Phone
                    </TableCell>
                    <TableCell align="center" className="fs-16px">
                      <PersonAddAltOutlinedIcon
                        fontSize="small"
                        className="text-field-light mr-4px"
                      />
                      Partner No.2 - Phone
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      backgroundColor: '#FCFBFC',
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="text-field-mid fs-16px pl-30px"
                    >
                      Application Status
                    </TableCell>
                    <TableCell align="center">
                      <IOSSwitch
                        checked={getApplicationStatus(0)}
                        onChange={(_: any, checked: boolean) => updateApplicationStatus(0, checked)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IOSSwitch
                        checked={getApplicationStatus(1)}
                        onChange={(_: any, checked: boolean) => updateApplicationStatus(1, checked)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IOSSwitch
                        checked={getApplicationStatus(2)}
                        onChange={(_: any, checked: boolean) => updateApplicationStatus(2, checked)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IOSSwitch
                        checked={getApplicationStatus(3)}
                        onChange={(_: any, checked: boolean) => updateApplicationStatus(3, checked)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{}}>
                    <TableCell
                      component="th"
                      scope="row"
                      className="text-field-mid fs-16px pl-30px"
                    >
                      News & Updates
                    </TableCell>
                    <TableCell align="center">
                      <IOSSwitch
                        checked={getNewsStatus(0)}
                        onChange={(_, checked) => updateNewsStatus(0, checked)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IOSSwitch
                        checked={getNewsStatus(1)}
                        onChange={(_, checked) => updateNewsStatus(1, checked)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IOSSwitch
                        checked={getNewsStatus(2)}
                        onChange={(_, checked) => updateNewsStatus(2, checked)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IOSSwitch
                        checked={getNewsStatus(3)}
                        onChange={(_, checked) => updateNewsStatus(3, checked)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      backgroundColor: '#FCFBFC',
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="text-field-mid fs-16px pl-30px"
                    >
                      Special Dates
                    </TableCell>
                    <TableCell align="center">
                      <IOSSwitch
                        checked={getSpecialDatesStatus(0)}
                        onChange={(_, checked) => updateSpecialDatesStatus(0, checked)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IOSSwitch
                        checked={getSpecialDatesStatus(1)}
                        onChange={(_, checked) => updateSpecialDatesStatus(1, checked)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IOSSwitch
                        checked={getSpecialDatesStatus(2)}
                        onChange={(_, checked) => updateSpecialDatesStatus(2, checked)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IOSSwitch
                        checked={getSpecialDatesStatus(3)}
                        onChange={(_, checked) => updateSpecialDatesStatus(3, checked)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{}}>
                    <TableCell
                      component="th"
                      scope="row"
                      className="text-field-mid fs-16px pl-30px"
                    >
                      College Updates
                    </TableCell>
                    <TableCell align="center">
                      <IOSSwitch
                        checked={getCollegeUpdatesStatus(0)}
                        onChange={(_, checked) => updateCollegeUpdatesStatus(0, checked)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IOSSwitch
                        checked={getCollegeUpdatesStatus(1)}
                        onChange={(_, checked) => updateCollegeUpdatesStatus(1, checked)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IOSSwitch
                        checked={getCollegeUpdatesStatus(2)}
                        onChange={(_, checked) => updateCollegeUpdatesStatus(2, checked)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IOSSwitch
                        checked={getCollegeUpdatesStatus(3)}
                        onChange={(_, checked) => updateCollegeUpdatesStatus(3, checked)}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
          <style scoped>{`
th, td {
  border: none !important;
};
          `}</style>
        </Row>
      </div>
    </div>
  );
};

export default SettingsNotification;
