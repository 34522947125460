/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import SelectInput from 'components/input/SelectInput';
import GroupRadioButton from 'components/input/GroupRadioButton';
import EducationConfig from './EducationConfig';
import { setArrayLength, siblingEducationOptions } from 'components/helper/helper';
import YesNoSwitch from 'components/input/YesNoSwitch';

interface Props {
  formik: any;
}

const Education = (props: Props) => {
  React.useEffect(() => {
    props.formik.setFieldValue(
      'parent_graduation_college_array',
      setArrayLength(
        props.formik.values.parent_graduation_college_array,
        props.formik.values.total_number_institutions_attended
      )
    );
  }, [props.formik.values.total_number_institutions_attended]);

  return (
    <>
      <div className="fs-5 p-1">Education</div>
      <SelectInput
        logo="span-cm-logo"
        form={props.formik}
        option={siblingEducationOptions}
        name="education_level"
        values={props.formik.values.education_level}
        onChange={props.formik.handleChange}
        label="Education Level"
      />
      <YesNoSwitch
        hidden={
          props.formik.values.education_level !==
            `Graduated from a trade school or community college` &&
          props.formik.values.education_level !== `Graduated from college/university`
        }
        logo="span-uc-logo"
        values={props.formik.values.csu_info?.parent_graduate_within_the_last_five_years}
        name="csu_info.parent_graduate_within_the_last_five_years"
        onChange={props.formik.handleChange}
        onClear={() =>
          props.formik.setFieldValue('csu_info.parent_graduate_within_the_last_five_years', '')
        }
        label="Did this parent graduate within the last five years?"
      />
      {[
        'Some trade school or community college',
        'Graduated from a trade school or community college',
        'Some college/university',
        'Graduated from college/university',
        'Graduate school',
      ].includes(props.formik.values.education_level) && (
        <>
          <GroupRadioButton
            label="Total number of institutions attended"
            className="pt-3 mb-3"
            logo="span-cm-logo"
            mdCol={1}
            name="total_number_institutions_attended"
            values={props.formik.values.total_number_institutions_attended}
            onChange={props.formik.handleChange}
            options={[
              { value: '1' },
              { value: '2' },
              { value: '3' },
              { value: '4' },
              { value: '5' },
            ]}
          />
          <div>
            {(props.formik.values.parent_graduation_college_array ?? []).map(
              (item: any, index: number) => (
                <div key={index}>
                  <EducationConfig
                    index={index}
                    formik={props.formik}
                    item={item}
                    indexText={`${index + 1}/${
                      props.formik.values.parent_graduation_college_array.length
                    }`}
                  />
                </div>
              )
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Education;
