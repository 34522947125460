/* eslint-disable no-empty-pattern */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { checkEnableParseJSON } from 'components/helper/helper';
import toast from 'react-hot-toast';
import familyServices from 'services/familyServices';

const buildRole = (myHousehold: any) => {
  const who_in_household_array = checkEnableParseJSON(myHousehold?.who_in_household_array, {});
  let role = {
    parentNo1: false,
    parentNo2: false,
    legalGuardian: false,
    stepParent1: false,
    stepParent2: false,
    siblings: false,
  };
  if (who_in_household_array?.is_parent_guardian) {
    role.parentNo1 = true;
    role.parentNo2 = true;
  }
  if (who_in_household_array?.is_sibbling) {
    role.siblings = true;
  }

  if (
    myHousehold?.is_listing_step_parents === 'true' &&
    myHousehold?.how_many_step_parents === '1'
  ) {
    role.stepParent1 = true;
  } else if (myHousehold?.is_listing_step_parents === 'true') {
    role.stepParent1 = true;
    role.stepParent2 = true;
  }
  if (
    myHousehold?.whom_live_permanently === 'Legal Guardian' ||
    myHousehold?.whom_live_permanently === 'Ward of Court/State'
  ) {
    role.legalGuardian = true;
  }

  return role;
};
export interface FamilyState {
  myHousehold: any;
  parent1: any;
  parent2: any;
  stepParent1: any;
  stepParent2: any;
  mySiblings: any;
  roleFamily: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: FamilyState = {
  myHousehold: null,
  parent1: null,
  parent2: null,
  stepParent2: null,
  stepParent1: null,
  mySiblings: null,
  roleFamily: {
    parent1: false,
    parent2: false,
    stepParent1: false,
    stepParent2: false,
    legalGuardian: false,
    siblings: false,
  },
  status: 'idle',
};
export const getHousehold: any = createAsyncThunk('family/getHousehold', async (studentId: any, {rejectWithValue}) => {
  try {
    const response = await familyServices.getHousehold(studentId);
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updateHousehold: any = createAsyncThunk(
  'family/updateHousehold',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const myHousehold = state.family.myHousehold;
    try {
      if (myHousehold) {
        const response = await familyServices.updateHousehold(payload);
        return response.message ? { ...payload, message: response.message } : response;
      } else {
        const response = await familyServices.createHousehold(payload);
        return response.message ? { ...payload, message: response.message } : response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getParent1: any = createAsyncThunk('family/getParent1', async (studentId: any, thunkAPI) => {
  try {
    const response = await familyServices.getParent1(studentId);
    return response; 
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getStepParent1: any = createAsyncThunk(
  'family/stepParent1',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await familyServices.getStepParent1(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getStepParent2: any = createAsyncThunk(
  'family/stepParent2',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await familyServices.getStepParent2(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStepParent1: any = createAsyncThunk(
  'family/updateStepParent1',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const stepParent1 = state.family.stepParent1;
    try {
      if (stepParent1) {
        const response = await familyServices.updateStepParent1(payload);
        return response;
      } else {
        const response = await familyServices.createStepParent1(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStepParent2: any = createAsyncThunk(
  'family/updateStepParent2',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const stepParent2 = state.family.stepParent2;
    try {
      if (stepParent2) {
        const response = await familyServices.updateStepParent2(payload);
        return response;
      } else {
        const response = await familyServices.createStepParent2(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateParent1: any = createAsyncThunk(
  'family/updateParent1',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const parent1 = state.family.parent1;
    try {
      if (parent1) {
        const response = await familyServices.updateParent1(payload);
        return response;
      } else {
        const response = await familyServices.createParent1(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getParent2: any = createAsyncThunk('family/getParent2', async (studentId: any, {rejectWithValue}) => {
  try {
  const response = await familyServices.getParent2(studentId);
  return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }

});
export const updateParent2: any = createAsyncThunk(
  'family/updateParent2',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const parent2 = state.family.parent2;
    try {
      if (parent2) {
        const response = await familyServices.updateParent2(payload);
        return response;
      } else {
        const response = await familyServices.createParent2(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getSiblings: any = createAsyncThunk('family/getSiblings', async (studentId: any, {rejectWithValue}) => {
  try {
    const response = await familyServices.getSiblings(studentId);
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updateSiblings: any = createAsyncThunk(
  'family/updateSiblings',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const mySiblings = state.family.mySiblings;
    try {
      if (mySiblings) {
        const response = await familyServices.updateSiblings(payload);
        return response;
      } else {
        const response = await familyServices.createSiblings(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const familySlice = createSlice({
  name: 'family',
  initialState,
  reducers: {},
  extraReducers: {
    [getHousehold.pending]: (state) => {
      state.status = 'loading';
    },
    [getHousehold.rejected]: (state) => {
      state.status = 'failed';
    },
    [getHousehold.fulfilled]: (state, action) => {
      state.myHousehold = action.payload;
      state.roleFamily = buildRole(action.payload);
      state.status = 'idle';
    },
    //
    [updateHousehold.pending]: (state) => {
      state.status = 'loading';
    },
    [updateHousehold.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateHousehold.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myHousehold = action.payload;
      state.roleFamily = buildRole(action.payload);
      state.status = 'idle';
    },
    //
    [getParent1.pending]: (state) => {
      state.status = 'loading';
    },
    [getParent1.rejected]: (state) => {
      state.status = 'failed';
    },
    [getParent1.fulfilled]: (state, action) => {
      state.parent1 = action.payload;
      state.status = 'idle';
    },
    //
    [updateParent1.pending]: (state) => {
      state.status = 'loading';
    },
    [updateParent1.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateParent1.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.parent1 = action.payload;
      state.status = 'idle';
    },
    //
    [getParent2.pending]: (state) => {
      state.status = 'loading';
    },
    [getParent2.rejected]: (state) => {
      state.status = 'failed';
    },
    [getParent2.fulfilled]: (state, action) => {
      state.parent2 = action.payload;
      state.status = 'idle';
    },
    //
    [updateParent2.pending]: (state) => {
      state.status = 'loading';
    },
    [updateParent2.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateParent2.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.parent2 = action.payload;
      state.status = 'idle';
    },
    //
    // [getParent2.pending]: (state) => {
    //   state.status = 'loading';
    // },
    // [getParent2.rejected]: (state) => {
    //   state.status = 'failed';
    // },
    // [getParent2.fulfilled]: (state, action) => {
    //   state.parent2 = action.payload;
    //   state.status = 'idle';
    // },
    // //
    // [updateParent2.pending]: (state) => {
    //   state.status = 'loading';
    // },
    // [updateParent2.rejected]: (state) => {
    //   state.status = 'failed';
    // },
    // [updateParent2.fulfilled]: (state, action) => {
    //   toast.success(action.payload.message);
    //   state.parent2 = action.payload;
    //   state.status = 'idle';
    // },
    //
    [getStepParent1.pending]: (state) => {
      state.status = 'loading';
    },
    [getStepParent1.rejected]: (state) => {
      state.status = 'failed';
    },
    [getStepParent1.fulfilled]: (state, action) => {
      state.stepParent1 = action.payload;
      state.status = 'idle';
    },
    //
    [updateStepParent1.pending]: (state) => {
      state.status = 'loading';
    },
    [updateStepParent1.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateStepParent1.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.stepParent1 = action.payload;
      state.status = 'idle';
    },
    //
    [getStepParent2.pending]: (state) => {
      state.status = 'loading';
    },
    [getStepParent2.rejected]: (state) => {
      state.status = 'failed';
    },
    [getStepParent2.fulfilled]: (state, action) => {
      state.stepParent2 = action.payload;
      state.status = 'idle';
    },
    //
    [updateStepParent2.pending]: (state) => {
      state.status = 'loading';
    },
    [updateStepParent2.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateStepParent2.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.stepParent2 = action.payload;
      state.status = 'idle';
    },
    //
    [getSiblings.pending]: (state) => {
      state.status = 'loading';
    },
    [getSiblings.rejected]: (state) => {
      state.status = 'failed';
    },
    [getSiblings.fulfilled]: (state, action) => {
      state.mySiblings = action.payload;
      state.status = 'idle';
    },
    //
    [updateSiblings.pending]: (state) => {
      state.status = 'loading';
    },
    [updateSiblings.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateSiblings.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.mySiblings = action.payload;
      state.status = 'idle';
    },
  },
});

export const {} = familySlice.actions;
