import http from './http';

const PaymentServices = {
  async getConfig() {
    const res: any = await http.get(`api/extension/stripe/config`);
    return res?.publishableKey;
  },
  async createPayment(amount: number) {
    const res: any = await http.get(`api/extension/stripe/create-payment-intent`, {
      params: { amount },
    });
    return res?.clientSecret;
  },


  async getSubcription(studentId: number) {
    const body = {
      student_id: studentId
    };
    const res = await http.post('api/stripe/stripe-subscription', body);
    return res;
  },

  async getBillPortalUrl(domainUrl:string, studentId: number) {
    const body = {
      domain_url: domainUrl,
      student_id: studentId
    };
    const res = await http.post('api/stripe/billing-portal', body);
    return res;
  },

  async getUserBillPortalUrl(returnUrl:string, studentId: number) {
    const body = {
      return_url: returnUrl,
      student_id: studentId
    };
    const res = await http.post('api/stripe/admin-billing-portal', body);
    return res;
  }, 

  async checkoutStripSession(sessionId: string) {
    const body = {
      session_id: sessionId
    };
    const res = await http.post('api/stripe/checkout-session', body);
    return res;
  },

  async createCheckoutSession(domainUrl:string, priceName:string, trialPeriodDays: number, student_id: string, studentEmail:string) {
    const body = {
      domain_url: domainUrl,
      price_name: priceName,
      trial_period_days: trialPeriodDays,
      student_id: student_id,
      customer_email: studentEmail
    };
    const res = await http.post('api/stripe/create-checkout-session', body);
    return res;
    
  },
 
  async getEnableExtention(student_id: any){ 
    const res = await http.post(`api/stripe/is-enable-extention`, {
      student_id
    });
    return res;
  },

  async deleteStripeSubcription(studentId: string | number) {
    const res = await http.delete(`api/stripe/stripe_subcription/${studentId}`);
    return res;
  },

  async getStripeCustomerMappping(studentId: string | number) {
    const res = await http.get(`/api/stripe/stripe_customer_mappings/${studentId}`);
    return res;
  },
 
  async createUserCheckoutSession(domainUrl:string, priceName:string, trialPeriodDays: number, 
    student_id: string, studentEmail:string, customerId: string) 
  {
    const body = {
      domain_url: domainUrl,
      price_name: priceName,
      trial_period_days: trialPeriodDays,
      student_id: student_id,
      customer_email: studentEmail,
      customer_id: customerId,
    };
    const res = await http.post('api/stripe/create-user-checkout-session', body);
    return res;    
  },
 


};

export default PaymentServices;
