import React from 'react';
import { Spinner } from 'react-bootstrap';

interface Props {}

const LoadingContent = (props: Props) => {
  return (
    <div className="w-100 my-5 text-center">
      <Spinner animation="grow" size="sm" className="mx-1" variant="primary" />
      <Spinner animation="grow" size="sm" className="mx-1" variant="primary" />
      <Spinner animation="grow" size="sm" className="mx-1" variant="primary" />
    </div>
  );
};

export default LoadingContent;
