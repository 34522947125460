import SelectInput from 'components/input/SelectInput';
import ReuseLoginLayout from 'components/layout/ReuseLoginLayout';
import React from 'react';
import { Button, Badge, Image, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { routers } from 'router/router';
import FormAuthPhoneNumber from './FormAuthPhoneNumber';
import FormRegister from './FormRegister';
import FormRegisterSchool from './FormRegisterSchool';
import { useFormik } from 'formik';
import { USER_ROLE } from 'constants/common';
import * as Yup from 'yup';
import { useAppDispatch } from 'app/hooks';
import { createStudent } from 'app/authorization/authorizationSlice';
import FormRegisterFamily from './FormRegisterFamily';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { updateHighSchool } from 'app/highSchoolSlice/highSchoolSlice';

interface Props {}

const RegisterPage = (props: Props) => {
  const [step, setStep] = React.useState(1);
  let history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const stepURL = params.get('step');
  const priceName = params.get('price');
  let paymentPath = priceName ? `${routers.payment.path}?price=${priceName}` : routers.payment.path;

  React.useEffect(() => {
    if (stepURL) {
      setStep(Number(stepURL));
    } else {
      setStep(1);
    }
    // setStep(Number(stepURL));
  }, [stepURL]);

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      user_role: USER_ROLE.student,
      school_or_organization_work_with: '',
      year_finish_high_school: new Date().getFullYear() + 1,
      password: '',
      state: '',
      city: '',
      csu_city: '',
      password_confirm: '',
      additional_info: {
        where_attend_high_school: '',
        school_system: '',
        high_school_name: '',
        majors_interest_1: '',
        majors_interest_2: '',
        year_plan_college_studies: '',
        first_name: '',
        last_name: '',
        relationship_to_you: '',
        prefered_email: '',
      },
      price_name: priceName,
      schoolId: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Required'),
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      password: Yup.string().min(6, 'Minlength is 6').required('Required'),
      password_confirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        const { price_name, ...newValues }: any = values; // newValues except for price_name
        const highSchoolData = {
          counselor_prefix: '',
          counselor_first_name: '',
          counselor_middle_initial: '',
          counselor_last_name: '',
          counselor_job_title: '',
          counselor_email: '',
          counselor_country_code: '',
          counselor_phone_number: '',
          graduating_class_size: null,
          class_rank_reporting: null,
          gpa_scale_reporting: null,
          culmative_gpa: null,
          gpa_weighting: null,
          other_school_array: [{}],
          number_of_other_school_attended: 0,
          high_school_info: {
            where_attend_high_school: '',
            state: '',
            city: '',
            country: '',
            is_home_school: false,
            is_independant_study: false,
            my_home_school_associated_with_high_school: false,
            school_name: '',
            school_type: '',
            left_highschool_reason: '',
          },
          graduating_school_array: [{}],
          high_school_array: [
            {
              where_attend_high_school: get(newValues, "additional_info.where_attend_high_school", ''),
              state: values.state,
              city: values.city,
              country: '',
              is_home_school: false,
              is_independant_study: false,
              my_home_school_associated_with_high_school: false,
              school_name: '',
              school_type: '',
              high_school_name: get(newValues, "additional_info.high_school_name", ''),
              degree: '',
              month: '',
              day: '',
              year: '',
              grade: [],
              academic_year: [],
              is_summer_took: [],
              specialized_curriculum_career: false,
              specialized_curriculum_international: false,
              specialized_curriculum_magnet: false,
              specialized_curriculum_other: false,
              specialized_curriculum_other_free_text: '',
              grading_scale: '',
              grading_scale_is_abcdf: false,
              grading_scale_is_0_100: false,
              grading_scale_is_1_7: false,
              grading_scale_is_other: false,
              is_block_scheduling: false,
              degreeFreeText: '',
              lowest_number_grade: '',
              highest_number_grade: '',
              classes_schedule: get(newValues, "additional_info.school_system", ''),
              other_grading_system: '',
              graduating_high_school: '',
              school_term_1: false,
              school_term_2: false,
              school_term_3: false,
              school_term_4: false,
              high_school_object: newValues?.high_school_object || {},
              is_boarding_school: '',
              is_graduate_from_this_school: '',
              is_live_on_campus: '',
              graduation_status: '',
              academic_performance: '',
              name_of_degree: '',
              allNoneAGCourses: false,
              have_other_grading_system: false,
              second_grading_system: '',
              csu_city: values.csu_city,
            },
          ],
        };
        
        await dispatch(
          createStudent({ ...newValues, additional_info: JSON.stringify(values.additional_info), high_school_data: JSON.stringify(highSchoolData)})
        ).then(async (res: any) => {
          if (res.payload) {
            Cookies.set('userId', res.payload.student_id);
            Cookies.set('request_id', res.payload.student_id);
            Cookies.set('userEmail', values.email);
            console.log('cookie id:', res.payload.student_id);
            console.log('userEmail:', values.email);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  // React.useEffect(() => history.push(routers.payment), []);
  return (
    <ReuseLoginLayout>
      <div className="d-flex mx-3 flex-wrap pb-md-5 mb-md-5 justify-content-between align-items-center">
        <span>
          <SelectInput
            values={'English (USA)'}
            borderless
            mdCol={'12'}
            option={['English (USA)']}
          />
        </span>
        <span className="mt-2">
          <span> Already a member ?</span>
          <Button
            className="p-0 mx-1 pb-1"
            onClick={() => history.push(routers.login.path)}
            variant="link"
          >
            Login
          </Button>
        </span>
      </div>
      {step === 1 && (
        <Col xs={12}>
          <h2 className="m-3">🎓 Let's set you up!</h2>
          <div className="m-3 d-flex justify-content-between align-items-center">
            <h5>Easy 3-step account creation</h5>
            <Badge className="px-2 py-1" bg="secondary">
              <span className="h6">{step}/3</span>
            </Badge>
          </div>
          <Col>
            <FormRegister
              onNextStep={(e) => {
                setStep(e);
              }}
              formik={formik}
            />
          </Col>
        </Col>
      )}
      {step === 2 && (
        <Col xs={12}>
          <h2 className="m-3">🎓 Let's set you up!</h2>
          <div className="m-3 d-flex justify-content-between align-items-center">
            <h5>Easy 3-step account creation</h5>
            <Badge className="px-2 py-1" bg="secondary">
              <span className="h6">{step}/3</span>
            </Badge>
          </div>
          <Col>
            <FormRegisterFamily onNextStep={setStep} formik={formik} />
          </Col>
        </Col>
      )}
      {step === 3 && (
        <Col xs={12}>
          <h2 className="m-3">🎓 Let's set you up!</h2>
          <div className="m-3 d-flex justify-content-between align-items-center">
            <h5>Share some info so we can tailor your GradMap</h5>
            <Badge className="px-2 py-1" bg="secondary">
              <span className="h6">{step}/3</span>
            </Badge>
          </div>
          <Col>
            <FormRegisterSchool onNextStep={setStep} formik={formik} />
          </Col>
        </Col>
      )}
      {step === 4 && (
        <Col xs={12}>
          <FormAuthPhoneNumber
            currentStep={step}
            setStep={
              (step) => {
                step === 4 ? history.push(paymentPath) : setStep(step);
              }
              // setStep
            }
            formik={formik}
          />
        </Col>
      )}
      {step === 5 && (
        <Col>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Image src={'/assets/images/image-11.png'} />
            <p className="h3 mt-5">Good job! Let's keep going!</p>
            <p className="h6 mx-3">We’ve send you a confirmation email to complete your account</p>
            <Button
              className="w-25 p-3 m-3"
              variant="primary"
              onClick={() => history.push(routers.login.path)}
            >
              <span className="h5 fw-bold">Login</span>
            </Button>
          </div>
        </Col>
      )}
    </ReuseLoginLayout>
  );
};

export default RegisterPage;
