import PasswordInput from 'components/input/PasswordInput';
import TextIconInput from 'components/input/TextIconInput';
import { useFormik } from 'formik';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Form, Row, Button, Spinner } from 'react-bootstrap';
import { routers } from 'router/router';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getStudent, login, setStudentId } from 'app/authorization/authorizationSlice';
import { getStripeSubscriptionInfo, getCurrentBasicDetail, getContactDetail } from 'app/basicInformationSlice/basicInformationSlice';
import Cookies from 'js-cookie';
import { getHighSchoolData } from 'app/highSchoolSlice/highSchoolSlice';
import { checkEnableParseJSON } from 'components/helper/helper';
import { get } from 'lodash';
interface Props {}

const FormLogin = (props: Props) => {
  const dispatch = useAppDispatch();
  const authorizationStore = useAppSelector((store) => store.authorization);

  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        const res:any = await dispatch(login(values))

        if (res?.error?.message === "User not found") {
          history.push(routers.register.path);
          return;
        }

        if (res.payload) {
          if (res.payload && res.payload.is_confirmed_by_phone) {
            localStorage.setItem('currentUserEmail', res.payload.email);
            localStorage.setItem('userRole', res.payload.user_role);
            const subStripeInfo: any = await dispatch(
              getStripeSubscriptionInfo(res.payload?.student_id)
            );

            if(res.payload.user_role === 'counselor') {
              history.push(routers.viewStudent.path);
            } else {
              if (subStripeInfo.payload?.subcription_plan === '') {
                Cookies.set('request_id', res.payload.student_id);
                Cookies.set('userEmail', res.payload.email);
                history.push(routers.payment.path);
              } else {
                const info = await dispatch(getCurrentBasicDetail(res.payload.student_id))
                const student = await dispatch(getStudent(res.payload.student_id));
                const contactDetail: any = await dispatch(getContactDetail(res.payload.student_id));
                let highschoolName = await getHighSchoolName(res.payload.student_id);
                const additionalInfo = checkEnableParseJSON(student.payload.additional_info, {});
                let stripeStatus = get(subStripeInfo, 'payload.subcription_status', '');

                if(stripeStatus === 'trialing') {
                  stripeStatus = 'Trial Mode';
                }

                if(window.Intercom && info.payload) {
                  let userPlan = ''
                  if (info.payload.use_extension && subStripeInfo.payload?.subcription_plan.includes('gold')) {
                    userPlan = 'gold';
                  }
          
                  if (info.payload.use_extension && subStripeInfo.payload?.subcription_plan.includes('silver_plus')) {
                    userPlan = 'silver-plus';
                  }
          
                  if (!info.payload.use_extension) {
                    userPlan = 'silver';
                  }

                  window.Intercom('boot', {
                    name: info.payload.last_name,
                    email: info.payload.email,
                    plan: userPlan,
                    user_hash: info.payload.user_hash,
                    phone: contactDetail.payload?.phone_number,
                    gm_user_id: res.payload.student_id,
                    student_first_name: info.payload.first_name,
                    student_last_name: info.payload.last_name,
                    student_email: info.payload.email,
                    current_high_school: highschoolName,
                    home_address_state: info.payload.state,
                    user_type: info.payload.user_role,
                    environment: process.env.REACT_APP_ENV,
                    major_1: get(additionalInfo, 'majors_interest_1', ''),
                    major_2: get(additionalInfo, 'majors_interest_2', ''),
                    graduation_year: info.payload.year_finish_high_school,
                    stripe_status: stripeStatus ?? '',
                  });
                }
                history.push({
                  pathname: routers.dashBoard.path,
                  search: `?role=${res.payload.user_role}`,
                })
              }
            }
          } else 
          if (!res.payload.is_confirmed_by_phone) {
            dispatch(setStudentId(res.payload.student_id));
            history.push({ pathname: routers.register.path, search: '?step=4' });
          }
          if (res.payload?.student_id) {
            dispatch(getStudent(res.payload?.student_id));
          }
        }

      } catch (error) {
        console.log({ error });
      }
    },
  });

  async function getHighSchoolName(studentId: any) {
    try {
      let hightSchoolData = await dispatch(getHighSchoolData(studentId)).unwrap();
      let currentHighSchool = checkEnableParseJSON(hightSchoolData.high_school_array, []);
      if (currentHighSchool) {
        return currentHighSchool[0]?.high_school_name;
      }
    }
    catch(ex){
      console.log('Error:', ex);
    }

    return '';
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row className="mb-0 p-0 mx-0">
        <TextIconInput
          icon={<MailOutlineIcon />}
          mdCol={'12'}
          label={'Email'}
          name={'email'}
          values={formik.values.email}
          onChange={formik.handleChange}
          feedback={
            formik.touched.email &&
            formik.errors.email && <span className="text-danger">{formik.errors.email}</span>
          }
        />

        <PasswordInput
          mdCol={'12'}
          label={'Password'}
          name={'password'}
          values={formik.values.password}
          onChange={formik.handleChange}
          feedback={
            formik.touched.password &&
            formik.errors.password && <span className="text-danger">{formik.errors.password}</span>
          }
        />
      </Row>
      <Button
        className="m-0 p-0"
        variant="link"
        onClick={() => history.push(routers.forgotPassword.path)}
      >
        Forgot your password?
      </Button>

      {/* <div className="w-100 d-flex align-items-center my-3">
        <div className="border mb-1" style={{ width: 'calc(50% - 20px)' }} />
        <div className="text-field-light text-center" style={{ width: '40px' }}>
          OR
        </div>
        <div className="border mb-1" style={{ width: 'calc(50% - 20px)' }} />
      </div>
      <div className="w-100 text-center my-4">
        <GoogleLogin
          clientId="822424685897-0gq4kp64obvug9k3odsmttmb4vmg9hei.apps.googleusercontent.com"
          buttonText="Create account with Google"
          cookiePolicy={'single_host_origin'}
          onSuccess={(response) => {}}
          onFailure={(response) => {}}
          style={{ padding: '3rem', borderRadius: '20px' }}
        />
      </div> */}
      {authorizationStore.status !== 'loading' ? (
        <Button className="w-100 my-3" type="submit" variant="primary" style={{ height: 42 }}>
          <span className="h5 fw-bold">Login</span>
        </Button>
      ) : (
        <Button className="w-100 my-3" variant="primary" style={{ height: 42 }}>
          <Spinner style={{ fontSize: 16 }} size="sm" animation="border" variant="light" />
        </Button>
      )}
    </Form>
  );
};

export default FormLogin;
