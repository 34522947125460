import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
const HomePage = () => {
  const history = useHistory();
  const role = localStorage.getItem('userRole');
  if (role === 'counselor') {
    history.push(routers.viewStudent.path);
  }
  return  <></>;
};

export default HomePage;
