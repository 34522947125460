/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import CustomTabPanel from 'screen/counselor/components/TabPanel';
import { buildClassName } from 'screen/counselor/components/RPersonalInformation';
import LoadingContent from 'components/LoadingContent';
import ItemContent, { CheckboxContent } from 'screen/summary/components/ItemContent';
import { checkEnableParseJSON } from 'components/helper/helper';
import { useParams } from 'react-router-dom';
import familyServices from 'services/familyServices';
import { householdOptions } from 'screen/family/household/Household';
import ParentSum from 'screen/summary/components/ParentSum';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import StepParentSum from 'screen/summary/components/StepParentSum';
import { getHousehold } from 'app/family/familySlice';
import Cookies from 'js-cookie';
import { authenticateSettings } from 'components/helper/appConfig';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { routers } from 'router/router';
import { useHistory } from 'react-router-dom';

const FamilySum = ({
  setCurrentModuleId,
}: {
  setCurrentModuleId: (id: string) => void;
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();  
  const [value, setValue] = useState(1);
  const params: { id?: string | number } = useParams();
  let token = Cookies.get(authenticateSettings.tokenName);

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };  

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCurrentModuleId(`2.${newValue}`);
  };

  const checkModule = useAppSelector((store) => store.basicInformation.checkModule);
  const role = useAppSelector((store) => store.family.roleFamily);

  useEffect(() => {
    const getData = async () => {
      try {
        token = Cookies.get(authenticateSettings.tokenName);
        if (token) {
          await dispatch(getHousehold(params.id)).unwrap();
        }
        else {
          handleLogout();
        }
      }
      catch(ex){
        console.log('Error: ', ex);
      }
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);  

  return (
    <div>
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
        <Tab value={1} label="Household" className={buildClassName('2.1', checkModule)} />
        <Tab
          value={2}
          label="Parent/Guardian 1"
          hidden={!role.parentNo1}
          className={buildClassName('2.2', checkModule)}
        />
        <Tab
          value={3}
          label="Parent/Guardian 2"
          hidden={!role.parentNo2}
          className={buildClassName('2.3', checkModule)}
        />
        <Tab
          value={5}
          label="Step-Parent(s)"
          hidden={!(role.stepParent1 || role.stepParent2)}
          className={buildClassName('2.5', checkModule)}
        />
        <Tab
          value={4}
          label="Siblings"
          hidden={!role.siblings}
          className={buildClassName('2.4', checkModule)}
        />
       
      </Tabs>
      <CustomTabPanel value={value} index={1}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            Household
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryHousehold />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            Parent 1
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryParent number={1} />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            Parent 2
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryParent number={2} />
          </Stack>
        </Card>
      </CustomTabPanel>
      {/* Step Parent */}
      <CustomTabPanel value={value} index={5}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          {/* <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
          Step Parent
          </Typography> */}
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryStepParent />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            Siblings
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummarySiblings />
          </Stack>
        </Card>
      </CustomTabPanel>
    </div>
  );
};

export default FamilySum;

const SummaryHousehold = () => {
  const dispatch = useAppDispatch();
  const [myHouseholdState, setMyHouseholdState] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const params: { id?: string | number } = useParams();

  const myHousehold = useAppSelector((store) => store.family.myHousehold);

  // Auto-Refresh myHouseholdState if database changed
  useEffect(() => {
    setMyHouseholdState({
      ...myHousehold,
      csu_info: checkEnableParseJSON(myHousehold?.csu_info),
      who_in_household_array: checkEnableParseJSON(myHousehold?.who_in_household_array),
    });

  },[myHousehold]);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);         
        let res:any = await dispatch(getHousehold(params.id)).unwrap();        
        setMyHouseholdState({
          ...res,
          csu_info: checkEnableParseJSON(res?.csu_info),
          who_in_household_array: checkEnableParseJSON(res?.who_in_household_array),
        });

      }
      catch(ex){
        console.log('Error: ', ex);
      }
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      <div className="text-dart fs-5">Household Income and Size Information</div>
      <div className="text-dart mt-4">
        Please review the following statements carefully to determine if any of them apply to you.
        Then, answer the question below.
      </div>
      <ul>
        <li className="text-mute m-1 ms-3">You were born before January 1, 2002.</li>

        <li className="text-mute m-1 ms-3">
          You are currently an active duty member or a veteran of the U.S. Armed Forces.
        </li>
        <li className="text-mute m-1 ms-3">
          As of today, you are married or you are separated, but not divorced.
        </li>
        <li className="text-mute m-1 ms-3">
          You have or will have children or dependents who will receive more than half of their
          support from you between July 1, 2024 and June 30, 2026.
        </li>
        <li className="text-mute m-1 ms-3">
          Someone other than your parent(s) or stepparent(s) has legal guardianship of you, as
          determined by a court in your state of legal residence.
        </li>
        <li className="text-mute m-1 ms-3">
          At any time since you turned age 13, both your parents were deceased, you were in foster
          care, or you were a dependent or ward of the court.
        </li>
        <li className="text-mute m-1 ms-3">
          At any time on or after July 1, 2024, your high school, an emergency shelter, a
          transitional housing program, or a homeless youth center determined you were an
          unaccompanied youth or were self-supporting and at risk of being homeless.
        </li>
        <li className="text-mute m-1 ms-3">
          You currently do not live with your parent(s)/stepparent(s) and have not had contact or
          financial support from them for the last 12 months or more.
        </li>
      </ul>
      {myHouseholdState && (
        <>
          <ItemContent
            mdCol={12}
            values={myHouseholdState?.csu_info?.household_income_information_statements}
            label="Do any of the above statements apply to you?"
          />
          <div>Who is in your household? (select all that apply)</div>
          {householdOptions.map((option, index) => {
            const key = option.key;
            const formValues: any = myHouseholdState?.who_in_household_array ?? {
              is_parent_guardian: false,
              is_sibbling: false,
              is_spouse: false,
              is_child: false,
            };
            return (
              <CheckboxContent col={4} key={index} label={option.value} values={formValues[key]} />
            );
          })}

          <ItemContent
            mdCol={12}
            label="Household Size *"
            values={myHouseholdState?.household_size}
          />
          <ItemContent
            mdCol={6}
            label="What is the highest level of education earned by any of your parents?*"
            values={myHouseholdState?.highest_level_education_parent}
          />
          {(myHouseholdState?.highest_level_education_parent === '2-year college graduate' ||
            myHouseholdState?.highest_level_education_parent === '4-year college graduate') && (
            <ItemContent
              mdCol={6}
              label="Did this parent graduate within the last five years?"
              values={myHouseholdState?.csu_info?.highest_level_education_parent_last_five_years}
            />
          )}

          <Col md={12}></Col>
          <ItemContent
            mdCol={6}
            label="What is your Parent/Guardian #1 highest level of education?"
            values={myHouseholdState?.csu_info?.parent_1_highest_level_education}
          />
          {(myHouseholdState?.csu_info?.parent_1_highest_level_education ===
            '2-year college graduate' ||
            myHouseholdState?.csu_info?.parent_1_highest_level_education ===
              '4-year college graduate') && (
            <ItemContent
              mdCol={6}
              label="Did this parent graduate within the last five years?"
              values={myHouseholdState?.csu_info?.parent_1_highest_level_education_last_five_years}
            />
          )}

          <Col md={12}></Col>
          <ItemContent
            mdCol={6}
            label="What is your Parent/Guardian #2 highest level of education?"
            values={myHouseholdState?.csu_info?.parent_2_highest_level_education}
          />
          {(myHouseholdState?.csu_info?.parent_2_highest_level_education ===
            '2-year college graduate' ||
            myHouseholdState?.csu_info?.parent_2_highest_level_education ===
              '4-year college graduate') && (
            <ItemContent
              mdCol={6}
              label="Did this parent graduate within the last five years?"
              values={myHouseholdState?.csu_info?.parent_2_highest_level_education_last_five_years}
            />
          )}

          <div className="border w-100 my-3" />
          <div className="fs-5 text-field-light">Parental Marital Status</div>
          <ItemContent
            mdCol={12}
            label="Parents'/Guardians' Marital Status (relative to each other) *"
            values={myHouseholdState?.parent_martial_status}
          />

          {myHouseholdState?.parent_martial_status === 'Divorced' && (
            <>
              <>
                <ItemContent
                  mdCol={6}
                  label="Year of divorce*"
                  values={myHouseholdState?.year_of_divorce}
                />
              </>
              <>
                <ItemContent
                  mdCol={6}
                  label="Do you wish to list any step-parents?*"
                  values={
                    myHouseholdState?.is_listing_step_parents === true ||
                    myHouseholdState?.is_listing_step_parents === 'true'
                      ? 'Yes'
                      : 'No'
                  }
                />
              </>
              {(myHouseholdState?.is_listing_step_parents === true ||
                myHouseholdState?.is_listing_step_parents === 'true') && (
                <ItemContent
                  mdCol={6}
                  label="How many?*"
                  values={myHouseholdState?.how_many_step_parents}
                />
              )}
            </>
          )}
          <ItemContent
            mdCol={12}
            label="With whom do you make your permanent home?*"
            values={myHouseholdState?.whom_live_permanently}
          />
          <div className="border w-100 my-3" />
          <div className="fs-5 text-field-light">Household Income</div>

          <ItemContent
            mdCol={6}
            label={'What was the total income earned in 2023 by your parents or legal guardians?'}
            values={myHouseholdState?.household_income}
          />
          <ItemContent
            mdCol={6}
            label="How many people were supported by this income?"
            values={myHouseholdState?.csu_info?.people_were_supported_by_this_income}
          />
          <ItemContent
            mdCol={6}
            label={
              "Parents' adjusted gross income (AGI) for 2023. This is the income reported on your parent(s) federal tax return:"
            }
            values={myHouseholdState?.csu_info?.parent_gross_income}
          />
          <ItemContent
            mdCol={6}
            label={
              "Parents' untaxed income and benefits for 2023. This is income not reported on your parent(s) federal tax return:"
            }
            values={myHouseholdState?.csu_info?.parent_untaxed_income}
          />

          <div className="border w-100 my-3" />
          <div className="fs-5 text-field-light">Dependents</div>
          <ItemContent
            mdCol={6}
            label="Do you have any children ?"
            values={myHouseholdState?.is_have_any_children}
          />
          <ItemContent mdCol={6} label="How many ?" values={myHouseholdState?.how_many_children} />
        </>
      )}
    </Row>
  );
};

const SummaryParent = ({ number }: { number: 1 | 2 }) => {
  const [data, seData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const params: { id?: string | number } = useParams();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const parent =
        number === 1
          ? await familyServices.getParent1(params.id)
          : await familyServices.getParent2(params.id);
      seData(parent);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      <ParentSum dataValues={data} isParentNo2={number === 2} />
    </Row>
  );
};

const SummarySiblings = () => {
  const [mySiblings, seMySiblings] = useState<any>({ parent1: null, parent2: null });
  const [loading, setLoading] = useState(false);
  const params: { id?: string | number } = useParams();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const [res] = await Promise.all([familyServices.getSiblings(params.id)]);
      seMySiblings(res);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {mySiblings && (
        <>
          <ItemContent
            label="Please specify number of siblings you have*"
            values={mySiblings.number_of_siblings}
            mdCol={12}
          />
          <ItemContent
            label="Your siblings"
            values={(checkEnableParseJSON(mySiblings.siblings_array) || []).map((e: any) =>
              [e.fullName, e.relationship, e.relationship, e.educationLevel]
                .filter((i) => !!i)
                .join(' - ')
            )}
            mdCol={12}
          />
        </>
      )}
    </Row>
  );
};

const SummaryLegalGuardian = () => {
  const [myHouseholdState, setMyHouseholdState] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const params: { id?: string | number } = useParams();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await familyServices.getHousehold(params.id === ':id' ? null : params.id);
      setMyHouseholdState({
        ...res,
        csu_info: checkEnableParseJSON(res?.csu_info),
        who_in_household_array: checkEnableParseJSON(res?.who_in_household_array),
      });
      setLoading(false);
    };
    params.id !== ':id' && getData();
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {myHouseholdState?.legal_guardian && (
        <StepParentSum
          dataValues={myHouseholdState?.legal_guardian}
          isLegal
          label="Legal Guardian"
        />
      )}
    </Row>
  );
};

const SummaryStepParent = () => {
  const [data, seData] = useState<any>({ stepParent1: null, stepParent2: null });
  const [loading, setLoading] = useState(false);
  const params: { id?: string | number } = useParams();
  const role = useAppSelector((store) => store.family.roleFamily);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const [stepParent1, stepParent2] = await Promise.all([
        familyServices.getStepParent1(params.id),
        familyServices.getStepParent2(params.id),
      ]);
      seData({ stepParent1, stepParent2 });
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      <div className="fs-5 w-100">Step Parent No.1</div>
      {data.stepParent1 && <StepParentSum dataValues={data.stepParent1} label="Step Parent 1" />}
      {role?.stepParent2 && <div className="fs-5 w-100">Step Parent No.2</div>}
      {data.stepParent2 && <StepParentSum dataValues={data.stepParent2} label="Step Parent 2" />}
    </Row>
  );
};
