/* eslint-disable react-hooks/exhaustive-deps */
import { useAppSelector } from 'app/hooks';
import CardConfig from 'components/CardConfig';
import { optionValues, optionValues2, setArrayLength } from 'components/helper/helper';
import CheckboxInput from 'components/input/CheckboxInput';
import GroupRadioButton from 'components/input/GroupRadioButton';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import React, { useMemo } from 'react';
import { Row } from 'react-bootstrap';
import {
  AToFGrade,
  getSubjectsByType,
  subjectAreaOptions,
  to100Grade,
  to4Grade,
} from 'screen/course/courseConstant';

export type TMiddleCourseWork = {
  school: string;
  grade_level: string;
  school_year: string;
  grade_scale: string;
  term_period: string; // period,
  schedule: string;
  describe_other_schedule: string;
  course_name: string;
  course_name_for_UC: string;
  subject_discipline: string;
  language_name_for_UC: string;
  course_category: string;
  ag_subject_requirement: string;
  type_of_class: string;
  is_weighted_course: string;
  is_take_class_at_college: boolean;
  college: string;
  grades: string[];
  credits: string[];

  final_grade: string | number;
  final_credit: string | number;
  not_information: string[];
  isFirstSemester: boolean;
  isSecondSemester: boolean;
  isThirdSemester: boolean;
  isFourthSemester: boolean;
};
export type TAdditionalInfoCourse = {
  high_school_academic_performance: string;
  degree_or_diploma_name: string;
  completed_high_school_outside_US: 'true' | 'false' | '';
  is_take_course_in_middle_school: 'true' | 'false' | '';
  is_have_course_issued_official_transcript_grades: 'true' | 'false' | '';
  course_work: TMiddleCourseWork[];
};

export const initialValuesMiddleCourseWork: TMiddleCourseWork = {
  school: '',
  grade_level: '',
  school_year: '',
  grade_scale: '',
  term_period: '',
  schedule: '',
  describe_other_schedule: '',
  course_name: '',
  course_name_for_UC: '',
  subject_discipline: '',
  language_name_for_UC: '',
  course_category: '',
  ag_subject_requirement: '',
  type_of_class: '',
  is_weighted_course: '',
  is_take_class_at_college: false,
  college: '',
  grades: ['', '', '', ''],
  credits: ['', '', '', ''],
  final_grade: '',
  final_credit: '',
  not_information: [],
  isFirstSemester: false,
  isSecondSemester: false,
  isThirdSemester: false,
  isFourthSemester: false,
};

const MiddleCourseWork = ({ formik, index }: { formik: any; index: number }) => {
  const [countGrades, setCountGrades] = React.useState(4);
  const highSchoolData = useAppSelector((store) => store.highSchool.highSchoolData);
  const collegeData = useAppSelector((store) => store.highSchool.collegeData);
  const buildName = (name: string) => {
    return `additional_info.course_work[${index}].${name}`;
  };
  const buildLabelName = (name: string) => {
    return `additional_info.course_work_${index}_${name}`;
  };
  const formItemValues = formik.values.additional_info?.course_work?.[index];

  const initGradingOptions = () => {
    let result: React.SetStateAction<any[]> = [];
    switch (formItemValues?.grade_scale) {
      case 'A-F':
        result = AToFGrade;
        break;
      case '1-100':
        result = [...to100Grade, ...optionValues2(106)];
        break;
      case '0.0-4.0':
        result = [...to4Grade, ...optionValues(Array.from({ length: 41 }, (_, i) => i / 10))];
        break;
      case '0.0-5.0':
        result = [...to4Grade, ...optionValues(Array.from({ length: 51 }, (_, i) => i / 10))];
        break;
      case '1-7':
        result = [...to4Grade, ...optionValues(Array.from({ length: 7 }, (_, i) => i + 1))];
        break;
      case '1-10':
        result = [...to4Grade, ...optionValues(Array.from({ length: 10 }, (_, i) => i + 1))];
        break;
      case '1-11':
        result = [...to4Grade, ...optionValues(Array.from({ length: 11 }, (_, i) => i + 1))];
        break;
      case '1-20':
        result = [...to4Grade, ...optionValues(Array.from({ length: 20 }, (_, i) => i + 1))];
        break;
      default:
        result = [];
    }
    return result;
  };

  const initSchedule = () => {
    switch (formItemValues?.schedule) {
      case 'Semester':
        setCountGrades(2);
        formik.setFieldValue(buildName('credits'), setArrayLength(formItemValues?.credits, 2, ''));
        formik.setFieldValue(buildName('grades'), setArrayLength(formItemValues?.grades, 2, ''));
        break;
      case 'Trimester':
        setCountGrades(3);
        formik.setFieldValue(buildName('credits'), setArrayLength(formItemValues?.credits, 3, ''));
        formik.setFieldValue(buildName('grades'), setArrayLength(formItemValues?.grades, 3, ''));
        break;
      case 'Quarters':
        setCountGrades(4);
        formik.setFieldValue(buildName('credits'), setArrayLength(formItemValues?.credits, 4, ''));
        formik.setFieldValue(buildName('grades'), setArrayLength(formItemValues?.grades, 4, ''));
        break;
      default:
        setCountGrades(0);
        formik.setFieldValue(buildName('credits'), []);
        formik.setFieldValue(buildName('grades'), []);
    }
  };

  React.useEffect(() => {
    initSchedule();
  }, [formItemValues?.schedule]);

  const gradingOptions = useMemo(() => {
    return initGradingOptions();
  }, [formik.values.additional_info?.course_work?.[index]?.grade_scale]);

  //const [gradingOptions, setGradingOptions] = React.useState<any[]>(initGradingOptions());
  // React.useEffect(() => {
  //   setGradingOptions(initGradingOptions());
  // }, [formik.values.additional_info?.course_work?.[index]?.grade_scale]);

  const SemesterOption = () => {
    let options: any = [];
    switch (formItemValues.schedule) {
      case 'Quarters':
        options = [
          { value: 'isFirstSemester', label: 'First Quarter', fieldIndex: 0 },
          { value: 'isSecondSemester', label: 'Second Quarter', fieldIndex: 1 },
          { value: 'isThirdSemester', label: 'Third Quarter', fieldIndex: 2 },
          { value: 'isFourthSemester', label: 'Fourth Quarter', fieldIndex: 3 },
        ];
        break;
      case 'Trimester':
        options = [
          { value: 'isFirstSemester', label: 'First Trimester', fieldIndex: 0 },
          { value: 'isSecondSemester', label: 'Second Trimester', fieldIndex: 1 },
          { value: 'isThirdSemester', label: 'Third Trimester', fieldIndex: 2 },
        ];
        break;
      case 'Semester':
        options = [
          { value: 'isFirstSemester', label: 'First Semester', fieldIndex: 0 },
          { value: 'isSecondSemester', label: 'Second Semester', fieldIndex: 1 },
        ];
        break;
      default:
        break;
    }

    return (
      <>
        <div className="text-dark mt-3">
          During which {formItemValues.schedule?.toLowerCase() ?? 'term'}(s) did you take this
          class? *
        </div>
        {options.map((opt: any, index: number) => {
          let key = opt.value as any;
          return (
            <CheckboxInput
              key={index}
              name={buildName(key)}
              label={opt.label}
              onChange={formik.handleChange}
              values={formItemValues?.[key]}
            />
          );
        })}
      </>
    );
  };

  const buildCardTitle = (name: string[]) => {
    return name
      .filter((item) => item !== '')
      .map((item) => {
        let value = formItemValues?.[item];
  
        // Handle case where value is an array
        if (Array.isArray(value)) {
          value = value.filter((v) => v !== '').join(', '); // Filter out empty strings and join the non-empty values
        }
  
        // Skip if the value is empty after filtering
        if (!value) {
          return '';
        }
  
        // Format the item with an uppercase prefix if it's one of the specified items
        if (['credits', 'grades', 'final_grade', 'final_credit'].includes(item)) {
          const formattedItem = item
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
          return `${formattedItem}: ${value}`;
        }
  
        return `${value}`;
      })
      .filter(Boolean) // Remove empty strings
      .join(' - ');
  };
  

  return formItemValues ? (
    <CardConfig
      headerElement={
        <span className="p-1">
          {buildCardTitle([
            'school',
            'schedule',
            'course_name',
            'grades',
            'credits',
            'final_grade',
            'final_credit',
          ])}
        </span>
      }
    >
      <SelectInput
        label="Select the school for this coursework"
        option={highSchoolData.map((i) => i.id)}
        mdCol="12"
        placeholder="Search your school"
        name={buildName('school')}
        values={formItemValues?.school}
        onChange={formik.handleChange}
      />
      <SelectInput
        label="Grade Level"
        option={['8th Grade', '7th Grade']}
        name={buildName('grade_level')}
        values={formItemValues?.grade_level}
        onChange={formik.handleChange}
      />
      <SelectInput
        label="School Year*"
        option={[
          '2027-28',
          '2026-27',
          '2025-26',
          '2024-25',
          '2023-24',
          '2022-23',
          '2021-22',
          '2020-21',
          '2019-20',
          '2018-19',
          '2017-18',
        ]}
        name={buildName('school_year')}
        values={formItemValues?.school_year}
        onChange={formik.handleChange}
      />
      <SelectInput
        label="Grading Scale*"
        option={['A-F', '1-100', '0.0-4.0', '0.0-5.0', '1-7', '1-10', '1-11', '1-20', 'Other']}
        name={buildName('grade_scale')}
        values={formItemValues?.grade_scale}
        onChange={(e: any) => {
          try {
            formik.setFieldValue(buildName('grade_scale'), e.target.value);

            // reset grades[] values :
            if (formItemValues?.grades && formItemValues?.grades.length > 0) {
              if (!formItemValues.grades.includes(gradingOptions)) {
                for (var k = 0; k < formItemValues.grades.length; k++) {
                  formik.setFieldValue(buildName(`grades[${k}]`), '');
                }
              }
            }

            if (formItemValues?.schedule_transcript !== 'One grade per term') {
              formik.setFieldValue(buildName(`final_grade`), '');
            }
          } catch (ex) {
            console.log('Error: ', ex);
          }
        }}
      />
      <SelectInput
        label="Term period *"
        option={['--select--', 'Full year', 'Less than full year']}
        name={buildName('term_period')}
        values={formItemValues?.term_period}
        onChange={formik.handleChange}
      />
      <SelectInput
        label="Schedule*"
        option={['Semester', 'Trimester', 'Quarters', 'Yearly', 'Other']}
        name={buildName('schedule')}
        values={formItemValues?.schedule}
        onChange={(e) => {
          formik.handleChange(e);
          formik.setFieldValue(buildName(`schedule_transcript`), '');
        }}
      />
      {formItemValues?.schedule === 'Other' && (
        <>
          <TextInput
            label="Please describe schedule*"
            name={buildName('describe_other_schedule')}
            values={formItemValues?.describe_other_schedule}
            countText={20}
            onChange={formik.handleChange}
          />
        </>
      )}

      <SelectInput
        option={subjectAreaOptions}
        logo="span-uc-csu-logo"
        label="A-G Subject Requirement"
        values={formItemValues?.ag_subject_requirement}
        name={buildName('ag_subject_requirement')}
        onChange={formik.handleChange}
        feedback={'If Science, please select D1 - Biological Science or D2 - Physical Science.'}
      />
      <SelectInput
        logo="span-cm-logo"
        // option={subjectOptions}
        option={getSubjectsByType(formItemValues?.ag_subject_requirement)}
        label="Subject/Discipline"
        values={formItemValues?.subject_discipline}
        name={buildName('subject_discipline')}
        onChange={(e) => {
          formik.handleChange(e);
        }}
      />
      {formItemValues?.ag_subject_requirement === 'C Mathematics' && (
        <SelectInput
          logo="span-uc-logo"
          option={[
            'Algebra I',
            'Geometry',
            'Algebra II',
            'Algebra II/Trigonometry',
            'Advanced Functions',
            'Mathematics I',
            'Mathematics II',
            'Mathematics III',
            'Trigonometry',
            'Pre-Caculus',
            'Statistics',
            'Calculus',
            'Discrete Math',
            'Finite Math',
            'Other Advanced Math',
          ]}
          label="Course name *"
          values={formItemValues?.course_name_for_UC}
          name={buildName('course_name_for_UC')}
          onChange={formik.handleChange}
        />
      )}
      <TextInput
        mdCol={12}
        logo="span-cm-logo"
        label="Course Name*"
        name={buildName('course_name')}
        values={formItemValues?.course_name}
        countText={20}
        onChange={formik.handleChange}
      />
      {formItemValues?.ag_subject_requirement === 'E Language Other Than English' && (
        <>
          <SelectInput
            option={[
              '--',
              'American Sign Language',
              'Arabic',
              'Armenian',
              'Chinese',
              'Farsi',
              'Filipino',
              'French',
              'German',
              'Greek',
              'Hebrew',
              'Hindi',
              'Hmong',
              'Italian',
              'Japanese',
              'Khmer',
              'Korean',
              'Latin',
              'Other Language',
              'Portuguese',
              'Punjabi',
              'Russian',
              'Spanish',
              'Tamil',
              'Turkish',
              'Vietnamese',
            ]}
            label="Language Name"
            values={formItemValues?.language_name_for_UC}
            name={buildName('language_name_for_UC')}
            onChange={formik.handleChange}
          />
          <SelectInput
            option={[
              'Yr 1 Lang Other than English',
              'Yr 2 Lang Other than English',
              'Yr 3 Lang Other than English',
              'Yr 4+ Lang Other than English',
            ]}
            label="Course category *"
            values={formItemValues?.course_category}
            name={buildName('course_category')}
            onChange={formik.handleChange}
            logo="span-uc-logo"
          />
        </>
      )}

      <SelectInput
        option={[
          'Regular',
          'Accelerated',
          'Advanced',
          'Advanced Placement (AP)',
          'AS/A-Level/International A-Level/Cambridge AICE',
          'College Prep',
          'Dual Enrollment',
          'Enriched',
          'GSCE/IGSCE',
          'Gifted',
          'High Honors',
          'Honors',
          'Intensive',
          'International Baccalaureate (IB)',
          'Pre-IB',
          'Regents',
          'Other ',
        ]}
        label="Type of Class *"
        values={formItemValues?.type_of_class}
        name={buildName('type_of_class')}
        onChange={formik.handleChange}
      />
      <SelectInput
        option={['', 'Yes', 'No']}
        label="Is this a weighted course?"
        values={formItemValues?.is_weighted_course}
        name={buildName('is_weighted_course')}
        onChange={formik.handleChange}
      />
      <CheckboxInput
        className="mb-3"
        label="I took this class at a college and/or I received college credit for this class."
        name={buildName('is_take_class_at_college')}
        values={formItemValues?.is_take_class_at_college}
        onChange={formik.handleChange}
      />
      {formItemValues?.is_take_class_at_college && (
        <GroupRadioButton
          label="Select College *"
          options={collegeData}
          mdCol={12}
          name={buildName('college')}
          values={formItemValues?.college}
          onChange={formik.handleChange}
        />
      )}
      <SemesterOption />

      <GroupRadioButton
        className="py-3"
        mdCol={12}
        hidden={!['Semester', 'Trimester', 'Quarters'].includes(formItemValues?.schedule)}
        options={[
          'One final Grade',
          'One grade per term',
          'One grade per term and one final grade',
        ]}
        label="How are the grades for this class reported on your transcript?"
        values={formItemValues?.schedule_transcript}
        name={buildName(`schedule_transcript`)}
        onChange={(e: any) => {
          formik.setFieldValue(buildName(`schedule_transcript`), e.target.value);
          initSchedule();
        }}
        onClear={() => formik.setFieldValue(buildName(`schedule_transcript`), '')}
      />
      {(formItemValues?.grades ?? []).map((item: string, i: number) => {
        if (i === 0 && !formItemValues?.isFirstSemester) return <div key={i} />;
        if (i === 1 && !formItemValues?.isSecondSemester) return <div key={i} />;
        if (i === 2 && !formItemValues?.isThirdSemester) return <div key={i} />;
        if (i === 3 && !formItemValues?.isFourthSemester) return <div key={i} />;
        if (i < countGrades) {
          return (
            <Row
              key={i}
              className={formItemValues?.schedule_transcript === 'One final Grade' ? 'd-none' : ''}
            >
              <SelectInput
                option={gradingOptions}
                label="Grade"
                values={formItemValues?.grades[i]}
                name={buildLabelName(`grades[${i}]`)}
                onChange={(e) => {
                  formik.setFieldValue(buildName(`grades[${i}]`), e.target.value);
                  formik.handleChange(e);
                }}
              />
              <TextInput
                label="Credits"
                decimalStep={0.1}
                type="number"
                name={buildLabelName(`credits[${i}]`)}
                values={formItemValues?.credits[i]}
                onChange={(e) => {
                  formik.setFieldValue(buildName(`credits[${i}]`), e.target.value);
                }}
              />
            </Row>
          );
        } else {
          return <></>;
        }
      })}

      <SelectInput
        hidden={formItemValues?.schedule_transcript === 'One grade per term'}
        option={gradingOptions}
        label="Final Grade"
        values={formItemValues?.final_grade}
        name={buildName('final_grade')}
        onChange={formik.handleChange}
      />
      <TextInput
        hidden={formItemValues?.schedule_transcript === 'One grade per term'}
        label="Final Credits"
        decimalStep={0.1}
        type="number"
        name={buildName('final_credit')}
        values={formItemValues?.final_credit}
        onChange={formik.handleChange}
      />
    </CardConfig>
  ) : null;
};

export default MiddleCourseWork;
