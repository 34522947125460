import http from './http';

const basicInformationServices = {
  async getCBOList() {
    const res = await http.get('api/citizenship/cbo-list');
    return res;
  },

  async getList(params: any) {
    const res = await http.get('api/personal_information');
    return res;
  },
  async create(body: any): Promise<any> {
    const res = await http.post('api/personal_information', body);
    return res;
  },
  async getBasicDetail(student_id: any) {
    if (!student_id || student_id === ':id') return;
    const res = await http.get(`api/personal_information/${student_id}`);
    return res;
  },

  async getContactDetail(student_id: any) {
    const res = await http.get(`api/contact-details/${student_id}`);
    return res;
  },

  async createContact(body: any): Promise<any> {
    const res = await http.post('api/contact-details', body);
    return res;
  },
  async updateContact(body: any): Promise<any> {
    const res = await http.put('api/contact-details', body);
    return res;
  },

  async update(body: any): Promise<any> {
    const res = await http.put('api/personal_information', body);
    return res;
  },

  async getDemoGraphics(student_id: any) {
    const res = await http.get(`api/demographics/${student_id}`);
    return res;
  },

  async updateDemographics(body: any): Promise<any> {
    const res = await http.put('api/demographics', body);
    return res;
  },
  async createDemographics(body: any): Promise<any> {
    const res = await http.post('api/demographics', body);
    return res;
  },
  async getCitizenship(student_id: any) {
    const res = await http.get(`api/citizenship/${student_id}`);
    return res;
  },

  async getStripeSubscription(student_id: any) {
    const res = await http.post(`api/stripe/stripe-subscription`, {
      student_id
    });
    return res;
  },

  async updateCitizenship(body: any): Promise<any> {
    const res = await http.put('api/citizenship', body);
    return res;
  },
  async createCitizenship(body: any): Promise<any> {
    const res = await http.post('api/citizenship', body);
    return res;
  },

  async getProfilePicture(profilePictureName: string): Promise<any> {
    if (!profilePictureName) return null;
    const res: string = await http.get(
      `api/personal_information/profile-picture/${profilePictureName}`,
      { responseType: 'blob' }
    );
    const blob = new Blob([res], { type: 'image/png;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    return url;
  },

  async updateProfilePicture(file: any, student_id: any): Promise<any> {
    const formData = new FormData();
    formData?.append('student_id', student_id);
    formData?.append('photo', file, file.name);
    const res = await http.post('api/personal_information/upload-profile-picture', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    return res;
  },

  async removeProfilePicture(student_id: any, profilePictureName: any): Promise<any> {
    let postData = {
      student_id: student_id,
      old_profilepic: profilePictureName,
    };

    const res = await http.post('api/personal_information/remove-profile-picture', postData, {
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    });
    return res;
  },

  async changePassword(body: any): Promise<any> {
    const res = await http.post('api/students/change-password-setting', body);
    return res;
  },

  async getEnableExtention(student_id: any){ 
    const res = await http.post(`api/stripe/is-enable-extention`, {
      student_id
    });
    return res;
  }
};

export default basicInformationServices;
