import { monthOptions, yearOptions } from 'components/helper/helper';
import { stateAndCounty } from 'components/helper/stateAndCounty';
import DatePickerInput from 'components/input/DatePicker';
import SelectInput from 'components/input/SelectInput';
import YesNoSwitch from 'components/input/YesNoSwitch';
import React from 'react';
import { Card, Row } from 'react-bootstrap';

interface Props {
  formik: any;
}

const CaliforniaResidency = (props: Props) => {
  return (
    <Row className="m-0">
      <div className="span-uc-logo fs-5 p-1">California residency for admission purposes</div>
      <label className="select-label label-14px form-label px-1">
        It's important for us to know your residency because UC has different admission criteria for
        California and non-California residents.
      </label>
      <label className="select-label label-14px form-label px-1">
        By the time you attend UC, will you have graduated from a California high school?*
      </label>
      <YesNoSwitch
        name="is_graduated_california_high_school"
        values={props.formik.values.is_graduated_california_high_school}
        onChange={props.formik.handleChange}
      />
      {props.formik.values.is_graduated_california_high_school === 'true' && (
        <Card className="h-100 bg-light border-0 p-2 p-md-4 mt-3">
          <YesNoSwitch
            label="By the time you attend UC, will you have attended a California school for three or more years during grades K through 12? *"
            name="is_three_or_more_year_graduated_california_high_school"
            values={props.formik.values.is_three_or_more_year_graduated_california_high_school}
            onChange={props.formik.handleChange}
          />
        </Card>
      )}
      {props.formik.values.is_three_or_more_year_graduated_california_high_school === 'false' && (
        <>
          <Card className="h-100 bg-light border-0 p-1 px-md-4">
            <YesNoSwitch
              className="py-3"
              label="Are you currently living in California?"
              name="is_currently_live_california"
              values={props.formik.values.is_currently_live_california}
              onChange={props.formik.handleChange}
            />
            {props.formik.values.is_currently_live_california === 'true' && (
              <>
                <YesNoSwitch
                  className="py-3"
                  label="Have you lived in California your entire life?"
                  name="is_live_california_entire_life"
                  values={props.formik.values.is_live_california_entire_life}
                  onChange={props.formik.handleChange}
                />
                <DatePickerInput
                  className="py-3"
                  hidden={props.formik.values.is_live_california_entire_life !== 'false'}
                  name="statement_legal_resident.when_your_present_stay_in_California_begin"
                  values={
                    props.formik.values.statement_legal_resident
                      ?.when_your_present_stay_in_California_begin
                  }
                  onChange={(value) =>
                    props.formik.setFieldValue(
                      'statement_legal_resident.when_your_present_stay_in_California_begin',
                      value
                    )
                  }
                  label="When did your present stay in California begin?"
                  mdCol="6"
                />
                <label className="select-label label-14px form-label">I have lived here since:</label>
                <Row>
                  <SelectInput
                    className="py-3"
                    form={props.formik}
                    values={props.formik.values.month_live_here_since}
                    name="month_live_here_since"
                    onChange={props.formik.handleChange}
                    option={monthOptions}
                    label="Month"
                    mdCol={'6'}
                  />
                  <SelectInput
                    option={yearOptions}
                    values={props.formik.values.year_live_here_since}
                    name="year_live_here_since"
                    onChange={props.formik.handleChange}
                    label="Year"
                    mdCol={'6'}
                  />
                </Row>
                <YesNoSwitch
                  className="py-3"
                  label="Is one of your parents or legal guardians a permanent resident of California?"
                  logo="span-uc-logo"
                  name="is_one_of_parent_permanent_resident_california"
                  values={props.formik.values.is_one_of_parent_permanent_resident_california}
                  onChange={props.formik.handleChange}
                />
                <div className="text-field-light"></div>
                <YesNoSwitch
                  className="py-3"
                  logo="span-uc-logo"
                  label="Is your parent, legal guardian, spouse or registered domestic partner an employee of the University of California or a UC-affiliated national laboratory (Los Alamos National Security LLC or Lawrence Livermore National Security LLC)?*"
                  name="is_parent_employee_university_california"
                  values={props.formik.values.is_parent_employee_university_california}
                  onChange={props.formik.handleChange}
                />
                {props.formik.values.is_one_of_parent_permanent_resident_california === 'true' && (
                  <div className="py-3">
                    <label className="select-label label-14px form-label">When did your parent or legal guardian become a permanent resident of California? *</label>
                    <Row>
                      <SelectInput
                        form={props.formik}
                        values={props.formik.values.month_parent_become_permanent_residence}
                        name="month_parent_become_permanent_residence"
                        onChange={props.formik.handleChange}
                        option={monthOptions}
                        label="Month"
                        mdCol={'6'}
                        logo="span-uc-logo"
                      />
                      <SelectInput
                        form={props.formik}
                        option={yearOptions}
                        label="Year"
                        values={props.formik.values.year_parent_become_permanent_residence}
                        name="year_parent_become_permanent_residence"
                        onChange={props.formik.handleChange}
                        logo="span-uc-logo"
                      />
                    </Row>
                  </div>
                )}
                {props.formik.values.is_one_of_parent_permanent_resident_california === 'false' && (
                  <>
                    <SelectInput
                      mdCol="12"
                      label="Which U.S State/possession does your parent/guardian regard as her permanent home ?"
                      option={Object.keys(stateAndCounty)}
                      name="csu_info.state_your_parent_as_her_permanent_home"
                      values={props.formik.values.csu_info?.state_your_parent_as_her_permanent_home}
                      onChange={props.formik.handleChange}
                    />
                  </>
                )}
              </>
            )}
          </Card>
        </>
      )}
    </Row>
  );
};

export default CaliforniaResidency;
