import React from 'react';
import { Button, Col, Spinner } from 'react-bootstrap';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonAddAltOutlinedIcon from 'components/icons/PersonAddAltOutlinedIcon';
import TextIconInput from 'components/input/TextIconInput';
import PersonOutlinedIcon from 'components/icons/PersonOutlinedIcon';
import { INVITATION_STATUS, USER_ROLE } from 'constants/common';
import SelectInput from 'components/input/SelectInput';
import { catName, toTitleCase, toLocalShortDate, formatDate } from 'components/helper/helper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import partnerServices from 'services/partnerServices';
import { useAppSelector } from 'app/hooks';
import collegeServices from 'services/collegeServices';

interface Props {}

const SettingsCounselor = (props: Props) => {
  const [waiting, setWaiting] = React.useState(false);
  const [currentRow, setCurrentRow] = React.useState<any>();

  const [showSession, setShowSession] = React.useState(3);
  const [userRole, setUserRole] = React.useState('');
  const [currentCounselor, setCurrentCounselor] = React.useState<any>();
  const [partnerList, setPartnerList] = React.useState<any[]>([]);
  const [refershList, setRefershList] = React.useState(true);

  const personalStore = useAppSelector((store) => store.basicInformation.myBasicInformation);
  const studentId = personalStore?.student_id;

  // current counselor formkik
  const currentformik = useFormik({
    initialValues: {
      counselor_first_name: '',
      counselor_last_name: '',
      counselor_role: USER_ROLE.counselor,
      counselor_email: '',
    },
    validationSchema: Yup.object({
      counselor_first_name: Yup.string(),
      counselor_last_name: Yup.string(),
      counselor_email: Yup.string().email('Invalid email').required('Required'),
    }),
    onSubmit: async (values) => {
      setWaiting(true);

      try {
        await partnerServices.updateParnerStatus(
          studentId,
          currentformik.values.counselor_email,
          INVITATION_STATUS.withdrawn
        );

        const err = {
          counselor_first_name: '',
          counselor_last_name: '',
          counselor_role: '',
          counselor_email: `The Counselor had been removed successfully.`,
        };
        currentformik.setErrors(err);
        window.location.reload();
      } catch (ex) {
        const err = {
          counselor_first_name: '',
          counselor_last_name: '',
          counselor_role: '',
          counselor_email: `${ex}`,
        };

        currentformik.setErrors(err);
      }
      setWaiting(false);
    },
  });

  // new counselor formkik
  const formik = useFormik({
    initialValues: {
      counselor_first_name: '',
      counselor_last_name: '',
      counselor_role: USER_ROLE.counselor,
      counselor_email: '',
    },
    validationSchema: Yup.object({
      counselor_first_name: Yup.string(),
      counselor_last_name: Yup.string(),
      counselor_email: Yup.string().email('Invalid email').required('Required'),
    }),
    onSubmit: async (values) => {
      setWaiting(true);

      try {
        await partnerServices
          .inviteCounselor(studentId, formik.values.counselor_email)
          .then(async (res) => {
            let obj = res.result;

            if (obj) {
              collegeServices.sendMailInvite({
                email: formik.values.counselor_email,
                username: formik.values.counselor_first_name,
                studentFirstname: personalStore.first_name,
                studentLastname: personalStore.last_name,
              });
              currentformik.setValues({
                counselor_first_name: obj.first_name,
                counselor_last_name: obj.last_name,
                counselor_role: obj.user_role,
                counselor_email: obj.email,
              });

              const err = {
                counselor_first_name: '',
                counselor_last_name: '',
                counselor_role: '',
                counselor_email: `The Counselor was invited successfully.`,
              };

              formik.setErrors(err);

              setTimeout(() => {
                const role: string = (personalStore?.user_role ?? '').toLowerCase();
                loadCurrentCounselor(role);
                setShowSession(1);
              }, 1000);
            }

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
      } catch (ex) {
        const err = {
          counselor_first_name: '',
          counselor_last_name: '',
          counselor_role: '',
          counselor_email: `${ex}`,
        };

        formik.setErrors(err);
      }
      setWaiting(false);
    },
  });

  const loadCurrentCounselor = (role: string) => {
    try {
      partnerServices.getCurrentCounselor(studentId).then((res) => {
        if (res && res.length > 0) {
          if (role === USER_ROLE.student) {
            let obj = res[0];
            if (
              obj &&
              (obj.requested_status === INVITATION_STATUS.pending ||
                obj.requested_status === INVITATION_STATUS.approved)
            ) {
              currentformik.setValues({
                counselor_first_name: obj.first_name,
                counselor_last_name: obj.last_name,
                counselor_role: obj.user_role,
                counselor_email: obj.email,
              });
              setCurrentCounselor(obj);
              setShowSession(1);
            }
          }
        }
      });
    } catch (ex) {}
  };

  const renderDate = (timestamp: string) => {
    try {
      if (timestamp && timestamp.length > 11) {
        let date = new Date(timestamp.substring(0, 11));
        return toLocalShortDate(date);
      }
    } catch (ex) {
      if (timestamp && timestamp.length > 11) {
        let year = timestamp?.substring(0, 4) ?? '';
        let month = timestamp?.substring(6, 2) ?? '';
        let day = timestamp?.substring(9, 2) ?? '';
        return `$${month}/${day}/${year}`;
      }
    }
    return '';
  };

  const renderInvitedStatus = (item: any) => {
    try {
      if (item) {
        let status = item.requested_status;
        if (item.requested_status === INVITATION_STATUS.approved) status = 'Invited';
        if (item.requested_status === INVITATION_STATUS.pending) status = 'Pending Invited';

        return renderDate(item.invited_date) + ': ' + toTitleCase(status);
      }
    } catch (ex) {}
    return '';
  };

  const loadParnerList = () => {
    try {
      partnerServices.getInvitePartnerList(studentId).then((res) => {
        if (res && res.length > 0) {
          setPartnerList(res);
        } else {
          setPartnerList([]);
        }
      });
    } catch (ex) {}
  };

  React.useEffect(() => {
    try {
      const role: string = (personalStore?.user_role ?? '').toLowerCase().trim();
      setShowSession(2);
      if (role && role.length > 0) {
        setUserRole(role);
        if (role === USER_ROLE.student.toLowerCase()) {
          setShowSession(2);
          if (studentId) {
            loadCurrentCounselor(role);
          }
        } else if (role === USER_ROLE.counselor.toLowerCase()) {
          setShowSession(4);
          if (refershList) {
            loadParnerList();
            setRefershList(false);
          }
        } else {
        }
      }
    } catch (ex) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalStore?.id, personalStore?.user_role, refershList]);

  const handleAddNew = function () {
    setShowSession(2);
  };

  const getBlurClass = (item: any) => {
    if (item && item?.requested_status === INVITATION_STATUS.approved) {
      return 'light';
    }
    return '';
  };

  const handleApproval = async (item: any) => {
    if (item && item?.requested_status === INVITATION_STATUS.pending) {
      setWaiting(true);
      setCurrentRow(item);
      const counselor_email = personalStore?.email;
      await partnerServices.updateParnerStatus(
        item.student_id,
        counselor_email,
        INVITATION_STATUS.approved
      );
      await partnerServices.sync(item?.email);
      setWaiting(false);
      setRefershList(true);
    }
  };
  const handleRemove = async (item: any) => {
    if (item && item?.requested_status === INVITATION_STATUS.approved) {
      setWaiting(true);
      setCurrentRow(item);
      const counselor_email = personalStore?.email;
      await partnerServices.updateParnerStatus(
        item.student_id,
        counselor_email,
        INVITATION_STATUS.removed
      );
      await partnerServices.sync(item?.email);
      setWaiting(false);
      setRefershList(true);
    }
  };

  return (
    <div className="tab-wrap-panel">
      <div className="tab-panel">
        <div className="text-left" style={{ paddingBottom: '16px' }}>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <div
                  className="text-primary p-2 rounded-circle bg-primary-light d-flex align-items-center justify-content-center"
                  style={{ width: '35px', height: '35px', marginRight: '8px' }}
                >
                  <PersonAddAltOutlinedIcon
                    className="text-primray-light"
                    width={'18px'}
                    height={'18px'}
                  />
                </div>
                <div className="text-primary fs-4 fw-bold mx-1">
                  List of Invited{' '}
                  {localStorage.getItem('userRole') === 'counselor' ? 'Students' : 'Counselors'}
                </div>
              </div>
            </div>
          </div>

          {userRole === USER_ROLE.student && showSession === 1 && (
            <div
              id="list-current-counselor"
              style={{ padding: '16px', marginTop: '24px', marginBottom: '24px' }}
              className="border rounded"
            >
              <div className="row">
                <div className="col-md-12">
                  <h3 className="fs-16px">Current Counselor</h3>
                </div>
              </div>
              <div className="row">
                <TextIconInput
                  disabled={true}
                  icon={<PersonOutlinedIcon />}
                  iconClass="text-primary3"
                  formClass="text-field-dark border-field-dark"
                  mdCol={'6'}
                  label={'First name'}
                  name={'counselor_first_name'}
                  values={currentformik.values.counselor_first_name}
                  onChange={currentformik.handleChange}
                />
                <TextIconInput
                  disabled={true}
                  icon={<PersonOutlinedIcon />}
                  iconClass="text-primary3"
                  formClass="text-field-dark border-field-dark"
                  mdCol={'6'}
                  label={'Last name'}
                  name={'counselor_last_name'}
                  values={currentformik.values.counselor_last_name}
                  onChange={currentformik.handleChange}
                />
              </div>
              <div className="row">
                <SelectInput
                  disabled={true}
                  mdCol={'6'}
                  form={currentformik}
                  values={currentformik.values.counselor_role}
                  onChange={currentformik.handleChange}
                  name={'counselor_role'}
                  label={'User Role'}
                  option={[
                    { value: USER_ROLE.student, name: 'Student', disabled: 'disabled' },
                    { value: USER_ROLE.counselor, name: 'Counselor', disabled: 'disabled' },
                  ]}
                  feedback={
                    currentformik.touched.counselor_role &&
                    currentformik.errors.counselor_role && (
                      <span className="text-danger">{currentformik.errors.counselor_role}</span>
                    )
                  }
                />
                <TextIconInput
                  disabled={true}
                  icon={<MailOutlineIcon />}
                  iconClass="text-primary3"
                  formClass="text-field-dark border-field-dark"
                  mdCol={'6'}
                  label={'Email*'}
                  name={'counselor_email'}
                  values={currentformik.values.counselor_email}
                  onChange={currentformik.handleChange}
                  feedback={
                    currentformik.touched.counselor_email &&
                    currentformik.errors.counselor_email && (
                      <span id="error_email" className="text-danger">
                        {currentformik.errors.counselor_email}
                      </span>
                    )
                  }
                />
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div
                    className="p-0 p-md-1 d-md-flex align-items-end"
                    style={{ paddingLeft: 0, justifyContent: 'space-between' }}
                  >
                    <div className="fs-13px text-field-ultralight">
                      {renderInvitedStatus(currentCounselor)}
                    </div>
                    <Button
                      variant="outline-danger"
                      onClick={async () => currentformik.handleSubmit()}
                      className="btn-update"
                    >
                      {waiting ? (
                        <Spinner size="sm" animation="border" variant="light" />
                      ) : (
                        'Remove'
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {userRole === USER_ROLE.student && showSession === 2 && (
            <>
              <div
                className="rounded border border-primary bg-primary-light border-2 d-flex flex-column flex-md-row align-items-center mt-3"
                style={{
                  minHeight: '114px',
                  paddingLeft: '31px',
                  paddingRight: '25px',
                  justifyContent: 'space-between',
                }}
              >
                <div className="d-flex align-items-center">
                  <PersonAddAltOutlinedIcon
                    className="text-primary"
                    width={'51px'}
                    height={'51px'}
                    style={{ fontSize: 47, minWidth: '51px', marginRight: '22px' }}
                  ></PersonAddAltOutlinedIcon>
                  <span
                    className="text-primary"
                    style={{
                      fontSize: '16px',
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                      maxWidth: '506px',
                      marginRight: '22px',
                      marginTop: '22px',
                      marginBottom: '22px',
                    }}
                  >
                    Add a counselor to accompany you in this process, it can be one of your parents,
                    your guardian / tutor or your counselor
                  </span>
                </div>
              </div>

              <div
                id="new-counselor"
                style={{ padding: '16px', marginTop: '16px' }}
                className="border rounded"
              >
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="fs-16px">Counselor</h3>
                  </div>
                </div>

                <div className="row">
                  <TextIconInput
                    icon={<PersonOutlinedIcon />}
                    iconClass="text-field-light"
                    formClass="text-field-light border-field-light"
                    mdCol={'6'}
                    label={'First name'}
                    name={'counselor_first_name'}
                    values={formik.values.counselor_first_name}
                    onChange={formik.handleChange}
                  />
                  <TextIconInput
                    //disabled={true}
                    icon={<PersonOutlinedIcon />}
                    iconClass="text-field-light"
                    formClass="text-field-light border-field-light"
                    mdCol={'6'}
                    label={'Last name'}
                    name={'counselor_last_name'}
                    values={formik.values.counselor_last_name}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="row">
                  <SelectInput
                    disabled={true}
                    mdCol={'6'}
                    form={formik}
                    values={formik.values.counselor_role}
                    onChange={formik.handleChange}
                    name={'counselor_role'}
                    label={'User Role'}
                    option={[
                      { value: USER_ROLE.student, name: 'Student', disabled: 'disabled' },
                      { value: USER_ROLE.counselor, name: 'Counselor', disabled: 'disabled' },
                    ]}
                    feedback={
                      formik.touched.counselor_role &&
                      formik.errors.counselor_role && (
                        <span className="text-danger">{formik.errors.counselor_role}</span>
                      )
                    }
                  />
                  <TextIconInput
                    icon={<MailOutlineIcon />}
                    iconClass="text-primary3"
                    formClass="text-field-dark border-field-dark"
                    mdCol={'6'}
                    label={'Email*'}
                    name={'counselor_email'}
                    values={formik.values.counselor_email}
                    onChange={formik.handleChange}
                    feedback={
                      formik.touched.counselor_email &&
                      formik.errors.counselor_email && (
                        <span id="error_email" className="text-danger">
                          {formik.errors.counselor_email}
                        </span>
                      )
                    }
                  />
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="p-0 p-md-1 d-md-flex justify-content-end"
                      style={{ paddingLeft: 0 }}
                    >
                      <Button
                        variant="primary"
                        onClick={async () => formik.handleSubmit()}
                        className="btn-update"
                      >
                        {waiting ? (
                          <Spinner size="sm" animation="border" variant="light" />
                        ) : (
                          'Invite'
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {userRole === USER_ROLE.student && showSession === 3 && (
            <div id="add-counselor-box" className="row">
              <Col xs={12} style={{ marginTop: '34px', marginBottom: '66px' }}>
                <div
                  className="rounded border border-primary bg-primary-light border-2 d-flex flex-column flex-md-row align-items-center"
                  style={{
                    minHeight: '114px',
                    paddingLeft: '31px',
                    paddingRight: '25px',
                    justifyContent: 'space-between',
                  }}
                >
                  {/* <div className="d-flex align-items-center">
                    <PersonAddAltOutlinedIcon
                      className="text-primary"
                      width={'51px'}
                      height={'51px'}
                      style={{ fontSize: 47, minWidth: '51px', marginRight: '22px' }}
                    ></PersonAddAltOutlinedIcon>
                    <span
                      className="text-primary"
                      style={{
                        fontSize: '16px',
                        lineHeight: '22px',
                        letterSpacing: '0.02em',
                        maxWidth: '506px',
                        marginRight: '22px',
                        marginTop: '22px',
                        marginBottom: '22px',
                      }}
                    >
                      Add a counselor to accompany you in this process, it can be one of your
                      parents, your guardian / tutor or your counselor
                    </span>
                  </div> */}

                  <Button variant="primary" className="my-3" onClick={handleAddNew}>
                    Add Counselor
                  </Button>
                </div>
              </Col>
            </div>
          )}

          {userRole === USER_ROLE.counselor && showSession === 4 && (
            <div
              id="list-partner"
              style={{ padding: '16px', marginTop: '24px', marginBottom: '24px' }}
              className="border rounded"
            >
              <div className="row">
                <div className="col-md-12">
                  <TableContainer component={'div'}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="fs-16px" sx={{ borderBottomColor: '#A5AFC0' }}>
                            <h5>Name</h5>
                          </TableCell>
                          <TableCell
                            align="center"
                            className="fs-16px"
                            sx={{ borderBottomColor: '#A5AFC0' }}
                          >
                            <h5>Role</h5>
                          </TableCell>
                          <TableCell
                            align="center"
                            className="fs-16px"
                            sx={{ borderBottomColor: '#A5AFC0' }}
                          >
                            <h5>Email</h5>
                          </TableCell>
                          <TableCell
                            align="center"
                            className="fs-16px"
                            sx={{ borderBottomColor: '#A5AFC0' }}
                          >
                            <h5>Date</h5>
                          </TableCell>
                          <TableCell
                            align="center"
                            className="fs-16px"
                            sx={{ borderBottomColor: '#A5AFC0' }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={5}
                            sx={{ borderBottom: 0, height: '16px' }}
                          ></TableCell>
                        </TableRow>
                        {(partnerList || []).map((item, index) => (
                          <TableRow
                            key={index}
                            id={`partner_row_${index}`}
                            className={getBlurClass(item)}
                          >
                            <TableCell align="left" sx={{ borderBottom: 0 }}>
                              <div
                                className={`fs-14px ${
                                  item.priority > 2 ? 'text-field-ultralight' : ''
                                }`.trim()}
                              >
                                {catName(item?.first_name, item?.middle_name, item?.last_name)}
                              </div>
                            </TableCell>
                            <TableCell align="center" sx={{ borderBottom: 0 }}>
                              <div
                                className={`fs-14px ${
                                  item.priority > 2 ? 'text-field-ultralight' : ''
                                }`.trim()}
                              >
                                {toTitleCase(item?.user_role ?? '')}
                              </div>
                            </TableCell>
                            <TableCell align="left" sx={{ borderBottom: 0 }}>
                              <div
                                className={`fs-14px ${
                                  item.priority > 2 ? 'text-field-ultralight' : ''
                                }`.trim()}
                              >
                                {item?.email ?? ''}
                              </div>
                            </TableCell>
                            <TableCell align="center" sx={{ borderBottom: 0 }}>
                              <div
                                className={`fs-14px ${
                                  item.priority > 2 ? 'text-field-ultralight' : ''
                                }`.trim()}
                              >
                                {formatDate(item?.invited_date)}
                              </div>
                              ``
                            </TableCell>
                            <TableCell align="right" sx={{ borderBottom: 0, paddingRight: 0 }}>
                              {item?.requested_status === INVITATION_STATUS.pending && (
                                <Button
                                  variant="primary"
                                  className={'btn-approval'}
                                  onClick={async () => handleApproval(item)}
                                >
                                  {waiting &&
                                  currentRow &&
                                  currentRow.student_id === item.student_id ? (
                                    <Spinner size="sm" animation="border" variant="light" />
                                  ) : (
                                    'Approval'
                                  )}
                                </Button>
                              )}
                              {item?.requested_status === INVITATION_STATUS.approved && (
                                <Button
                                  variant="light"
                                  className={'btn-remove'}
                                  onClick={async () => handleRemove(item)}
                                >
                                  {waiting &&
                                  currentRow &&
                                  currentRow.student_id === item.student_id ? (
                                    <Spinner size="sm" animation="border" variant="dark" />
                                  ) : (
                                    'Remove'
                                  )}
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <style scoped>{`
#new-counselor .form-control:disabled, 
#new-counselor .form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}
#new-counselor input.text-field-light.border-field-light.form-control:disabled, 
#new-counselor input.text-field-light.border-field-light.form-control[readonly] {
  border-color: #8894AA !important;
  opacity: 1!important;
}

.btn.btn-approval, .btn.btn-remove {
  width: 103px!important;
  height: 44px!important;
}

.btn.btn-remove.btn-light { 
  color: #0C2551!important;
  border-color: #EFF1F5!important;
  background-color: #EFF1F5!important; 
}

.btn.btn-remove.btn-light:hover { 
  color: #fff!important;
  background-color: #DC3746!important;
  border-color: #DC3746!important;
}

    `}</style>
    </div>
  );
};

export default SettingsCounselor;
