import http from './http';

const testingServices = {
  async getGeneral(student_id: any) {
    const res = await http.get(`api/test-general-info/${student_id}`);
    return res;
  },

  async createGeneral(body: any): Promise<any> {
    const res = await http.post('api/test-general-info', body);
    return res;
  },

  async updateGeneral(body: any): Promise<any> {
    const res = await http.put('api/test-general-info', body);
    return res;
  },

  async getSATTest(student_id: any) {
    const res = await http.get(`api/sat-test/${student_id}`);
    return res;
  },

  async createSATTest(body: any): Promise<any> {
    const res = await http.post('api/sat-test', body);
    return res;
  },

  async updateSATTest(body: any): Promise<any> {
    const res = await http.put('api/sat-test', body);
    return res;
  },

  async getCLEPTest(student_id: any) {
    const res = await http.get(`api/clep-test/${student_id}`);
    return res;
  },

  async createCLEPTest(body: any): Promise<any> {
    const res = await http.post('api/clep-test', body);
    return res;
  },

  async updateCLEPTest(body: any): Promise<any> {
    const res = await http.put('api/clep-test', body);
    return res;
  },

  async getSATSubjectTest(student_id: any) {
    const res = await http.get(`api/sat-subject-test/${student_id}`);
    return res;
  },

  async createSATSubjectTest(body: any): Promise<any> {
    const res = await http.post('api/sat-subject-test', body);
    return res;
  },

  async updateSATSubjectTest(body: any): Promise<any> {
    const res = await http.put('api/sat-subject-test', body);
    return res;
  },

  async getACTTest(student_id: any) {
    const res = await http.get(`api/act-test/${student_id}`);
    return res;
  },

  async createACTTest(body: any): Promise<any> {
    const res = await http.post('api/act-test', body);
    return res;
  },

  async updateACTTest(body: any): Promise<any> {
    const res = await http.put('api/act-test', body);
    return res;
  },

  async getACTSubjectTest(student_id: any) {
    const res = await http.get(`api/act-subject-test/${student_id}`);
    return res;
  },

  async createACTSubjectTest(body: any): Promise<any> {
    const res = await http.post('api/act-subject-test', body);
    return res;
  },

  async updateACTSubjectTest(body: any): Promise<any> {
    const res = await http.put('api/act-subject-test', body);
    return res;
  },
  async getIBSubjectTest(student_id: any) {
    const res = await http.get(`api/ib-subject-test/${student_id}`);
    return res;
  },

  async createIBSubjectTest(body: any): Promise<any> {
    const res = await http.post('api/ib-subject-test', body);
    return res;
  },

  async updateIBSubjectTest(body: any): Promise<any> {
    const res = await http.put('api/ib-subject-test', body);
    return res;
  },

  async getIELTSAndPTE(student_id: any) {
    const res = await http.get(`api/ielts-pte/${student_id}`);
    return res;
  },

  async createIELTSAndPTE(body: any): Promise<any> {
    const res = await http.post('api/ielts-pte', body);
    return res;
  },

  async updateIELTSAndPTE(body: any): Promise<any> {
    const res = await http.put('api/ielts-pte', body);
    return res;
  },

  async getTOEFL(student_id: any) {
    const res = await http.get(`api/toefl-test/${student_id}`);
    return res;
  },

  async createTOEFL(body: any): Promise<any> {
    const res = await http.post('api/toefl-test', body);
    return res;
  },

  async updateTOEFL(body: any): Promise<any> {
    const res = await http.put('api/toefl-test', body);
    return res;
  },

  async getOtherTest(student_id: any) {
    const res = await http.get(`api/other-test/${student_id}`);
    return res;
  },

  async createOtherTest(body: any): Promise<any> {
    const res = await http.post('api/other-test', body);
    return res;
  },

  async updateOtherTest(body: any): Promise<any> {
    const res = await http.put('api/other-test', body);
    return res;
  },
  async getDoulingo(student_id: any) {
    const res = await http.get(`api/doulingo-test/${student_id}`);
    return res;
  },

  async createDoulingo(body: any): Promise<any> {
    const res = await http.post('api/doulingo-test', body);
    return res;
  },

  async updateDoulingo(body: any): Promise<any> {
    const res = await http.put('api/doulingo-test', body);
    return res;
  },
};

export default testingServices;
