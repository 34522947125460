/* eslint-disable react-hooks/exhaustive-deps */
import GroupRadioButton from 'components/input/GroupRadioButton';
import SelectInput from 'components/input/SelectInput';
import SearchIcon from '@mui/icons-material/Search';
import RemoveIcon from '@mui/icons-material/Remove';
import { Typeahead } from 'react-bootstrap-typeahead';
import React from 'react';
import CheckboxInput from 'components/input/CheckboxInput';
import TextInput from 'components/input/textInput';
import SelectSearchInput from 'components/input/SelectSearchInput';
import { Col, Row } from 'react-bootstrap';
import { states_usa } from 'components/helper/state_usa';
import { csuCountry, notUsCountry, highSchoolType } from 'components/helper/helper';
import courseServices from 'services/courseServices';
import axios from 'axios';
import AutocompleteInput from 'components/input/AutoCompleteInput';
import { canadaProvince, usaState } from 'components/helper/stateAndCounty';
import { debounce, defer } from 'lodash';

export const fixHighSchoolObject = (school:any) => {
  let high_school_object: any = {
    college_board_code: '',
    school_name: '',
    school_type: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    csu_name: '',
    uc_name: '',
    cm_name: '',
    match_all: false,
    label: '',
    code: null,
    is_manual: false,
   };
   
   try {
      let isOld = false;
      if (school.hasOwnProperty('high_school_code')){
        isOld = true;
        if (school.high_school_code && school.high_school_code?.trim().length>0) {
          high_school_object.college_board_code = school.high_school_code;
          high_school_object.code = high_school_object.college_board_code;
        }
      } 
      if (school.hasOwnProperty('college_board_code')) {

        if (typeof school.college_board_code === 'string') {
          if (school.college_board_code && school.college_board_code.trim().length>0) {
            high_school_object.college_board_code = school.college_board_code;
            high_school_object.code = high_school_object.college_board_code;
          }
        }
        else {
          // high_school_object.college_board_code is number;
          high_school_object.college_board_code = `${school.college_board_code}`;
          high_school_object.code = high_school_object.college_board_code;
        }
      }
      
      if (school.hasOwnProperty('high_school_point')) {
        isOld = true;
        if (school.high_school_point && school.high_school_point.trim().length>0) {
          high_school_object.school_name = school.high_school_point;
        }
      }

      if (school.hasOwnProperty('school_name')) {
        if (school.school_name && school.school_name.trim().length>0) {
          high_school_object.school_name = school.school_name;
        }
      }

      if (school.hasOwnProperty('governance')) {
        isOld = true;
        high_school_object.school_type = school.governance;
      }
      if (school.hasOwnProperty('school_type')) {
        high_school_object.school_type = school.school_type;
      }
      if (school.hasOwnProperty('city')) {
        high_school_object.city = school.city;
      }
      if (school.hasOwnProperty('state')) {
        high_school_object.state = school.state;
      }
      if (school.hasOwnProperty('zip')) {
        high_school_object.zip = school.zip;
      }
      if (school.hasOwnProperty('country')) {
        high_school_object.country = school.country;
      }
      if (school.hasOwnProperty('csu_name')) {
        high_school_object.csu_name = school.csu_name;
      }
      else {
        isOld = true;
      }
      if (school.hasOwnProperty('uc_name')) {
        high_school_object.uc_name = school.uc_name;
      }
      else {
        isOld = true;
      }
      if (school.hasOwnProperty('cm_name')) {
        high_school_object.cm_name = school.cm_name;
      }
      else {
        isOld = true;
      }
      if (school.hasOwnProperty('match_all')) {
        high_school_object.match_all = school.match_all;
      }
      else {
        isOld = true;
      }

      if (school.hasOwnProperty('label')) {
        high_school_object.label = school.label;
      }
      if (school.hasOwnProperty('value')) {
        high_school_object.value = school.value;
      }
      if (school.hasOwnProperty('code')) {
        high_school_object.code = `${school.code}`; // force is string value

        // fix high_school_object.college_board_code is empty
        if (high_school_object.college_board_code?.trim().length===0) {
          high_school_object.college_board_code = `${school.code}`; // force is string value
        }
      }

      if (school.hasOwnProperty('id')) {
        high_school_object.id = school.id;
      }

      if (school.hasOwnProperty('is_manual')) {
        high_school_object.is_manual = school.is_manual;
      }

      if (isOld) {
        high_school_object.is_manual = false;
      }

      return high_school_object;
   }
   catch(ex){
    console.log('Error: ', ex);
   }
   return school;
}

type MaskEditSchoolInfo = {
  college_board_code: boolean;
  school_name: boolean;
  school_type: boolean;
  city: boolean;
  state: boolean;
  zip: boolean;
  country: boolean;
}

type Props = {
  formik: any;
  isFirstSchool?: boolean;
  index: number;
};

const loadStates = () => {
  const arr = [
    { name: 'State/U.S. territory *', value: 'N/A' },
    //{ name: "Other/Unknown", value: "N/A"}
  ];
  const states = arr.concat(
    states_usa.filter((obj) => {
      return obj.value !== 'CA';
    })
  );

  return states.map((o) => {
    return o.name;
  });
};

const getStateName = (stateCode: string) => {
  for (let i = 0; i < states_usa.length; i++) {
    if (states_usa[i].value === stateCode) return states_usa[i].name;
  }
  return stateCode;
};

const getStatecode = (stateName: string) => {
  for (let i = 0; i < states_usa.length; i++) {
    if (states_usa[i].name.toLowerCase() === stateName.toLowerCase()) return states_usa[i].value;
  }
  return '';
};

const HighSchoolInfo = ({ formik, isFirstSchool, index }: Props) => {
  const [highSchoolName, setHighSchoolName] = React.useState(formik.values.high_school_name)
  const [editManual, setEditManual] = React.useState(false);
  const [showNotFound, setShowNotFound] = React.useState(false);
  const [editMask, setEditMask] = React.useState<MaskEditSchoolInfo>({
    college_board_code: true,
    school_name: true,
    school_type: true,
    city: true,
    state: true,
    zip: true,
    country: true,
  });

  const [loading, setLoading] = React.useState(false);
  const typeahead = React.createRef<typeof Typeahead>();
  let searchCtrl = new AbortController();
  let states = loadStates();

  React.useEffect(() => {
    try {
      let school = fixHighSchoolObject(formik.values.high_school_object);
      formik.setFieldValue('high_school_object', Object.assign({},school));

      if (school) {
        if (school.is_manual) {
          setEditManual(true);
        }
        if (school.college_board_code && !school.is_manual) {
          let arr: any[] = [];
          arr.push(school);
          setHighSchoolData(arr);
          handleChangeItem(school);
          setEditMask(getEditMask(school));
        }
        else {
          if (school.is_manual) {
            setEditManual(true);
          }
        }
      }
      else {
        clearTypeHead();
      }
    }
    catch (ex) {
      console.log('Error:', ex);
    }
  }, []);

  const [highSchoolData, setHighSchoolData] = React.useState<any[]>([]);
  const [selectedEducation, setSelectedEducation] = React.useState<any>();

  const optionWhere = [
    { value: 'IN_CA', label: 'In California' },
    { value: 'OUTSIDE_CA', label: 'Outside California (in the U.S.)' },
    { value: 'OUTSIDE_USA', label: 'Outside the U.S.' },
  ];

  const handleGetHighSchool = async (keyword: string) => {
    if (keyword && keyword.length < 1) return;
    searchCtrl.abort();
    searchCtrl = new AbortController();

    try {
      setLoading(true);
      let res: any = await courseServices.searchHighSchool(
        keyword,
        getStatecode(formik.values.state),
        formik.values.country,
        formik.values.where_attend_high_school,
        searchCtrl
      );
      const data = res.map((item: any) => ({
        ...item,
        label:
          `${item.high_school_point ?? item.school_name}` +
          ` (code: ${item?.college_board_code ?? ''}, city: ${item.city ?? ''
          }, state: ${getStateName(item.state) ?? ''}, country: ${item.country ?? ''})`,
        code: item.college_board_code,
      }));

      if (res && res.length === 0) {
        setShowNotFound(true);
      }
      else {
        setShowNotFound(false);
      }

      setHighSchoolData(data);
      setLoading(false);

    } catch (error: any) {
      setLoading(false);
      if (axios.isCancel(error)) {
      } else {
        // skip
      }
    }
  };

  const handleSearchHighSchool = debounce((keyword: string) => {
    defer(() => {
      handleGetHighSchool(keyword);
    });
  }, 300);

  const clearTypeHead = () => {
    let obj: any = typeahead.current;
    obj?.clear();
  }


  const resetHighSchool = () => {
    formik.setFieldValue('high_school_object.college_board_code', '');
    formik.setFieldValue('high_school_object.school_name', '');
    formik.setFieldValue('high_school_object.school_type', '');
    formik.setFieldValue('high_school_object.city', '');
    formik.setFieldValue('high_school_object.state', '');
    formik.setFieldValue('high_school_object.zip', '');
    formik.setFieldValue('high_school_object.country', '');
    formik.setFieldValue('high_school_object.csu_name', '');
    formik.setFieldValue('high_school_object.uc_name', '');
    formik.setFieldValue('high_school_object.cm_name', '');
    formik.setFieldValue('high_school_object.match_all', false);
    formik.setFieldValue('high_school_object.label', '');
    formik.setFieldValue('high_school_object.code', null);
    formik.setFieldValue('high_school_name', '');
    formik.setFieldValue('high_school_code', '');
    formik.setFieldValue('csu_city', '');
    formik.setFieldValue('city', '');
    formik.setFieldValue('state', '');
  }

  const clearManualForm = () => {
    resetHighSchool();
    setHighSchoolName('');
    setEditMask(getEditMask(null));
  }

  const getEditMask = (item: any) => {
    let mask: MaskEditSchoolInfo = {
      college_board_code: true,
      school_name: true,
      school_type: true,
      city: true,
      state: true,
      zip: true,
      country: true,
    };

    if (item) {
      if (item.hasOwnProperty('match_all')) {
        mask.college_board_code = false;
        mask.school_name = false;

        if (item.match_all) {
          mask.school_type = false;
          mask.city = false;
          mask.state = false;
          mask.zip = false;
          mask.country = false;
        }
        else {
          mask.school_type = false;
          mask.city = false;
          mask.state = false;
          mask.zip = false;
          mask.country = false;
          if (item.hasOwnProperty('college_board_code') && (item.college_board_code === null || item.college_board_code?.trim() === '')) {
            mask.college_board_code = true;
          }
          if (item.hasOwnProperty('school_type') && (item.school_type === null || item.school_type?.trim() === '')) {
            mask.school_type = true;
          }
          if (item.hasOwnProperty('city') && (item.city === null || item.city?.trim() === '')) {
            mask.city = true;
          }
          if (item.hasOwnProperty('state') && (item.state === null || item.state?.trim() === '')) {
            mask.state = true;
          }
          if (item.hasOwnProperty('zip') && (item.zip === null || item.zip?.trim() === '')) {
            mask.zip = true;
          }
          if (item.hasOwnProperty('country') &&(item.country === null || item.country?.trim() === '')) {
            mask.country = true;
          }
        }
      }
    }

    return mask;
  }

  const checkEditMask = () => {
    return editMask.college_board_code ||
      editMask.school_name ||
      editMask.school_type ||
      editMask.city ||
      editMask.state ||
      editMask.zip ||
      editMask.country;
  }

  const handleChangeItem = (school: any) => {
    try {
      setHighSchoolData([]);
      let item: any = Object.assign({}, school);
      item.is_manual = false;                     // add is_manual to input manual or not
      item.high_school_name = item.school_name;   // add high_school_name to compatible old object. 
      setSelectedEducation(item);
      //console.log('item :', item);
      setEditMask(getEditMask(item));
      setHighSchoolName(item?.school_name);
      formik.setFieldValue('school_name', item?.school_name);
      formik.setFieldValue('high_school_name', item?.school_name);
      formik.setFieldValue('high_school_code', item?.college_board_code);
      formik.setFieldValue('country', item?.country);
      formik.setFieldValue('state', getStateName(item?.state) ?? '');
      formik.setFieldValue('city', item?.city ?? '');
      formik.setFieldValue('csu_city', item?.city);
      formik.setFieldValue('high_school_object', item);
      clearTypeHead();
      setEditManual(true);
    } catch (ex) {
      console.log('Error:', ex);
    }
  };

  const onChangeWhereAttend = (e: any) => {
    try {
      let data = highSchoolData;
      switch (e.target.value) {
        case 'IN_CA':
          setHighSchoolData(data);
          if (formik.values.high_school_object?.state !== 'CA') {
            setSelectedEducation(undefined);
            setHighSchoolName('');
            formik.setFieldValue('high_school_object', {});
            formik.setFieldValue('high_school_name', '');
            formik.setFieldValue('school_name', '');
            formik.setFieldValue('city', '');
            formik.setFieldValue('csu_city', '');
            clearTypeHead();
            setEditManual(false);
            setShowNotFound(false);
          }
          formik.setFieldValue('state', getStateName('CA'));
          formik.setFieldValue('country', 'United States of America');
          break;
        case 'OUTSIDE_CA':
          setHighSchoolData(data);
          if (formik.values.high_school_object?.state === 'CA' ||
            formik.values.high_school_object?.country !== 'United States of America'
          ) {
            setSelectedEducation(undefined);
            setHighSchoolName('');
            formik.setFieldValue('high_school_object', {});
            formik.setFieldValue('high_school_name', '');
            formik.setFieldValue('school_name', '');
            formik.setFieldValue('state', '');
            formik.setFieldValue('city', '');
            formik.setFieldValue('csu_city', '');
            clearTypeHead();
            setEditManual(false);
            setShowNotFound(false);
          }
          formik.setFieldValue('country', 'United States of America');
          break;
        case 'OUTSIDE_USA':
          if (formik.values.country === 'United States of America') {
            setSelectedEducation(undefined);
            formik.setFieldValue('high_school_object', {});
            formik.setFieldValue('state', '');
            formik.setFieldValue('country', '');
            clearTypeHead();
            setEditManual(false);
            setShowNotFound(false);
          }

          setHighSchoolData([]);
          break;
      }
      formik.handleChange(e);
      setHighSchoolData([]);

      console.log('formik.values.where_attend_high_school:', formik.values.where_attend_high_school);
    }
    catch (ex) {
      console.log('Error: ', ex);
    }
  };

  const handleNotFound = (e: any) => {
    setEditManual(true);
    setShowNotFound(false);
    resetHighSchool();
    setSelectedEducation(undefined);
    formik.setFieldValue('high_school_object.school_name', highSchoolName);
    formik.setFieldValue('high_school_name', highSchoolName);
    formik.setFieldValue('high_school_object.is_manual', true);
    setEditMask(getEditMask(null));
  };

  const handleRemove = () => {
    setSelectedEducation(undefined);
    clearTypeHead();
    clearManualForm();
    setEditManual(false);
  }

  return (
    <>
      <GroupRadioButton
        name="where_attend_high_school"
        onChange={onChangeWhereAttend}
        values={formik.values.where_attend_high_school}
        mdCol={4}
        logo="span-uc-logo"
        label="Where do/did you attend high school?"
        options={optionWhere}
      />
      {formik.values.where_attend_high_school === 'OUTSIDE_CA' && (
        <>
          <SelectInput
            name="state"
            values={formik.values.state}
            onChange={formik.handleChange}
            label="State/U.S. territory"
            option={states}
          />
          <TextInput
            countText={50}
            logo="span-csu-logo"
            label="City"
            name="csu_city"
            values={formik.values.csu_city}
            onChange={formik.handleChange}
          />
        </>
      )}
      {formik.values.where_attend_high_school === 'OUTSIDE_USA' && (
        <>
          <AutocompleteInput
            className="d-flex flex-column justify-content-end"
            name="country"
            form={formik}
            values={formik.values.country}
            label="Select a country *"
            option={notUsCountry}
            onChange={(e) => {
              formik.handleChange(e);
              //(e.target == country) e.target.value Cannot read properties of undefined (reading 'value')
              formik.setFieldValue('high_school_object.country', e.target);
            }}
          />
          <TextInput
            countText={49}
            logo="span-csu-logo"
            label="Academic Performance (High School GPA, Standing, Percentage, etc.)"
            name="academic_performance"
            values={formik.values.academic_performance}
            onChange={formik.handleChange}
          />
          <TextInput
            countText={49}
            logo="span-csu-logo"
            label="Name of Degree/Diploma (e.g. Diploma, A-Level, Abitur, Certificado)"
            name="name_of_degree"
            values={formik.values.name_of_degree}
            onChange={formik.handleChange}
          />
        </>
      )}
      <div className="span-uc-logo">Specialized curriculum (only select if applicable to you)</div>
      <Col md={6}>
        <CheckboxInput
          label="Home School"
          name="is_home_school"
          values={formik.values.is_home_school}
          onChange={formik.handleChange}
        />
      </Col>
      <Col md={6}>
        <CheckboxInput
          label="Independent Study"
          name="is_independant_study"
          values={formik.values.is_independant_study}
          onChange={formik.handleChange}
        />
      </Col>
      {formik.values.is_home_school === true && (
        <>
          <CheckboxInput
            label="My home schooling curriculum is not associated with a high school."
            name="my_home_school_associated_with_high_school"
            values={formik.values.my_home_school_associated_with_high_school}
            onChange={formik.handleChange}
          />
          {formik.values.my_home_school_associated_with_high_school === true && (
            <>
              <div>Manualy Enter School Info</div>
              <TextInput
                name="school_name"
                values={formik.values.school_name}
                onChange={formik.handleChange}
                label="School Name"
                countText={36}
              />
              <TextInput
                name="city"
                values={formik.values.city}
                onChange={formik.handleChange}
                label="City"
                countText={32}
              />
              <SelectInput
                name="school_type"
                values={formik.values.school_type}
                onChange={formik.handleChange}
                label="School Type"
                option={['School Type*', 'Public', 'Private', 'Junior High']}
              />
            </>
          )}
        </>
      )}

      <SelectSearchInput
        icon={<SearchIcon />}
        mdCol="12"
        form={formik}
        values={`${formik.values.high_school_object?.label ?? formik.values.high_school_name ??''}`}
        name="high_school_name"
        label={
          isFirstSchool ? 'Search for Current or Most Recent Secondary/High School' : 'Search for High School'
        }
        feedback="Search by CEEB code, City, County/Region/Territory, High School Name"
        option={highSchoolData}
        onChangeItem={handleChangeItem}
        onSearch={(keyword: string) => {
          try {
            if (selectedEducation === undefined && !parseInt(keyword)) {
              if (keyword && keyword.length > 0) {
                formik.setFieldValue('high_school_name', keyword);
                formik.setFieldValue('high_school_object.is_manual', true);
                setHighSchoolName(keyword);
                setEditMask(getEditMask(null));
                //setEditManual(true);
              }
            }
            else {
              if (!parseInt(keyword)) {
                setHighSchoolName(keyword);
              }
              setEditManual(false);
            }
            handleSearchHighSchool(keyword);
          }
          catch (ex) {
            console.log('Error: ', ex);
          }
        }}
        className='mt-4 mb-4'
        inputRef={typeahead}
        isLoading={loading}
        emptyLabel={(<></>)}
      />
      {showNotFound && (
        <div id="not-found" className="d-block not-found">
          <div className="text-center">
            <button type="button" className="btn-link btn-not-found" onClick={handleNotFound}>
              Desired high school, not found, I want to enter my high school manually
            </button>
          </div>
        </div>
      )}
      <div className="my-2 text-field-light">Selected High School</div>
      {selectedEducation?.college_board_code && (
        <>
          <div className="d-flex align-items-end position-relative">
            <div className="mx-3 fw-bold">{selectedEducation?.school_name}</div>
            <span className="position-absolute h-100 d-flex align-items-center end-0">
              <RemoveIcon
                fontSize="small"
                className="bg-light text-dart rounded-circle mx-4 pointer"
                onClick={handleRemove}
              />
            </span>
          </div>
          <div className="d-block mx-3 pb-2">
            <div className="text-field-light sized70  pb-2">
              {`(code: ${selectedEducation?.college_board_code ?? ''}, city: ${selectedEducation?.city ?? ''
                }, state: ${getStateName(selectedEducation?.state) ?? ''}, country: ${selectedEducation?.country ?? ''})`}
            </div>
          </div>
        </>
      )}

      {(highSchoolData !== undefined && !highSchoolData?.length) || (!formik.values.high_school_object?.match_all) || (formik.values.high_school_object?.match_all === false) ? (
        <>
          {editManual && checkEditMask() && (
            <Row className="bg-light rounded p-3">
              <TextInput
                logo="span-uc-logo"
                mdCol={12}
                hidden={!editMask.school_name}
                name="high_school_name"
                values={highSchoolName}
                onChange={(e) => {
                  try {
                    setHighSchoolName(e.target.value);
                    formik.setFieldValue('high_school_object.school_name', e.target.value);
                    formik.setFieldValue('high_school_name', e.target.value);
                    formik.setFieldValue('school_name', e.target.value);
                    formik.setFieldValue('high_school_object.is_manual', true);
                  }
                  catch (ex) {
                    console.log('Error: ', ex);
                  }
                }}
                label="High School Name"
                countText={60}
              />
              <TextInput
                name="high_school_object.city"
                hidden={!editMask.city}
                values={formik.values.high_school_object?.city ?? ''}
                onChange={(e: any) => {
                  formik.setFieldValue('high_school_object.city', e.target.value);
                }}
                label="City"
                countText={30}
              />
              <SelectInput
                name="high_school_object.school_type"
                hidden={!editMask.school_type}
                values={formik.values.high_school_object?.school_type ?? ''}
                onChange={(e: any) => {
                  formik.setFieldValue('high_school_object.school_type', e.target.value);
                }}
                label="School Type *"
                option={highSchoolType}

              />
              <AutocompleteInput
                name="high_school_object.country"
                hidden={!editMask.country}
                form={formik}
                defautlValues={formik.values.high_school_object?.country?.trim() ?? ''}
                values={formik.values.high_school_object?.country?.trim() ?? ''}
                label="Country"
                option={csuCountry}
              />
              {formik.values.high_school_object?.country === 'United States of America' ? (
                <AutocompleteInput
                  hidden={!editMask.state}
                  option={usaState}
                  form={formik}
                  label="State"
                  name="high_school_object.state"
                  values={getStateName(formik.values.high_school_object?.state)}
                />
              ) :
                formik.values.high_school_object?.country === 'Canada' ? (
                  <AutocompleteInput
                    hidden={!editMask.state}
                    form={formik}
                    option={canadaProvince}
                    label="Province"
                    name="high_school_object.province"
                    values={formik.values.high_school_object?.province}
                  />
                ) : (
                  <TextInput
                    name="high_school_object.state_or_province"
                    hidden={!editMask.state}
                    values={formik.values.high_school_object?.state_or_province}
                    onChange={(e: any) => {
                      formik.setFieldValue('high_school_object.state_or_province', e.target.value);
                    }}
                    label="State or Province"
                    countText={30}
                  />
                )}
              <TextInput
                name="high_school_object.zip"
                hidden={!editMask.zip}
                values={formik.values.high_school_object?.zip}
                onChange={(e: any) => {
                  formik.setFieldValue('high_school_object.zip', e.target.value);
                }}
                label="Zip Code"
                countText={10}
              />
              <TextInput
                name="high_school_object.college_board_code"
                hidden={!editMask.college_board_code}
                values={formik.values.high_school_object?.college_board_code}
                onChange={(e: any) => {
                  formik.setFieldValue('high_school_object.college_board_code', e.target.value);
                  formik.setFieldValue('high_school_code', e.target.value);
                }}
                label="College Board Code"
                countText={10}
              />
            </Row>
          )}
        </>
      ) : (<></>)}
      <style scoped>{` 
button.btn-link.btn-not-found {
  color: #0d6efd!important;
  border: none!important;
  background: transparent;
  text-decoration: underline!important;
  text-decoration-color: #0d6efd!important;
  font-weight: 300;
  text-decoration-style: solid;
}
      `}</style>
    </>
  );
};

export default HighSchoolInfo;
