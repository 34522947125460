/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import {
  BoolToString,
  checkEnableParseJSON,
  dateFormat,
  formatBoolean,
  reorder,
  setArrayLength,
} from 'components/helper/helper';
import { getACTSubjectTest, updateACTSubjectTest } from 'app/testingSlice/testingSlice';
import ACTSubjectConfig from './components/ACTSubjectConfig';
import { debounce } from 'lodash';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import TextInput from 'components/input/textInput';
import moment from 'moment';

interface Props {}

const ACTSubjectTest = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const testing = useAppSelector((store) => store.testing);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const formik = useFormik({
    initialValues: {
      is_have_ap_exam_report: '',
      number_of_ap_test_report: 0,
      ap_test_array: [],
      csu_info: {
        apId: '',
      },
    },
    onSubmit: async (values:any) => {
      studentId = Cookies.get('userId');
      token = Cookies.get(authenticateSettings.tokenName);
      if (token && studentId) {
        try {
          
          for (var i=0; i< values.ap_test_array.length; i++) {

            if (values.ap_test_array[i].examStatus === "Planned") {
              let planToTakeExam = values.ap_test_array[i].planToTakeExam;
              let planToTakeExamMonth = moment(planToTakeExam).format("MMMM");
              let planToTakeExamYear = moment(planToTakeExam).format("yyyy");
              if (planToTakeExamMonth !== "Invalid date") {
                values.ap_test_array[i].planToTakeExamMonth = planToTakeExamMonth;
                values.ap_test_array[i].planToTakeExamYear = planToTakeExamYear;
              }
              else {
                values.ap_test_array[i].planToTakeExamMonth = '';
                values.ap_test_array[i].planToTakeExamYear = '';                
              }

              values.ap_test_array[i].dateTaken = '';
              values.ap_test_array[i].dateTakenCSUApp = '';
              values.ap_test_array[i].dateTakenMonth = '';
              values.ap_test_array[i].dateTakenYear = '';              
            }
            else {
              let dateTaken = values.ap_test_array[i].dateTaken;
              let dateTakenCSUApp = moment(dateTaken).format(dateFormat);          
              let dateTakenMonth = moment(dateTaken).format("MMMM");
              let dateTakenYear = moment(dateTaken).format("yyyy");
              if (dateTakenMonth !== "Invalid date") {
                values.ap_test_array[i].dateTakenMonth = dateTakenMonth;
                values.ap_test_array[i].dateTakenYear = dateTakenYear;
                values.ap_test_array[i].dateTakenCSUApp = dateTakenCSUApp;
              }
              else {
                values.ap_test_array[i].dateTakenCSUApp = '';
                values.ap_test_array[i].dateTakenMonth = '';
                values.ap_test_array[i].dateTakenYear = '';
              }

              values.ap_test_array[i].planToTakeExam = '';
              values.ap_test_array[i].planToTakeExamMonth = '';
              values.ap_test_array[i].planToTakeExamYear = '';
            }
          
          }
  
          const newValues = {
            ...values,
            student_id: studentId,
            is_have_ap_exam_report: formatBoolean(values.is_have_ap_exam_report),
            ap_test_array: JSON.stringify(values.ap_test_array),
          };
          await dispatch(updateACTSubjectTest(newValues)).unwrap();
        }
        catch(ex){
          console.log('Error:', ex);
        }
      }
      else {
        handleLogout();
      }      
    },
  });

  const handleContinue = () => {
    formik.handleSubmit();
    const nextRoute = Object.keys(testing.roleTest).find(
      (key, index) =>
        index > Object.keys(testing.roleTest).indexOf('actSubjectTest') &&
        testing.roleTest[key] === true
    );
    history.push(nextRoute ? routers?.[nextRoute]?.path : routers.otherTest.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      let res = await dispatch(getACTSubjectTest(studentId));
      const dataLoaded: any = res.payload;
      if (dataLoaded) {
        const values: any = {
          ...dataLoaded,
          ap_test_array: checkEnableParseJSON(dataLoaded.ap_test_array, []) ?? [],
          csu_info: {
            ...formik.values.csu_info,
            ...checkEnableParseJSON(dataLoaded.csu_info),
          },
          is_have_ap_exam_report: BoolToString(dataLoaded.is_have_ap_exam_report),
        };
        formik.resetForm({ values });
        // setACTData(checkEnableParseJSON(dataLoaded.ap_test_array));
      }
      setLoading(false);
    } else {
      handleLogout();
    }
  };
  React.useEffect(() => {
    getDetail();
  }, []);

  React.useEffect(() => {
    formik.values.ap_test_array.length !== formik.values.number_of_ap_test_report &&
      formik.setFieldValue(
        'ap_test_array',
        setArrayLength(formik.values.ap_test_array, formik.values.number_of_ap_test_report)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.number_of_ap_test_report]);

  // Auto save function ==========
  React.useEffect(() => {
    formik.dirty && debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========
  const handleAddAP = () => {
    if (formik.values.ap_test_array.length > 19) return;
    formik.setFieldValue('ap_test_array', [...formik.values.ap_test_array, {}]);
  };
  const [forceRenderKey, setForceRenderKey] = React.useState(0);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const dataList = reorder(
      formik.values.ap_test_array,
      result.source.index,
      result.destination.index
    );
    formik.setFieldValue('ap_test_array', [...dataList]);
    setForceRenderKey(forceRenderKey + 1);
  };

  const ACTData: any[] = formik.values.ap_test_array ?? [];
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[]}
        title="Standardized Testing"
        process={1}
        icon={<CalculateOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form
            style={{ maxWidth: '900px' }}
            onSubmit={formik.handleSubmit}
            onChange={() => formik.setStatus('autoSave')}
          >
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">AP Subject Tests</div>
              <TextInput
                countText={8}
                name="csu_info.apId"
                onChange={formik.handleChange}
                values={formik.values.csu_info?.apId}
                logo="span-csu-logo"
                label="AP ID"
              />
              <DragDropContext onDragEnd={onDragEnd} key={forceRenderKey}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {formik.values.ap_test_array.map((item: any, index: number) => (
                        <div key={index + '-' + ACTData.length}>
                          <Draggable draggableId={index + '-' + ACTData.length} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  key={index + '-' + ACTData.length}
                                  className="position-relative"
                                >
                                  <ACTSubjectConfig
                                    key={index}
                                    handleSave={(values) => {
                                      const newACTData = [...ACTData];
                                      newACTData.splice(index, 1, values);
                                      formik.setFieldValue('ap_test_array', newACTData);
                                    }}
                                    item={item}
                                    indexText={index + 1 + '/' + ACTData.length}
                                  />
                                  <RemoveCircleOutlineIcon
                                    className="position-absolute text-field-light pointer"
                                    style={{ right: '-24px', top: '12px' }}
                                    onClick={() => {
                                      const newACTData = [...ACTData];
                                      newACTData.splice(index, 1);
                                      formik.setFieldValue('ap_test_array', newACTData);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </div>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div className="my-3 w-100 d-flex flex-column align-items-center">
                <Button variant="primary" className="p-2 px-5" onClick={handleAddAP}>
                  Add AP Test
                </Button>
              </div>
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {testing.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default ACTSubjectTest;
