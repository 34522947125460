/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getDoulingo, updateDoulingo } from 'app/testingSlice/testingSlice';
import { checkEnableParseJSON, optionValues } from 'components/helper/helper';
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import SelectInput from 'components/input/SelectInput';
import YesNoSwitch from 'components/input/YesNoSwitch';
import GroupRadioButton from 'components/input/GroupRadioButton';
import DatePickerInput from 'components/input/DatePicker';
import { debounce } from 'lodash';

type Props = {};
const formatScore = (x: any) => {
  return Number.parseFloat(x).toFixed(1);
};
const DuoLingoTest = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const testing = useAppSelector((store) => store.testing);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const formik = useFormik({
    initialValues: {
      number_of_time_taken_duolingo: '',
      duolingo_received_score: '',
      duolingo_plan_on_retake: '',
      number_of_future_duolingo_expect: '',
      duolingo_date_retake: {
        test1: '',
        test2: '',
        test3: '',
      },
      duolingo_literacy_score: '',
      duolingo_conversation_score: '',
      duolingo_comprehension_score: '',
      duolingo_production_score: '',
      duolingo_overall_score: '',
      duolingo_highest_literacy_score: '',
      duolingo_highest_literacy_date: '',
      duolingo_highest_comprehension_score: '',
      duolingo_highest_comprehension_date: '',
      duolingo_highest_conversation_score: '',
      duolingo_highest_conversation_date: '',
      duolingo_highest_production_score: '',
      duolingo_highest_production_date: '',
      duolingo_highest_total_score: '',
      duolingo_highest_total_date: '',
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        duolingo_date_retake: JSON.stringify(values.duolingo_date_retake),
        student_id: studentId,
      };
      await dispatch(updateDoulingo(newValues));
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    const nextRoute = Object.keys(testing.roleTest).find(
      (key, index) =>
        index > Object.keys(testing.roleTest).indexOf('douLingoTest') &&
        testing.roleTest[key] === true
    );
    history.push(nextRoute ? routers?.[nextRoute]?.path : routers.otherTest.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      let res = await dispatch(getDoulingo(studentId));
      const dataLoaded: any = res.payload;
      if (dataLoaded) {
        const values: any = {
          ...formik.values,
          ...dataLoaded,
          duolingo_date_retake: checkEnableParseJSON(dataLoaded.duolingo_date_retake),
        };
        formik.setValues(values);
      }
      setLoading(false);
      formik.setStatus('idle');
    } else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Auto save function ==========
  const notInitialRender = React.useRef(false);
  React.useEffect(() => {
    debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (notInitialRender.current) {
        if (!loading) formik.handleSubmit();
      } else {
        notInitialRender.current = true;
      }
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[{ title: 'Duo Lingo', percentOnProcess: 20 }]}
        title="Standardized Testing"
        process={1}
        icon={<CalculateOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0 common-flag">
              <div className="text-field-mid fs-3 mb-4">Duolingo</div>
              <SelectInput
                form={formik}
                label="Number of times you have already taken a Duolingo English Test*"
                option={optionValues(Array.from({ length: 6 }, (_, i) => i))}
                name="number_of_time_taken_duolingo"
                values={formik.values.number_of_time_taken_duolingo}
                onChange={formik.handleChange}
              />
              <YesNoSwitch
                className="mb-3"
                label="Have you received your scores yet?*"
                name="duolingo_received_score"
                values={formik.values.duolingo_received_score}
                onChange={formik.handleChange}
              />

              <SelectInput
                name="duolingo_highest_literacy_score"
                label="Highest literacy score*"
                values={formik.values.duolingo_highest_literacy_score}
                onChange={formik.handleChange}
                option={Array.from(Array(31).keys()).map((item) => `${item * 5 + 10}`)}
              />
              <DatePickerInput
                label="Literacy score date*"
                name="duolingo_highest_literacy_date"
                values={formik.values.duolingo_highest_literacy_date}
                onChange={(values) =>
                  formik.setFieldValue('duolingo_highest_literacy_date', values)
                }
              />
              <SelectInput
                name="duolingo_highest_comprehension_score"
                label="Highest comprehension score*"
                values={formik.values.duolingo_highest_comprehension_score}
                onChange={formik.handleChange}
                option={Array.from(Array(31).keys()).map((item) => `${item * 5 + 10}`)}
              />
              <DatePickerInput
                label="Comprehension score date*"
                name="duolingo_highest_comprehension_date"
                values={formik.values.duolingo_highest_comprehension_date}
                onChange={(values) =>
                  formik.setFieldValue('duolingo_highest_comprehension_date', values)
                }
              />
              <SelectInput
                name="duolingo_highest_conversation_score"
                label="Highest conversation score*"
                values={formik.values.duolingo_highest_conversation_score}
                onChange={formik.handleChange}
                option={Array.from(Array(31).keys()).map((item) => `${item * 5 + 10}`)}
              />
              <DatePickerInput
                label="Conversation score date*"
                name="duolingo_highest_conversation_date"
                values={formik.values.duolingo_highest_conversation_date}
                onChange={(values) =>
                  formik.setFieldValue('duolingo_highest_conversation_date', values)
                }
              />
              <SelectInput
                name="duolingo_highest_production_score"
                label="Highest production score*"
                values={formik.values.duolingo_highest_production_score}
                onChange={formik.handleChange}
                option={Array.from(Array(31).keys()).map((item) => `${item * 5 + 10}`)}
              />
              <DatePickerInput
                label="Production score date*"
                name="duolingo_highest_production_date"
                values={formik.values.duolingo_highest_production_date}
                onChange={(values) =>
                  formik.setFieldValue('duolingo_highest_production_date', values)
                }
              />
              <SelectInput
                name="duolingo_highest_total_score"
                label="Highest Duolingo total score*"
                values={formik.values.duolingo_highest_total_score}
                onChange={formik.handleChange}
                option={Array.from(Array(31).keys()).map((item) => `${item * 5 + 10}`)}
              />
              <DatePickerInput
                label="Duolingo total score date*"
                name="duolingo_highest_total_date"
                values={formik.values.duolingo_highest_total_date}
                onChange={(values) => formik.setFieldValue('duolingo_highest_total_date', values)}
              />
              <div>Scores</div>
              <SelectInput
                name="duolingo_literacy_score"
                label="Literacy*"
                values={formik.values.duolingo_literacy_score}
                onChange={formik.handleChange}
                option={Array.from(Array(18).keys()).map((item) => formatScore(item / 2 + 0.5))}
              />
              <SelectInput
                name="duolingo_conversation_score"
                label="Conversation*"
                values={formik.values.duolingo_conversation_score}
                onChange={formik.handleChange}
                option={Array.from(Array(18).keys()).map((item) => formatScore(item / 2 + 0.5))}
              />
              <SelectInput
                name="duolingo_comprehension_score"
                label="Comprehension*"
                values={formik.values.duolingo_comprehension_score}
                onChange={formik.handleChange}
                option={Array.from(Array(18).keys()).map((item) => formatScore(item / 2 + 0.5))}
              />
              <SelectInput
                name="duolingo_production_score"
                label="Production*"
                values={formik.values.duolingo_production_score}
                onChange={formik.handleChange}
                option={Array.from(Array(18).keys()).map((item) => formatScore(item / 2 + 0.5))}
              />
              <SelectInput
                name="duolingo_overall_score"
                label="Overall Score*"
                values={formik.values.duolingo_overall_score}
                onChange={formik.handleChange}
                option={Array.from(Array(18).keys()).map((item) => formatScore(item / 2 + 0.5))}
              />
              <YesNoSwitch
                className="mb-3"
                label="Do you plan on retaking this test?*"
                values={formik.values.duolingo_plan_on_retake}
                onChange={formik.handleChange}
                name="duolingo_plan_on_retake"
              />
              {formik.values.duolingo_plan_on_retake === 'true' && (
                <>
                  <GroupRadioButton
                    label="Number of future Duolingo sittings you expect*"
                    options={['0', '1', '2', '3']}
                    values={formik.values.number_of_future_duolingo_expect}
                    onChange={formik.handleChange}
                    name="number_of_future_duolingo_expect"
                  />
                  {[1, '1', '2', '3', 2, 3].includes(
                    formik.values.number_of_future_duolingo_expect
                  ) && (
                    <DatePickerInput
                      className="common-flag"
                      label={`Future testing date 1*`}
                      name="duolingo_date_retake.test1"
                      values={formik.values?.duolingo_date_retake?.test1}
                      onChange={(values) =>
                        formik.setFieldValue('duolingo_date_retake.test1', values)
                      }
                    />
                  )}
                  {['2', '3', 2, 3].includes(formik.values.number_of_future_duolingo_expect) && (
                    <DatePickerInput
                      className="common-flag end-flag"
                      label={`Future testing date 2*`}
                      name="duolingo_date_retake.test2"
                      values={formik.values?.duolingo_date_retake?.test2}
                      onChange={(values) =>
                        formik.setFieldValue('duolingo_date_retake.test2', values)
                      }
                    />
                  )}
                  {['3', 3].includes(formik.values.number_of_future_duolingo_expect) && (
                    <DatePickerInput
                      className="common-flag"
                      label={`Future testing date 3*`}
                      name="duolingo_date_retake.test3"
                      values={formik.values?.duolingo_date_retake?.test3}
                      onChange={(values) =>
                        formik.setFieldValue('duolingo_date_retake.test3', values)
                      }
                    />
                  )}
                </>
              )}
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {testing.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default DuoLingoTest;
