import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import React from 'react';
import { Row } from 'react-bootstrap';

interface Props {
  formik: any;
}

const Counselor = (props: Props) => {
  return (
    <Row className="m-0 common-flag">
      <div>CBO Counselor / Advisor / Mentor</div>
      <SelectInput
        option={['Dr.', 'Mr.', 'Ms.', 'Mrs.']}
        label="Prefix"
        name="advisor_prefix"
        placeholder="Prefix"
        values={props.formik.values.advisor_prefix}
        onChange={props.formik.handleChange}
      />
      <TextInput
        label="First Name"
        name="advisor_first_name"
        placeholder="First Name"
        values={props.formik.values.advisor_first_name}
        onChange={props.formik.handleChange}
      />
      <TextInput
        label="Last Name"
        placeholder="Last Name"
        name="advisor_last_name"
        onChange={props.formik.handleChange}
        values={props.formik.values.advisor_last_name}
      />
      <TextInput
        label="Phone number"
        type="phoneNumber"
        placeholder="Phone number"
        name="advisor_phone_number"
        onChange={props.formik.handleChange}
        values={props.formik.values.advisor_phone_number}
      />
      <TextInput
        mdCol="12"
        label="Email"
        placeholder="Email"
        name="advisor_email"
        onChange={props.formik.handleChange}
        values={props.formik.values.advisor_email}
      />
    </Row>
  );
};

export default Counselor;
