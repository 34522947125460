import { authenticateSettings } from 'components/helper/appConfig';
import Cookies from 'js-cookie';

import { Redirect, Route, RouteProps } from 'react-router-dom';
import { userRouters } from './router';
import React from 'react';
import { Userpilot } from 'userpilot';
import { useAppSelector } from 'app/hooks';
import { userpilotDomain } from 'components/helper/helper';

const ProtectedRoute = (props: RouteProps) => {
  const token = Cookies.get(authenticateSettings.tokenName);
  // const userId = localStorage.getItem('userId');
  const information = useAppSelector((store) => store.basicInformation.myBasicInformation);
  const stripeSubscription = useAppSelector((store) => store.basicInformation.stripeSubscriptionInfo);
  // const location = useLocation();
  React.useEffect(() => {
    if (token && information?.id) {
      Userpilot.identify((information?.student_id ?? 'undefined') + userpilotDomain, {
        name: information?.first_name ?? 'undefined',
        email: information?.email ?? 'undefined',
        created_at: information?.created_at ?? 'undefined',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information?.id]);

  if (!token) {
    return (
      <Redirect
        to={{
          pathname: userRouters.login.path,
          state: { from: props.location },
        }}
      />
    );
  }
  if(token && stripeSubscription?.subcription_plan === '' && information?.user_role === 'student') {
    return (
      <Redirect
        to={{
          pathname: userRouters.payment.path,
          state: { from: props.location },
        }}
      />
    );
  } else {
    return (
      <>
        <Route {...props} />
      </>
    );
  }
};

export default ProtectedRoute;
