import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import TextInput from 'components/input/textInput';
import DatePickerInput from 'components/input/DatePicker';
import SelectInput from 'components/input/SelectInput';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  handleSave: (value: any) => void;
  item: any;
}

const subjectTestOption = [
  { value: 'Biology - Ecological' },
  { value: 'Biology - Molecular' },
  { value: 'Chemistry' },
  { value: 'Chinese with Listening' },
  { value: 'French Reading' },
  { value: 'French with Listening' },
  { value: 'German Reading' },
  { value: 'German with Listening' },
  { value: 'Italian Reading' },
  { value: 'Japanese with Listening' },
  { value: 'Korean with Listening' },
  { value: 'Latin Reading' },
  { value: 'Literature' },
  { value: 'Math Level 1' },
  { value: 'Math Level 2' },
  { value: 'Modern Hebrew Reading' },
  { value: 'Physics' },
  { value: 'Spanish Reading' },
  { value: 'Spanish with Listening' },
  { value: 'US History' },
  { value: 'World History' },
  { value: 'Writing' },
];
const SATSubjectConfig = (props: Props) => {
  const formItem = useFormik({
    initialValues: {
      subject: 'Biology - Ecological',
      dateTaken: undefined,
      score: undefined,
    },
    onSubmit: (values) => {},
  });
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  React.useEffect(() => {
    if (props.item) formItem.setValues(props.item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [countForm, setCountForm] = React.useState(0);

  React.useEffect(() => {
    let count = 3;
    const formValues: any = formItem.values;
    Object.keys(formItem.values).forEach((key) => {
      if (formValues[key] === undefined || formValues[key] === '') count--;
    });
    setCountForm(count);
  }, [formItem.values]);
  return (
    <Card className="w-100 my-3 px-0">
      <CardActions
        disableSpacing
        onClick={handleExpandClick}
        sx={{
          backgroundColor: expanded ? '#AAEBF7' : 'none',
          borderBottom: expanded ? '2px solid #4D88F0' : 'none',

          marginLeft: '-8px',
        }}
      >
        <div className="w-100 d-flex justify-content-between">
          <span className="p-1">{formItem.values.subject}</span>
          <strong className="text-field-light">{countForm}/3</strong>
        </div>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Row>
            <SelectInput
              form={formItem}
              option={subjectTestOption}
              label="Subject*"
              name="subject"
              values={formItem.values.subject}
              onChange={formItem.handleChange}
            />
            <TextInput
              label="Score *"
              name="score"
              type="number"
              values={formItem.values.score}
              onChange={formItem.handleChange}
            />
            <DatePickerInput
              label={`Date taken or planned*`}
              name="dateTaken"
              values={formItem.values.dateTaken}
              onChange={(value) => formItem.setFieldValue('dateTaken', value)}
            />

            <Col xs={6} md={9} />
            <Col xs={6} md={3} className="p-3">
              <Button
                className="w-100"
                onClick={() => {
                  props.handleSave(formItem.values);
                  setExpanded(false);
                }}
              >
                Save Info
              </Button>
            </Col>
          </Row>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default SATSubjectConfig;
