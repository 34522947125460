/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Image, Spinner } from 'react-bootstrap';
import TextIconInput from 'components/input/TextIconInput';
import LoadingContent from 'components/LoadingContent';
import SelectInput from 'components/input/SelectInput';
import PersonOutlinedIcon from 'components/icons/PersonOutlinedIcon';
import PersonalInformationOutlinedIcon from 'components/icons/PersonalInformationOutlinedIcon';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Avatar } from '@mui/material';
import { FileToBase64, finishYearOption, userpilotDomain } from 'components/helper/helper';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getCurrentBasicDetail, update } from 'app/basicInformationSlice/basicInformationSlice';
import basicInformationServices from 'services/basicInformationApi';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Userpilot } from 'userpilot';
import TextInput from 'components/input/textInput';
import { USER_ROLE } from 'constants/common';

const SettingsPersonalInformation = () => {
  const fileInputRef: any = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [waiting, setWaiting] = React.useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);  

  const handleChangeImage = async (fileList: any) => {
    if (fileList[0].size > 10000000) {
      toast.error('Only accept less than 10mb image');
      return;
    }
    setLoading(true);
    await basicInformationServices.updateProfilePicture(fileList[0], studentId);
    dispatch(getCurrentBasicDetail(studentId)).then((res: any) => {
      if (res.payload) {
        formik.setValues(res.payload);
        window.location.reload();
      }
    });
    setLoading(false);
    formik.setStatus('idle');
  };
  const information = useAppSelector((store) => store.basicInformation.myBasicInformation);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      secondary_email: '',
      school_or_organization_work_with: '',
      year_finish_high_school: undefined,
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Required'),
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      secondary_email: Yup.string().email('Invalid email').nullable(),
    }),
    onSubmit: async (values) => {
      setWaiting(true);
      Userpilot.identify((studentId ?? 'undefined') + userpilotDomain, {
        name: information?.first_name ?? 'undefined',
        created_at: information?.created_at ?? 'undefined',
        firstName: values.first_name,
        lastName: values.last_name,
        email: values.email,
        school: values.school_or_organization_work_with,
        userType: localStorage.getItem('userRole'),
        graduationYear: values.year_finish_high_school,
      });
      
      window.Intercom('update', {
        graduation_year: values?.year_finish_high_school,
      });

      await dispatch(update(values));

      setWaiting(false);

      // reload data to update topbar/header
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    },
  });

  const [profilePicture, setProfilePicture] = React.useState<any>();
  const personalStore = useAppSelector((store) => store.basicInformation.myBasicInformation);

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };  


  React.useEffect(() => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      const profilePictureName = personalStore?.profile_picture_file_name ?? null;
      if (profilePictureName) {
        setLoading(true);
        basicInformationServices.getProfilePicture(profilePictureName).then(async (res) => {
          const blob = new Blob([res], { type: 'image/png;charset=utf-8' });
          const url = await FileToBase64(blob);
          setProfilePicture(url);
  
          const data = { id: studentId, url_pic: url };
          localStorage.setItem('cachedProfilePic', JSON.stringify(data));
        });
        setLoading(false);
      }      
    }
    else {
      handleLogout();
    }    

  }, [personalStore?.profile_picture_file_name, personalStore?.user_role, studentId]);

  React.useEffect(() => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      if (!personalStore?.id) {
        setLoading(true);
        dispatch(getCurrentBasicDetail(studentId)).then((res: any) => {
          if (res.payload) {
            formik.setValues(res.payload);
          }
          setLoading(false);
        });
      } else {
        formik.setValues(personalStore);
      }      
    }
    else {
      handleLogout();
    }
  }, []);

  const removePictureHandle = () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setProfilePicture(null);
      (async () => {
        const profilePictureName = personalStore?.profile_picture_file_name ?? null;
        await basicInformationServices.removeProfilePicture(studentId, profilePictureName);
        window.location.reload();
      })();      
    }
    else {
      handleLogout();
    }
  };

  return loading ? (
    <LoadingContent />
  ) : (
    <div className="tab-wrap-panel">
      <div className="tab-panel">
        <div className="text-left" style={{ paddingBottom: '16px' }}>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <div
                  className="text-primary p-2 rounded-circle bg-primary-light d-flex align-items-center justify-content-center"
                  style={{ width: '35px', height: '35px', marginRight: '8px' }}
                >
                  <PersonalInformationOutlinedIcon
                    className="text-primray-light"
                    width={'18px'}
                    height={'18px'}
                  />
                </div>
                <div className="text-primary fs-4 fw-bold mx-1">Personal Information</div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 py-3 text-dark">Profile Picture</div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="gm-profile-section">
                <div className="d-picture">
                  <Avatar
                    className="d-avatar"
                    alt="sb-avatar"
                    src={profilePicture}
                    sx={{ width: 108, height: 108 }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    className="d-none"
                    onChange={(e) => handleChangeImage(e.target.files)}
                    ref={fileInputRef}
                  />
                </div>
                <div className="d-buttons">
                  <div className="col-button">
                    <Button className="py-2 my-3" onClick={() => fileInputRef.current.click()}>
                      Upload New Photo
                    </Button>
                  </div>
                  <div className="col-button">
                    <Button
                      className="py-2 my-3"
                      // variant="outline-danger"
                      onClick={() => removePictureHandle()}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 gm-divider">
              <hr className="text-grey-semidark" style={{ margin: '0px', opacity: 1 }} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="fs-5 text-field-mid">Basic Details</div>
            </div>

            <div className="row">
              <TextIconInput
                className="p-0"
                icon={<PersonOutlinedIcon />}
                iconClass="text-primary3"
                mdCol={'6'}
                label={'First name'}
                name={'first_name'}
                values={formik.values.first_name}
                onChange={formik.handleChange}
                feedback={
                  formik.touched.first_name &&
                  formik.errors.first_name && (
                    <span id="error_first_name" className="text-danger">
                      {formik.errors.first_name}
                    </span>
                  )
                }
              />
              <TextIconInput
                icon={<PersonOutlinedIcon />}
                iconClass="text-primary3"
                mdCol={'6'}
                label={'Last name'}
                name={'last_name'}
                values={formik.values.last_name}
                onChange={formik.handleChange}
                feedback={
                  formik.touched.last_name &&
                  formik.errors.last_name && (
                    <span id="error_last_name" className="text-danger">
                      {formik.errors.last_name}
                    </span>
                  )
                }
              />
            </div>

            <div className="row">
              {localStorage.getItem('userRole') === USER_ROLE.counselor ? (
                <TextInput
                  label={'What school or organization do work with?'}
                  name={'school_or_organization_work_with'}
                  values={formik.values.school_or_organization_work_with}
                  onChange={formik.handleChange}
                />
              ) : (
                <SelectInput
                  form={formik}
                  values={formik.values.year_finish_high_school}
                  onChange={formik.handleChange}
                  name={'year_finish_high_school'}
                  label={'When will you finish highschool?'}
                  option={finishYearOption}
                />
              )}
            </div>

            <div className="row">
              <TextIconInput
                disabled
                icon={<MailOutlineIcon />}
                iconClass="text-primary3"
                formClass="text-field-dark border-field-dark"
                mdCol={'6'}
                label={'Email*'}
                name={'email'}
                values={formik.values.email}
                onChange={formik.handleChange}
                feedback={
                  formik.touched.email &&
                  formik.errors.email && (
                    <span id="error_email" className="text-danger">
                      {formik.errors.email}
                    </span>
                  )
                }
              />
              <TextIconInput
                className={'text-field-light'}
                icon={<MailOutlineIcon />}
                iconClass="text-field-light"
                mdCol={'6'}
                label={'Secondary Email'}
                name={'secondary_email'}
                values={formik.values.secondary_email}
                onChange={formik.handleChange}
                feedback={
                  formik.touched.secondary_email &&
                  formik.errors.secondary_email && (
                    <span id="error_secondary_email" className="text-danger">
                      {formik.errors.secondary_email}
                    </span>
                  )
                }
              />
            </div>

            <div className="row">
              <div className="my-3 d-md-flex justify-content-end" style={{ paddingLeft: 0 }}>
                <Button
                  variant="primary"
                  onClick={() => formik.handleSubmit()}
                  className="btn-update"
                >
                  {waiting ? <Spinner size="sm" animation="border" variant="white" /> : 'Update'}
                </Button>
              </div>
            </div>
          </div>

          <div className="w-100 border" style={{ marginTop: '15px', marginBottom: '38px' }} />

          <div id="link-account" className="d-none">
            <div className="row m-0">
              <div className="col-md-12 m-0 p-0">
                <div className="fs-5">Linked Accounts</div>
              </div>

              <div className="col-md-12 m-0 p-0">
                <div className="text-field-light my-3">
                  We use this account to let you sign in and populate your profile information
                </div>
              </div>

              <div className="col-md-12 m-0 p-0">
                <div className="d-flex align-items-center m-3">
                  <Image src="./assets/icons/google-icon.png" roundedCircle width="30px" />
                  <span className="mx-3">example.student@gmail.com</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPersonalInformation;
