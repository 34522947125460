import React from 'react';
import { Row } from 'react-bootstrap';
import ItemContent, { CheckboxContent } from './ItemContent';

interface Props {
  formValues: any;
}

const EthnicitySum = (props: Props) => {
  const [data, setData] = React.useState<any>({
    language: undefined,
    religious_preference: undefined,
    other_religion: undefined,
    us_armed_forces_status: undefined,
    other_religion_language: undefined,
    is_dependent_us_military: '',
    is_consider_hispanic_latino: '',
    is_complete_satisfaction: true,
    number_language_proficient: 1,
    language_array: [],
    is_option_1: false,
    is_option_2: false,
    is_option_3: false,
    is_option_4: false,
    describe_latino_1: false,
    describe_latino_2: false,
    describe_latino_3: false,
    describe_latino_4: false,
    describe_latino_5: false,
    describe_latino_6: false,
    describe_latino_7: false,
    hispanic_latino_1: false,
    hispanic_latino_2: false,
    hispanic_latino_3: false,
    hispanic_latino_4: false,
    hispanic_latino_5: false,
    hispanic_latino_6: false,
    hispanic_latino_7: false,
    racial_background_AsianIndian: false,
    racial_background_Japanese: false,
    racial_background_Filipino: false,
    racial_background_Taiwanese: false,
    racial_background_Bangladeshi: false,
    racial_background_Korean: false,
    racial_background_Hmong: false,
    racial_background_Thai: false,
    racial_background_Chinese: false,
    racial_background_Cambodian: false,
    racial_background_Laotian: false,
    racial_background_Indonesian: false,
    racial_background_Vietnamese: false,
    racial_background_Malaysian: false,
    racial_background_Pakistani: false,
    racial_background_OtherAsian: false,
    racial_background_Afghan: false,
    racial_background_Algerian: false,
    racial_background_Armenian: false,
    racial_background_AssyrianChaldean: false,
    racial_background_Bahraini: false,
    racial_background_Berber: false,
    racial_background_Circassian: false,
    racial_background_Djiboutian: false,
    racial_background_Egyptian: false,
    racial_background_Emirati: false,
    racial_background_Georgian: false,
    racial_background_Iranian: false,
    racial_background_Iraqi: false,
    racial_background_Israeli: false,
    racial_background_Jordanian: false,
    racial_background_Kurdish: false,
    racial_background_Omani: false,
    racial_background_Palestinian: false,
    racial_background_Qatari: false,
    racial_background_SaudiArabian: false,
    racial_background_Somali: false,
    racial_background_Sudanese: false,
    racial_background_Syrian: false,
    racial_background_Tunisian: false,
    racial_background_Turkish: false,
    racial_background_Yemeni: false,
    racial_background_OtherNorthAfrican: false,
    racial_background_OtherSouthwestAsian: false,
    racial_background_African: false,
    racial_background_AfricanAmericanOrBlack: false,
    racial_background_BlackCaribbean: false,
    racial_background_OtherAfrican: false,
    racial_background_AmericanIndian: false,
    racial_background_EuropeanDescent: false,
    racial_background_OtherWhite: false,
    racial_background_more_AfricanAmerican: false,
    racial_background_more_Asian: false,
    racial_background_more_AmericanIndian: false,
    racial_background_more_White: false,
    racial_background_more_NativeHawaiian: false,
    racial_background_white_Europe: false,
    racial_background_white_MiddleEast: false,
    racial_background_white_NorthAfrica: false,
    racial_background_white_Other: false,
    racial_background_identify_AfricanAmerican: false,
    racial_background_identify_NativeHawaiian: false,
    racial_background_identify_White: false,
    racial_background_identify_AmericanIndian: false,
    racial_background_identify_Asian: false,
  });
  React.useEffect(() => {
    if (props.formValues.all_apply_array) {
      setData({
        ...props.formValues,
        ...JSON.parse(props.formValues.best_option_latino_backgroud_array),
        ...JSON.parse(props.formValues.best_group_describe_racial_background_array),
        ...JSON.parse(props.formValues.best_group_latino_background_array),
      });
    }
  }, [props.formValues]);
  return (
    <>
      {/* {data?.is_consider_hispanic_latino === true && (
        <>
          <ItemContent
            mdCol={12}
            label="Which best describes your Hispanic or Latino background?"
            values={data?.value_consider_hispanic_latino}
          />
          <ItemContent
            hidden={data?.value_consider_hispanic_latino !== 'Other Central American'}
            label={'Please specify Other Central American:'}
            values={data?.specify_is_Other_Central_American}
          />
          <ItemContent
            hidden={data?.value_consider_hispanic_latino !== 'Other South American'}
            label={'Please specify Other South American:'}
            values={data?.specify_is_Other_South_American}
          />
          <ItemContent
            hidden={data?.value_consider_hispanic_latino !== 'Other Hispanic or Latino'}
            label={'Please specify Other Hispanic or Latino:'}
            values={data?.specify_is_Other_Hispanic_or_Latino}
          />
        </>
      )} */}
      <Row>
        <div className="px-1 mt-3">
          Regardless of your answer to the prior question, please indicate how you identify
          yourself.
        </div>
        {[
          {
            value: 'racial_background_identify_AfricanAmerican',
            label: 'Black or African American',
          },
          {
            value: 'racial_background_identify_White',
            label: 'White',
          },
          {
            value: 'racial_background_identify_Asian',
            label: 'Asian',
          },
          {
            value: 'racial_background_identify_Southwest_Asian_and_North_African',
            label: 'Southwest Asian and North African',
          },
          {
            value: 'racial_background_identify_NativeHawaiian',
            label: 'Native Hawaiian or Other Pacific Islander',
          },
          {
            value: 'racial_background_identify_AmericanIndian',
            label: 'American Indian or Alaska Native',
          },
        ].map((option, index) => {
          const keyName = option.value;
          return (
            <CheckboxContent key={index} col={3} label={option.label} values={data[keyName]} />
          );
        })}
      </Row>

        <Row className={data?.racial_background_identify_AmericanIndian ? '' : 'd-none'}>
          <ItemContent
            mdCol={12}
            label="Are you an enrolled member of an American Indian or Alaska Native tribe (U.S.)?"
            values={data?.is_enrolled_member_of_American_Indian_or_Alaska_Native}
          />
          <ItemContent
            mdCol={12}
            label="Please select the category that best reflects your tribal enrollment. If you are an enrolled member of more than one tribe, select your primary tribal affiliation from the list of recognized tribes.*"
            values={data?.tribal_enrollment_category}
          />
          <ItemContent
            mdCol={12}
            hidden={data?.tribal_enrollment_category !== 'Federally recognized tribe'}
            label="Please select your primary tribal affiliation*"
            values={data?.tribal_enrollment_primary}
          />
          <ItemContent
            mdCol={12}
            values={data?.tribal_citizenship_or_enrollment_number}
            label="Please provide your tribal citizenship or enrollment number."
          />

          <div className="text-mute">
            Your enrollment in an American Indian or Alaska Native tribe may be considered during
            the comprehensive review of your UC application for admission.
          </div>
          <CheckboxContent
            label="I consent to the use of my tribal citizenship and enrollment status for purposes concerning my UC application for admission. I understand that this information is subject to verification."
            values={data?.is_consent_to_the_use_of_tribal_citizenship_or_enrollment_information}
          />

          <ItemContent
            mdCol={12}
            label="Please choose the tribe you are enrolled in"
            values={data?.csu_tribe_enrolled}
          />
        </Row>

        <ItemContent
          mdCol={12}
          hidden={!data?.racial_background_identify_AfricanAmerican}
          label="What best describes your Black or African American background?"
          values={data?.racial_background_African}
        />

      <Row className={data?.racial_background_identify_NativeHawaiian ? '' : 'd-none'}>
        <ItemContent
          mdCol={12}
          label="Which best describes your Native Hawaiian or Other Pacific Islander background?"
          values={data?.racial_background_NativeHawaiian}
        />
        {/* {(data?.racial_background_NativeHawaiian ?? '')?.includes('Other') && (
          <ItemContent
            mdCol={12}
            label="Please Specify Your Other Background"
            values={data?.racial_OtherPacific_value}
          />
        )} */}
        {/* {nativeHawaiianOptions.map((option, index) => {
          const keyName = option.value.slice(44);
          return (
            <CheckboxContent key={index} col={3} label={option.label} values={data[keyName]} />
          );
        })} */}
      </Row>

      <Row className={data?.racial_background_identify_White ? '' : 'd-none'}>
        <ItemContent
          mdCol={12}
          label="Which best describes your White background?"
          values={
            data?.racial_background_White === 'Other White'
              ? `Other White, ${data?.racial_OtherWhite_value}`
              : data?.racial_background_White
          }
        />
      </Row>

      <Row className={data?.racial_background_identify_Asian ? '' : 'd-none'}>
        {/* {asianOptions.map((option, index) => {
          const keyName = option.key.slice(44);
          return (
            <CheckboxContent key={index} col={3} label={option.value} values={data[keyName]} />
          );
        })} */}
        {data?.racial_background_identify_Asian && (
          <>
            <ItemContent
              mdCol={12}
              label="Which best describes your Asian background?"
              values={data?.racial_background_Asian}
            />
            {/* {data?.racial_background_Asian === 'Other Asian' && (
              <ItemContent
                mdCol={12}
                label="Specify other Asian background"
                values={data?.racial_OtherAsian_value}
              />
            )} */}
          </>
        )}
      </Row>

      <Row
        className={
          data?.racial_background_identify_Southwest_Asian_and_North_African ? '' : 'd-none'
        }
      >
        <ItemContent
          mdCol={6}
          label="Which best describes your Southwest Asian or North African background?"
          values={data?.racial_Southwest_Asian_and_North_African}
        />
        {/* <ItemContent
          hidden={!(data?.racial_Southwest_Asian_and_North_African ?? '').includes('Other')}
          mdCol={6}
          label="Please Specify:"
          values={data?.racial_Southwest_Asian_and_North_African_other}
        /> */}
      </Row>
      <ItemContent
        mdCol={12}
        label="California State University often needs to report ONLY ONE summary race/ethnicity description for a person. Please select your reporting preferences:"
        values={data?.csu_reporting_preferences}
      />
      <CheckboxContent
        col={12}
        label="Yes, I have completed this section to my satisfaction."
        values={data?.is_complete_satisfaction}
      />
    </>
  );
};

export default EthnicitySum;
