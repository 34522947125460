import { monthOptions }  from 'components/helper/helper';


    
export const getMonthIndex = (month:string) => {
    try {
        for(let i=0; i<monthOptions.length; i++) {
            if (month.toUpperCase() === monthOptions[i].value.toUpperCase()) 
                return i;
        }
    }
    catch(ex){
        // skip;
    }

    return 1;
}


// export const dayOptions = [
//     { value: '1' },
//     { value: '2' },
//     { value: '3' },
//     { value: '4' },
//     { value: '5' },
//     { value: '6' },
//     { value: '7' },
//     { value: '8' },
//     { value: '9' },
//     { value: '10' },
//     { value: '11' },
//     { value: '12' },
//     { value: '13' },
//     { value: '14' },
//     { value: '15' },
//     { value: '16' },
//     { value: '17' },
//     { value: '18' },
//     { value: '19' },
//     { value: '20' },
//     { value: '21' },
//     { value: '22' },
//     { value: '23' },
//     { value: '24' },
//     { value: '25' },
//     { value: '26' },
//     { value: '27' },
//     { value: '28' },
//     { value: '29' },
//     { value: '30' },
//     { value: '31' },
// ];

// export const monthOptions = [
//     { value: 1,  name: 'January' },
//     { value: 2,  name: 'February' },
//     { value: 3,  name: 'March' },
//     { value: 4,  name: 'April' },
//     { value: 5,  name: 'May' },
//     { value: 6,  name: 'June' },
//     { value: 7,  name: 'July' },
//     { value: 8,  name: 'August' },
//     { value: 9,  name: 'September' },
//     { value: 10, name: 'October' },
//     { value: 11, name: 'November' },
//     { value: 12, name: 'December' },
// ];


/**
 * @param {int} The month number, 0 based
 * @param {int} The year, not zero based, required to account for leap years
 * @return {string[]} List with date objects for each day of the month
 */
export const getDaysInMonth = (month:number, year:number): string[] =>  {
    let startDate = new Date(year, month, 1); // month is 0-indexed
    let endDate = new Date(year, month + 1, 1);
   
    let dates = [];
    while (startDate < endDate) {
      dates.push(new Date(startDate)); // clone the date object
      startDate.setDate(startDate.getDate() + 1);
    }
    
    const days = dates.map((o)=> {
        return o.getDate().toString();
    });
  
    return days;
};


export const getDaysInMonthOptions = (month:number, year:number) => {
    let startDate = new Date(year, month, 1); // month is 0-indexed
    let endDate = new Date(year, month + 1, 1);
   
    let dates = [];
    while (startDate < endDate) {
      dates.push(new Date(startDate)); // clone the date object
      startDate.setDate(startDate.getDate() + 1);
    }
    
    const days = dates.map((o)=> {
        return monthOptions[o.getDate()];
    });
  
    return days;
};



export function getAllDatesInMonth(month:number, year:number) {
    let startDate = new Date(year, month, 1); // month is 0-indexed
    let endDate = new Date(year, month + 1, 1);
      
    let dates = [];
    while (startDate < endDate) {
      dates.push(new Date(startDate)); // clone the date object
      startDate.setDate(startDate.getDate() + 1);
    }
  
    return dates;
}

/**
 * @param {int} The month number, 0 based
 * @param {int} The year, not zero based, required to account for leap years
 * @return {Date[]} List with date objects in UTC  for each day of the month
 */
export const getDaysInMonthUTC = (month:any, year:any): string[] => {
    let startDate = new Date(Date.UTC(year, month, 1)); // month is 0-indexed
    let endDate = new Date(Date.UTC(year, month + 1, 1));
   
    let dates = [];
    while (startDate < endDate) {
        dates.push(new Date(startDate)); // clone the date object
        startDate.setUTCDate(startDate.getUTCDate() + 1);
    }

    const days:string[] = dates.map((o)=> {
        return o.getDate().toString();
    });
  
    return days;
};


export function getAllUTCDatesInMonth(month:number, year:number) {
    let startDate = new Date(Date.UTC(year, month, 1)); // month is 0-indexed
    let endDate = new Date(Date.UTC(year, month + 1, 1));
   
    let dates = [];
    while (startDate < endDate) {
        dates.push(new Date(startDate)); // clone the date object
        startDate.setUTCDate(startDate.getUTCDate() + 1);
    }

    return dates;
}

