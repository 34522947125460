import React from 'react';
import { Row } from 'react-bootstrap';
import ItemContent, { CheckboxContent } from './ItemContent';
import Card from '@mui/material/Card';

interface Props {
  data: any;
}

const CourseCollegeSum = ({ data }: Props) => {
  return (
    <>
      {(data || []).map((item: any, index: number) => (
        <Card className="mt-2 px-3" key={index}>
          <Row style={{ margin: '8px' }}>
            <div className="fs-5 p-3">
              {[item?.collegeName, item?.term, item?.academicYear].filter((i) => !!i).join(' - ')}
            </div>
            <ItemContent label="Schedule System *" values={item?.scheduleSystem} mdCol={6} />
            <ItemContent label="College Academic Status" values={item?.academicStatus} mdCol={6} />

            {(item?.collegeCourseArray ?? []).map((itemCourse: any, index: number) => (
              <Row key={index}>
                <div className="p-1">
                  {[itemCourse?.dept, itemCourse?.courseNumber, itemCourse?.courseName]
                    .filter((i) => !!i)
                    .join(' - ')}
                </div>
                <div className="p-1">
                  {[
                    `Course Subject: ${itemCourse?.collegeCourseSubject}`,
                    `A-G Subject: ${itemCourse?.subject}`,
                    `Honor Status: ${itemCourse?.honorStatus}`,
                  ].join(' - ')}
                </div>
                <div className="p-1">
                  {[
                    `Grade: ${itemCourse?.grade}`,
                    `Credits: ${itemCourse?.creditsPerHours}`,
                    `Completion Status: ${itemCourse?.completionStatus}`,
                  ].join(' - ')}
                </div>

                <CheckboxContent values={itemCourse?.transferable} label="Transferable" />
                <div className="my-3 border" />
              </Row>
            ))}
          </Row>
        </Card>
      ))}
    </>
  );
};

export default CourseCollegeSum;
