/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography,
  Grid,
  Card,
  Stack,
  FormControlLabel,
  Checkbox,
  Box,
  Pagination,
} from '@mui/material';
import CardConfig from 'components/CardConfig';
import TextInput from 'components/input/textInput';
import { useFormik } from 'formik';
import React, { ReactNode } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import collegeServices from 'services/collegeServices';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import LoadingContent from 'components/LoadingContent';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import { useAppDispatch } from 'app/hooks';
import Cookies from 'js-cookie';
import { checkEnableParseJSON } from 'components/helper/helper';
import { debounce } from 'lodash';
import ModalMajor from './ModalMajor';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ModalLocation from './ModalLocation';
import ModalCampusLife from './ModalCampusLife';
import ModalAdmissionRequirement from './ModalAdmissionRequirement';
import ModalCollegeInfo from './ModalCollegeInfo';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import counselorServices from 'services/counselorServices';
import { ConversationCard } from 'screen/summary/ReviewPersonal';
export const typoSx = {
  title: {
    fontWeight: 700,
    fontSize: 20,
  },
};

export type TCollege = {
  w_gpa?: boolean;
  url_price_calc?: string;
  counselor_ranking?: string;
  act: string;
  address_line_1: string;
  address_line_2: string;
  area_code_1: string;
  area_code_2: string;
  area_code_fax: string;
  city: string;
  cmps_setting: string;
  college_id: number;
  country_code: string;
  county: string;
  difficulty_for_admittance: string;
  founding_year: number;
  hous_frsh_policy: string;
  id: number;
  life_frat_nat: string;
  life_sor_nat: string;
  name: string;
  phone_number_1: string;
  phone_number_2: string;
  phone_number_fax: string;
  region: string;
  size_of_school: string;
  state: string;
  state_name: string;
  under_student: number;
  url_address: string;
  year_type: string;
  zip_code: string;
  is_saved: boolean;
  gpa?: number;
  sat?: number | string;
  type_of_school?: string;
};

export type ListCollegeByGroup = {
  name: string;
  collegeList: TCollege[];
}[];

const CollegeSearch = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const [openMajorSearch, setOpenMajorSearch] = React.useState(false);
  const [openLocationSearch, setOpenLocationSearch] = React.useState(false);
  const [openCollegeInfo, setOpenCollegeInfo] = React.useState(false);
  const [openCampusLife, setOpenCampusLife] = React.useState(false);
  const [openAdmissionRequirement, setOpenAdmissionRequirement] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(40);
  const [myCurrentList, setMyCurrentList] = React.useState<ListCollegeByGroup>([]);
  const [data, setData] = React.useState<any>(null);
  const [colleges, setColleges] = React.useState<TCollege[]>([]);
  const [openModal, setOpenModal] = React.useState<TCollege | undefined | null>();
  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      keyword: '',
      state_name: '',
      cip_ids: [],
      state_codes: [],
      cmps_setting: [],
      size_of_school: [],
      life_frat_nat: '',
      life_sor_nat: '',
      from_gpa: null,
      to_gpa: null,
      from_sat: null,
      to_sat: null,
      from_act: null,
      to_act: null,
      region: null as any,
      type_of_school: [],
      year_types: [],
    },
    onSubmit: async (val) => {
      setPage(1);
      searchCollege(val, 1);
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };   

  const searchCollege = async (filter: any, pageNumberInput: number) => {
    setLoading(true);
    const [res, total]: any = await collegeServices.getUSCollegeV1({
      life_frat_nat:
        filter?.life_frat_nat === 'true'
          ? 'Y'
          : filter?.life_frat_nat === 'false'
          ? 'N'
          : filter?.life_frat_nat,
      life_sor_nat:
        filter?.life_sor_nat === 'true'
          ? 'Y'
          : filter?.life_sor_nat === 'false'
          ? 'N'
          : filter?.life_sor_nat,
      take: 100,
      skip: (pageNumberInput - 1) * 100,
      type_of_schools: filter.type_of_school?.length ? filter.type_of_school : undefined,

      regions: filter.region,
      keyword: filter.keyword,
      state_codes: filter.state_codes,
      state_name: filter.state_name,
      cmps_settings: filter.cmps_setting ? filter.cmps_setting : undefined,
      // sizes_of_school: [
      //   string
      // ],
      // difficulties_for_admittance: [
      //   string
      // ],
      // hous_frsh_policies: [
      //   string
      // ],
      sport_codes: filter.sport_codes,
      cip_ids: filter.cip_ids,
      // zip_code: string,
      from_gpa: filter.from_gpa,
      to_gpa: filter.to_gpa,
      from_sat: filter.from_sat,
      to_sat: filter.to_sat,
      from_act: filter.from_act,
      to_act: filter.to_act,
      year_types: filter.year_types?.length ? filter.year_types : undefined,
    });
    setColleges(res);
    setTotalPage(Math.ceil(total / 100));
    setLoading(false);
  };
  
  React.useEffect(() => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {     
      collegeServices.getMyCollege(studentId).then((res: any) => {
        if (!!res?.colleges_array) {
          setMyCurrentList(checkEnableParseJSON(res?.colleges_array, []));
        }
        setData(res);
      });

    }
    else{
      handleLogout();
    }
  }, []);

  React.useEffect(() => {
    searchCollege(formik.values, page);
  }, [page]);

  const handleSavingData = async (dataInput: any) => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) { 
      dataInput?.id
      ? await collegeServices.updatetMyCollege(studentId, JSON.stringify(myCurrentList))
      : await collegeServices
          .createMyCollege(studentId, JSON.stringify(myCurrentList))
          .then(() => setData({ id: studentId }));
    }
    else {
      handleLogout();
    }
  };

  React.useEffect(
    debounce(() => {
      if (!studentId || data === null) return;
      if (!loading) handleSavingData(data);
    }, 1000),
    [myCurrentList]
  );
  const handleClickAddToList = (
    item: TCollege,
    currentListItem: {
      name: string;
      collegeList: TCollege[];
    }
  ) => {
    const newMyCurrentList = myCurrentList.map((list) => {
      return list.name === currentListItem.name
        ? {
            name: list.name,
            collegeList: [...list.collegeList, item].filter((college, collegeIndex) => {
              return (
                [...list.collegeList, item].findLastIndex((col) => col.id === college.id) ===
                collegeIndex
              );
            }),
          }
        : list;
    });

    setMyCurrentList(newMyCurrentList);
  };

  const handleAddNewList = (name: string, item: TCollege | undefined | null) => {
    if (!!name && !!item && myCurrentList.findIndex((list) => list.name === name) !== -1) {
      handleClickAddToList(
        item,
        myCurrentList[myCurrentList.findIndex((list) => list.name === name)]
      );
      setOpenModal(null);
      return;
    }
    item
      ? setMyCurrentList((prev) => [...prev, { name, collegeList: [item] }])
      : setOpenModal(null);
    setOpenModal(null);
  };

  const handleRemoveSchool = (item: TCollege, listName: string) => {
    const newMyCurrentList = myCurrentList.map((list) => {
      return list.name === listName
        ? {
            name: list.name,
            collegeList: list.collegeList.filter((college) => college.id !== item.id),
          }
        : list;
    });
    setMyCurrentList(newMyCurrentList);
  };
  const handleRemoveList = (index: number) => {
    const newMyCurrentList = myCurrentList.filter((list, i) => i !== index);
    setMyCurrentList(newMyCurrentList);
  };

  const handleCheckedItem = (item: TCollege, index: number, listName: string) => {
    const newMyCurrentList = myCurrentList.map((list) => {
      let newListCollege = [...list.collegeList];
      newListCollege[index] = item;

      return list.name === listName
        ? {
            name: list.name,
            collegeList: newListCollege,
          }
        : list;
    });
    setMyCurrentList(newMyCurrentList);
  };

  let CampusCount =
    formik.values.cmps_setting.length +
    formik.values.size_of_school.length +
    (formik.values.life_frat_nat.length ? 1 : 0) +
    (formik.values.life_sor_nat.length ? 1 : 0);
  let AdmissionCount =
    (formik.values.from_gpa !== null || formik.values.to_gpa !== null ? 1 : 0) +
    (formik.values.from_sat !== null || formik.values.to_sat !== null ? 1 : 0) +
    (formik.values.from_act !== null || formik.values.to_act !== null ? 1 : 0);
  let InfoCount =
    (formik.values.type_of_school?.length > 11
      ? 2
      : formik.values.type_of_school?.length > 0
      ? 1
      : 0) + (formik.values?.year_types?.length ?? 0);
  let countLocation = formik.values.state_codes.length + formik.values.region?.length;
  const [listCounselorConnection, setListCounselorConnection] = React.useState<any[]>([]);
  React.useEffect(() => {
    counselorServices.getCounselor(studentId).then((res: any[]) => {
      setListCounselorConnection(res ?? []);
    });
  }, [studentId]);
  return (
    <Grid container sx={{ width: '100%' }}>
      <Grid item xs={12} md={8}>
        <form autoComplete="off">
          <Row className="px-2">
            <Typography sx={typoSx.title}>College Search</Typography>
            <TextInput
              mdCol={5}
              label=""
              name="keyword"
              placeholder="Search by Name"
              values={formik.values.keyword}
              onChange={formik.handleChange}
              otherProps={{
                onKeyPress: (ev: any) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    formik.handleSubmit();
                  }
                },
              }}
            />
            <TextInput
              mdCol={5}
              label=""
              placeholder="Search by City or State"
              name="state_name"
              values={formik.values.state_name}
              onChange={formik.handleChange}
              otherProps={{
                onKeyPress: (ev: any) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    formik.handleSubmit();
                  }
                },
                autoComplete: 'new-password',
                id: 'state_name_controlled',
              }}
            />
            <Col md={2}>
              <Button
                style={{ marginTop: '0.8rem', width: '100%', height: '48px' }}
                onClick={() => formik.handleSubmit()}
              >
                Search
              </Button>
            </Col>
            {loading ? (
              <LoadingContent />
            ) : (
              <Stack justifyContent={'center'} alignItems={'center'}>
                <Card
                  className="p-2 w-100"
                  sx={{
                    maxWidth: '840px',
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent={'center'}
                    spacing={2}
                    className="w-100 mb-3"
                  >
                    <CardItem
                      icon={
                        <LocationOnOutlinedIcon
                          color="primary"
                          sx={{ fontSize: 26, marginBottom: 1 }}
                        />
                      }
                      onClick={() => setOpenLocationSearch((prev) => !prev)}
                      label={`Location ${countLocation ? `(${countLocation})` : ''}`}
                      onDelete={
                        countLocation > 0
                          ? () => {
                              formik.setFieldValue('state_codes', []);
                              formik.setFieldValue('region', []);
                              formik.handleSubmit();
                            }
                          : null
                      }
                    />
                    <CardItem
                      icon={
                        <DeviceHubOutlinedIcon
                          color="primary"
                          sx={{ fontSize: 26, marginBottom: 1 }}
                        />
                      }
                      onClick={() => setOpenMajorSearch((prev) => !prev)}
                      label={`Majors ${
                        formik.values.cip_ids.length > 0 ? `(${formik.values.cip_ids.length})` : ''
                      }`}
                      onDelete={
                        formik.values.cip_ids.length > 0
                          ? () => {
                              formik.setFieldValue('cip_ids', []);
                              formik.handleSubmit();
                            }
                          : null
                      }
                    />
                    <CardItem
                      icon={
                        <HolidayVillageOutlinedIcon
                          color="primary"
                          sx={{ fontSize: 26, marginBottom: 1 }}
                        />
                      }
                      onClick={() => setOpenCampusLife((prev) => !prev)}
                      label={`Campus Life ${CampusCount > 0 ? `(${CampusCount})` : ''}`}
                      onDelete={
                        CampusCount
                          ? () => {
                              formik.setValues({
                                ...formik.values,
                                cmps_setting: [],
                                size_of_school: [],
                                life_frat_nat: '',
                                life_sor_nat: '',
                              });
                              formik.handleSubmit();
                            }
                          : null
                      }
                    />
                    <CardItem
                      icon={
                        <TuneOutlinedIcon color="primary" sx={{ fontSize: 26, marginBottom: 1 }} />
                      }
                      onClick={() => setOpenAdmissionRequirement((prev) => !prev)}
                      label={`Admission Requirements ${
                        AdmissionCount > 0 ? `(${AdmissionCount})` : ''
                      }`}
                      onDelete={
                        AdmissionCount
                          ? () => {
                              formik.setValues({
                                ...formik.values,
                                from_gpa: null,
                                to_gpa: null,
                                from_sat: null,
                                to_sat: null,
                                from_act: null,
                                to_act: null,
                              });
                              formik.handleSubmit();
                            }
                          : null
                      }
                    />
                    <CardItem
                      onClick={() => setOpenCollegeInfo((prev) => !prev)}
                      label={`College Info ${InfoCount > 0 ? `(${InfoCount})` : ''}`}
                      icon={
                        <SchoolOutlinedIcon
                          color="primary"
                          sx={{ fontSize: 26, marginBottom: 1 }}
                        />
                      }
                      onDelete={
                        InfoCount
                          ? () => {
                              formik.setValues({
                                ...formik.values,
                                region: null,
                                type_of_school: [],
                                year_types: [],
                              });
                              formik.handleSubmit();
                            }
                          : null
                      }
                    />
                  </Stack>
                  <Box
                    sx={{ maxHeight: '50dvh', overflowY: 'auto', paddingBottom: '2rem' }}
                    className="my-2"
                  >
                    {(colleges ?? []).map((item, index) => (
                      <Grid
                        container
                        key={index}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className="p-2 pt-3 mt-1 border-bottom"
                      >
                        <Grid item md={6}>
                          {item.name}
                        </Grid>
                        <Grid item md={4}>
                          {[item.city, item.state].join(', ')}
                        </Grid>
                        <Grid
                          item
                          md={2}
                          justifyContent="flex-end"
                          display={'flex'}
                          flexDirection={'row'}
                        >
                          <Dropdown>
                            <Dropdown.Toggle>Add to List</Dropdown.Toggle>

                            <Dropdown.Menu>
                              {(myCurrentList ?? []).map((currentListItem, currentListIndex) => (
                                <Dropdown.Item
                                  key={currentListIndex}
                                  onClick={() => handleClickAddToList(item, currentListItem)}
                                >
                                  {currentListItem.name}
                                </Dropdown.Item>
                              ))}
                              {myCurrentList?.length < 5 && (
                                <Dropdown.Item onClick={() => setOpenModal(item)}>
                                  Add List Name
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </Card>
              </Stack>
            )}
            {colleges?.length ? (
              <Stack className="w-100 m-4" justifyContent="center" direction="row">
                <Pagination
                  count={totalPage}
                  color="primary"
                  page={page}
                  onChange={(e, value) => setPage(value)}
                />
              </Stack>
            ) : (
              <Stack className="w-100 m-4" justifyContent="center" direction="row">
                <span>No College found!</span>
              </Stack>
            )}
            <Box sx={{ padding: '0 84px !important' }}>
              {(myCurrentList ?? [])?.map((item, indexList) => (
                <div key={indexList}>
                  <CardConfig
                    headerElement={
                      <Stack
                        className="w-100"
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{item.name}</span>
                        <Button
                          variant="outline-danger"
                          onClick={() => handleRemoveList(indexList)}
                        >
                          Remove
                        </Button>
                      </Stack>
                    }
                  >
                    {item.collegeList.map((miniItem, index) => (
                      <div key={index}>
                        <CollegeInformationDetail
                          miniItem={miniItem}
                          index={index}
                          item={item}
                          handleRemoveSchool={handleRemoveSchool}
                          handleCheckedItem={handleCheckedItem}
                        />
                      </div>
                    ))}
                  </CardConfig>
                </div>
              ))}
            </Box>
          </Row>
        </form>
      </Grid>
      <Grid item xs={12} md={4} sx={{ marginTop: { md: '48px' } }}>
        {listCounselorConnection.map((item, index) => (
          <ConversationCard conversationItem={item} currentModuleId={'8.1'} key={index} />
        ))}
      </Grid>
      <ModalAddList
        open={!!openModal?.id}
        handleClose={(str: string) =>
          !str ? setOpenModal(null) : handleAddNewList(str, openModal)
        }
      />
      <ModalMajor open={openMajorSearch} setOpen={setOpenMajorSearch} formik={formik} />
      <ModalLocation open={openLocationSearch} setOpen={setOpenLocationSearch} formik={formik} />
      <ModalCampusLife open={openCampusLife} setOpen={setOpenCampusLife} formik={formik} />
      <ModalAdmissionRequirement
        open={openAdmissionRequirement}
        setOpen={setOpenAdmissionRequirement}
        formik={formik}
      />
      <ModalCollegeInfo open={openCollegeInfo} setOpen={setOpenCollegeInfo} formik={formik} />
    </Grid>
  );
};

export const buildLineRow = (content: (string | ReactNode)[]) => {
  return (
    <Stack
      className="w-100 py-2"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <span>{content?.[0]}</span>
      <span>{content?.[1]}</span>
    </Stack>
  );
};
const ModalAddList = ({ open, handleClose }: any) => {
  const formRef = React.useRef(null) as any;
  const [validated, setValidated] = React.useState(true);
  const handleSave = (value: string) => {
    if (!value) {
      setValidated(false);
      return;
    } else {
      handleClose(value);
    }
  };
  return (
    <Modal show={open} onHide={handleClose} centered>
      <Modal.Body>
        <Form
          ref={formRef}
          validated={validated}
          onSubmit={(e) => {
            e.preventDefault();
            handleSave(formRef?.current?.elements[0]?.value);
          }}
        >
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>List Name</Form.Label>
            <Form.Control required autoFocus />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => handleSave(formRef?.current?.elements[0]?.value)}>
          Create List
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const CardItem = ({ label, onClick, onDelete, icon }: any) => {
  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        zIndex: 1,
        cursor: 'pointer',
        width: 132,
      }}
    >
      <Stack
        sx={{
          margin: 1,
          paddingTop: 1,
          height: '80px',
          position: 'relative',
          zIndex: 2,
          fontWeight: 'bold',
        }}
        alignItems="center"
        justifyContent="start"
        onClick={onClick}
      >
        {icon}
        <Box sx={{ textAlign: 'center' }}>{label}</Box>
      </Stack>

      {onDelete && (
        <CancelOutlinedIcon
          onClick={(e) => {
            e.preventDefault();
            onDelete();
          }}
          sx={{
            position: 'absolute',
            top: 3,
            right: 3,
            zIndex: 3,
            cursor: 'pointer',
            fontSize: 16,
            color: 'gray',
          }}
        />
      )}
    </Card>
  );
};

export const CollegeInformationDetail = ({
  miniItem,
  handleRemoveSchool,
  handleUnSave,
  index,
  item,
  handleCheckedItem,
}: {
  miniItem: TCollege;
  handleRemoveSchool?: any;
  handleUnSave?: any;
  index: number;
  item: any;
  handleCheckedItem?: any;
}) => {
  const cmpsSettingConvert = (str: string | undefined) => {
    let data: any = {
      RURAL: 'Rural',
      SMALL: 'Small Town',
      SUBRB: 'Suburban',
      URBAN: 'Urban',
    };
    return str?.toUpperCase()?.length ? data[str?.toUpperCase()] : '';
  };

  const type_of_school_convert = (str: string | undefined) => {
    let data: any = {
      G: 'Public',
      GCI: 'Public',
      GCO: 'Public',
      GCW: 'Public',
      GDI: 'Public',
      GF: 'Public',
      GP: 'Public',
      GS: 'Public',
      GSL: 'Public',
      GSR: 'Public',
      GT: 'Public',
      P: 'Private',
      PN: 'Private',
      PP: 'Private',
      PR: 'Private',
    };
    return str?.toUpperCase()?.length ? data[str?.toUpperCase()] : '';
  };
  return (
    <CardConfig
      headerElement={
        <Grid
          container
          className="w-100"
          direction="row"
          justifyContent="space-between"
          alignItems={'center'}
        >
          <Grid item md={6} className="px-2">
            {miniItem.name}
          </Grid>
          <Grid
            item
            md={6}
            className="px-2"
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <span>{[miniItem.city, miniItem.state].join(', ')}</span>
            {handleRemoveSchool && (
              <Button
                variant="outline-danger"
                onClick={() => handleRemoveSchool(miniItem, item.name)}
              >
                Remove
              </Button>
            )}
            {/* {handleUnSave && (
              <Button variant="outline-danger" onClick={() => handleUnSave(miniItem.name)}>
                Remove
              </Button>
            )} */}
          </Grid>
        </Grid>
      }
      handleRemove={handleUnSave ? () => handleUnSave(miniItem.name) : undefined}
    >
      <Row>
        {buildLineRow([
          miniItem.address_line_1,
          <a target="_blank" rel="noreferrer" href={miniItem.url_address}>
            {miniItem.url_address}
          </a>,
        ])}
        {buildLineRow([
          [miniItem.city, miniItem.state, miniItem.zip_code.toString().padStart(5, '0')]
            .filter((i) => !!i)
            .join(', '),
          <div>
            {miniItem.url_price_calc ? (
              <a target="_blank" rel="noreferrer" href={miniItem.url_price_calc}>
                Net Price Calculator
              </a>
            ) : (
              'Net Price Calculator Link: N/A'
            )}
          </div>,
        ])}
        {buildLineRow([
          [
            miniItem.year_type,
            type_of_school_convert(miniItem?.type_of_school),
            miniItem?.size_of_school,
            cmpsSettingConvert(miniItem?.cmps_setting),
          ].join(' - '),
          <div>
            <div>Counselor Ranking</div>
            <div>{miniItem.counselor_ranking ?? 'N/A'}</div>
          </div>,
        ])}
        {buildLineRow([
          <>
            GPA Range: {miniItem?.gpa ?? 'N/A'} - Weighted{' '}
            <FormControlLabel control={<Checkbox checked={!!miniItem.w_gpa} />} label="" />
          </>,
          '',
        ])}
        {buildLineRow([`SAT Range: ${miniItem?.sat ?? 'N/A'}`, ''])}
        {buildLineRow([
          `ACT Average: ${miniItem?.act ?? 'N/A'}`,
          <FormControlLabel
            control={
              <Checkbox
                checked={!!miniItem.is_saved}
                onChange={(e) =>
                  handleCheckedItem &&
                  handleCheckedItem({ ...miniItem, is_saved: e.target.checked }, index, item.name)
                }
              />
            }
            label="Save to My Colleges"
          />,
        ])}
        {/* {buildLineRow([
          '<Admission Rate>',

          <FormControlLabel
            control={
              <Checkbox
                checked={!!miniItem.is_saved}
                onChange={(e) =>
                  handleCheckedItem &&
                  handleCheckedItem({ ...miniItem, is_saved: e.target.checked }, index, item.name)
                }
              />
            }
            label="Save to My Colleges"
          />,
        ])} */}
      </Row>
    </CardConfig>
  );
};

export default CollegeSearch;
