import { useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Row, Col, Image } from 'react-bootstrap';
import Stack from '@mui/material/Stack';
import PaymentServices from 'services/payment';
import { routers } from 'router/router';
import { useHistory } from 'react-router';
import ReuseLoginLayout from 'components/layout/ReuseLoginLayout';
import SelectInput from 'components/input/SelectInput';
import Cookies from 'js-cookie';
import { authenticateSettings } from 'components/helper/appConfig';
import CheckIcon from 'components/icons/CheckIcon';

interface Props {}

const SuccessPage = (props: Props) => {
  let history = useHistory();
  const [session, setSession] = useState({});
  const location = useLocation();
  const sessionId = location.search.replace('?session_id=', '');

  useEffect(() => {   
    Cookies.set(authenticateSettings.tokenName, ''); // force clear token
    async function fetchSession() {
      const session = await PaymentServices.checkoutStripSession(sessionId);
      setSession(session);
    }
    fetchSession();
  }, [sessionId]);

 
  return (
    <ReuseLoginLayout>
      <div className="d-flex mx-3 flex-wrap pb-md-5 mb-md-5 justify-content-between align-items-center">
        <span>
          <SelectInput
            values={'English (USA)'}
            borderless
            mdCol={'12'}
            option={['English (USA)']}
          />
        </span>
        <span className="mt-2">
          <span> Already a member ?</span>
          <Button
            className="p-0 mx-1 pb-1"
            onClick={() => history.push(routers.login.path)}
            variant="link"
          >
            Login
          </Button>
        </span>
      </div>
      <Row className="p-0 m-0">
        <Col xs={12} md={8} className="p-0 text-center">
          <div className="PaymentSuccess-iconContainer flex-item width-grow mb-3">
            <Image src={'/assets/images/confirm-check.png'} height={'80px'}/>
          </div>

          <h2 className="m-3 mt-4">Your payment has successfully been processed.</h2>

          <div className="m-3">
            <h5>Please log into your account.</h5>
          </div>

          <Button
              className="w-25 p-3 m-3"
              variant="primary"
              onClick={() => history.push(routers.login.path)}
            >
              <span className="h5 fw-bold">Login</span>
            </Button>
        </Col>
      </Row>     
    </ReuseLoginLayout>
  );

};

export default SuccessPage;
