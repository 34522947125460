// import { primaryTribalAffiliationOption } from 'components/helper/appConfig';
import CheckboxInput from 'components/input/CheckboxInput';
// import GroupCheckbox from 'components/input/GroupCheckbox';
import GroupRadioButton from 'components/input/GroupRadioButton';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import YesNoSwitch from 'components/input/YesNoSwitch';
import { Row, Col, Card } from 'react-bootstrap';
// import { AlaskaNativeOption } from './components/constantDemographic';
import GroupCheckbox from 'components/input/GroupCheckbox';
import { primaryTribalAffiliationOption } from 'components/helper/appConfig';

interface Props {
  formik: any;
}

export const nativeHawaiianOptions = [
  {
    value: 'best_group_describe_racial_background_array.racial_background_Carolinian',
    label: 'Carolinian',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_Chuukese',
    label: 'Chuukese',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_Fijian',
    label: 'Fijian',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_Guam',
    label: 'Guamanian / Chamorro',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_IKiribati',
    label: 'I-Kiribati',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_Kosraean',
    label: 'Kosraean',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_MarianaIslander',
    label: 'Mariana Islander',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_Marshallese',
    label: 'Marshallese',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_Hawaii',
    label: 'Native Hawaiian',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_NiVanuatu',
    label: 'Ni-Vanuatu',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_Palauan',
    label: 'Palauan',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_PapuaNewGuinean',
    label: 'Papua New Guinean',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_Pohnpeian',
    label: 'Pohnpeian',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_Saipanese',
    label: 'Saipanese',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_Samoa',
    label: 'Samoan',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_SolomonIslander',
    label: 'Solomon Islander',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_Tahitian',
    label: 'Tahitian',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_Tokelauan',
    label: 'Tokelauan',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_Tongan',
    label: 'Tongan',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_Yapese',
    label: 'Yapese',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_OtherMelanesian',
    label: 'Other Melanesian',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_OtherMicronesian',
    label: 'Other Micronesian',
  },
  {
    value: 'best_group_describe_racial_background_array.racial_background_OtherPacific',
    label: 'Other Pacific Islander',
  },

  {
    value: 'best_group_describe_racial_background_array.racial_background_OtherPolynesian',
    label: 'Other Polynesian',
  },
];

export const asianOptions = [
  {
    key: 'best_group_describe_racial_background_array.racial_background_AsianIndian',
    value: 'Asian Indian',
  },
  {
    key: 'best_group_describe_racial_background_array.racial_background_Bangladeshi',
    value: 'Bangladeshi',
  },

  {
    key: 'best_group_describe_racial_background_array.racial_background_Bhutanese',
    value: 'Bhutanese',
  },

  {
    key: 'best_group_describe_racial_background_array.racial_background_Burmese',
    value: 'Burmese',
  },
  {
    key: 'best_group_describe_racial_background_array.racial_background_Cambodian',
    value: 'Cambodian',
  },
  {
    key: 'best_group_describe_racial_background_array.racial_background_Chinese',
    value: 'Chinese, except Taiwanese',
  },
  {
    key: 'best_group_describe_racial_background_array.racial_background_Filipino',
    value: 'Filipino',
  },
  { key: 'best_group_describe_racial_background_array.racial_background_Hmong', value: 'Hmong' },

  {
    key: 'best_group_describe_racial_background_array.racial_background_IndoChinese',
    value: 'Indo Chinese',
  },
  {
    key: 'best_group_describe_racial_background_array.racial_background_Indonesian',
    value: 'Indonesian',
  },
  {
    key: 'best_group_describe_racial_background_array.racial_background_IwoJiman',
    value: 'Iwo Jiman',
  },
  {
    key: 'best_group_describe_racial_background_array.racial_background_Japanese',
    value: 'Japanese',
  },
  { key: 'best_group_describe_racial_background_array.racial_background_Korean', value: 'Korean' },
  {
    key: 'best_group_describe_racial_background_array.racial_background_Laotian',
    value: 'Laotian',
  },
  {
    key: 'best_group_describe_racial_background_array.racial_background_Malaysian',
    value: 'Malaysian',
  },

  {
    key: 'best_group_describe_racial_background_array.racial_background_Maldivian',
    value: 'Maldivian',
  },

  {
    key: 'best_group_describe_racial_background_array.racial_background_Nepalese',
    value: 'Nepalese',
  },
  {
    key: 'best_group_describe_racial_background_array.racial_background_Okinawan',
    value: 'Okinawan',
  },
  {
    key: 'best_group_describe_racial_background_array.racial_background_Pakistani',
    value: 'Pakistani',
  },
  {
    key: 'best_group_describe_racial_background_array.racial_background_Singaporean',
    value: 'Singaporean',
  },
  {
    key: 'best_group_describe_racial_background_array.racial_background_SriLankan',
    value: 'Sri Lankan',
  },
  {
    key: 'best_group_describe_racial_background_array.racial_background_Taiwanese',
    value: 'Taiwanese',
  },
  { key: 'best_group_describe_racial_background_array.racial_background_Thai', value: 'Thai' },
  {
    key: 'best_group_describe_racial_background_array.racial_background_Vietnamese',
    value: 'Vietnamese',
  },
  {
    key: 'best_group_describe_racial_background_array.racial_background_OtherAsian',
    value: 'Other Asian',
  },
];
export const swAsianAndNorthAfricanOptions = [
  {
    key: 'best_group_describe_racial_background_array.racial_background_EuropeanDescent',
    value: 'European',
  },
  {
    key: 'best_group_describe_racial_background_array.racial_background_OtherWhite',
    value: 'Other White',
  },
];
export const backgroundDescribeOption = [
  { id: 'best_group_describe_racial_background_array.is_Argentinean', value: 'Argentinean' },
  { id: 'best_group_describe_racial_background_array.is_Bolivian', value: 'Bolivian' },
  { id: 'best_group_describe_racial_background_array.is_Chilean', value: 'Chilean' },
  { id: 'best_group_describe_racial_background_array.is_CostaRican', value: 'Costa Rican' },
  { id: 'best_group_describe_racial_background_array.is_Colombian', value: 'Colombian' },
  { id: 'best_group_describe_racial_background_array.is_Cuban', value: 'Cuban' },
  { id: 'best_group_describe_racial_background_array.is_Dominican', value: 'Dominican (Republic)' },
  { id: 'best_group_describe_racial_background_array.is_Ecuadorian', value: 'Ecuadorian' },
  { id: 'best_group_describe_racial_background_array.is_Guatemalan', value: 'Guatemalan' },
  { id: 'best_group_describe_racial_background_array.is_Honduran', value: 'Honduran' },
  { id: 'best_group_describe_racial_background_array.is_Mexican', value: 'Mexican' },
  { id: 'best_group_describe_racial_background_array.is_Nicaraguan', value: 'Nicaraguan' },
  { id: 'best_group_describe_racial_background_array.is_Panamanian', value: 'Panamanian' },
  { id: 'best_group_describe_racial_background_array.is_Paraguayan', value: 'Paraguayan' },
  { id: 'best_group_describe_racial_background_array.is_Peruvian', value: 'Peruvian' },
  { id: 'best_group_describe_racial_background_array.is_Puerto', value: 'Puerto Rican' },
  { id: 'best_group_describe_racial_background_array.is_Salvadoran', value: 'Salvadoran' },
  { id: 'best_group_describe_racial_background_array.is_Spaniard', value: 'Spaniard' },
  { id: 'best_group_describe_racial_background_array.is_Uruguayan', value: 'Uruguayan' },
  { id: 'best_group_describe_racial_background_array.is_Venezuelan', value: 'Venezuelan' },
  {
    id: 'best_group_describe_racial_background_array.is_Other_Central_American',
    value: 'Other Central American',
  },
  {
    id: 'best_group_describe_racial_background_array.is_Other_South_American',
    value: 'Other South American',
  },
  {
    id: 'best_group_describe_racial_background_array.is_Other_Hispanic_or_Latino',
    value: 'Other Hispanic or Latino',
  },
];
const Ethnicity = (props: Props) => {
  return (
    <Row className="m-0">
      <div className="fs-5 p-1" style={{ marginLeft: '-4px' }}>
        Ethnicity
      </div>
      <div className="text-field-light">
        To help us understand the diverse racial and ethnic backgrounds of our students, please tell
        us which of the following groups best describes your background. Select all categories that
        apply to you
      </div>
      <div className="my-1 mt-3  uc-flag">Do you consider yourself Hispanic or Latino?</div>

      <YesNoSwitch
        name={'is_consider_hispanic_latino'}
        values={props.formik.values.is_consider_hispanic_latino}
        onChange={props.formik.handleChange}
        label="Includes persons of Cuban, Mexican, Puerto Rican, South or Central American or other Spanish culture or origin."
      />

      {props.formik.values.is_consider_hispanic_latino === 'true' && (
        <>
          <GroupRadioButton
            label="Which best describes your Hispanic or Latino background?"
            logo="span-csu-logo"
            name={'best_group_describe_racial_background_array.value_consider_hispanic_latino'}
            values={
              props.formik.values.best_group_describe_racial_background_array
                ?.value_consider_hispanic_latino
            }
            onChange={props.formik.handleChange}
            options={backgroundDescribeOption.map((i) => i.value)}
          />
          <TextInput
            logo="span-csu-logo"
            hidden={
              props.formik.values.best_group_describe_racial_background_array
                .value_consider_hispanic_latino !== 'Other Central American'
            }
            mdCol={'12'}
            label={'Please specify Other Central American:'}
            name={'best_group_describe_racial_background_array.specify_is_Other_Central_American'}
            values={
              props.formik.values.best_group_describe_racial_background_array
                ?.specify_is_Other_Central_American
            }
            onChange={props.formik.handleChange}
            countText={50}
          />
          <TextInput
            logo="span-csu-logo"
            hidden={
              props.formik.values.best_group_describe_racial_background_array
                .value_consider_hispanic_latino !== 'Other South American'
            }
            mdCol={'12'}
            label={'Please specify Other South American:'}
            name={'best_group_describe_racial_background_array.specify_is_Other_South_American'}
            values={
              props.formik.values.best_group_describe_racial_background_array
                ?.specify_is_Other_South_American
            }
            onChange={props.formik.handleChange}
            countText={50}
          />
          <TextInput
            logo="span-csu-logo"
            hidden={
              props.formik.values.best_group_describe_racial_background_array
                .value_consider_hispanic_latino !== 'Other Hispanic or Latino'
            }
            mdCol={'12'}
            label={'Please specify Other Hispanic or Latino:'}
            name={'best_group_describe_racial_background_array.specify_is_Other_Hispanic_or_Latino'}
            values={
              props.formik.values.best_group_describe_racial_background_array
                ?.specify_is_Other_Hispanic_or_Latino
            }
            onChange={props.formik.handleChange}
            countText={50}
          />
        </>
      )}

      <GroupCheckbox
        label="Regardless of your answer to the prior question, please indicate how you identify yourself."
        logo="span-csu-logo"
        form={props.formik}
        options={[
          {
            mdCol: 6,
            value:
              'best_group_describe_racial_background_array.racial_background_identify_AfricanAmerican',
            label: 'Black or African American',
          },
          {
            mdCol: 6,
            value: 'best_group_describe_racial_background_array.racial_background_identify_White',
            label: 'White',
          },
          {
            mdCol: 6,
            value: 'best_group_describe_racial_background_array.racial_background_identify_Asian',
            label: 'Asian',
          },
          {
            mdCol: 6,
            value:
              'best_group_describe_racial_background_array.racial_background_identify_Southwest_Asian_and_North_African',
            label: 'Southwest Asian and North African',
          },
          {
            mdCol: 6,
            value:
              'best_group_describe_racial_background_array.racial_background_identify_NativeHawaiian',
            label: 'Native Hawaiian or Other Pacific Islander',
          },
          {
            mdCol: 6,
            value:
              'best_group_describe_racial_background_array.racial_background_identify_AmericanIndian',
            label: 'American Indian or Alaska Native',
          },
        ]}
      />

      {/* If American Indian or Alaska Native then show following content */}
      {props.formik.values.best_group_describe_racial_background_array
        ?.racial_background_identify_AmericanIndian && (
        <Card className="my-3 bg-light p-3">
          <YesNoSwitch
            label="Are you an enrolled member of an American Indian or Alaska Native tribe (U.S.)?"
            name="best_group_describe_racial_background_array.is_enrolled_member_of_American_Indian_or_Alaska_Native"
            onChange={props.formik.handleChange}
            values={
              props.formik.values.best_group_describe_racial_background_array
                ?.is_enrolled_member_of_American_Indian_or_Alaska_Native
            }
          />
          {props.formik.values.best_group_describe_racial_background_array
            ?.is_enrolled_member_of_American_Indian_or_Alaska_Native === 'true' && (
            <>
              <GroupRadioButton
                mdCol={12}
                label="Please select the category that best reflects your tribal enrollment. If you are an enrolled member of more than one tribe, select your primary tribal affiliation from the list of recognized tribes.*"
                options={[
                  'Federally recognized tribe',
                  'State recognized tribe (not in California)',
                  'California non-federally recognized tribe',
                ]}
                name="best_group_describe_racial_background_array.tribal_enrollment_category"
                onChange={props.formik.handleChange}
                values={
                  props.formik.values.best_group_describe_racial_background_array
                    ?.tribal_enrollment_category
                }
              />
              <TextInput
                hidden={
                  props.formik.values.best_group_describe_racial_background_array
                    .tribal_enrollment_category !== 'Federally recognized tribe'
                }
                label="Please select your primary tribal affiliation*"
                name="best_group_describe_racial_background_array.tribal_enrollment_primary"
                onChange={props.formik.handleChange}
                values={
                  props.formik.values.best_group_describe_racial_background_array
                    ?.tribal_enrollment_primary
                }
              />
              <TextInput
                name="best_group_describe_racial_background_array.tribal_citizenship_or_enrollment_number"
                onChange={props.formik.handleChange}
                values={
                  props.formik.values.best_group_describe_racial_background_array
                    ?.tribal_citizenship_or_enrollment_number
                }
                label="Please provide your tribal citizenship or enrollment number."
                countText={20}
              />
            </>
          )}

          <div className="text-mute">
            Your enrollment in an American Indian or Alaska Native tribe may be considered during
            the comprehensive review of your UC application for admission.
          </div>
          <CheckboxInput
            name="best_group_describe_racial_background_array.is_consent_to_the_use_of_tribal_citizenship_or_enrollment_information"
            label="I consent to the use of my tribal citizenship and enrollment status for purposes concerning my UC application for admission. I understand that this information is subject to verification."
            values={
              props.formik.values.best_group_describe_racial_background_array
                ?.is_consent_to_the_use_of_tribal_citizenship_or_enrollment_information
            }
            onChange={(e) =>
              props.formik.setFieldValue(
                'best_group_describe_racial_background_array.is_consent_to_the_use_of_tribal_citizenship_or_enrollment_information',
                e.target.checked
              )
            }
          />
          <TextInput
            hidden={
              props.formik.values.best_group_describe_racial_background_array
                .tribal_enrollment_category !== 'State recognized tribe (not in California)'
            }
            label="Please select your primary tribal affiliation*"
            name="best_group_describe_racial_background_array.tribal_enrollment_primary"
            onChange={props.formik.handleChange}
            values={
              props.formik.values.best_group_describe_racial_background_array
                ?.tribal_enrollment_primary
            }
          />
          <TextInput
            hidden={
              props.formik.values.best_group_describe_racial_background_array
                .tribal_enrollment_category !== 'California non-federally recognized tribe'
            }
            label="Please select your primary tribal affiliation*"
            name="best_group_describe_racial_background_array.tribal_enrollment_primary"
            onChange={props.formik.handleChange}
            values={
              props.formik.values.best_group_describe_racial_background_array
                ?.tribal_enrollment_primary
            }
          />
          <SelectInput
            logo="span-csu-logo"
            option={[
              'Achomawi/Achumawi',
              'Acoma Pueblo',
              'Apache',
              'Blackfeet',
              'Cahto',
              'Cahuilla',
              'Chemehuevi',
              'Cherokee',
              'Cheyenne',
              'Chippewa/ Ojibwa',
              'Choctaw',
              'Chumash',
              'Comanche',
              'Costanoan/Ohlone',
              'Creek/ Muskogee',
              'Cupeno',
              'Gabrielino/Tongva',
              'Hopi Pueblo',
              'Hupa/Hoopa',
              'Iroquois',
              'Karuk',
              'Kumeyaay',
              'Laguna Pueblo',
              'Luiseno',
              'Maidu',
              'Miwok',
              'Mojave',
              `Navajo – Dine’`,
              'Nomlaki',
              'Ohlone',
              'Osage',
              'Paiute',
              'Pit River',
              'Pomo',
              'Quechan',
              'Sauk and Fox',
              'Serrano',
              'Shasta',
              'Shoshone',
              'Sioux',
              'Tolowa',
              'Wailaki',
              'Wappo',
              'Washoe',
              'Western Mono',
              'Wintun',
              'Wiyot',
              'Yokuts',
              'Yuki',
              'Yurok',
              'Zuni Pueblo',
              'Indigenous Central American',
              'Indigenous Mexican',
              'Indigenous South American',
              'Other Alaska Native Tribe',
              'Other American Indian Tribes',
              ...primaryTribalAffiliationOption,
            ]}
            label="Please choose the tribe you are enrolled in"
            values={
              props.formik.values.best_group_describe_racial_background_array?.csu_tribe_enrolled ??
              ''
            }
            onChange={props.formik.handleChange}
            name="best_group_describe_racial_background_array.csu_tribe_enrolled"
          />
        </Card>
      )}
      {/* If Asian then show following content */}
      {props.formik.values.best_group_describe_racial_background_array
        ?.racial_background_identify_Asian && (
        <Card className="my-3 bg-light p-3">
          <GroupRadioButton
            options={asianOptions.map((option) => option.value)}
            logo="span-csu-logo"
            label="Which best describes your Asian background?"
            values={
              props.formik.values.best_group_describe_racial_background_array
                ?.racial_background_Asian
            }
            onChange={props.formik.handleChange}
            name="best_group_describe_racial_background_array.racial_background_Asian"
          />

          {props.formik.values.best_group_describe_racial_background_array
            ?.racial_background_Asian === 'Other Asian' && (
            <TextInput
              label="Specify other Asian background"
              mdCol="12"
              name="best_group_describe_racial_background_array.racial_OtherAsian_value"
              values={
                props.formik.values.best_group_describe_racial_background_array
                  ?.racial_OtherAsian_value
              }
              onChange={props.formik.handleChange}
              countText={50}
            />
          )}
        </Card>
      )}

      {/* If Black or African American is selected */}
      {props.formik.values.best_group_describe_racial_background_array
        ?.racial_background_identify_AfricanAmerican && (
        <Card className="my-3 bg-light p-3">
          <GroupRadioButton
            options={['African', 'U.S. / African American', 'Caribbean', 'Other']}
            logo="span-csu-logo"
            label="What best describes your Black or African American background?"
            values={
              props.formik.values.best_group_describe_racial_background_array
                ?.racial_background_African
            }
            onChange={props.formik.handleChange}
            name="best_group_describe_racial_background_array.racial_background_African"
          />
          {props.formik.values.best_group_describe_racial_background_array
            ?.racial_background_African === 'Other' && (
            <TextInput
              label="Describe other*"
              mdCol="12"
              name="best_group_describe_racial_background_array.racial_OtherAfrican_value"
              values={
                props.formik.values.best_group_describe_racial_background_array
                  ?.racial_OtherAfrican_value
              }
              onChange={props.formik.handleChange}
              countText={50}
            />
          )}
        </Card>
      )}
      {/* If Native Hawaiian or Other Pacific Islander is seleted */}
      {props.formik.values.best_group_describe_racial_background_array
        ?.racial_background_identify_NativeHawaiian && (
        <Card className="my-3 bg-light p-3">
          <GroupRadioButton
            options={nativeHawaiianOptions.map((option) => option.label)}
            logo="span-csu-logo"
            label="Which best describes your Native Hawaiian or Other Pacific Islander background?"
            // label="Which best describes your Southwest Asian or North African background?"Usernap 766
            values={
              props.formik.values.best_group_describe_racial_background_array
                ?.racial_background_NativeHawaiian
            }
            onChange={props.formik.handleChange}
            name="best_group_describe_racial_background_array.racial_background_NativeHawaiian"
          />
          {(
            props.formik.values.best_group_describe_racial_background_array
              ?.racial_background_NativeHawaiian ?? ''
          )?.includes('Other') && (
            <TextInput
              label="Please Specify Your Other Background"
              mdCol="12"
              name="best_group_describe_racial_background_array.racial_OtherPacific_value"
              values={
                props.formik.values.best_group_describe_racial_background_array
                  ?.racial_OtherPacific_value
              }
              onChange={props.formik.handleChange}
              countText={100}
            />
          )}
        </Card>
      )}
      {/* If White is selected */}
      {props.formik.values.best_group_describe_racial_background_array
        ?.racial_background_identify_White && (
        <Card className="my-3 bg-light p-3">
          <GroupRadioButton
            options={swAsianAndNorthAfricanOptions.map((option) => option.value)}
            logo="span-csu-logo"
            label="Which best describes your White background?"
            values={
              props.formik.values.best_group_describe_racial_background_array
                ?.racial_background_White
            }
            onChange={props.formik.handleChange}
            name="best_group_describe_racial_background_array.racial_background_White"
          />

          {props.formik.values.best_group_describe_racial_background_array
            ?.racial_background_White === 'Other White' && (
            <TextInput
              label="Please Specify Your Other Background"
              mdCol="12"
              name="best_group_describe_racial_background_array.racial_OtherWhite_value"
              values={
                props.formik.values.best_group_describe_racial_background_array
                  ?.racial_OtherWhite_value
              }
              onChange={props.formik.handleChange}
              countText={50}
            />
          )}
        </Card>
      )}
      {/* If _Southwest_Asian_and_North_African is selected */}
      {props.formik.values.best_group_describe_racial_background_array
        ?.racial_background_identify_Southwest_Asian_and_North_African && (
        <Card className="my-3 bg-light p-3">
          <GroupRadioButton
            options={[
              'Afghan',
              'Algerian',
              'Amazigh',
              'Armenian',
              'Assyrian / Chaldean',
              'Azerbaijani',
              'Bahraini',
              'Circassian',
              'Cypriot',
              'Djiboutian',
              'Egyptian',
              'Emirati',
              'Georgian',
              'Iranian',
              'Iraqi',
              'Israeli',
              'Jordanian',
              'Kurdish',
              'Kuwaiti',
              'Lebanese',
              'Libyan',
              'Mauritanian',
              'Moroccan',
              'Omani',
              'Palestinian',
              'Pontic Greek',
              'Qatari',
              'Saudi Arabian',
              'Sudanese',
              'Syrian',
              'Tunisian',
              'Turkish',
              'Uzbek',
              'Yemeni',
              'Other Southwest Asian',
              'Other North African',
            ]}
            label="Which best describes your Southwest Asian or North African background?"
            mdCol={4}
            name="best_group_describe_racial_background_array.racial_Southwest_Asian_and_North_African"
            values={
              props.formik.values.best_group_describe_racial_background_array
                ?.racial_Southwest_Asian_and_North_African
            }
            logo="span-csu-logo"
            onChange={props.formik.handleChange}
          />
          <TextInput
            hidden={
              !(
                props.formik.values.best_group_describe_racial_background_array
                  ?.racial_Southwest_Asian_and_North_African ?? ''
              ).includes('Other')
            }
            label="Please Specify:"
            values={
              props.formik.values.best_group_describe_racial_background_array
                ?.racial_Southwest_Asian_and_North_African_other
            }
            name="best_group_describe_racial_background_array.racial_Southwest_Asian_and_North_African_other"
            onChange={props.formik.handleChange}
          />
        </Card>
      )}

      <Col xs={12} className="common-flag">
        <Card
          className="my-3 p-4 position-relative"
          style={{ backgroundColor: '#AAEBF7', borderColor: '#AAEBF7' }}
        >
          <SelectInput
            mdCol="12"
            option={[
              'Select',
              'American Indian or Alaska Native',
              'Asian',
              'Black or African American',
              'Hispanic or Latino',
              'Native Hawaiian or other Pacific Islander',
              'White',
              'Two or more races/ethnicities',
              'Decline to state',
            ]}
            logo="span-csu-logo"
            name={'best_group_describe_racial_background_array.csu_reporting_preferences'}
            values={
              props.formik.values.best_group_describe_racial_background_array
                ?.csu_reporting_preferences
            }
            onChange={props.formik.handleChange}
            label="California State University often needs to report ONLY ONE summary race/ethnicity description for a person. Please select your reporting preferences:"
          />
          <div className="my-2">
            The questions in this section, while helpful to colleges, are entirely optional, and
            you're welcome to move on without answering them. Before you do, please confirm for us
            that you've completed this section to your satisfaction.
          </div>
          <CheckboxInput
            label={
              <span className="ms-1 text-primary">
                Yes, I have completed this section to my satisfaction.
              </span>
            }
            name={'is_complete_satisfaction'}
            values={props.formik.values.is_complete_satisfaction}
            onChange={props.formik.handleChange}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Ethnicity;
