/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { getGradeAndCourse, updateGradeAndCourse } from 'app/course/courseSlice';
import CheckboxInput from 'components/input/CheckboxInput';
import CourseConfig from '../CourseConfig';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { HtmlTooltip } from 'screen/selectCollege/SelecCollegePage';
import CourseWork from '../generalInfo/components/CourseWork';
import { checkEnableParseJSON, setArrayLength } from 'components/helper/helper';
import { debounce } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { reorder } from 'components/helper/helper';
import { getHighSchoolData } from 'app/highSchoolSlice/highSchoolSlice';
import GroupRadioButton from 'components/input/GroupRadioButton';

interface Props {}

const TwelvethGrade = (props: Props) => {
  const MAX_COURSES = 15;
  const MAX_SUBJECTS = 15;

  const [courseWorkData, setCourseWorkData] = React.useState<any[]>([]);
  // const [currentSavingCardStatus, setCurrentSavingCardStatus] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const course = useAppSelector((store) => store.course);
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const [courseData, setCourseData] = React.useState<any[]>([]);
  const formik = useFormik({
    initialValues: {
      grade_12_course_array: '',
      is_reported_all_grade_12: false,
      number_of_course_to_report: 0,
      course_scheduling_system_is_using: '',
      is_fields_enter_your_current_year_course: false,

      course_title: {
        is_indicated_all_course: false,
      },
    },
    onSubmit: async (values) => {
      studentId = Cookies.get('userId');
      token = Cookies.get(authenticateSettings.tokenName);
      if (token && studentId) {
        try {
          const newValues = {
            ...values,
            student_id: studentId,
            grade_12_course_array: JSON.stringify(courseData),
            course_array: JSON.stringify(courseWorkData),
            course_title: JSON.stringify(values.course_title),
          };
    
          await dispatch(updateGradeAndCourse(newValues)).unwrap();
        }
        catch(ex){
          console.log('Error: ', ex);
        }
      }
      else {
        handleLogout();
      }
    },
  });
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.collegeCourse.path);
  };

  React.useEffect(() => {
    setCourseWorkData(
      setArrayLength(courseWorkData || [], formik.values.number_of_course_to_report)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.number_of_course_to_report]);
  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      dispatch(getHighSchoolData(studentId));
      const res = await dispatch(getGradeAndCourse(studentId));
      const dataLoaded = res.payload;
      if (dataLoaded) {
        formik.setValues({
          ...dataLoaded,
          is_reported_all_grade_12: dataLoaded.is_reported_all_grade_12,
          course_title: checkEnableParseJSON(dataLoaded.course_title, {}),
        });
        setCourseData(JSON.parse(dataLoaded.grade_12_course_array) || []);
        setCourseWorkData(JSON.parse(dataLoaded.course_array));
      }
      setLoading(false);
      formik.setStatus('idle');      
    }
    else {
      handleLogout();
    }
  };
  React.useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddCourse = () => {
    if (courseData.length > MAX_COURSES - 1) return;
    setCourseData([...courseData, {}]);
  };
  const handleSetCurrentCourse = (e: boolean) => {
    if (e) {
      const newCourseWorkData: any[] = [];
      courseData.forEach((item) => {
        if (item.subjectDetails.length) {
          item.subjectDetails.forEach((sub: any) => {
            let scheduleKey = item.schedule === 'Quarters' ? 'Quarter' : item.schedule;
            const schedule = [
              sub.isFirstSemester ? `First ${scheduleKey}` : null,
              sub.isSecondSemester ? `Second ${scheduleKey}` : null,
              sub.isThirdSemester ? `Third ${scheduleKey}` : null,
              sub.isFourthSemester ? `Fourth ${scheduleKey}` : null,
            ].filter((i) => !!i);
            newCourseWorkData.push({
              course_scheduling_system_is_using: item.schedule,
              title: sub.courseName,
              level: sub.typeOfClass,
              schedule,
              subject: sub.subject,
            });
          });
        }
      });

      formik.setFieldValue('number_of_course_to_report', newCourseWorkData.length);
      setTimeout(() => setCourseWorkData([...newCourseWorkData]), 100);
    }
  };

  // BEGIN - Auto save function ==========
  React.useEffect(() => {
    if (!!formik.status) debounceSaving();
  }, [formik.values, courseData, courseWorkData]);
  const debounceSaving = React.useCallback(
    debounce(() => {
        if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // END -  Auto save function ==========

  const [forceRenderKey, setForceRenderKey] = React.useState(0);
  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const dataList = reorder(courseData, result.source.index, result.destination.index);
    setCourseData([...dataList]);
    setForceRenderKey(forceRenderKey + 1);
  };
  const onDragEndForCourseWork = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const dataList = reorder(courseWorkData, result.source.index, result.destination.index);
    setCourseWorkData([...dataList]);
    setForceRenderKey(forceRenderKey + 1);
  };

  const handleAddSubject = () => {
    if (courseWorkData.length > MAX_SUBJECTS - 1) return;
    setCourseWorkData([...courseWorkData, {}]);
  };
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[{ percentOnProcess: 30, title: '' }]}
        title="Courses & Grades"
        process={1}
        icon={<StackedLineChartIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="fs-6 text-dark my-2 fw-bold">
                    <InfoOutlinedIcon fontSize="small" className="mx-1" />
                    Courses & Grades
                  </div>
                  <div className="d-flex flex-wrap text-field-light">
                    Many college applications will want you to list your Courses and Grades and/or
                    GPA in various formats. Enter your information as you complete each of your high
                    school courses to make it easy for you during college application season.
                  </div>
                </React.Fragment>
              }
              placement="right-start"
            >
              <Row className="m-0">
                <div className="text-field-mid fs-3 mb-4">12th Grade</div>
                <DragDropContext onDragEnd={onDragEnd} key={forceRenderKey}>
                  <Droppable droppableId="list">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {(courseData || []).map((item, index) => (
                          <div key={index + '-' + courseData.length}>
                            <Draggable draggableId={index + '-' + courseData.length} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    key={index + '-' + courseData.length}
                                    className="position-relative"
                                  >
                                    <CourseConfig
                                      is12
                                      defaultGradeValue="11th Grade"
                                      handleSave={(values) => {
                                        const newCourseData = [...courseData];
                                        newCourseData.splice(index, 1, values);
                                        setCourseData(newCourseData);
                                      }}
                                      item={item}
                                      index={index + 1}
                                      total={courseData.length}
                                    />
                                    <RemoveCircleOutlineIcon
                                      className="position-absolute text-field-light pointer"
                                      style={{ right: '-24px', top: '12px' }}
                                      onClick={() => {
                                        const newCourseData = [...courseData];
                                        newCourseData.splice(index, 1);
                                        setCourseData(newCourseData);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </div>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <div className="my-3 w-100 d-flex flex-column align-items-center">
                  <div className="text-field-light sized70">{`You can add up to ${MAX_COURSES} courses`}</div>
                  <Button variant="primary" className="p-2 px-5" onClick={handleAddCourse}>
                    Add Additional High Schools
                  </Button>
                </div>
                <div className="common-flag py-3">
                  <div className="text-field-light">
                    Please indicate that you have reported all courses and grades for 12th grade.*
                  </div>
                  <CheckboxInput
                    name="is_reported_all_grade_12"
                    values={formik.values.is_reported_all_grade_12}
                    onChange={(e) => {
                      formik.setFieldValue('is_reported_all_grade_12', e.target.checked);
                    }}
                    label="I have reported all of my courses for this grade."
                  />
                  <div className="text-field-light mt-3">
                    Have you indicated all your middle school, post-12th grade, or summer courses?
                  </div>
                  <CheckboxInput
                    name="course_title.is_indicated_all_course"
                    values={formik.values.course_title?.is_indicated_all_course}
                    onChange={(e) => {
                      formik.setFieldValue('course_title.is_indicated_all_course', e.target.checked);
                    }}
                    label="I indicated all my middle school, post-12th grade, or summer courses."
                  />
                </div>
              </Row>
            </HtmlTooltip>
            <div className="text-dark fs-5 p-1 fw-bold  my-3">
              Current or Most Recent Year Courses
            </div>

            <div className="text-dark fs-5 p-1 fw-bold">Coursework</div>
            <GroupRadioButton
              logo="span-cm-logo"
              label="Please select the main course scheduling system your institution is using.*"
              options={['Semester', 'Trimester', 'Quarter', 'Yearly']}
              mdCol={3}
              values={formik.values.course_scheduling_system_is_using}
              onChange={(e: any) => {
                formik.handleChange(e);
              }}
              name="course_scheduling_system_is_using"
            />

            <CheckboxInput
              label="My 12th grade courses are my current courses"
              name="is_fields_enter_your_current_year_course"
              values={formik.values.is_fields_enter_your_current_year_course}
              onChange={(e) => {
                formik.handleChange(e);
                handleSetCurrentCourse(e.target.checked);
              }}
            />

            <div className="text-field-light my-2">
              Please list all courses you are taking this academic year. If you are not currently
              enrolled, please list courses from your most recent academic year.
            </div>

            <Row>
              <DragDropContext onDragEnd={onDragEndForCourseWork} key={forceRenderKey}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {(courseWorkData || []).map((item, index) => (
                        <div key={index + '-' + courseWorkData.length}>
                          <Draggable
                            draggableId={index + '-' + courseWorkData.length}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  key={index + '-' + courseWorkData.length}
                                  className="position-relative"
                                >
                                  <CourseWork
                                    handleSave={(values) => {
                                      const newCourseWorkData = [...courseWorkData];
                                      newCourseWorkData.splice(index, 1, values);
                                      setCourseWorkData(newCourseWorkData);
                                    }}
                                    item={item}
                                    index={index + 1}
                                    total={courseWorkData.length}
                                  />
                                  <RemoveCircleOutlineIcon
                                    className="position-absolute text-field-light pointer"
                                    style={{ right: '-24px', top: '12px' }}
                                    onClick={() => {
                                      const newCourseWorkData = [...courseWorkData];
                                      newCourseWorkData.splice(index, 1);
                                      setCourseWorkData(newCourseWorkData);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </div>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="my-3 w-100 d-flex flex-column align-items-center">
                <div className="text-field-light sized70">{`You can add up to ${MAX_SUBJECTS} subjects`}</div>
                <Button variant="primary" className="p-2 px-5" onClick={handleAddSubject}>
                  Add Subject
                </Button>
              </div>
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {course.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default TwelvethGrade;
