/* eslint-disable no-empty-pattern */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';

import authorizationServices from 'services/authorizationServices';
import { authenticateSettings } from 'components/helper/appConfig';
import { checkEnableParseJSON } from 'components/helper/helper';
import { AxiosError } from 'axios';
// import toast from 'react-hot-toast';
export interface AuthorizationState {
  myAuthorizationEmail: string | undefined;
  verifyCode: string;
  studentId: number | undefined;
  status: 'idle' | 'loading' | 'failed';
  studentAdditionalInfo: any;
}

const initialState: AuthorizationState = {
  myAuthorizationEmail: undefined,
  studentId: 0,
  verifyCode: '',
  status: 'idle',
  studentAdditionalInfo: undefined,
};
export const createStudent = createAsyncThunk(
  'authorization/create-student',
  async (payload: any, {rejectWithValue}) => {
    try {
      const response = await authorizationServices.createStudent(payload);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const sendVerificationCode = createAsyncThunk(
  'authorization/sentVerifyCode',
  async (payload: any, {rejectWithValue}) => {
    try {
      const response = await authorizationServices.sendVerificationCode(payload);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const checkVerificationCode = createAsyncThunk(
  'authorization/checkVerifyCode',
  async (payload: any, {rejectWithValue}) => {
    try {
      const response = await authorizationServices.checkVerificationCode(payload);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const login = createAsyncThunk('authorization/login', async (payload: any, {rejectWithValue}) => {
  try {
    const response = await authorizationServices.login(payload);
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const getStudent = createAsyncThunk('authorization/getStudent', async (payload: any, {rejectWithValue}) => {
  try {
    const response = await authorizationServices.getStudent(payload);
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const sendEmailForgotPassword = createAsyncThunk(
  'authorization/forgotPassword',
  async (payload: any, {rejectWithValue}) => {
    try {
      const response = await authorizationServices.sendEmailForgotPassword(payload);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const checkCodeForgotPassword = createAsyncThunk(
  'authorization/checkForgotPassword',
  async (payload: any, {rejectWithValue}) => {
    try {
      const response = await authorizationServices.checkCodeForgotPassword(payload);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const resetPassword = createAsyncThunk(
  'authorization/resetPassword',
  async (payload: any, {rejectWithValue}) => {
    try {
      const response = await authorizationServices.resetPassword(payload);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    setMyAuthorizationEmail: (state, action: any) => {
      state.myAuthorizationEmail = action.payload;
    },
    setVerifyCodeRedux: (state, action: any) => {
      state.verifyCode = action.payload;
    },
    setStudentId: (state, action: any) => {
      state.studentId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStudent.fulfilled, (state, action) => {
        state.studentAdditionalInfo = checkEnableParseJSON(action.payload?.additional_info);
      })
      // Create Student
      .addCase(createStudent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createStudent.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createStudent.fulfilled, (state, action) => {
        toast.success(action.payload.message);
        state.status = 'idle';
        state.studentId = action.payload.student_id;
      })

      // Send Verification Code
      .addCase(sendVerificationCode.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendVerificationCode.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(sendVerificationCode.fulfilled, (state, action) => {
        toast.success(action.payload.message);
        state.status = 'idle';
      })
      // Check Verification Code
      .addCase(checkVerificationCode.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkVerificationCode.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(checkVerificationCode.fulfilled, (state, action) => {
        toast.success(action.payload.message);
        state.status = 'idle';
      })

      // Login

      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(login.fulfilled, (state, action) => {
        toast.success(action.payload.message);
        state.myAuthorizationEmail = action.payload.email;
        Cookies.set(authenticateSettings.tokenName, action.payload.access_token, { expires: 3 });
        Cookies.set('userId', action.payload.student_id, { expires: 3 });

        state.status = 'idle';
      })

      // Forgot password
      .addCase(sendEmailForgotPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendEmailForgotPassword.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(sendEmailForgotPassword.fulfilled, (state, action) => {
        toast.success(action.payload.message);
        state.status = 'idle';
      })

      // Check code forgot password
      .addCase(checkCodeForgotPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkCodeForgotPassword.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(checkCodeForgotPassword.fulfilled, (state, action) => {
        toast.success(action.payload.message);
        state.status = 'idle';
      })
      // Reset password
      .addCase(resetPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetPassword.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        toast.success(action.payload.message);
        state.status = 'idle';
      });
  },
});

export const { setMyAuthorizationEmail, setVerifyCodeRedux, setStudentId } =
  authorizationSlice.actions;
