import http from './http';

const counselorServices = {
  async getPartner(id: any) {
    const res: any = await http.post(`api/partner/get-all-partners`, {
      is_show_student_information: true,
      partner_id: id,
    });
    const result = res.filter((item: any) => item.requested_status === 'approved');
    return result;
  },

  async getCounselor(id: any): Promise<any> {
    const res: any = await http.post(`api/partner/get-all-partners`, {
      is_show_partner_information: true,
      student_id: parseInt(id),
    });
    const result = res.filter((item: any) => item.requested_status === 'approved');
    return result;
  },

  async getPartnerById(id: any) {
    const res = await http.get(`/api/partner/${id}`);
    return res;
  },
  async updatePartnerById(body: any) {
    const res = await http.put(`/api/partner`, body);
    return res;
  },

  async createPartnerById(body: any) {
    const res = await http.put(`/api/partner`, body);
    return res;
  },

  async sendChatNotesMessage(body: any) {
    const res = await http.post(`/api/partner/send-chat-notes-message`, body);
    return res;
  },

  async getChatNotesMessage(conversationId:string) {
    let body = {
      conversation_id: conversationId
    };
    const res = await http.post(`/api/partner/get-chat-notes-message`, body);
    return res;
  },
  

  //   async getCollege(body: any) {
  //     const requestBody = {
  //       ...body,
  //       limit_item: body.keyword.length < 5 ? 10 : 1000,
  //     };
  //     const res = await http.post('api/us-college-list', requestBody);
  //     return res;
  //   },
  //   async getDetail(studentId: any) {
  //     const res = await http.get(`api/college-information/${studentId}`);
  //     return res;
  //   },
  //   async updateDetail(body: any) {
  //     const res = await http.put(`api/college-information`, body);
  //     return res;
  //   },
  //   async createDetail(body: any) {
  //     const res = await http.post(`api/college-information`, body);
  //     return res;
  //   },
};

export default counselorServices;
