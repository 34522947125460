import GroupRadioButton from 'components/input/GroupRadioButton';
import SelectInput from 'components/input/SelectInput';
import SelectPhoneCodeInput from 'components/input/SelectPhoneCodeInput';
import TextInput from 'components/input/textInput';
import React from 'react';
import TextIconInput from 'components/input/TextIconInput';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { fullSuffixOptions } from 'components/helper/helper';
import { countryOptions } from 'components/helper/countryPhoneCode';
import collegeServices from 'services/collegeServices';
import YesNoSwitch from 'components/input/YesNoSwitch';
import CheckboxInput from 'components/input/CheckboxInput';
import AutocompleteInput from 'components/input/AutoCompleteInput';
// import Button from 'react-bootstrap/esm/Button';

interface Props {
  formik: any;
}

const BasicContact = (props: Props) => {
  // const handleRemoveContact = () => {
  //   props.formik.setFieldValue('phone_number_type', '');
  //   props.formik.setFieldValue('phone_number', '');
  // };
  const [stateOptions, setStateOptions] = React.useState<any[]>([]);
  React.useEffect(() => {
    collegeServices.getState().then((res: any) => {
      const states = res.map((item: any) => {
        return item.descr;
      });
      setStateOptions(states);
    });
  }, []);
  return (
    <>
      <div className="fs-5 p-1">Basic & Contact Information</div>
      <SelectInput
        className="common-flag"
        form={props.formik}
        option={[{ value: 'Dr.' }, { value: 'Mr.' }, { value: 'Ms.' }, { value: 'Mrs.' }]}
        label="Prefix"
        onChange={props.formik.handleChange}
        name="prefix"
        values={props.formik.values.prefix}
      />

      <TextInput
        label="First/Given name"
        name="first_name"
        values={props.formik.values.first_name}
        onChange={props.formik.handleChange}
        countText={20}
      />
      <TextInput
        className="uc-common-flag"
        label="Middle Initial"
        name="middle_name"
        values={props.formik.values.middle_name}
        onChange={props.formik.handleChange}
        countText={20}
      />
      <TextInput
        label="Last Name"
        name="last_name"
        values={props.formik.values.last_name}
        onChange={props.formik.handleChange}
        countText={20}
      />
      <TextInput
        className="common-flag"
        label="Former Last Name"
        name="former_last_name"
        values={props.formik.values.former_last_name}
        onChange={props.formik.handleChange}
        countText={20}
      />

      <SelectInput
        className="common-flag end-flag"
        form={props.formik}
        option={fullSuffixOptions}
        label="Suffix"
        name="suffix"
        values={props.formik.values.suffix}
        onChange={props.formik.handleChange}
      />
      <AutocompleteInput
        className="common-coallition-flag"
        form={props.formik}
        option={countryOptions}
        label="Birth country/region/territory*"
        name="birth_country"
        values={props.formik.values.birth_country}
        feedback={<span className="text-danger">{props.formik.errors.birth_country}</span>}
      />
      <TextInput
        countText={36}
        className="common-coallition-flag end-2-flag"
        label="Email address"
        name="prefered_email"
        values={props.formik.values.prefered_email}
        onChange={props.formik.handleChange}
        feedback={<span className="text-danger">{props.formik.errors.prefered_email}</span>}
      />

      <SelectPhoneCodeInput
        className="coallition-flag"
        mdCol={'3'}
        label="Country Code"
        name="country_code"
        onChange={props.formik.handleChange}
        values={props.formik.values.country_code}
      />
      <TextIconInput
        type="number"
        icon={<PhoneAndroidIcon />}
        name="phone_number"
        onChange={props.formik.handleChange}
        values={props.formik.values.phone_number}
        label="Phone Number"
      />
      <SelectInput
        logo="span-csu-logo"
        label="Type"
        mdCol="3"
        className="common-flag"
        name="phone_number_type"
        onChange={props.formik.handleChange}
        values={props.formik.values.phone_number_type}
        option={['Select', 'Home', 'Mobile', 'Work', 'School']}
      />
      {/* <Button variant="outlined" onClick={handleRemoveContact}>
        Remove
      </Button> */}

      <GroupRadioButton
        label="Parent Address is"
        className="uc-flag mb-5 pt-3 "
        name="parent_address_is"
        onChange={props.formik.handleChange}
        values={props.formik.values.parent_address_is}
        options={[
          { value: 'The same as my home address', mdCol: 4 },
          { value: 'A different address' },
          { value: 'Unknown' },
        ]}
      />
      <div />
      {props.formik.values.parent_address_is === 'A different address' && (
        <>
          <AutocompleteInput
            option={countryOptions}
            label={'Country'}
            name={'different_parent_address_country'}
            values={props.formik.values.different_parent_address_country}
            form={props.formik}
          />
          <TextInput
            label={'Address Line 1'}
            name={'different_parent_address_line_1'}
            values={props.formik.values.different_parent_address_line_1}
            onChange={props.formik.handleChange}
            countText={36}
          />
          <TextInput
            label={'Address Line 2'}
            name={'different_parent_address_line_2'}
            values={props.formik.values.different_parent_address_line_2}
            onChange={props.formik.handleChange}
          />
          <TextInput
            label={'City'}
            name={'different_parent_address_city'}
            values={props.formik.values.different_parent_address_city}
            onChange={props.formik.handleChange}
            countText={25}
          />
          {props.formik.values.different_parent_address_country === 'United States of America' ? (
            <SelectInput
              option={stateOptions}
              label={'State'}
              name={'different_parent_address_state'}
              values={props.formik.values.different_parent_address_state}
              onChange={props.formik.handleChange}
            />
          ) : (
            <TextInput
              label={'Province *'}
              name={'different_parent_address_province'}
              values={props.formik.values.different_parent_address_province}
              onChange={props.formik.handleChange}
              countText={80}
            />
          )}

          <TextInput
            countText={11}
            label={'Zip Code'}
            name={'different_parent_address_zip_code'}
            values={props.formik.values.different_parent_address_zip_code}
            onChange={props.formik.handleChange}
          />
        </>
      )}
      <div className="fs-5 p-1">Authorization</div>
      <YesNoSwitch
        logo="span-csu-logo"
        className="py-1"
        values={props.formik.values.csu_info?.is_give_permission_communicate_to}
        onChange={props.formik.handleChange}
        name="csu_info.is_give_permission_communicate_to"
        label="Do you give permission for the admission office to communicate with your Parent/Guardian regarding your application?"
      />
      <YesNoSwitch
        logo="span-csu-logo"
        className="py-3"
        values={props.formik.values.csu_info?.is_give_permission_to_receive_information}
        onChange={props.formik.handleChange}
        name="csu_info.is_give_permission_to_receive_information"
        label="Do you give permission for your parent/guardian to receive university communications? These may include new student and housing information, parent activities, volunteer and charitable opportunities, and general campus information."
      />

      <YesNoSwitch
        values={props.formik.values.csu_info?.authorize_parent_guardian_as_emergency_contact}
        onChange={props.formik.handleChange}
        className="py-3"
        logo="span-csu-logo"
        name="csu_info.authorize_parent_guardian_as_emergency_contact"
        label="I authorize the use of this parent/guardian information to be used as my emergency contact when I enroll at a CSU."
      />

      <div className="px-1 py-1">
        <div className="d-flex flex-column">
          <label className="label-14px pb-1 span-uc-logo">
            Can we share your parent's contact information with UC campuses and programs?
          </label>
          <div className="py-1">
            If you check this option, your parent may receive information about admissions tips,
            updates and reminders from UC campuses (even ones you haven't applied to) and outreach
            programs. They may contact them before or after you've submitted the application.
          </div>
        </div>
      </div>
      <CheckboxInput
        setValue={(e) =>
          props.formik.setFieldValue('csu_info.is_authorized_UC_to_share_contact_information', e)
        }
        values={props.formik.values.csu_info?.is_authorized_UC_to_share_contact_information}
        onChange={props.formik.handleChange}
        name="csu_info.is_authorized_UC_to_share_contact_information"
        label="I authorize the University of California to share my parent's contact information."
      />
    </>
  );
};

export default BasicContact;
