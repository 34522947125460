/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import SchoolConfig from './SchoolConfig';
import { Button } from 'react-bootstrap';
import { reorder } from 'components/helper/helper';

interface Props {
  formik: any;
  setOtherSchoolData: (values: any[]) => void;
  otherSchoolData: any[];
  setLoading: (status: boolean) => void;
}

const OtherSecondary = (props: Props) => {
  const [schools, setSchools] = React.useState(props.otherSchoolData ?? []);

  const handleAddSchool = () => {
    props.otherSchoolData.length < 6 && setSchools([...schools, {}]);
  };

  React.useEffect(() => {
    try {
      props.setOtherSchoolData(schools);
    }
    catch(ex){
      console.log('Error:', ex);
    }
  }, [schools]);
  return (
    <>
      <div className="fs-5 py-1">All high schools attending/attended</div>
      <div>
         Add in all the high schools you attended/attended or even take/took courses for credit at.
      </div>
      <div className="common-flag">
        {schools.map((item, index) => {
          return (
            <SchoolConfig
              key={index + 1}
              index={index}
              indexText={`${index + 1}/${schools.length}`}
              handleSave={(values) => {
                try {
                  const idx = (schools ?? []).findIndex(
                    (school) => school.high_school_name === values?.high_school_name
                  );
  
                  if(idx === 0) {
                    window.Intercom('update', {
                      current_high_school_term: values?.classes_schedule,
                    })
                  }

                  const newOtherSchoolData = [...schools].map((school) =>
                    values?.is_graduate_from_this_school === 'true'
                      ? {
                          ...school,
                          is_graduate_from_this_school: 'false',
                          degree: '',
                          degreeFreeText: '',
                          year: null,
                          month: null,
                          day: null,
                        }
                      : school
                  );
                  newOtherSchoolData.splice(index, 1, values);
                  setSchools([...newOtherSchoolData]);
                }
                catch(ex){
                  console.log('Error: ', ex);
                }
              }}
              item={item}
              onRemove={() => {
                const newOtherSchoolData = [...schools];
                newOtherSchoolData.splice(index, 1);
                setSchools(newOtherSchoolData);
              }}
              handleCheckCurrent={(name) => {
                const index = (schools ?? []).findIndex(
                  (school) => school.high_school_name === name
                );
                if (index !== 0) {
                  const dataList = reorder(schools, index, 0);
                  setSchools([...dataList]);
                }

                window.Intercom('update', {
                  current_high_school: schools[0]?.high_school_name,
                })

              }}
            />
          );
        })}
        <div className="my-3 w-100 d-flex flex-column align-items-center">
          <div className="text-field-light sized70">You can add up to 6 other high schools</div>
          <Button variant="primary" className="p-2 px-5" onClick={handleAddSchool}>
            Add additional high schools
          </Button>
        </div>
      </div>
    </>
  );
};

export default OtherSecondary;
