import moment from 'moment';
import { appMenuGroups } from 'router/router';

export const userpilotDomain = `(${window.location?.hostname}/${localStorage.getItem('userRole')})`;
export const createArrayNumber = (numberLength: number) => {
  return Array.from({ length: numberLength }, (_, i) => ({ value: i + 1 }));
};

export const isNotEmpty = (str: String) => {
  return str.length !== 0 && !!str;
};

export const removeDuplicate = (data: any[]) => {
  return Array.from(new Set(data));
};

export const checkDecimalInRange = (num: any) => {
  const numStr = String(num);
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length <= 5;
  }
  return true;
};

export const setArrayLength = (arr: any[], lengthInput: number | string, defaultModal?: any) => {
  const length = Number(lengthInput);
  const data = [...(arr ?? [])].filter((item, index) => index < length);
  if (data.length < length) {
    const newData = [...data, ...Array(length - data.length)].map((item) =>
      !item ? defaultModal ?? {} : item
    );
    return newData;
  }
  return data;
};
export const checkEnableParseJSON: any = (data: string, undefinedReturn?: any) => {
  if (data === undefined || data === null || typeof data !== 'string') {
    return undefinedReturn ?? null;
  } else if (
    data?.includes(`{"`) ||
    data?.includes(`[`) ||
    data?.includes(`[]`) ||
    data?.includes(`{}`)
  ) {
    return JSON.parse(data);
  } else {
    return undefinedReturn ?? null;
  }
};

export const renderDate = (data: any) => {
  return moment(data).isValid() ? moment(data).format('MM/DD/yyyy') : '';
};
export const countWordInString = (string: string) => {
  const spaces = (string || '').trim().replace(/\s+/g, ' ').split(' ').length;
  return isNaN(spaces) ? 0 : spaces;
};

export async function base64toBlob(base64Data: string, contentType?: any) {
  contentType = contentType || '';
  const sliceSize = 1024;
  const byteCharacters = btoa(unescape(encodeURIComponent(base64Data)));
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);
  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export function FileToBase64(file: any) {
  const reader: FileReader = new FileReader();
  return new Promise((resolve: Function, reject: Function) => {
    reader.onerror = () => {
      reader.abort();
      reject(new Error('Error parsing file'));
    };
    reader.readAsBinaryString(file);
    reader.onloadend = () => {
      resolve(reader.result);
    };
    // reader.readAsArrayBuffer(file);
  });
}
export const optionValues = (options: any[]) => {
  const returnValues: { value: number }[] = options.map((opt) => {
    return { value: Number(opt) };
  });
  return returnValues;
};
export const optionValues2 = (length: number, start?: number, step?: number) => {
  const returnValues: { value: number }[] = Array.from(Array(length).keys()).map((opt) => {
    return { value: Number(opt + (start ?? 0)) * (step ?? 1) };
  });
  return returnValues;
};

export const optionFromArray = (arr: string[]) => {
  const res = arr.map((str) => {
    return { value: str.trim() };
  });
  return res;
};
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const finishYearOption = [
  { value: 2025 },
  { value: 2026 },
  { value: 2027 },
  { value: 2028 },
  { value: 2029 },
];

export const PrefixOptions = [
  {
    value: ' ',
  },
  {
    value: 'Mr.',
  },
  {
    value: 'Mrs.',
  },
  {
    value: 'Ms.',
  },
];

export const suffixOptions = [
  {
    value: ' ',
  },
  {
    value: 'Jr',
  },
  {
    value: 'Sr',
  },
  {
    value: 'II',
  },
  {
    value: 'III',
  },
  {
    value: 'IV',
  },
];
export const fullSuffixOptions = [
  ' ',
  'Jr.',
  'Sr.',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
];
export const jobCategory = [
  'Accounting',
  'Admin & Clerical',
  'Automotive',
  'Banking',
  'Biotech',
  'Broadcast Journalism',
  'Business Development',
  'Construction',
  'Consultant',
  'Customer Service',
  'Design',
  'Distribution - Shipping',
  'Education - Teaching',
  'Engineering',
  'Entry Level - New Grad',
  'Executive',
  'Facilities',
  'Finance',
  'Franchise',
  'General Business',
  'General Labor',
  'Government',
  'Grocery',
  'Health Care',
  'Hotel - Hospitality',
  'Human Resources',
  'Information Technology',
  'Installation - Maint - Repair',
  'Insurance',
  'Inventory',
  'Legal',
  'Legal Admin',
  'Management',
  'Manufacturing',
  'Marketing',
  'Media - Journalistm - Newspaper',
  'Nonprofit - Social Services',
  'Nurse',
  'Pharmaceutical',
  'Professional Services',
  'Purchasing - Procurement',
  'QA - Quality Control',
  'Real Estate',
  'Research',
  'Restaurant - Food Service',
  'Retail',
  'Sales',
  'Science',
  'Skilled Labor - Trades',
  'Strategy - Planning',
  'Supply Chain',
  'Telecommunications',
  'Training',
  'Transportation',
  'Warehouse',
  'Other',
];
export const strongPasswordRegexp = new RegExp(
  '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
);
export const mediumPasswordRegexp = new RegExp(
  '((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))'
);

export const dateFormat = 'MM/DD/yyyy';
export const monthFormat = 'MM/yyyy';

export function getMonthNumberFromName(monthName: String) {
  return !monthName.length ? '' : new Date(`${monthName} 1, 2022`).getMonth() + 1;
}

export const formatDate = (values: any, format?: string) => {
  if (!values || values === null) return '-';
  const returnValues = moment(values).format(format ?? dateFormat);
  return moment(values).isValid() ? returnValues : '-';
};

export const formatMonth = (values: any) => {
  if (values === null) return '-';
  const returnValues = moment(values).format('MM/yyyy');
  return moment(values).isValid() ? returnValues : '-';
};

export const formatBoolean = (values: any) => {
  if (values === 'true') return true;
  if (values === 'false') return false;
  if (typeof values == "boolean") return values;
  return undefined;
};
export const BoolToString = (values: any) => {
  if (values === true) return 'true';
  if (values === false) return 'false';
  if (values === 'true') return 'true';
  if (values === 'false') return 'false';
  return '';
};

export const monthOptions = [
  { value: 'January' },
  { value: 'February' },
  { value: 'March' },
  { value: 'April' },
  { value: 'May' },
  { value: 'June' },
  { value: 'July' },
  { value: 'August' },
  { value: 'September' },
  { value: 'October' },
  { value: 'November' },
  { value: 'December' },
];
export const dayOptions = [
  { value: '1' },
  { value: '2' },
  { value: '3' },
  { value: '4' },
  { value: '5' },
  { value: '6' },
  { value: '7' },
  { value: '8' },
  { value: '9' },
  { value: '10' },
  { value: '11' },
  { value: '12' },
  { value: '13' },
  { value: '14' },
  { value: '15' },
  { value: '16' },
  { value: '17' },
  { value: '18' },
  { value: '19' },
  { value: '20' },
  { value: '21' },
  { value: '22' },
  { value: '23' },
  { value: '24' },
  { value: '25' },
  { value: '26' },
  { value: '27' },
  { value: '28' },
  { value: '29' },
  { value: '30' },
  { value: '31' },
];
export const yearOptions = [
  { value: '- Select -' },
  ...optionValues(Array.from({ length: 124 }, (_, i) => i + 1901)).reverse(),
];
export const occupationOptions = [
  { value: 'Choose an option' },
  { value: 'Accountant or actuary' },
  { value: 'Actor or entertainer' },
  { value: 'Architect or urban planner' },
  { value: 'Artist' },
  { value: 'Business (clerical)' },
  { value: 'Business executive (management, administrator)' },
  { value: 'Business owner or proprietor' },
  { value: 'Business salesperson or buyer' },
  { value: 'Clergy (minister, priest)' },
  { value: 'Clergy (other religious)' },
  { value: 'Clinical Psychologist' },
  { value: 'College Administrator/staff' },
  { value: 'College Teacher' },
  { value: 'Computer programmer or analyst' },
  { value: 'Conservationist or forester' },
  { value: 'Dentist (including orthodontist)' },
  { value: 'Dietitian or nutritionist' },
  { value: 'Engineer' },
  { value: 'Farmer or rancher' },
  { value: 'Foreign service worker (including diplomat)' },
  { value: 'Homemaker (full-time)' },
  { value: 'Interior decorator (including designer)' },
  { value: 'Lab technician or hygienist' },
  { value: 'Laborer' },
  { value: 'Law enforcement officer' },
  { value: 'Lawyer (attorney) or judge' },
  { value: 'Military service (career)' },
  { value: 'Musician (performer, composer)' },
  { value: 'Nurse' },
  { value: 'Optometrist' },
  { value: 'Pharmacist' },
  { value: 'Physician' },
  { value: 'Policymaker/Government' },
  { value: 'School Counselor' },
  { value: 'School principal or superintendent' },
  { value: 'Scientific researcher' },
  { value: 'Skilled trades' },
  { value: 'Social' },
  { value: 'Welfare, or recreation worker' },
  { value: 'Teacher or administrator (elementary)' },
  { value: 'Teacher or administrator (secondary)' },
  { value: 'Therapist (physical, occupational, speech)' },
  { value: 'Veterinarian' },
  { value: 'Writer or journalist' },
  { value: 'Other' },
];

export const educationLevelOptions = [
  { value: 'None' },
  { value: 'Some High School or Less' },
  { value: 'High School Diploma/GED' },
  { value: 'Some College/No Degree Earned' },
  { value: `Two-year associate's degree` },
  { value: "Bachelor's Degree" },
  { value: `Master's Degree` },
  { value: 'Professional Degree (MBA, MD, JD, etc.)' },
  { value: 'Doctorate Degree or Higher' },
  { value: 'Unknown' },
];
export const siblingEducationOptions = [
  'None',
  'Some grade/primary school',
  'Completed grade/primary school',
  'Some high/secondary school',
  'Graduated from high/secondary school (or equivalent)',
  'Some trade school or community college',
  'Graduated from trade school or community college',
  'Some college/university',
  'Graduated from college/university',
  'Graduate school',
];
export const progressionHighSchool = [
  { value: 'progression_is_graduate_early', label: 'Did or will graduate early' },
  { value: 'progression_is_graduate_late', label: 'Did or will graduate late' },
  { value: 'progression_is_time_off', label: 'Did or will take time off' },
  { value: 'progression_is_take_gap_year', label: 'Did or will take gap year' },
  { value: 'progression_is_no_change', label: 'No change in progression' },
];

export const schoolGradingScaleOptions = [
  { value: 'grading_scale_is_abcdf', label: 'A B C D F' },
  { value: 'grading_scale_is_0_100', label: '0-100(U.S.)' },
  { value: 'grading_scale_is_1_7', label: "1-7 Int'l Bacc" },
  { value: 'grading_scale_is_other', label: 'Other' },
];

export const findNextRoute = (pathname: string, sectionPermissionDenied: string[]): string => {
  const parseMenuRoute = appMenuGroups.map((item: any) => {
    return {
      ...item,
      pathIncludes: item.isGroup ? item.children.map((i: any) => i.path) : [],
    };
  });
  const readyRoute = parseMenuRoute
    .find((e: any) => e.pathIncludes.includes(pathname))
    .children.filter((child: any) => !sectionPermissionDenied.includes(child?.name));
  const currentIndex = readyRoute.findIndex((route: any) => route.path === pathname);
  return readyRoute[currentIndex + 1].path as string;
};

export const reorder: (
  list: Iterable<unknown> | ArrayLike<unknown>,
  startIndex: number,
  endIndex: number
) => any[] = (
  list: Iterable<unknown> | ArrayLike<unknown>,
  startIndex: number,
  endIndex: number
) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

export const csuCountry = [
  'United States of America',
  'Afghanistan',
  'Aland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antartica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas, The',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Saint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros',
  'Congo (the Democratic Republic of the )',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  `Côte d'Ivoire`,
  'Croatia',
  'Cuba',
  'Curacao',
  'Cyprus',
  'Czechia',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia, The',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  `Korea (the Democratic People's Republic of)`,
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  `Lao People's Democratic Republic (the)`,
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia, Federated States of',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'North Macedonia',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Saint Barthelemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome & Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch Part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia And The South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard And Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const notUsCountry = [
  'Country name*',
  'Afghanistan',
  // 'Aland Islands',
  'Albania',
  'Algeria',
  // 'American Samoa',
  'Andorra',
  'Angola',
  // 'Anguilla',
  // 'Antartica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Azores Islands',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  // 'Bonaire, Saint Eustatius and Saba',
  'Bosnia-Herzegovina',
  'Botswana',
  // 'Bouvet Island',
  'Brazil',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China, Peoples Republic of',
  // 'Christmas Island',
  'Cocos Islands',
  'Colombia',
  'Comoros',
  'Congo, Democratic Rep. of the',
  'Congo, Republic of the',
  'Cook Islands',
  'Costa Rica',
  `Cote D Ivoire`,
  'Croatia',
  'Cuba',
  'Curacao',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'England',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands',
  // 'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  // 'French Southern Territories (the)',
  'Gabon',
  'Gambia',
  'Georgia (Republic Of)',
  'Germany, Fed Republic',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  // 'Guam',
  'Guatemala',
  // 'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  // 'Heard Island and McDonald Islands',
  // 'Holy See',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  // 'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, Dem Republic (North)',
  'Korea, Repub. Of (South)',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  // 'Mauritius',
  // 'Mayotte',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar, Union Of (Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  // 'North Macedonia',
  // 'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  // 'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn Island',
  'Poland',
  'Portugal',
  // 'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russian',
  'Rwanda',
  // 'Saint Barthelemy',
  // 'Saint Helena, Ascension and Tristan da Cunha',
  // 'Saint Kitts and Nevis',
  // 'Saint Lucia',
  // 'Saint Martin (French part)',
  // 'Saint Pierre and Miquelon',
  // 'Saint Vincent and the Grenadines',
  'Samoa (formerly Western Samoa)',
  'San Marino',
  'Sao Tome & Principe',
  'Saudi Arabia',
  'Scotland',
  'Senegal',
  'Serbia',
  'Seychelles Islands',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  // 'South Georgia And The South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'St Vincent & The Grenadines',
  'St. Helena',
  'St. Kitts-Nevis-Anguilla',
  'St. Lucia',
  'St. Pierre & Miquelon',
  'Sudan',
  'Suriname',
  // 'Svalbard And Jan Mayen',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Tahiti',
  'Taiwan (Rep. Of China)',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  // 'Tokelau',
  'Tonga',
  'Trinidad & Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks & Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  // 'United States Minor Outlying Islands (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Wales',
  // 'Virgin Islands (British)',
  // 'Virgin Islands (U.S.)',
  'Wallis & Futuma Islands',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Other'
];

export const highSchoolType = [
  { name: 'Public', value: 'Public' },
  { name: 'Charter', value: 'Charter' },
  { name: 'Home School', value: 'Home School' },
  { name: 'Religious', value: 'Religious' },
  { name: 'Independent', value: 'Independent' },
];


export const catName = (firstName: string, midName: string, lastName: string) => {
  var s2 = midName ?? '' ? ' ' + (midName ?? '') + ' ' : ' ';
  return (firstName ?? '') + s2 + (lastName ?? '');
};

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  });
};

export const toLocalShortDate = (d: Date) => {
  return new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale).format(d);
};

export const removeIntercomeCache = () => {

  // Xóa Cookie liên quan đến Intercom
  document.cookie.split(";").forEach((cookie) => {
    const cookieName = cookie.split("=")[0].trim();
    if (cookieName.startsWith("intercom-")) {
      // Xóa cookie bằng cách đặt giá trị rỗng và ngày hết hạn trong quá khứ
      document.cookie = cookieName + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
      // Xóa cookie với tất cả các đường dẫn có thể
      document.cookie = cookieName + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=" + window.location.hostname;
    }
  });


  // Xóa Local Storage liên quan đến Intercom
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith('intercom-')) {
      localStorage.removeItem(key);
    }
  });

  // Xóa Session Storage liên quan đến Intercom
  Object.keys(sessionStorage).forEach((key) => {
    if (key.startsWith('intercom-')) {
      sessionStorage.removeItem(key);
    }
  });
};
