/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import CheckboxInput from 'components/input/CheckboxInput';
import YesNoSwitch from 'components/input/YesNoSwitch';
import SelectInput from 'components/input/SelectInput';
import { BoolToString, formatBoolean, optionValues } from 'components/helper/helper';
import GroupRadioButton from 'components/input/GroupRadioButton';
import ReuseFieldScoreAndDate from '../IELTSAndPTE/components/ReuseFieldScoreAndDate';
import { getTOEFL, updateTOEFL } from 'app/testingSlice/testingSlice';
import { HtmlTooltip } from 'screen/selectCollege/SelecCollegePage';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TextInput from 'components/input/textInput';
import DatePickerInput from 'components/input/DatePicker';
import { debounce } from 'lodash';

interface Props {}

const TOEFL = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const testing = useAppSelector((store) => store.testing);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const formik = useFormik({
    initialValues: {
      // New field -------
      toefl_ibt_registration_number: '',
      toefl_ibt_received_score: '',
      toefl_ibt_reading_score: '',
      toefl_ibt_listening_score: '',
      toefl_ibt_writing_score: '',
      toefl_ibt_speaking_score: '',
      toefl_ibt_plan_on_retake: '',
      toefl_ibt_date_retake: '',
      toefl_paper_registration_number: '',
      toefl_paper_highest_twe_score: '',
      toefl_paper_twe_date_taken: '',
      toefl_paper_received_score: '',
      toefl_paper_reading_score: '',
      toefl_paper_listening_score: '',
      toefl_paper_writing_score: '',
      toefl_paper_plan_on_retake: '',
      toefl_paper_date_retake: '',
      // New field -------
      is_not_required_toefl_test: true,
      is_considered_international_student: '',
      number_of_time_taken_toefl_ibt: 0,
      number_of_future_toefl_ibt_expect: '0',
      toefl_ibt_highest_reading_score: '',
      toefl_ibt_reading_date_taken: '',
      toefl_ibt_highest_speaking_score: '',
      toefl_ibt_speaking_date_taken: '',
      toefl_ibt_highest_listening_score: '',
      toefl_ibt_listening_date_taken: '',
      toefl_ibt_highest_writing_score: '',
      toefl_ibt_writing_date_taken: '',
      toefl_ibt_highest_total_score: '',
      toefl_ibt_total_score_date_taken: '',
      number_of_time_taken_toefl_paper: 0,
      number_of_future_toefl_paper_expect: '0',
      toefl_paper_highest_reading_score: '',
      toefl_paper_reading_date_taken: '',
      toefl_paper_highest_speaking_score: '',
      toefl_paper_speaking_date_taken: '',
      toefl_paper_highest_listening_score: '',
      toefl_paper_listening_date_taken: '',
      toefl_paper_highest_writing_score: '',
      toefl_paper_writing_date_taken: '',
      toefl_paper_highest_total_score: '',
      toefl_paper_total_score_date_taken: '',
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        is_considered_international_student: formatBoolean(
          values.is_considered_international_student
        ),
        student_id: studentId,
      };
      await dispatch(updateTOEFL(newValues));
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    const nextRoute = Object.keys(testing.roleTest).find(
      (key, index) =>
        index > Object.keys(testing.roleTest).indexOf('toeflTest') && testing.roleTest[key] === true
    );
    history.push(nextRoute ? routers?.[nextRoute]?.path : routers.otherTest.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      let res = await dispatch(getTOEFL(studentId));
      const dataLoaded: any = res.payload;
      if (dataLoaded) {
        const values: any = {
          ...dataLoaded,
          is_considered_international_student: BoolToString(
            dataLoaded.is_considered_international_student
          ),
          number_of_future_toefl_ibt_expect:
            dataLoaded.number_of_future_toefl_ibt_expect.toString(),
          number_of_future_toefl_paper_expect:
            dataLoaded.number_of_future_toefl_paper_expect.toString(),
        };
        formik.setValues({ ...formik.values, ...values });
      }
      setLoading(false);
      formik.setStatus('idle');
    } else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getDetail();
  }, []);

  React.useEffect(() => {
    if (formik.values.toefl_ibt_received_score === 'false') {
      formik.setValues({
        ...formik.values,
        toefl_ibt_highest_reading_score: '',
        toefl_ibt_reading_date_taken: '',
        toefl_ibt_speaking_date_taken: '',
        toefl_ibt_highest_speaking_score: '',
        toefl_ibt_highest_listening_score: '',
        toefl_ibt_listening_date_taken: '',
        toefl_ibt_highest_writing_score: '',
        toefl_ibt_writing_date_taken: '',
        toefl_ibt_highest_total_score: '',
        toefl_ibt_total_score_date_taken: '',
        toefl_ibt_listening_score: '',
        toefl_ibt_reading_score: '',
        toefl_ibt_writing_score: '',
        toefl_ibt_speaking_score: '',
      });
    }
  }, [formik.values.toefl_ibt_received_score]);
  // Auto save function ==========
  const notInitialRender = React.useRef(false);
  React.useEffect(() => {
    debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (notInitialRender.current) {
        if (!loading) formik.handleSubmit();
      } else {
        notInitialRender.current = true;
      }
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[
          { title: 'General', percentOnProcess: 20 },
          { title: 'TOEFL iBT', percentOnProcess: 50 },
          { title: 'TOEFL Paper', percentOnProcess: 80 },
        ]}
        title="Standardized Testing"
        process={1}
        icon={<CalculateOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography className="fs-6 text-dark my-2 fw-bold">
                    <InfoOutlinedIcon fontSize="small" className="mx-1" />
                    TOEFL Test
                  </Typography>
                  <div className="d-flex flex-wrap text-field-light">
                    For international students - the testing policies for specific colleges can
                    vary. Check with the colleges you intend to apply to in advance to determine the
                    tests, if any, you'll need to take. Note, be sure to do in your 1st or 2nd year
                    of high school so you can plan accordingly when to take your test(s).
                  </div>
                </React.Fragment>
              }
              placement="right-start"
            >
              <Row className="m-0 common-flag">
                <div className="text-field-mid fs-3 mb-4" id="General">
                  TOEFL Tests
                </div>
                <CheckboxInput
                  onChange={formik.handleChange}
                  name="is_not_required_toefl_test"
                  values={formik.values.is_not_required_toefl_test}
                  label="I’m a domestic student or am not required to take the TOEFL Tests."
                />

                <YesNoSwitch
                  className="mb-3"
                  label="Are you considered an International Student or have recently started high school in the U.S.?"
                  disabled={formik.values.is_not_required_toefl_test}
                  values={formik.values.is_considered_international_student}
                  onChange={formik.handleChange}
                  name="is_considered_international_student"
                />
                <div className="text-dark fs-5 p-1 mt-3" id="TOEFLiBT">
                  TOEFL IBT
                </div>
                <SelectInput
                  form={formik}
                  disabled={formik.values.is_not_required_toefl_test}
                  label="Number of times you have already taken the TOEFL iBT*"
                  option={optionValues(Array.from({ length: 5 }, (_, i) => i + 1))}
                  name="number_of_time_taken_toefl_ibt"
                  values={formik.values.number_of_time_taken_toefl_ibt}
                  onChange={formik.handleChange}
                />
                <TextInput
                  type="number"
                  label="Registration number *"
                  name="toefl_ibt_registration_number"
                  countText={7}
                  values={formik.values.toefl_ibt_registration_number}
                  onChange={formik.handleChange}
                />
                <YesNoSwitch
                  className="mb-3"
                  label="Have you received your scores yet? *"
                  name="toefl_ibt_received_score"
                  values={formik.values.toefl_ibt_received_score}
                  onChange={formik.handleChange}
                />
                {formik.values.toefl_ibt_received_score === 'true' && (
                  <>
                    <ReuseFieldScoreAndDate
                      selectOptions={optionValues(Array.from(Array(31).keys()))}
                      disabled={formik.values.is_not_required_toefl_test}
                      formik={formik}
                      arrayName={[
                        {
                          fieldName: 'toefl_ibt_highest_reading_score',
                          dateName: 'toefl_ibt_reading_date_taken',
                          label: 'reading',
                        },
                        {
                          fieldName: 'toefl_ibt_highest_speaking_score',
                          dateName: 'toefl_ibt_speaking_date_taken',
                          label: 'speaking',
                        },
                        {
                          fieldName: 'toefl_ibt_highest_listening_score',
                          dateName: 'toefl_ibt_listening_date_taken',
                          label: 'listiening',
                        },
                        {
                          fieldName: 'toefl_ibt_highest_writing_score',
                          dateName: 'toefl_ibt_writing_date_taken',
                          label: 'writing',
                        },
                      ]}
                    />
                    <TextInput
                      type="number"
                      max={999}
                      label="Highest TOEFL iBT total score*"
                      name={`toefl_ibt_highest_total_score`}
                      values={formik.values.toefl_ibt_highest_total_score}
                      onChange={formik.handleChange}
                    />
                    <DatePickerInput
                      label="Date taken"
                      name={`toefl_ibt_total_score_date_taken`}
                      values={formik.values.toefl_ibt_total_score_date_taken}
                      onChange={(values) =>
                        formik.setFieldValue('toefl_ibt_total_score_date_taken', values)
                      }
                    />
                    <TextInput
                      type="number"
                      max={30}
                      name="toefl_ibt_listening_score"
                      values={formik.values.toefl_ibt_listening_score}
                      onChange={formik.handleChange}
                      label="Listening *"
                    />
                    <TextInput
                      type="number"
                      max={30}
                      name="toefl_ibt_reading_score"
                      values={formik.values.toefl_ibt_reading_score}
                      onChange={formik.handleChange}
                      label="Reading *"
                    />
                    <TextInput
                      type="number"
                      max={30}
                      name="toefl_ibt_writing_score"
                      values={formik.values.toefl_ibt_writing_score}
                      onChange={formik.handleChange}
                      label="Writing *"
                    />
                    <TextInput
                      type="number"
                      max={30}
                      name="toefl_ibt_speaking_score"
                      values={formik.values.toefl_ibt_speaking_score}
                      onChange={formik.handleChange}
                      label="Speaking*"
                    />
                  </>
                )}
                <YesNoSwitch
                  className="mb-3"
                  label="Do you plan on retaking this test? *"
                  values={formik.values.toefl_ibt_plan_on_retake}
                  onChange={formik.handleChange}
                  name="toefl_ibt_plan_on_retake"
                />
                {formik.values.toefl_ibt_plan_on_retake === 'true' && (
                  <>
                    <GroupRadioButton
                      label="Number of future TOEFL iBT sittings you expect*"
                      options={['0', '1', '2', '3']}
                      values={formik.values.number_of_future_toefl_ibt_expect}
                      onChange={formik.handleChange}
                      name="number_of_future_toefl_ibt_expect"
                      disabled={formik.values.is_not_required_toefl_test}
                    />
                    {formik.values.number_of_future_toefl_ibt_expect !== '0' && (
                      <DatePickerInput
                        label="When will you retake this test? *"
                        name={`toefl_ibt_date_retake`}
                        values={formik.values.toefl_ibt_date_retake}
                        onChange={(values) => formik.setFieldValue('toefl_ibt_date_retake', values)}
                      />
                    )}
                  </>
                )}
                <div className="text-dark fs-5 p-1 mt-3" id="TOEFLPaper">
                  TOEFL Paper
                </div>
                <SelectInput
                  form={formik}
                  disabled={formik.values.is_not_required_toefl_test}
                  label="Number of times you have already taken the TOEFL Paper?* "
                  option={optionValues(Array.from({ length: 5 }, (_, i) => i + 1))}
                  name="number_of_time_taken_toefl_paper"
                  values={formik.values.number_of_time_taken_toefl_paper}
                  onChange={formik.handleChange}
                />
                <TextInput
                  type="number"
                  label="Registration number *"
                  name="toefl_paper_registration_number"
                  countText={7}
                  values={formik.values.toefl_paper_registration_number}
                  onChange={formik.handleChange}
                />
                <ReuseFieldScoreAndDate
                  selectOptions={optionValues(Array.from(Array(100).keys()))}
                  disabled={formik.values.is_not_required_toefl_test}
                  formik={formik}
                  arrayName={[
                    {
                      fieldName: 'toefl_paper_highest_reading_score',
                      dateName: 'toefl_paper_reading_date_taken',
                      label: 'reading',
                    },
                    {
                      fieldName: 'toefl_paper_highest_speaking_score',
                      dateName: 'toefl_paper_speaking_date_taken',
                      label: 'speaking',
                    },
                    {
                      fieldName: 'toefl_paper_highest_listening_score',
                      dateName: 'toefl_paper_listening_date_taken',
                      label: 'listiening',
                    },
                    {
                      fieldName: 'toefl_paper_highest_writing_score',
                      dateName: 'toefl_paper_writing_date_taken',
                      label: 'writing',
                    },
                    {
                      fieldName: 'toefl_paper_highest_twe_score',
                      dateName: 'toefl_paper_twe_date_taken',
                      label: 'TWE',
                      options: optionValues(Array.from({ length: 7 }, (_, i) => i)),
                    },
                  ]}
                />
                <TextInput
                  type="number"
                  max={999}
                  label="Highest TOEFL Paper total score*"
                  name={`toefl_paper_highest_total_score`}
                  values={formik.values.toefl_paper_highest_total_score}
                  onChange={formik.handleChange}
                />
                <DatePickerInput
                  label="Date taken"
                  name={`toefl_paper_total_score_date_taken`}
                  values={formik.values.toefl_paper_total_score_date_taken}
                  onChange={(values) =>
                    formik.setFieldValue('toefl_paper_total_score_date_taken', values)
                  }
                />
                <TextInput
                  type="number"
                  max={30}
                  name="toefl_paper_listening_score"
                  values={formik.values.toefl_paper_listening_score}
                  onChange={formik.handleChange}
                  label="Listening *"
                />
                <TextInput
                  type="number"
                  max={30}
                  name="toefl_paper_reading_score"
                  values={formik.values.toefl_paper_reading_score}
                  onChange={formik.handleChange}
                  label="Reading *"
                />
                <TextInput
                  type="number"
                  max={30}
                  name="toefl_paper_writing_score"
                  values={formik.values.toefl_paper_writing_score}
                  onChange={formik.handleChange}
                  label="Writing *"
                />

                <YesNoSwitch
                  className="mb-3"
                  label="Do you plan on retaking this test? *"
                  values={formik.values.toefl_paper_plan_on_retake}
                  onChange={formik.handleChange}
                  name="toefl_paper_plan_on_retake"
                />
                {formik.values.toefl_paper_plan_on_retake === 'true' && (
                  <>
                    <GroupRadioButton
                      label="Number of future TOEFL iBT sittings you expect*"
                      options={['0', '1', '2', '3']}
                      values={formik.values.number_of_future_toefl_paper_expect}
                      onChange={formik.handleChange}
                      name="number_of_future_toefl_paper_expect"
                      disabled={formik.values.is_not_required_toefl_test}
                    />
                    {formik.values.number_of_future_toefl_paper_expect !== '0' && (
                      <DatePickerInput
                        label="When will you retake this test? *"
                        name={`toefl_paper_date_retake`}
                        values={formik.values.toefl_paper_date_retake}
                        onChange={(values) =>
                          formik.setFieldValue('toefl_paper_date_retake', values)
                        }
                      />
                    )}
                  </>
                )}
              </Row>
            </HtmlTooltip>

            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {testing.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default TOEFL;
