import { Card, Stack, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import CustomTabPanel from './TabPanel';
import LoadingContent from 'components/LoadingContent';
import ItemContent, { CheckboxContent } from 'screen/summary/components/ItemContent';
import { Row } from 'react-bootstrap';
import { checkEnableParseJSON } from 'components/helper/helper';
import { useParams } from 'react-router-dom';

import courseServices from 'services/courseServices';
import CourseGrade9Summary from 'screen/summary/components/CourseGrade9Summary';
import CourseCollegeSum from 'screen/summary/components/CourseCollegeSum';
import SimpleTable from 'components/table/SimpleTable';
import { useAppSelector } from 'app/hooks';
import { buildClassName } from './RPersonalInformation';

const RCoursesAndGrades = ({
  setCurrentModuleId,
}: {
  setCurrentModuleId: (id: string) => void;
}) => {
  const checkModule = useAppSelector((store) => store.basicInformation.checkModule);
  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCurrentModuleId(`5.${newValue}`);
  };
  return (
    <div>
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
        <Tab value={1} label="General" className={buildClassName('5.1', checkModule)} />
        <Tab value={2} label="9th" className={buildClassName('5.2', checkModule)} />
        <Tab value={3} label="10th" className={buildClassName('5.3', checkModule)} />
        <Tab value={4} label="11th" className={buildClassName('5.4', checkModule)} />
        <Tab value={5} label="12th" className={buildClassName('5.5', checkModule)} />
        <Tab value={6} label="College Courses" className={buildClassName('5.6', checkModule)} />
      </Tabs>
      <CustomTabPanel value={value} index={1}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            General
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryGeneral />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            9th
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <Summary9th />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            10th
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <Summary10th />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            11th
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <Summary11th />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            12th
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <Summary12th />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            College Courses
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryCollegeCourses />
          </Stack>
        </Card>
      </CustomTabPanel>
    </div>
  );
};

export default RCoursesAndGrades;

const SummaryGeneral = () => {
  const [myGeneralInfo, setMyGeneralInfo] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await courseServices.getGeneral(params.id);
      setMyGeneralInfo({ ...res, additional_info: checkEnableParseJSON(res.additional_info, {}) });
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {myGeneralInfo && (
        <>
          <ItemContent
            mdCol={6}
            label="I am able to obtain a copy of my transcript(s)"
            values={myGeneralInfo.is_able_obtain_copy_transcript}
          />
          {!myGeneralInfo.is_able_obtain_copy_transcript && (
            <ItemContent
              mdCol={6}
              label="Based on your resspanonses, you are not required to complete this section. Colleges who are listed above as requiring this information will not penalize you. Please have your counselor submit your transcripts as per their usual methods"
              values={myGeneralInfo.is_counselor_submit_transcript}
            />
          )}
          <ItemContent
            mdCol={6}
            label="My transcript shows grades for courses I've completed"
            values={myGeneralInfo.is_transcript_show_grade_completed}
          />
          {!myGeneralInfo.is_transcript_show_grade_completed && (
            <ItemContent
              mdCol={6}
              label="Based on your resspanonses, you are not required to complete this section. Colleges who are listed above as requiring this information will not penalize you. Please have your counselor submit your transcripts as per their usual methods"
              values={myGeneralInfo.is_counselor_submit_transcript_2}
            />
          )}
          <div className="border w-100 my-3" />
          <div className="text-field-light fs-5">Middle School - Academic History</div>
          <div className="text-field-light">
            Mathematics or foreign language courses are common middle courses that count toward your
            high school requirements.
          </div>
          <div className="text-field-light">
            If any of your middle school courses are listed on your transcript, be sure to list them
            here, too.
          </div>
          <div className="text-field-light">
            Note: On the college applications, you do not need to list the courses under your middle
            school name, they will be listed under your high school
          </div>
          <ItemContent
            mdCol={12}
            label="Did you take any course(s) in middle school that fulfilled your high school subject requirements?"
            values={myGeneralInfo?.additional_info?.is_take_course_in_middle_school}
          />
          <ItemContent
            mdCol={12}
            label="Do you have middle school courses listed on your transcript that have been issued official transcript grades?*"
            values={
              myGeneralInfo?.additional_info?.is_have_course_issued_official_transcript_grades
            }
          />
          <div className="text-field-light fs-5">Middle School Coursework</div>
          {(myGeneralInfo?.additional_info?.course_work ?? []).map((course: any, index: number) => {
            return (
              <Row key={index}>
                <div className="p-2 fs-5 fw-bold text-field-light">{course?.grade_level}</div>
                <ItemContent
                  mdCol={12}
                  label="Select the school for this coursework"
                  values={course.school}
                />
                <ItemContent label="School Year*" values={course?.school_year} />
                <ItemContent label="Schedule" values={course?.schedule} />
                <ItemContent label="Grading Scale" values={course?.grade_scale} />
                <Row className="bg-white rounded p-3">
                  <ItemContent label="Course Name" values={course?.course_name} />
                  <ItemContent label="A-G Subject Area" values={course?.ag_subject_requirement} />
                  <ItemContent label="Subject/Discipline" values={course?.subject_discipline} />
                  <ItemContent label="Type of Class" values={course?.type_of_class} />
                  <ItemContent
                    hidden={course.schedule === 'Yearly'}
                    label="During which term(s) did you take this class?"
                    values={buildSemesterData(course)}
                  />
                  <ItemContent
                    hidden={course.schedule === 'Yearly'}
                    label="How are the grades for this class reported on your transcript?"
                    values={course?.schedule_transcript}
                  />
                  <ItemContent
                    label="Is this a weighted course?"
                    values={course?.is_weighted_course}
                  />
                  <div />
                  {(course?.grades ?? []).map((grade: any, gradeIndex: any) => (
                    <Row key={gradeIndex}>
                      <ItemContent
                        label={[course?.schedule, gradeIndex + 1, 'Grade'].join(' ')}
                        values={course?.grades[gradeIndex]}
                      />
                      <ItemContent
                        label={[course?.schedule, gradeIndex + 1, 'Credit'].join(' ')}
                        values={course?.credits[gradeIndex]}
                      />
                    </Row>
                  ))}
                  <CheckboxContent
                    label="I took this class at a college and/or I received college credit for this class"
                    values={course?.is_take_class_at_college}
                  />
                  <ItemContent label="Select College *" mdCol={12} values={course?.college} />
                </Row>
              </Row>
            );
          })}

          <div className="border w-100 my-3" />
          <div className="text-field-light fs-5">International school experience</div>
          <ItemContent
            mdCol={12}
            label="Did you attend school outside the United States for any part of 6th through 8th grade?"
            values={myGeneralInfo.is_attend_school_outside_us_6_through_8}
          />
        </>
      )}
    </Row>
  );
};

const Summary9th = () => {
  const [myGradeAndCourse, setMyGradeAndCourse] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await courseServices.getGradeAndCourse(params.id);
      setMyGradeAndCourse(res);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {checkEnableParseJSON(myGradeAndCourse?.grade_9_course_array, []).length !== 0 && (
        <Row>
          <CourseGrade9Summary
            data={checkEnableParseJSON(myGradeAndCourse?.grade_9_course_array, [])}
          />
          <div className="text-field-light p-2">
            Please indicate that you have reported all courses and grades for 9th grade.*
          </div>
          <CheckboxContent
            values={myGradeAndCourse.is_reported_all_grade_9}
            label="I have reported all of my courses for this grade."
          />
        </Row>
      )}
    </Row>
  );
};

const Summary12th = () => {
  const [myGradeAndCourse, setMyGradeAndCourse] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();
  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await courseServices.getGradeAndCourse(params.id);
      setMyGradeAndCourse(res);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  let course_array = checkEnableParseJSON(myGradeAndCourse?.course_array, [])?.map((i: any) => {
    return {
      ...i,
      schedule: i.schedule.join(', '),
    };
  });
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {myGradeAndCourse?.grade_12_course_array && (
        <Row>
          <CourseGrade9Summary
            data={checkEnableParseJSON(myGradeAndCourse?.grade_12_course_array, [])}
            is12={true}
          />
          <div className="text-field-light p-2">
            Please indicate that you have reported all courses and grades for 12th grade.*
          </div>
          <CheckboxContent
            values={myGradeAndCourse.is_reported_all_grade_12}
            label="I have reported all of my courses for this grade."
          />
          { checkEnableParseJSON(myGradeAndCourse?.course_title, {})?.is_indicated_all_course &&
            <>
              <div className="text-field-light p-2">
                Have you indicated all your middle school, post-12th grade, or summer courses?
              </div>
              <CheckboxContent
                values={
                  checkEnableParseJSON(myGradeAndCourse?.course_title, {})?.is_indicated_all_course
                }
                label="I have reported all of my courses for this grade."
              />
            </>
          }
          <div className="my-3 w-100 border" />
          <div className="fs-5 p-2 text-field-light">Current or Most Recent Year Courses</div>
          <div className="text-field-light fs-5 p-2">Coursework</div>
          <ItemContent
            mdCol={12}
            label="Please select the main course scheduling system your institution is using.**"
            values={myGradeAndCourse?.course_scheduling_system_is_using}
          />

          <CheckboxContent
            label="My 12th grade courses are my current courses"
            values={myGradeAndCourse.is_fields_enter_your_current_year_course}
          />
          <div className="text-field-light p-2">
            Please list all courses you are taking this academic year. If you are not currently
            enrolled, please list courses from your most recent academic year.
          </div>
          <SimpleTable
            data={course_array}
            column={[
              {
                label: 'Please select the course scheduling system your school is using',
                value: 'course_scheduling_system_is_using',
              },
              { label: 'Course title', value: 'title' },
              { label: 'Subject', value: 'subject' },
              { label: 'Course Level', value: 'level' },
              { label: 'Course Schedule', value: 'schedule' },
            ]}
          />
        </Row>
      )}
    </Row>
  );
};
const Summary10th = () => {
  const [myGradeAndCourse, setMyGradeAndCourse] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await courseServices.getGradeAndCourse(params.id);
      setMyGradeAndCourse(res);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {checkEnableParseJSON(myGradeAndCourse?.grade_10_course_array, []).length !== 0 && (
        <Row>
          <CourseGrade9Summary
            data={checkEnableParseJSON(myGradeAndCourse?.grade_10_course_array, [])}
          />
          <div className="text-field-light p-2">
            Please indicate that you have reported all courses and grades for 10th grade.*
          </div>
          <CheckboxContent
            values={myGradeAndCourse.is_reported_all_grade_10}
            label="I have reported all of my courses for this grade."
          />
        </Row>
      )}
    </Row>
  );
};
const Summary11th = () => {
  const [myGradeAndCourse, setMyGradeAndCourse] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await courseServices.getGradeAndCourse(params.id);
      setMyGradeAndCourse(res);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {checkEnableParseJSON(myGradeAndCourse?.grade_11_course_array, []).length !== 0 && (
        <Row>
          <CourseGrade9Summary
            data={checkEnableParseJSON(myGradeAndCourse?.grade_11_course_array, [])}
          />
          <div className="text-field-light p-2">
            Please indicate that you have reported all courses and grades for 11th grade.*
          </div>
          <CheckboxContent
            values={myGradeAndCourse.is_reported_all_grade_11}
            label="I have reported all of my courses for this grade."
          />
        </Row>
      )}
    </Row>
  );
};

const SummaryCollegeCourses = () => {
  const [myGradeAndCourse, setMyGradeAndCourse] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await courseServices.getGradeAndCourse(params.id);
      setMyGradeAndCourse(res);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {checkEnableParseJSON(myGradeAndCourse?.grade_9_course_array, []).length !== 0 && (
        <Row>
          {checkEnableParseJSON(myGradeAndCourse?.college_course_array, []).length !== 0 && (
            <>
              <CourseCollegeSum
                data={checkEnableParseJSON(myGradeAndCourse?.college_course_array, [])}
              />
            </>
          )}
          <div className="text-field-light mt-4">
            Please indicate that you added all of the courses you have taken or plan to take for
            every college
          </div>
          <CheckboxContent
            values={myGradeAndCourse?.is_finish_adding_all_college_grade}
            label={`I'm finished adding all the college grade classes I've taken or plan to take for every college I've attended. (I can still edit my classes if I need to.)`}
          />
        </Row>
      )}
    </Row>
  );
};

const buildSemesterData = (item: any) => {
  let options: any[] = [];
  switch (item.schedule) {
    case 'Quarters':
      options = [
        { value: 'isFirstSemester', label: 'First Quarter', fieldIndex: 0 },
        { value: 'isSecondSemester', label: 'Second Quarter', fieldIndex: 1 },
        { value: 'isThirdSemester', label: 'Third Quarter', fieldIndex: 2 },
        { value: 'isFourthSemester', label: 'Fourth Quarter', fieldIndex: 3 },
      ];
      break;
    case 'Trimester':
      options = [
        { value: 'isFirstSemester', label: 'First Trimester', fieldIndex: 0 },
        { value: 'isSecondSemester', label: 'Second Trimester', fieldIndex: 1 },
        { value: 'isThirdSemester', label: 'Third Trimester', fieldIndex: 2 },
      ];
      break;
    case 'Semester':
      options = [
        { value: 'isFirstSemester', label: 'First Semester', fieldIndex: 0 },
        { value: 'isSecondSemester', label: 'Second Semester', fieldIndex: 1 },
      ];
      break;
    default:
      break;
  }
  let str = options
    .map((opt) => (item[opt.value] ? opt.label : ''))
    .filter((i) => !!i)
    .join(', ');
  return str;
};
