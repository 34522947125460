/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import LoadingContent from 'components/LoadingContent';
import { Button, Col, Container, Row } from 'react-bootstrap';
import ItemContent, { CheckboxContent, CheckboxContentGroup } from './components/ItemContent';
import { getCollege, getFuturePlan, getHighSchool } from 'app/highSchoolSlice/highSchoolSlice';
import { checkEnableParseJSON, formatDate, formatMonth, progressionHighSchool } from 'components/helper/helper';

interface Props {}

export const buildContent = (item: any, index: number) => {
  const renderOption = [
    { value: 'IN_CA', label: 'In California' },
    { value: 'OUTSIDE_CA', label: 'Outside California (in the U.S.)' },
    { value: 'OUTSIDE_USA', label: 'Outside the U.S.' },
  ];
  const renderText = item?.where_attend_high_school?.includes('_')
    ? renderOption.find((opt) => opt.value === item?.where_attend_high_school)?.label
    : item?.where_attend_high_school;
  return !item ? null : (
    <Row key={index} className="mb-3">
      {index !== 1 && index !== -1 && <div className="border w-100 my-3" />}
      <div className="text-field-light"> School name</div>
      <p style={{
        color: item.high_school_object?.match_all ? '#4d88f0' : 'black'
      }} >{item?.high_school_name}</p>
      <div className="text-field-light mt-2">Where do/did you attend high school?</div>
      {renderText ? `${renderText}` : ''}
      <div className="text-field-light mt-2">
        Specialized curriculum (only select if applicable to you)
      </div>
      <CheckboxContent label="Home School" values={item?.is_home_school} />
      <CheckboxContent label="Independent Study" values={item?.is_independant_study} />
      <div className="mt-2">
        ({formatMonth(item?.start_date)} to {formatMonth(item?.end_date)})
      </div>
      {/* <div className=" text-field-light mt-4">
        Please indicate if any of these options will have affected your progression through or since
        secondary/high school. Check all that apply.*
      </div>
      {progressionHighSchool.map((progressItem, progressIndex) => (
        <CheckboxContent
          key={progressIndex}
          label={progressItem.label}
          values={item[`${progressItem.value}`]}
        />
      ))} */}
      <div className="text-field-light mt-3">What grades did you attend this high school? *</div>
      {(item?.grade ?? []).map((grade: string, i: number) => (
        <Row className="mb-3">
          <Col md={6}>
            <div className="mt-3">
              {grade} ({item?.academic_year[i]})
            </div>
          </Col>
          <Col md={6}>
            <CheckboxContent
              label="I took summer courses after this grade"
              values={item?.is_summer_took[i]}
            />
          </Col>
        </Row>
      ))}
      <Col md={6}>
        <CheckboxContentGroup
          form={item}
          label="Specialized curriculum (if associated with this school)"
          options={[
            { label: 'Career Pathway/Academy', value: 'specialized_curriculum_career' },
            {
              label: 'International Baccalaureate',
              value: 'specialized_curriculum_international',
            },
            { label: 'Magnet', value: 'specialized_curriculum_magnet' },
            { label: 'Other', value: 'specialized_curriculum_other' },
          ]}
        />
      </Col>
      <Col md={6} className="mb-2">
        <CheckboxContentGroup
          form={item}
          label="What’s this school grading scale?"
          options={[
            { value: 'grading_scale_is_abcdf', label: 'A B C D F' },
            { value: 'grading_scale_is_0_100', label: '0-100(U.S.)' },
            { value: 'grading_scale_is_1_7', label: "1-7 Int'l Bacc" },
            { value: 'grading_scale_is_other', label: 'Other' },
          ]}
        />
      </Col>
      <ItemContent mdCol={6} values={item?.classes_schedule} label="What's its school system?" />
      <ItemContent
        mdCol={12}
        label="Does your school have another grading system that has been used while you have attended?"
        values={item?.have_other_grading_system}
      />
      <ItemContent
        label="What is the second school system?"
        values={item?.second_grading_system}
        mdCol={6}
      />

      <ItemContent
        mdCol={6}
        label="Is this a boarding school?*"
        values={item?.is_boarding_school}
      />

      <ItemContent mdCol={6} label="Do you live on campus?" values={item?.is_live_on_campus} />

      <ItemContent
        label="Did or will you graduate from this school?"
        values={item?.is_graduate_from_this_school}
      />
      {index !== -1 ? (
        <></>
      ) : (
        <>
          <div className="text-field-light">Graduation Details</div>
          <div>
            {item?.degree} - {item?.month}/{item?.day}/{item?.year}
          </div>
        </>
      )}
    </Row>
  );
};
export const buildContentCollege = (item: any, index: number) => {
  let grades:any[] =  item?.grades || [];
  let grades_fall:any[] = item?.grades_fall || [];
  let grades_winter:any[] = item?.grades_winter || [];
  let grades_spring:any[] = item?.grades_spring || [];
  let grades_summer:any[] = item?.grades_summer || [];

  let bCheckGrades = Array.isArray(item?.grades) &&  
          Array.isArray(item?.grades_fall) && (grades.length === grades_fall.length) && 
          Array.isArray(item?.grades_winter) && (grades.length === grades_winter.length) &&
          Array.isArray(item?.grades_spring) && (grades.length === grades_spring.length) &&
          Array.isArray(item?.grades_summer) && (grades.length === grades_summer.length);

  return !item ? null : (
    <Row>
      <div className="border w-100 my-3" />
      <div key={index} className="mb-1" style={{
        color: item?.school?.id ? '#4d88f0' : 'black'
      }}>
        {item?.fullName}- {item?.college_info?.where_attend_college}- {item?.state}
      </div>
      <div>
        ({formatDate(item?.fromDate)} - {formatDate(item?.toDate)})
      </div>
      <CheckboxContent
        label="Check if you are still attending this college or university"
        values={item?.isStillAttendingThisCollege}
      />
      <div className="mt-3" />
      <CheckboxContentGroup
        label="Course Details"
        form={item}
        options={[
          {
            value: 'college_is_dual_enrollment',
            label: 'Dual enrollment with high school,',
          },
          {
            value: 'college_is_credit_awarded',
            label: 'Credit awarded directly by college ',
          },
          { value: 'college_is_summer_program', label: 'Summer program' },
        ]}
      />
      <ItemContent
        mdCol={12}
        label={`Do you expect to earn a degree from ${item?.fullName}? *`}
        values={item?.expect_to_earn_degree_from_San_Diego_Mesa}
      />
      {item?.expect_to_earn_degree_from_San_Diego_Mesa === 'true' && (
        <>
          <ItemContent mdCol={6} label={`Degree info`} values={item?.degree_info} />
          <ItemContent
            mdCol={6}
            label={`Degree in progress or will be earned`}
            values={item?.degree_earned}
          />

          <ItemContent
            mdCol={6}
            label={`Details of degree in progress or will be earned`}
            values={item?.college_info?.detail_degree_earned}
          />
          <ItemContent
            mdCol={6}
            label={`When did you earn that degree?`}
            values={`${item?.college_info?.when_you_earn_degree_month} - ${item?.college_info?.when_you_earn_degree_year}`}
          />

          <ItemContent
            mdCol={6}
            label={`What was your major?`}
            values={item?.college_info?.your_major}
          />

          <ItemContent
            mdCol={6}
            label={`What was your minor?`}
            values={item?.college_info?.your_minor}
          />

          <ItemContent
            mdCol={6}
            label={`Check if you were a double major`}
            values={item?.college_info?.have_double_major}
          />

          {item?.college_info?.have_double_major && (
            <ItemContent
              mdCol={6}
              label={`Select Second Major?`}
              values={item?.college_info?.your_second_major}
            />
          )}
        </>
      )}

      <Col md={12} className="my-4">
        <CheckboxContentGroup
          form={item}
          label={`College's grading system?`}
          options={[
            {
              value: 'college_grading_system_alphabet',
              label: 'A B C D F',
            },
            { value: 'college_grading_system_other', label: `Other` },
          ]}
        />
      </Col>
      <ItemContent
        mdCol={6}
        label={`Type of term system?`}
        values={item?.college_info?.type_of_term_systerm}
      />
      <ItemContent
        mdCol={6}
        label={`Your tuition status`}
        values={item?.college_info?.your_tuition_status}
      />
      
      <div className="mt-3">During which grades and terms did you attend this college?</div>
      {bCheckGrades ? (
        <>
        {(item?.grades && item?.grades.length>0) && (item?.grades ?? []).map((grade: any, i: any) => (
          <Row key={index}>
            {item?.grades && item?.grades.length>0 && (
              <ItemContent mdCol={4} label="Grades" values={item?.grades[i]} />
            )}
            {item?.grades_fall && item?.grades_fall.length>0 && (
              <ItemContent mdCol={2} label="Fall" values={item?.grades_fall[i]} />
            )}
            {item?.grades_winter && item?.grades_winter.length>0  && (
              <ItemContent mdCol={2} label="Winter" values={item?.grades_winter[i]} />
            )}
            {item?.grades_spring && item?.grades_spring.length>0 && (
              <ItemContent mdCol={2} label="Spring" values={item?.grades_spring[i]} />  
            )}
            {item?.grades_summer && item?.grades_summer.length>0 && (
              <ItemContent mdCol={2} label="Summer" values={item?.grades_summer[i]} />
            )}
          </Row>
        ))}
        </>
      ) : (<div>-</div>)}

    </Row>
  );
};

const EducationSum = (props: Props) => {
  const [loading, setLoading] = React.useState(true);
  const highSchoolStore = useAppSelector((store) => store.highSchool);
  const myHighSchool = {
    ...highSchoolStore.myHighSchool,
    graduating_school_array:
      checkEnableParseJSON(highSchoolStore.myHighSchool?.graduating_school_array) ?? [],
    high_school_array: checkEnableParseJSON(highSchoolStore.myHighSchool?.high_school_array) ?? [],
    high_school_info: checkEnableParseJSON(highSchoolStore.myHighSchool?.high_school_info),
  };

  const myCollege = {
    ...highSchoolStore.myCollege,
    colleges_taken_coursework_array:
      checkEnableParseJSON(highSchoolStore.myCollege?.colleges_taken_coursework_array) ?? [],
  };
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const getData = () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      Promise.all([
        dispatch(getHighSchool(studentId)),
        dispatch(getCollege(studentId)),
        dispatch(getFuturePlan(studentId)),
      ]).then(() => {
        setLoading(false);
      });
      return () => setLoading(true);
    } else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return loading === true ? (
    <LoadingContent />
  ) : (
    <div>
      <TopProcessBar stepProcess={[]} title="Summary" process={1} icon={<NoteAddOutlinedIcon />} />
      <Container className="main-container">
        <div className="px-2">
          <Row className="m-0 p-3 w-100 bg-light rounded">
            <div className="text-field-mid fs-3 mb-4">Education</div>
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="fs-5">High School</div>
              <Button
                onClick={() => history.push(routers.highSchool.path)}
                variant="outline-primary"
              >
                Edit
              </Button>
            </div>
            {buildContent(myHighSchool?.high_school_array[0], -1)}
            {myHighSchool && (
              <>
                <div className="fs-5">Other Secondary/High School(s)</div>

                <ItemContent
                  label="Number of other secondary/high school(s) you have attended"
                  values={myHighSchool?.number_of_other_school_attended}
                  mdCol={12}
                />

                <ItemContent
                  label="Other Secondary/High School(s)"
                  values={myHighSchool?.high_school_array
                    .filter((item: any, index: number) => index !== 0)
                    .map((item: any, index: number) => buildContent(item, index))}
                  // `${item.high_school_name} (${formatDate(item?.startDate)} - ${formatDate(
                  //   item?.endDate
                  // )}) `
                  mdCol={12}
                />
                <ItemContent
                  mdCol={12}
                  label="Please provide details about why you left each of the above secondary/high schools"
                  values={myHighSchool?.high_school_info?.left_highschool_reason}
                />
                <div className="border w-100 my-3" />
                <div className="fs-5 text-field-light">Graduating School</div>
                <ItemContent
                  label="Your graduating high school."
                  mdCol={12}
                  values={myHighSchool?.graduating_school_array?.graduating_high_school}
                />
                <ItemContent
                  label="Most recent academic year *"
                  values={myHighSchool?.graduating_school_array?.graduating_school_year}
                />
                <ItemContent
                  label="GPA Weighting"
                  values={myHighSchool?.graduating_school_array?.graduating_school_gpa_type}
                />
                <ItemContent
                  label="GPA Scale"
                  values={myHighSchool?.graduating_school_array?.graduating_school_gpa_scale}
                />
                <ItemContent
                  label="Cumulative GPA"
                  values={myHighSchool?.graduating_school_array?.graduating_school_cumulative_gpa}
                />
                {myHighSchool?.graduating_school_gpa_scale === 'Other' && (
                  <ItemContent label=" GPA scale other *" values={myHighSchool?.gpa_scale_other} />
                )}

                <ItemContent
                  label="Rank Report"
                  values={myHighSchool?.graduating_school_array?.graduating_school_rank_report}
                />
                <ItemContent label="Class Rank*" values={myHighSchool?.class_rank} />
                <ItemContent
                  mdCol={4}
                  label="How are the grades for this class reported on your transcript?"
                  values={myHighSchool?.rank_weighting}
                />
                <ItemContent
                  label="Class size"
                  values={myHighSchool?.graduating_school_array?.graduating_school_class_size}
                />

                <div className="border w-100 my-3" />
                <div className="fs-5 text-field-light">Counselor Information</div>
                {/* <ItemContent label="Counselor's Prefix" values={myHighSchool?.counselor_prefix} /> */}

                <ItemContent
                  mdCol={4}
                  label="Counselor's First Name"
                  values={myHighSchool?.counselor_first_name}
                />

                <ItemContent
                  mdCol={4}
                  label="Counselor's Last Name"
                  values={myHighSchool?.counselor_last_name}
                />
                {/* <ItemContent
                  label="Counselor's Middle Initital"
                  values={myHighSchool?.counselor_middle_initial}
                />
                <ItemContent label="Country Code" values={myHighSchool?.counselor_country_code} /> */}
                <ItemContent
                  mdCol={4}
                  label="Counselor's Email"
                  values={myHighSchool?.counselor_email}
                />
                {/* <ItemContent
                  label="Counselor's Job Title"
                  values={myHighSchool?.counselor_job_title}
                />
                <ItemContent label="Phone Number" values={myHighSchool?.counselor_phone_number} /> */}
              </>
            )}
            <div className="rich-border w-100 my-3" />
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="fs-5">Colleges & Universities </div>
              <Button
                onClick={() => history.push(routers.collegeAndUniversity.path)}
                variant="outline-primary"
              >
                Edit
              </Button>
            </div>
            {myCollege && (
              <>
                <ItemContent
                  mdCol={6}
                  label="Have you taken college courses or earned college credit? *"
                  values={myCollege?.is_taken_college_courses}
                />
                {myCollege?.is_taken_college_courses === true && (
                  <>
                    <div>College Courses or earned College Credit</div>
                    <ItemContent
                      mdCol={6}
                      label="Are you currently taking courses in college? *"
                      values={myCollege?.is_currently_taking_courses_in_college}
                    />
                    <div />
                    <ItemContent
                      mdCol={6}
                      label="How many college credits have you earned to date? *"
                      values={myCollege?.number_of_college_credits_have_earned}
                    />
                    <ItemContent
                      mdCol={6}
                      label="How many credits were earned after high school? *"
                      values={myCollege?.number_of_credits_earned_after_school}
                    />
                    <ItemContent
                      mdCol={6}
                      label="Indicate the number of colleges of universities you've taken a course at or received credit from"
                      values={myCollege?.number_of_colleges_taken_coursework}
                    />
                    {myCollege?.colleges_taken_coursework_array.map((item: any, index: number) =>
                      buildContentCollege(item, index)
                    )}
                    {/* {(collegeData || []).map((item, index) => (
                    <CollegeConfig
                      key={index + '-' + collegeData?.length}
                      handleSave={(values) => {
                        const newCollegeData = [...collegeData];
                        newCollegeData.splice(index, 1, values);
                        setCollegeData(newCollegeData);
                      }}
                      item={item}
                      index={`${index + 1}/${collegeData.length}`}
                    />
                  ))} */}
                  </>
                )}
              </>
            )}

            <div className="rich-border w-100 my-3" />
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="fs-5">Future Plans</div>
              <Button
                onClick={() => history.push(routers.futurePlan.path)}
                variant="outline-primary"
              >
                Edit
              </Button>
            </div>
            {highSchoolStore.myFuturePlan && (
              <>
                <ItemContent
                  label="Career Interest"
                  values={highSchoolStore.myFuturePlan.career_interest}
                  mdCol={6}
                />
                {highSchoolStore.myFuturePlan?.career_interest === 'Other' && (
                  <ItemContent
                    mdCol={6}
                    values={highSchoolStore.myFuturePlan?.other_career_interest}
                    label="Specify other organization"
                  />
                )}
                <ItemContent
                  label="Highest Degree you intend to earn"
                  values={highSchoolStore.myFuturePlan.highest_degree_intend_to_earn}
                  mdCol={6}
                />
              </>
            )}
          </Row>
          <div className="m-3 d-flex justify-content-end border-top pt-3">
            <div className="col col-md-3">
              <Button
                variant="primary"
                onClick={() => history.push(routers.testSum.path)}
                className="p-2 px-5 w-100"
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EducationSum;
