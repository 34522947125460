import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import ItemContent, { CheckboxContent } from './ItemContent';
import { formatDate } from 'components/helper/helper';
import CardConfig from 'components/CardConfig';
import Stack from '@mui/material/Stack';

interface Props {
  data: any;
  startIndex: number;
  total: number;
}

const ActivitySum = ({ data, startIndex, total }: Props) => {
  let showEarning = data.filter((i: any) => i?.categoryUC === 'Work Experience');

  return (
    <>
      {(data || []).map((item: any, index: number) => (
        <ActivityItem activity={item} key={index} index={index + 1 + startIndex + '/' + total} />
      ))}

      <div
        className={`text-field-light px-2 py-3 ${
          !showEarning.length && 'd-none'
        }`}
      >
        Please tell us how you've used your earnings from all of the jobs you've listed. This
        information will NOT be used to determine financial aid — rather, it gives us more context
        about you and your experiences.
      </div>
      <ItemContent
        mdCol={12}
        hidden={!showEarning.length}
        label="How have you used or will you use your earnings?"
        values={showEarning[0]?.howHaveYouUsedYourEarnings}
      />
    </>
  );
};

const ActivityItem = ({ activity, index }: { activity: any; index: string }) => {
  const [labelWhatDoYouDo, setLabelWhatDoYouDo] = React.useState({
    label: 'What did you do? *',
    feedback: '',
  });
  React.useEffect(() => {
    switch (activity?.categoryUC) {
      case 'Educational Preparation Program':
        setLabelWhatDoYouDo({
          label: 'Briefly Describe the Program',
          feedback: `Think about the program's main focus, your experience, and what you accomplished and learned while participating in the program.`,
        });
        break;
      case 'Other Coursework':
        setLabelWhatDoYouDo({
          label: 'Briefly Describe the course *',
          feedback: `What program or school offered the course? Also, think about describing the major themes or topics the course covered, as well as what knowledge or skills you learned.`,
        });
        break;
      case 'Work Experience':
        setLabelWhatDoYouDo({
          label: 'What were your job responsibilities?',
          feedback: ``,
        });
        break;
      default:
        setLabelWhatDoYouDo({
          label: 'What did you do? *',
          feedback: '',
        });
        break;
    }
  }, [activity?.categoryUC]);
  return (
    <CardConfig
      headerElement={
        <Stack direction="row" justifyContent="space-between" className="w-100">
          <span>
            {[
              activity?.categoryUC,
              activity?.activityExperienceProgramName,
              activity?.positionDescription,
              activity?.categoryUC === 'Work Experience'
                ? activity?.workExperience_whereDidYouWork
                : null,
              activity?.categoryUC === 'Work Experience' ? activity?.workExperience_jobTitle : null,
            ]
              .filter((text) => !!text)
              .join(' - ')}
            {renderFillAppStatus(activity)}
          </span>
          <span className="p-1 text-field-light fw-bold">{index}</span>
        </Stack>
      }
    >
      <Row className="p-3">
        <ItemContent mdCol={6} label="Category *" values={activity?.categoryUC} />
        <ItemContent mdCol={6} label="Activity Type" values={activity?.activityType} />

        {activity?.categoryUC === 'Extracurricular Activity' && (
          <Row className="bg-light rounded p-3">
            <Col md={6}>
              <div className="px-2">Based on Extracurricular Activity</div>
              <ItemContent
                label="What's the name of the activity?"
                values={activity?.activityExperienceProgramName}
                mdCol={12}
              />
            </Col>
            {(activity?.activityType === 'Athletics: Club' ||
              activity?.activityType === 'Athletics: JV/Varsity') && (
              <Col md={6}>
                <div>Based on Activity type Athetics</div>
                <ItemContent mdCol={12} label="Sport/team*" values={activity?.sportOrTeam} />
              </Col>
            )}
          </Row>
        )}

        {activity?.categoryUC === 'Other Coursework' && (
          <Row className="bg-light rounded p-3">
            <div className="px-2">Based on Other Coursework</div>
            <ItemContent label="What was the course name?" values={activity?.otherCoursewordName} />
          </Row>
        )}
        {activity?.categoryUC === 'Educational Preparation Program' && (
          <Row className="bg-light rounded p-3">
            <div className="px-2">Based on Educational Prep Program</div>
            <ItemContent
              mdCol={6}
              label="What was the program name? *"
              values={activity?.programNameForEducationPreparation}
            />
            {activity?.programNameForEducationPreparation === 'Other' && (
              <ItemContent
                mdCol={6}
                label="Program Name *"
                values={activity?.programNameForEducationPreparationSpecify}
              />
            )}
          </Row>
        )}
        {activity?.categoryUC === 'Volunteer/Community Service' && (
          <Row className="bg-light rounded p-3">
            <div className="px-2">Based on Volunteer/Community Service</div>
            <ItemContent
              mdCol={12}
              label="What's the name of the organization, program, school or group you volunteered for?"
              values={activity?.organizationVolunteeredFor}
            />
            <ItemContent
              mdCol={12}
              label="Please describe the organization, program, school or group. *"
              values={activity?.describeOrganization}
            />
          </Row>
        )}
        {activity?.categoryUC === 'Work Experience' && (
          <Row className="bg-light rounded p-3">
            <div className="px-2">Based on Work Experience</div>
            <ItemContent
              mdCol={12}
              label="Where did you work?"
              values={activity?.workExperience_whereDidYouWork}
            />
            <ItemContent
              mdCol={12}
              label="Please briefly describe the company or organization where you worked. *"
              values={activity?.describeCompany}
            />
            <ItemContent
              label="What was your job title/position"
              mdCol={12}
              values={activity?.workExperience_jobTitle}
            />
            <div className="text-dark px-2">When did you work at this job? *</div>

            <Card className="bg-body my-1 pb-2">
              <Row className="px-2">
                <CheckboxContent
                  col={3}
                  values={activity?.workExperience_workWhenNinthGrade}
                  label="9th Grade"
                />
                {activity?.workExperience_workWhenNinthGrade && (
                  <>
                    <CheckboxContent
                      col={2}
                      values={activity?.workExperience_ninthGradeSchoolYear}
                      label="School Year"
                    />
                    <ItemContent
                      mdCol={2}
                      label="Hours Per week"
                      values={activity?.workExperience_ninthGradeSchoolYearHoursPerWeek}
                    />

                    <CheckboxContent
                      col={2}
                      values={activity?.workExperience_ninthGradeSummer}
                      label="Summer"
                    />
                    <ItemContent
                      mdCol={2}
                      label="Hours Per week"
                      values={activity?.workExperience_ninthGradeSummerHoursPerWeek}
                    />
                  </>
                )}
              </Row>
            </Card>
            <Card className="bg-body my-1 pb-2">
              <Row className="px-2">
                <CheckboxContent
                  values={activity?.workExperience_workWhenTenthGrade}
                  label="10th Grade"
                  col={3}
                />
                {activity?.workExperience_workWhenTenthGrade && (
                  <>
                    <CheckboxContent
                      values={activity?.workExperience_tenthGradeSchoolYear}
                      label="School Year"
                      col={2}
                    />
                    <ItemContent
                      label="Hours Per week"
                      values={activity?.workExperience_tenthGradeSchoolYearHoursPerWeek}
                      mdCol={2}
                    />

                    <CheckboxContent
                      values={activity?.workExperience_tenthGradeSummer}
                      label="Summer"
                      col={2}
                    />
                    <ItemContent
                      label="Hours Per week"
                      values={activity?.workExperience_tenthGradeSummerHoursPerWeek}
                      mdCol={2}
                    />
                  </>
                )}
              </Row>
            </Card>

            <Card className="bg-body my-1 pb-2">
              <Row className="px-2">
                <CheckboxContent
                  col={3}
                  values={activity?.workExperience_workWhenEleventhGrade}
                  label="11th Grade"
                />
                {activity?.workExperience_workWhenEleventhGrade && (
                  <>
                    <CheckboxContent
                      col={2}
                      values={activity?.workExperience_eleventhGradeSchoolYear}
                      label="School Year"
                    />
                    <ItemContent
                      mdCol={2}
                      label="Hours Per week"
                      values={activity?.workExperience_eleventhGradeSchoolYearHoursPerWeek}
                    />

                    <CheckboxContent
                      col={2}
                      values={activity?.workExperience_eleventhGradeSummer}
                      label="Summer"
                    />
                    <ItemContent
                      mdCol={2}
                      label="Hours Per week"
                      values={activity?.workExperience_eleventhGradeSummerHoursPerWeek}
                    />
                  </>
                )}
              </Row>
            </Card>

            <Card className="bg-body my-1 pb-2">
              <Row className="px-2">
                <CheckboxContent
                  values={activity?.workExperience_workWhenTwelfthGrade}
                  label="12th Grade"
                  col={3}
                />
                {activity?.workExperience_workWhenTwelfthGrade && (
                  <>
                    <CheckboxContent
                      col={2}
                      values={activity?.workExperience_twelfthGradeSchoolYear}
                      label="School Year"
                    />
                    <ItemContent
                      label="Hours Per week"
                      values={activity?.workExperience_twelfthGradeSchoolYearHoursPerWeek}
                      mdCol={2}
                    />

                    <CheckboxContent
                      values={activity?.workExperience_twelfthGradeSummer}
                      label="Summer"
                      col={2}
                    />
                    <ItemContent
                      label="Hours Per week"
                      values={activity?.workExperience_twelfthGradeSummerHoursPerWeek}
                      mdCol={2}
                    />
                  </>
                )}
              </Row>
            </Card>
            {/* <Card className="bg-body my-1">
              <Row className="px-2">
                <CheckboxContent
                  values={activity?.workExperience_workAfterTwelfthGrade}
                  label="After 12th"
                />
                {activity?.workExperience_workAfterTwelfthGrade && (
                  <>
                    <ItemContent
                      values={activity?.workExperience_afterTwelfthGradeSummerYear}
                      label="Number of Years *"
                    />
                    <ItemContent
                      values={activity?.workExperience_afterTwelfthGradeSummerMonth}
                      label="Number of Months *"
                    />
                    <CheckboxContent
                      values={activity?.workExperience_afterTwelfthGradeSchoolYear}
                      label="School Year"
                    />
                    <ItemContent
                      // type="number"
                      label="Hours Per week"
                      values={activity?.workExperience_afterTwelfthGradeSchoolYearHoursPerWeek}
                    />

                    <CheckboxContent
                      values={activity?.workExperience_afterTwelfthGradeSummer}
                      label="Summer"
                    />
                    <ItemContent
                      label="Hours Per week"
                      values={activity?.workExperience_afterTwelfthGradeSummerHoursPerWeek}
                    />
                  </>
                )}
              </Row>
            </Card> */}
            <ItemContent values={activity?.isStillWork} label="Do you still work at this job? *" />
            <ItemContent label="Start date" values={formatDate(activity?.startDate)} />
            <ItemContent label="End date" values={formatDate(activity?.endDate)} />
          </Row>
        )}
        <div />

        <ItemContent
          hidden={
            activity?.categoryUC === 'Volunteer/Community Service' ||
            activity?.categoryUC === 'Work Experience'
          }
          label="Organization Name*"
          values={activity?.programName}
          mdCol={6}
        />
        <ItemContent
          hidden={activity?.categoryUC === 'Work Experience'}
          label={
            activity?.categoryUC === 'Work Experience'
              ? 'What is your Job Title/Position'
              : 'Position/Leadership description'
          }
          values={activity?.positionDescription}
          mdCol={6}
        />
        <ItemContent
          mdCol={12}
          label="Please describe this activity, including what you accomplished and any recognition you received, etc."
          values={activity?.describeActivity}
        />
        <ItemContent mdCol={12} label={labelWhatDoYouDo.label} values={activity?.whatDidYouDo} />

        <div
          className={`text-field-light px-2 ${
            activity?.categoryUC !== 'Work Experience' && 'd-none'
          }`}
        >
          Please tell us how you've used your earnings from all of the jobs you've listed. This
          information will NOT be used to determine financial aid — rather, it gives us more context
          about you and your experiences.
        </div>
        <ItemContent
          mdCol={12}
          hidden={activity?.categoryUC !== 'Work Experience'}
          label="How have you used or will you use your earnings?"
          values={activity?.howHaveYouUsedYourEarnings}
        />
        <div className="text-dark px-2">Participation grade levels*</div>
        <CheckboxContent col={3} label="9" values={activity?.isGrade9ParticipationLevels} />
        <CheckboxContent col={3} label="10" values={activity?.isGrade10ParticipationLevels} />
        <CheckboxContent col={3} label="11" values={activity?.isGrade11ParticipationLevels} />
        <CheckboxContent col={3} label="12" values={activity?.isGrade12ParticipationLevels} />
        {/* <CheckboxContent
          col={6}
          label="Post-graduate (can select mulitple)"
          values={activity?.isPostGraduateParticipationLevels}
        /> */}

        <ItemContent
          mdCol={6}
          label="Are you currently participating in this activity or experience?"
          values={activity?.isCurrentlyParticipatingActivity}
        />

        <ItemContent
          mdCol={6}
          label="I intend to participate in a similar activity in college.*"
          values={activity?.isIntendParticipateSimilarActivity}
        />
        <div className="text-dark">Timing of Participation</div>
        <CheckboxContent
          col={4}
          label="During school year"
          values={activity?.timmingOfParticipation_duringYear}
        />
        <CheckboxContent
          col={4}
          label="During school break"
          values={activity?.timmingOfParticipation_duringBreak}
        />
        <CheckboxContent
          col={4}
          label="All year"
          values={activity?.timmingOfParticipation_allYear}
        />
        <ItemContent label="Hours per Week" values={activity?.hoursPerWeek} />
        <ItemContent label="Weeks per Year" values={activity?.weeksPerYear} />

        <div className="text-dark">
          Select the applications you would like to include this activity on. *See App Notes above
          for activity limits
        </div>
        <CheckboxContent col={4} label="UC App" values={activity?.isIncludeIntoUCApp} />
        <CheckboxContent col={4} label="Common App" values={activity?.isIncludeIntoCommonApp} />
      </Row>
    </CardConfig>
  );
};

export default ActivitySum;
const renderFillAppStatus = (item: any) => {
  return (
    <span>
      {item.isIncludeIntoCommonApp && (
        <img
          alt="cm"
          src="/assets/icons/icon-cm-5.svg"
          style={{ marginTop: '-8px', marginLeft: '4px' }}
        />
      )}
      {item.isIncludeIntoUCApp && (
        <img
          alt="uc"
          src="/assets/icons/icon-uc-5.svg"
          style={{ marginTop: '-8px', marginLeft: '4px' }}
        />
      )}
      {item.isIncludeIntoCSUApp && (
        <img
          alt="csu"
          src="./assets/icons/icon-csu-5.svg"
          style={{ marginTop: '-8px', marginLeft: '4px' }}
        />
      )}
    </span>
  );
};
