import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Slider } from '@mui/material';

const ModalAdmissionRequirement = ({ open, setOpen, formik }: any) => {
  const [valueGPA, setValueGPA] = React.useState<any>([0, 4]);
  const [valueSAT, setValueSAT] = React.useState<any>([400, 1600]);
  const [valueACT, setValueACT] = React.useState<any>([0, 36]);
  return (
    <Dialog open={open} onClose={() => setOpen(false)} keepMounted fullWidth>
      <DialogTitle className="fw-bold fs-3">Admission Requirements</DialogTitle>
      <DialogContent>
        {/* <Box className="fw-bold fs-5 mt-3">Difficulty for Admittance</Box> */}

        <Box className="fw-bold fs-5 mt-3">Average UWGPA for Incoming Freshmen</Box>
        <Box className="m-3">
          <Slider
            min={0}
            max={4}
            step={0.25}
            getAriaLabel={() => 'Average GPA'}
            value={valueGPA}
            onChange={(event, newValue) => setValueGPA(newValue)}
            valueLabelDisplay="auto"
            marks={[
              { value: 0, label: '0.0' },
              { value: 4, label: '4.0' },
            ]}
          />
        </Box>

        <Box className="fw-bold fs-5 mt-3">Test Scores</Box>
        <Box className="m-3">
          <Box>Average SAT Score</Box>
          <Slider
            min={400}
            max={1600}
            step={50}
            getAriaLabel={() => 'Average SAT Score'}
            value={valueSAT}
            onChange={(event, newValue) => setValueSAT(newValue)}
            valueLabelDisplay="auto"
            marks={[
              { value: 400, label: '400' },
              { value: 1600, label: '1600' },
            ]}
          />
        </Box>
        <Box className="m-3">
          <Box>Average ACT Score</Box>
          <Slider
            min={0}
            max={36}
            step={1}
            getAriaLabel={() => 'Average ACT Score'}
            value={valueACT}
            onChange={(event, newValue) => setValueACT(newValue)}
            valueLabelDisplay="auto"
            marks={[
              { value: 0, label: '0' },
              { value: 36, label: '36' },
            ]}
          />
        </Box>
      </DialogContent>
      <DialogActions className="p-4">
        <Button
          variant="contained"
          onClick={() => {
            formik.setValues({
              ...formik.values,
              from_gpa: valueGPA[0] === 0 ? null : valueGPA[0],
              to_gpa: valueGPA[1] === 4 ? null : valueGPA[1],
              from_sat: valueSAT[0] === 400 ? null : valueSAT[0],
              to_sat: valueSAT[1] === 1600 ? null : valueSAT[1],
              from_act: valueACT[0] === 0 ? null : valueACT[0],
              to_act: valueACT[1] === 36 ? null : valueACT[1],
            });
            formik.handleSubmit();
            setOpen(false);
          }}
        >
          Show Colleges
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAdmissionRequirement;
