import * as Yup from 'yup';

export const basicInformationRules = Yup.object({
  email: Yup.string(),
  dob: Yup.mixed(),
  city: Yup.mixed(),
  state: Yup.mixed(),
  first_name: Yup.string().max(32, 'Maximum 32 character.'),
  gender_identity_description: Yup.string().max(100, 'Maximum 100 character.').nullable(),
  last_name: Yup.string().max(25, 'Maximum 25 character.'),
  middle_name: Yup.string().max(25, 'Maximum 25 character.').nullable(),
  nick_name: Yup.string().max(20, 'Maximum 20 character.').nullable(),
  legal_my_first_name: Yup.string().max(50, 'Maximum 50 character.').nullable(),
  legal_first_name: Yup.string().max(23, 'Maximum 23 character.').nullable(),
  legal_middle_name: Yup.string().max(23, 'Maximum 23 character.').nullable(),
  legal_family_name: Yup.string().max(23, 'Maximum 23 character.').nullable(),
  legal_first_name_you_go_by: Yup.string().max(56, 'Maximum 56 character.').nullable(),
});
