/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { getGradeAndCourse, updateGradeAndCourse } from 'app/course/courseSlice';
import CheckboxInput from 'components/input/CheckboxInput';
import CourseConfig from '../CourseConfig';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { HtmlTooltip } from 'screen/selectCollege/SelecCollegePage';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { debounce } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { reorder } from 'components/helper/helper';
import { getHighSchoolData } from 'app/highSchoolSlice/highSchoolSlice';

interface Props {}

const NinthGrade = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const course = useAppSelector((store) => store.course);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const [courseData, setCourseData] = React.useState<any[]>([]);
  // const [currentSavingCardStatus, setCurrentSavingCardStatus] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      grade_9_course_array: '',
      is_reported_all_grade_9: false,
    },
    onSubmit: async (values) => {
      studentId = Cookies.get('userId');
      token = Cookies.get(authenticateSettings.tokenName);
      if (token && studentId) {
        try {
          const newValues = {
            ...values,
            student_id: studentId,
            grade_9_course_array: JSON.stringify(courseData),
          };
          await dispatch(updateGradeAndCourse(newValues));
        } catch (ex) {
          console.log('Error:', ex);
        }
      } else {
        handleLogout();
      }
    },
  });
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.grade10.path);
  };
  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      try {
        setLoading(true);
        dispatch(getHighSchoolData(studentId));
        const res = await dispatch(getGradeAndCourse(studentId));
        const dataLoaded = res.payload;
        if (dataLoaded) {
          formik.setValues({
            ...dataLoaded,
            is_reported_all_grade_9: dataLoaded.is_reported_all_grade_9,
          });
          setCourseData(JSON.parse(dataLoaded.grade_9_course_array) || []);
        }
        setLoading(false);
        formik.setStatus('idle');
      } catch (ex) {
        console.log('Error:', ex);
      }
      setLoading(false);
    } else {
      handleLogout();
    }
  };
  React.useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddCourse = () => {
    if (courseData.length > 8) return;
    setCourseData([...courseData, {}]);
  };
  // Auto save function ==========
  const notInitialRender = React.useRef(false);
  React.useEffect(() => {
    debounceSaving();
  }, [formik.values, courseData]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (notInitialRender.current) {
        if (!loading) formik.handleSubmit();
      } else {
        notInitialRender.current = true;
      }
    }, 1500),
    []
  );
  // Auto save function ==========
  const [forceRenderKey, setForceRenderKey] = React.useState(0);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const dataList = reorder(courseData, result.source.index, result.destination.index);
    setCourseData([...dataList]);
    setForceRenderKey(forceRenderKey + 1);
  };
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[{ percentOnProcess: 30, title: '' }]}
        title="Courses & Grades"
        process={1}
        icon={<StackedLineChartIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="fs-6 text-dark my-2 fw-bold">
                    <InfoOutlinedIcon fontSize="small" className="mx-1" />
                    Courses & Grades
                  </div>
                  <div className="d-flex flex-wrap text-field-light">
                    Many college applications will want you to list your Courses and Grades and/or
                    GPA in various formats. Enter your information as you complete each of your high
                    school courses to make it easy for you during college application season.
                  </div>
                </React.Fragment>
              }
              placement="right-start"
            >
              <Row className="m-0">
                <div className="text-field-mid fs-3 mb-4">9th Grade</div>
                <DragDropContext onDragEnd={onDragEnd} key={forceRenderKey}>
                  <Droppable droppableId="list">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {(courseData || []).map((item, index) => (
                          <div key={index + '-' + courseData.length}>
                            <Draggable draggableId={index + '-' + courseData.length} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    key={index + '-' + courseData.length}
                                    className="position-relative"
                                  >
                                    <CourseConfig
                                      defaultGradeValue="11th Grade"
                                      handleSave={(values) => {
                                        const newCourseData = [...courseData];
                                        newCourseData.splice(index, 1, values);
                                        setCourseData(newCourseData);
                                      }}
                                      item={item}
                                      total={courseData.length}
                                      index={index + 1}
                                    />
                                    <RemoveCircleOutlineIcon
                                      className="position-absolute text-field-light pointer"
                                      style={{ right: '-24px', top: '12px' }}
                                      onClick={() => {
                                        const newCourseData = [...courseData];
                                        newCourseData.splice(index, 1);
                                        setCourseData(newCourseData);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </div>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <div className="my-3 w-100 d-flex flex-column align-items-center">
                  <div className="text-field-light sized70">You can add up to 9 high schools</div>
                  <Button variant="primary" className="p-2 px-5" onClick={handleAddCourse}>
                    Add Additional High Schools
                  </Button>
                </div>
                <div className="common-flag pt-3">
                  <div className="text-field-light">
                    Please indicate that you have reported all courses and grades for 9th grade.*
                  </div>
                  <CheckboxInput
                    name="is_reported_all_grade_9"
                    values={formik.values.is_reported_all_grade_9}
                    onChange={formik.handleChange}
                    label="I have reported all of my courses for this grade."
                  />
                </div>
              </Row>
            </HtmlTooltip>

            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {course.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default NinthGrade;
