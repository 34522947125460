import http from './http';

const highSchoolServices = {
  async searchHighSchool(keyword: string) {
    const res = keyword ? await http.get(`api/high-school-list/${keyword}`) : [];
    return res;
  },

  async getHighSchool(student_id: any) {
    const res = await http.get(`api/high-school/${student_id}`);
    return res;
  },

  async createHighSchool(body: any): Promise<any> {
    const res = await http.post('api/high-school', body);
    return res;
  },

  async updateHighSchool(body: any): Promise<any> {
    const res = await http.put('api/high-school', body);
    return res;
  },
  async getCollege(student_id: any) {
    const res = await http.get(`api/college-university/${student_id}`);
    return res;
  },

  async createCollege(body: any): Promise<any> {
    const res = await http.post('api/college-university', body);
    return res;
  },

  async updateCollege(body: any): Promise<any> {
    const res = await http.put('api/college-university', body);
    return res;
  },
  async getFuturePlan(student_id: any) {
    const res = await http.get(`api/future-plan/${student_id}`);
    return res;
  },

  async createFuturePlanThunk(body: any): Promise<any> {
    const res = await http.post('api/future-plan', body);
    return res;
  },

  async updateFuturePlanThunk(body: any): Promise<any> {
    const res = await http.put('api/future-plan', body);
    return res;
  },

  async createFuturePlan(values: any, studentId: any): Promise<any> {
    const body = {
      ...values,
      student_id: studentId,
    };
    const res = await http.post('api/future-plan', body);
    return res;
    
  },

  async updateFuturePlan(values: any, studentId: any): Promise<any> {
    const body = {
      ...values,
      student_id: studentId,
    };
    const res = await http.put('api/future-plan', body);
    return res;
  },

};

export default highSchoolServices;
