import CardConfig from 'components/CardConfig';
import { formatDate, optionValues2 } from 'components/helper/helper';
import DatePickerInput from 'components/input/DatePicker';
import SelectInput from 'components/input/SelectInput';
import React from 'react';
import { Row } from 'react-bootstrap';

type Props = {
  form: any;
  index: number;
};

const ACTItem = ({ form, index }: Props) => {
  const formValues = form.values?.csu_info?.act_score[index];
  const formName = `csu_info.act_score[${index}].`;

  const buildHeader = () => {
    let title = `ACT Test Scores (${index + 1})`;
    const details = [];

    if (formValues?.test_date) {
      details.push(
        formatDate(formValues.test_date, 'MM-DD-YYYY'),
      );

    }

    if (formValues?.composite_score) {
      details.push(`Composite Score: ${formValues.composite_score}`);
    }

    if (formValues?.english) {
      details.push(`English: ${formValues.english}`);
    }

    if (formValues?.mathematics) {
      details.push(`Math: ${formValues.mathematics}`);
    }

    if (formValues?.reading) {
      details.push(`Reading: ${formValues.reading}`);
    }

    if (formValues?.science) {
      details.push(`Science: ${formValues.science}`);
    }

    if(!details.length ) {
      return title;
    }
    
    switch (details.length) {
      case 1:
        return `${title} - ${details[0]}`;
      case 2:
        return `${title} - ${details[0]} - ${details[1]}`;
      default:
        return `${title} - ${[details.slice(0, 2).join(' - '), details.slice(2, details.length).join(", ")].join(', ')}`;
    }
  }
  const renderFillAppStatus = () => {
    return (
      <span>
          <img
            alt="csu"
            src="./assets/icons/icon-csu-5.svg"
            style={{ marginTop: '-8px', marginLeft: '4px' }}
          />
      </span>
    );
  };
  return !formValues ? null : (
    <CardConfig headerElement={<>{buildHeader()}{renderFillAppStatus()}</>}>
      <Row>
        <DatePickerInput
          label="Test Date *"
          name={formName + 'test_date'}
          values={formValues.test_date}
          onChange={(value) => form.setFieldValue(formName + 'test_date', value)}
        />
        <SelectInput
          label="Composite score*"
          name={formName + 'composite_score'}
          option={optionValues2(37).reverse()}
          values={formValues.composite_score}
          onChange={form.handleChange}
        />
        <SelectInput
          label="English *"
          name={formName + 'english'}
          option={optionValues2(37).reverse()}
          values={formValues.english}
          onChange={form.handleChange}
        />
        <SelectInput
          label="Mathematics *"
          name={formName + 'mathematics'}
          option={optionValues2(37).reverse()}
          values={formValues.mathematics}
          onChange={form.handleChange}
        />
        <SelectInput
          label="Reading *"
          name={formName + 'reading'}
          option={optionValues2(37).reverse()}
          values={formValues.reading}
          onChange={form.handleChange}
        />
        <SelectInput
          label="Science *"
          name={formName + 'science'}
          option={optionValues2(37).reverse()}
          values={formValues.science}
          onChange={form.handleChange}
        />
        <SelectInput
          hidden={form.values.is_have_act_score_report !== 'true'}
          label="Writing Score"
          name={formName + 'writing'}
          option={optionValues2(11, 2, 1).reverse()}
          values={formValues.writing}
          onChange={form.handleChange}
        />
      </Row>
    </CardConfig>
  );
};

export default ACTItem;
