/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import CheckboxInput from 'components/input/CheckboxInput';
import DatePickerInput from 'components/input/DatePicker';
import SelectInput from 'components/input/SelectInput';
import ReuseFieldScoreAndDate from './components/ReuseFieldScoreAndDate';
import GroupRadioButton from 'components/input/GroupRadioButton';
import { getIELTSAndPTE, updateIELTSAndPTE } from 'app/testingSlice/testingSlice';
import { optionValues } from 'components/helper/helper';
import { HtmlTooltip } from 'screen/selectCollege/SelecCollegePage';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import YesNoSwitch from 'components/input/YesNoSwitch';
import { debounce } from 'lodash';

interface Props {}

const IELTSAndPTE = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const testing = useAppSelector((store) => store.testing);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const formik = useFormik({
    initialValues: {
      is_not_required_pte_test: false,
      number_of_times_taken_pte_test: 0,
      number_of_future_pte_test_expect: 0,
      future_pte_testing_date: '',
      pte_highest_listening_score: '',
      pte_listening_date_taken: '',
      pte_highest_reading_score: '',
      pte_reading_date_taken: '',
      pte_highest_speaking_score: '',
      pte_speaking_date_taken: '',
      pte_highest_writing_score: '',
      pte_writing_date_taken: '',
      pte_highest_grammar_score: '',
      pte_grammar_date_taken: '',
      pte_highest_oral_fluency_score: '',
      pte_oral_fluency_date_taken: '',
      pte_highest_pronunciation_score: '',
      pte_pronunciation_date_taken: '',
      pte_highest_spelling_score: '',
      pte_spelling_date_taken: '',
      pte_highest_vocabulary_score: '',
      pte_vocabulary_date_taken: '',
      pte_highest_written_discourse_score: '',
      pte_written_discourse_date_taken: '',
      is_not_required_ielts: false,
      number_of_times_taken_ielts: 0,
      future_ielts_testing_date: '',
      number_of_future_ielts_expect: '0',
      ielts_highest_listening_score: '',
      ielts_listening_date_taken: '',
      ielts_highest_reading_score: '',
      ielts_reading_date_taken: '',
      ielts_highest_speaking_score: '',
      ielts_speaking_date_taken: '',
      ielts_highest_writing_score: '',
      ielts_writing_date_taken: '',
      ielts_highest_overall_band_score: '',
      ielts_overall_band_score_date_taken: '',
      // New field
      ielts_received_score: '',
      ielts_listening_score: '',
      ielts_reading_score: '',
      ielts_speaking_score: '',
      ielts_writing_score: '',
      ielts_overall_band_score: '',
      ielts_plan_on_retake_test: '',
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        student_id: studentId,
      };
      await dispatch(updateIELTSAndPTE(newValues));
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    const nextRoute = Object.keys(testing.roleTest).find(
      (key, index) =>
        index > Object.keys(testing.roleTest).indexOf('ieltsAndPte') &&
        testing.roleTest[key] === true
    );
    history.push(nextRoute ? routers?.[nextRoute]?.path : routers.otherTest.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      let res = await dispatch(getIELTSAndPTE(studentId));
      const dataLoaded: any = res.payload;
      if (dataLoaded) {
        const values: any = {
          ...dataLoaded,
          number_of_future_ielts_expect: dataLoaded.number_of_future_ielts_expect.toString(),
        };
        formik.setValues(values);
      }
      setLoading(false);
      formik.setStatus('idle');
    } else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getDetail();
  }, []);

  // Auto save function ==========
  const notInitialRender = React.useRef(false);
  React.useEffect(() => {
    debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (notInitialRender.current) {
        if (!loading) formik.handleSubmit();
      } else {
        notInitialRender.current = true;
      }
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[
          { title: 'PTE', percentOnProcess: 30 },
          { title: 'IELTS', percentOnProcess: 70 },
        ]}
        title="Standardized Testing"
        process={1}
        icon={<CalculateOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2" id="PTE">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography className="fs-6 text-dark my-2 fw-bold">
                    <InfoOutlinedIcon fontSize="small" className="mx-1" />
                    PTE Academic Tests
                  </Typography>
                  <div className="d-flex flex-wrap text-field-light">
                    For international students - the testing policies for specific colleges can
                    vary. Check with the colleges you intend to apply to in advance to determine the
                    tests, if any, you'll need to take. Note, be sure to do in your 1st or 2nd year
                    of high school so you can plan accordingly when to take your test(s).
                  </div>
                </React.Fragment>
              }
              placement="right-start"
            >
              <Row className="m-0 common-flag">
                <div className="text-field-mid fs-3 mb-4">PTE Academic Tests</div>
                <CheckboxInput
                  onChange={formik.handleChange}
                  name="is_not_required_pte_test"
                  values={formik.values.is_not_required_pte_test}
                  label="I’m a domestic student or am not required to take the PTE Academic Tests."
                />
                <SelectInput
                  form={formik}
                  disabled={formik.values.is_not_required_pte_test}
                  label="Number of times you have already taken the PTE Academic Test*"
                  option={optionValues(Array.from(Array(6).keys()))}
                  name="number_of_times_taken_pte_test"
                  values={formik.values.number_of_times_taken_pte_test}
                  onChange={formik.handleChange}
                />
                <SelectInput
                  form={formik}
                  disabled={formik.values.is_not_required_pte_test}
                  label="Number of future PTE Academic Test sittings you expect*"
                  option={[{ value: '0' }, { value: '1' }, { value: '2' }, { value: '3' }]}
                  name="number_of_future_pte_test_expect"
                  values={formik.values.number_of_future_pte_test_expect}
                  onChange={formik.handleChange}
                />
                <DatePickerInput
                  disabled={formik.values.is_not_required_pte_test}
                  label="Future testing date *"
                  name="future_pte_testing_date"
                  values={formik.values.future_pte_testing_date}
                  onChange={(values) => formik.setFieldValue('future_pte_testing_date', values)}
                />
                <div />
                <ReuseFieldScoreAndDate
                  selectOptions={optionValues(
                    Array.from(Array(81).keys()).map((item) => item + 10)
                  )}
                  disabled={formik.values.is_not_required_pte_test}
                  formik={formik}
                  arrayName={[
                    {
                      fieldName: 'pte_highest_listening_score',
                      dateName: 'pte_listening_date_taken',
                      label: 'listening',
                    },
                    {
                      fieldName: 'pte_highest_reading_score',
                      dateName: 'pte_reading_date_taken',
                      label: 'reading',
                    },
                    {
                      fieldName: 'pte_highest_speaking_score',
                      dateName: 'pte_speaking_date_taken',
                      label: 'speaking',
                    },
                    {
                      fieldName: 'pte_highest_writing_score',
                      dateName: 'pte_writing_date_taken',
                      label: 'writing',
                    },
                    {
                      fieldName: 'pte_highest_grammar_score',
                      dateName: 'pte_grammar_date_taken',
                      label: 'grammer',
                    },
                    {
                      fieldName: 'pte_highest_oral_fluency_score',
                      dateName: 'pte_oral_fluency_date_taken',
                      label: 'oral fluency',
                    },
                    {
                      fieldName: 'pte_highest_pronunciation_score',
                      dateName: 'pte_pronunciation_date_taken',
                      label: 'pronunciation',
                    },
                    {
                      fieldName: 'pte_highest_spelling_score',
                      dateName: 'pte_spelling_date_taken',
                      label: 'spelling',
                    },
                    {
                      fieldName: 'pte_highest_vocabulary_score',
                      dateName: 'pte_vocabulary_date_taken',
                      label: 'vocabulary',
                    },
                    {
                      fieldName: 'pte_highest_written_discourse_score',
                      dateName: 'pte_written_discourse_date_taken',
                      label: 'written discourse',
                    },
                  ]}
                />
              </Row>
            </HtmlTooltip>

            <div className="border w-100 my-3" id="IELTS" />

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography className="fs-6 text-dark my-2 fw-bold">
                    <InfoOutlinedIcon fontSize="small" className="mx-1" />
                    IELTS
                  </Typography>
                  <div className="d-flex flex-wrap text-field-light">
                    For international students - the testing policies for specific colleges can
                    vary. Check with the colleges you intend to apply to in advance to determine the
                    tests, if any, you'll need to take. Note, be sure to do in your 1st or 2nd year
                    of high school so you can plan accordingly when to take your test(s).
                  </div>
                </React.Fragment>
              }
              placement="right-start"
            >
              <Row className="m-0 common-flag">
                <div className="text-field-mid fs-3 mb-4">IELTS</div>
                <CheckboxInput
                  onChange={formik.handleChange}
                  name="is_not_required_ielts"
                  values={formik.values.is_not_required_ielts}
                  label="I’m a domestic student or am not required to take the IELTS."
                />
                <SelectInput
                  form={formik}
                  label="Number of times you have already taken the IELTS*"
                  option={optionValues(Array.from(Array(6).keys()))}
                  name="number_of_times_taken_ielts"
                  values={formik.values.number_of_times_taken_ielts}
                  onChange={formik.handleChange}
                  disabled={formik.values.is_not_required_ielts}
                />
                <div />
                <YesNoSwitch
                  className="mb-3"
                  name="ielts_received_score"
                  label="Have you received your scores yet? *"
                  values={formik.values.ielts_received_score}
                  onChange={formik.handleChange}
                />
                <ReuseFieldScoreAndDate
                  selectOptions={optionValues(Array.from(Array(19).keys()).map((item) => item / 2))}
                  disabled={formik.values.is_not_required_ielts}
                  formik={formik}
                  arrayName={[
                    {
                      fieldName: 'ielts_highest_listening_score',
                      dateName: 'ielts_listening_date_taken',
                      label: 'listening',
                    },
                    {
                      fieldName: 'ielts_highest_reading_score',
                      dateName: 'ielts_reading_date_taken',
                      label: 'reading',
                    },
                    {
                      fieldName: 'ielts_highest_speaking_score',
                      dateName: 'ielts_speaking_date_taken',
                      label: 'speaking',
                    },
                    {
                      fieldName: 'ielts_highest_writing_score',
                      dateName: 'ielts_writing_date_taken',
                      label: 'writing',
                    },
                    {
                      fieldName: 'ielts_highest_overall_band_score',
                      dateName: 'ielts_overall_band_score_date_taken',
                      label: 'IELTS overall band',
                    },
                  ]}
                />
                <div>Score</div>
                <SelectInput
                  name="ielts_listening_score"
                  label="Listening*"
                  values={formik.values.ielts_listening_score}
                  onChange={formik.handleChange}
                  option={optionValues(Array.from(Array(19).keys()).map((item) => item / 2))}
                />
                <SelectInput
                  name="ielts_reading_score"
                  label="Reading*"
                  values={formik.values.ielts_reading_score}
                  onChange={formik.handleChange}
                  option={optionValues(Array.from(Array(19).keys()).map((item) => item / 2))}
                />
                <SelectInput
                  name="ielts_writing_score"
                  label="Writing*"
                  values={formik.values.ielts_writing_score}
                  onChange={formik.handleChange}
                  option={optionValues(Array.from(Array(19).keys()).map((item) => item / 2))}
                />
                <SelectInput
                  name="ielts_speaking_score"
                  label="Speaking*"
                  values={formik.values.ielts_speaking_score}
                  onChange={formik.handleChange}
                  option={optionValues(Array.from(Array(19).keys()).map((item) => item / 2))}
                />
                <SelectInput
                  name="ielts_overall_band_score"
                  label="Overall Band Score"
                  values={formik.values.ielts_overall_band_score}
                  onChange={formik.handleChange}
                  option={optionValues(Array.from(Array(19).keys()).map((item) => item / 2))}
                />
                <div />
                <YesNoSwitch
                  className="mb-3"
                  label="Do you plan on retaking this test?*"
                  name="ielts_plan_on_retake_test"
                  values={formik.values.ielts_plan_on_retake_test}
                  onChange={formik.handleChange}
                />
                {formik.values.ielts_plan_on_retake_test === 'true' && (
                  <>
                    <GroupRadioButton
                      label="Number of future IELTS sittings you expect*"
                      options={[{ value: '0' }, { value: '1' }, { value: '2' }, { value: '3' }]}
                      values={formik.values.number_of_future_ielts_expect}
                      onChange={formik.handleChange}
                      name="number_of_future_ielts_expect"
                      disabled={formik.values.is_not_required_ielts}
                    />
                    {formik.values.number_of_future_ielts_expect !== '0' && (
                      <DatePickerInput
                        label="When will you retake this test? *"
                        name="future_ielts_testing_date"
                        values={formik.values.future_ielts_testing_date}
                        onChange={(values) =>
                          formik.setFieldValue('future_ielts_testing_date', values)
                        }
                        disabled={formik.values.is_not_required_ielts}
                      />
                    )}
                  </>
                )}
              </Row>
            </HtmlTooltip>

            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {testing.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default IELTSAndPTE;
