/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAppSelector } from 'app/hooks';
import LoadingContent from 'components/LoadingContent';
import { checkEnableParseJSON, reorder } from 'components/helper/helper';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import { debounce } from 'lodash';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { getCLEPTest, updateCLEPTest } from 'app/testingSlice/testingSlice';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CLEPConfig from './CLEPConfig';

type Props = {};

type FormItem = {
  CLEPExam: string;
  is_taken_exam: string;
  plan_taking_on: string;
  date_taken: string;
  score: string;
  is_not_received: boolean;
};

const formItem: FormItem = {
  CLEPExam: '',
  is_taken_exam: '',
  plan_taking_on: '',
  date_taken: '',
  score: '',
  is_not_received: false,
};

const initialValues: { csu_info: FormItem[] } = { csu_info: [formItem] };

const CLEP = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const testing = useAppSelector((store) => store.testing);
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      const newValues = {
        student_id: studentId,
        csu_info: JSON.stringify(values.csu_info),
      };
      await dispatch(updateCLEPTest(newValues));
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.otherTest.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      let res = await dispatch(getCLEPTest(studentId));
      const dataLoaded: any = res.payload;
      if (dataLoaded) {
        let csuInformation = checkEnableParseJSON(dataLoaded.csu_info);
        const values: any = {
          csu_info: Array.isArray(csuInformation) ? csuInformation : [],
        };
        formik.setValues(values);
      }
      setLoading(false);
      formik.setStatus('idle');        
    }
    else {
      handleLogout();
    } 
  };

  React.useEffect(() => {
    getDetail();
  }, []);

  const handleAdd = () => {
    if (formik.values.csu_info.length > 9) return;
    formik.setFieldValue('csu_info', [...formik.values.csu_info, formItem]);
  };

  const [forceRenderKey, setForceRenderKey] = React.useState(0);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const dataList = reorder(formik.values.csu_info, result.source.index, result.destination.index);
    formik.setFieldValue('csu_info', [...dataList]);

    setForceRenderKey(forceRenderKey + 1);
  };

  const setData = (data: FormItem[]) => {
    formik.setFieldValue('csu_info', [...data]);
  };
  let data = formik.values.csu_info;
  // Auto save function ==========
  const notInitialRender = React.useRef(false);
  React.useEffect(() => {
    debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (notInitialRender.current) {
        if (!loading) formik.handleSubmit();
      } else {
        notInitialRender.current = true;
      }
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[]}
        title="College Level Examination Program"
        process={1}
        icon={<CalculateOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2" id="General">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">College Level Examination Program</div>
              <DragDropContext onDragEnd={onDragEnd} key={forceRenderKey}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {formik.values.csu_info.map((item, index) => (
                        <div key={index + '-' + formik.values.csu_info.length}>
                          <Draggable
                            draggableId={index + '-' + formik.values.csu_info.length}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  key={index + '-' + formik.values.csu_info.length}
                                  className="position-relative"
                                >
                                  <CLEPConfig formik={formik} key={index} index={index} />
                                  <RemoveCircleOutlineIcon
                                    className="position-absolute text-field-light pointer"
                                    style={{ right: '-24px', top: '12px' }}
                                    onClick={() => {
                                      const newACTData = [...data];
                                      newACTData.splice(index, 1);
                                      setData(newACTData);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </div>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="my-3 w-100 d-flex flex-column align-items-center">
                <Button variant="primary" className="p-2 px-5" onClick={handleAdd}>
                  Add CLEP Test
                </Button>
              </div>
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {testing.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default CLEP;
