import { checkCodeForgotPassword, setVerifyCodeRedux } from 'app/authorization/authorizationSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import VerifyCodeInput from 'components/input/VerifyCodeInput';
import React from 'react';
import { Button } from 'react-bootstrap';

interface Props {
  setStep: (values: any) => void;
}

const FormAuthEmail = (props: Props) => {
  const [verifyCode, setVerifyCode] = React.useState<string | undefined>(undefined);
  const dispatch = useAppDispatch();
  const authorizationStore = useAppSelector((store) => store.authorization);
  React.useEffect(() => {}, [authorizationStore.myAuthorizationEmail]);
  const sendVerifyCode = async () => {
    if (!verifyCode) return;
    const res = await dispatch(
      checkCodeForgotPassword({
        email: authorizationStore.myAuthorizationEmail,
        verification_code: verifyCode,
      })
    );
    if (res.payload) {
      dispatch(setVerifyCodeRedux(verifyCode));
      props.setStep('inputNewPassword');
    }
  };
  return (
    <>
      <h2 className="m-3">Restore my password</h2>
      <div className=" d-flex justify-content-between align-items-center">
        <h5 className="m-3">
          Introduce the code we sent you to your email to restore your password
        </h5>
      </div>
      <div className="m-3" style={{ maxWidth: '400px' }}>
        <VerifyCodeInput onChange={(value) => setVerifyCode(value.join(''))} />
        <Button className="w-100 p-2 mt-5" variant="primary" onClick={() => sendVerifyCode()}>
          <span className="h5 fw-bold">Verify Code</span>
        </Button>
      </div>
    </>
  );
};

export default FormAuthEmail;
